import { Modal } from '../Modal/Modal';
import { InputSelect } from '../InputSelect/InputSelect';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Measure } from '../../models/Measure/Types';
import { DownloadButton } from '../DownloadButton/DownloadButton';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import './ExportPresenceModal.css';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { throwInfo } from '../../models/Toasts/Toasts';

/**
 * Props for the ExportPresenceModal component.
 */
interface ExportPresenceModalProps {
    // The measure the presences should be exported from
    measure: Measure;

    // The participants the presences should be exported from
    participants: ParticipantWithPresence[];

    // Indicates whether the modal should be shown
    show: boolean;

    // Called if the modal was closed
    onClose: () => void;
}

/**
 * Shows a modal to export presences.
 *
 * @param props
 * @constructor
 */
export const ExportPresenceModal = (props: ExportPresenceModalProps) => {
    const [measureTimePeriod, setMeasureTimePeriod] = useState<
        { value: string; month: number; year: number; id: number }[]
    >([]);
    const [exportData, setExportData] = useState<{
        clientId: number[] | undefined;
        startMonth: number | null;
        startYear: number | null;
        endMonth: number | null;
        endYear: number | null;
    }>({
        clientId: undefined,
        startMonth: new Date(props.measure.startAt).getMonth() + 1,
        startYear: new Date(props.measure.startAt).getFullYear(),
        endMonth: new Date().getMonth() + 1,
        endYear: new Date().getFullYear()
    });
    const [participantClients, setParticipantClients] = useState<string[]>(['']);
    const [inputSelectValues, setInputSelectValues] = useState<{ start: string; end: string }>();

    /**
     * Change start date of export data
     * @param value
     */
    const onChangeStart = (value: number | undefined) => {
        if (!value && value != 0) return;

        const month = measureTimePeriod?.find((measureTimePeriod) => measureTimePeriod.id === value)?.month;
        const year = measureTimePeriod?.find((measureTimePeriod) => measureTimePeriod.id === value)?.year;

        if (
            exportData.endMonth != null &&
            exportData.endYear != null &&
            year != null &&
            month?.toLocaleString() != null &&
            (year < exportData.endYear || (year === exportData.endYear && month <= exportData.endMonth))
        ) {
            setExportData({
                ...{
                    startMonth: month,
                    startYear: year,
                    endMonth: exportData.endMonth,
                    endYear: exportData.endYear,
                    clientId: exportData.clientId
                }
            });
        } else {
            throwInfo('Das Anfangsdatum darf nicht hinter dem Enddatum liegen');
        }
    };

    /**
     * Change end date of export data
     * @param value
     */
    const onChangeEnd = (value: number | undefined) => {
        if (!value && value != 0) return;

        const month = measureTimePeriod?.find((measureTimePeriod) => measureTimePeriod.id === value)?.month;
        const year = measureTimePeriod?.find((measureTimePeriod) => measureTimePeriod.id === value)?.year;

        if (
            exportData.startMonth != null &&
            exportData.startYear != null &&
            year != null &&
            month?.toLocaleString() != null &&
            (year > exportData.startYear || (year === exportData.startYear && month >= exportData.startMonth))
        ) {
            setExportData({
                ...{
                    endMonth: month,
                    endYear: year,
                    startMonth: exportData.startMonth,
                    startYear: exportData.startYear,
                    clientId: exportData.clientId
                }
            });
        } else {
            throwInfo('Das Enddatum darf nicht vor dem Anfangsdatum liegen');
        }
    };

    /**
     * Change clientIds of export data (clients that are associated with participants and therefore corresponding participats should be printed)
     * @param client
     */
    const onChangeClientId = (client: string | undefined) => {
        if (!client) return;

        const participants = props.participants.filter((participant) => participant.participantClient.name === client);
        setExportData({
            ...{
                endMonth: exportData.endMonth,
                endYear: exportData.endYear,
                startMonth: exportData.startMonth,
                startYear: exportData.startYear,
                clientId: participants.map((participant) => {
                    return participant.participantClient.id;
                })
            }
        });
    };

    /**
     * on initial render filter clients
     */
    useEffect(() => {
        const clients: string[] = [];
        props.participants.forEach((participant) => {
            if (!clients.some((client) => client === participant.participantClient.name)) {
                clients.push(participant.participantClient.name);
            }
        });
        setParticipantClients(clients);
    }, [props.participants]);
    useMemo(
        () => [
            { value: 'Januar', id: 1 },
            { value: 'Februar', id: 2 },
            { value: 'März', id: 3 },
            { value: 'April', id: 4 },
            { value: 'Mai', id: 5 },
            { value: 'Juni', id: 6 },
            { value: 'Juli', id: 7 },
            { value: 'August', id: 8 },
            { value: 'September', id: 9 },
            { value: 'Oktober', id: 10 },
            { value: 'November', id: 11 },
            { value: 'Dezember', id: 12 }
        ],
        []
    );
    const createMeasureTimePeriod = useCallback(() => {
        const months = [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember'
        ];

        const measureTimePeriodObject = [];
        if (new Date(props.measure.startAt).getFullYear() < new Date().getFullYear()) {
            const startYear = new Date(props.measure.startAt).getFullYear();
            const currentYear = new Date().getFullYear();
            let startMonth = new Date(props.measure.startAt).getMonth();
            while (startMonth <= 11) {
                measureTimePeriodObject.push({
                    value: `${months[startMonth]} ${startYear}`,
                    month: startMonth,
                    year: startYear,
                    id: measureTimePeriodObject.length
                });
                startMonth++;
            }

            for (let i = 0; i <= 11; i++) {
                measureTimePeriodObject.push({
                    value: `${months[i]} ${currentYear}`,
                    month: i,
                    year: currentYear,
                    id: measureTimePeriodObject.length
                });
            }
        } else {
            const currentYear = new Date().getFullYear();
            let startMonth = new Date(props.measure.startAt).getMonth();
            while (startMonth <= 11) {
                measureTimePeriodObject.push({
                    value: `${months[startMonth]} ${currentYear}`,
                    month: startMonth,
                    year: currentYear,
                    id: measureTimePeriodObject.length
                });
                startMonth++;
            }
        }

        setMeasureTimePeriod(measureTimePeriodObject);
    }, [props.measure.startAt]);

    useEffect(() => {
        createMeasureTimePeriod();
    }, [createMeasureTimePeriod]);

    /**
     * Refresh the new values for the InputSelect.
     */
    const refreshInputSelectValues = useCallback(() => {
        const start = measureTimePeriod.find(
            (timePeriod) => timePeriod.month === exportData.startMonth && timePeriod.year === exportData.startYear
        )?.value;

        const end = measureTimePeriod.find(
            (timePeriod) => timePeriod.month === exportData.endMonth && timePeriod.year === exportData.endYear
        )?.value;

        if (!start || !end) return;
        setInputSelectValues({ start: start, end: end });
    }, [measureTimePeriod, exportData]);

    /**
     * Set the values for InputSelect, when the exportData values was changed.
     */
    useEffect(() => {
        refreshInputSelectValues();
    }, [refreshInputSelectValues]);

    /**
     * The string of the start date to export the data.
     */
    const fromDateString: string = useMemo(() => {
        if (exportData.startMonth?.toLocaleString()) {
            return `${exportData.startYear}-${exportData.startMonth + 1 || 1}-01`;
        } else {
            return '';
        }
    }, [exportData]);

    /**
     * The string of the end date to export the data.
     */
    const toDateString: string = useMemo(() => {
        // Yupp, 0 day of the month is the last of the previous moth,... javascript... no comments
        if (exportData.endYear && exportData.endMonth?.toLocaleString()) {
            return `${exportData.endYear}-${exportData.endMonth + 1 || 12}-${new Date(
                exportData.endYear,
                exportData.endMonth + 1 || 12,
                0
            ).getDate()}`;
        } else {
            return '';
        }
    }, [exportData]);

    return (
        <Modal show={props.show} header={'Anwesenheit drucken'}>
            {props.measure ? (
                <div className="export-presence-modal-body">
                    <InputSelect
                        label={'Auftraggeber'}
                        dropdownOptions={participantClients.map((client) => {
                            return { value: client, id: client };
                        })}
                        onChange={onChangeClientId}
                    />
                    <div className="export-presence-modal-body-dates">
                        <InputSelect
                            label={'Von'}
                            dropdownOptions={measureTimePeriod?.map((timePeriod) => {
                                return { value: timePeriod.value, id: timePeriod.id };
                            })}
                            onChange={onChangeStart}
                            value={inputSelectValues?.start}
                        />
                        <InputSelect
                            label={'Bis'}
                            dropdownOptions={measureTimePeriod?.map((timePeriod) => {
                                return { value: timePeriod.value, id: timePeriod.id };
                            })}
                            onChange={onChangeEnd}
                            value={inputSelectValues?.end}
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button
                        buttonStyle={'outline'}
                        size={'medium'}
                        type={'secondary'}
                        text={'Abbrechen'}
                        onClick={props.onClose}
                    />
                    {props.measure?.id && (
                        <DownloadButton
                            targetPath={`pdf_creation/presence_list?measure_id=${
                                props.measure?.id
                            }&from=${fromDateString}&to=${toDateString}${
                                exportData.clientId
                                    ? `&participant_client_ids=[${exportData.clientId.map((id) => `${id}`)}]`
                                    : ''
                            }`}
                            filename={`Anwesenheitsliste_${props.measure?.id || ''}.pdf`}
                            type={'primary'}
                            size={'medium'}
                            buttonStyle={'outline'}
                            text={'Exportieren'}
                            firstIcon={<Icon type={'Download'} />}
                            className="statistics-print-button"
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};
