import { AppNavigationSubMenu } from '../AppNavigationSubMenu/AppNavigationSubMenu';
import { Button } from '../Button/Button';
import React, { useState } from 'react';
import { DropDown } from '../DropDown/DropDown';
import { Icon } from '../Icon/Icon';
import { CoursesPrintModal } from '../CoursesPrintModal/CoursesPrintModal';

/**
 * Menu bar shown in the measure management view. (Not administrative)
 *
 * @constructor
 */
export const UserMeasureManagementSubMenu = () => {
    const [showCoursesPrintModal, setShowCoursesPrintModal] = useState<boolean>(false);

    /**
     * Called if the user clicks the button to print courses.
     * Opens the modal to select the courses to print.
     */
    const onClickPrintCourses = () => {
        setShowCoursesPrintModal(true);
    };

    /**
     * Called if the modal to print the courses sends the signal to close the modal.
     * Closes the modal.
     */
    const onClosePrintCoursesModal = () => {
        setShowCoursesPrintModal(false);
    };

    return (
        <div className="sub-menu">
            <div className="sub-menu-head">
                <h6>Verwaltung</h6>
            </div>
            <div className="sub-menu-items">
                <AppNavigationSubMenu items={['Betriebe', 'Briefvorlagen', 'Statistik', 'Qualifizierungsangebote']} />
                <div className="sub-menu-items-dropdown">
                    <DropDown
                        title={
                            <Button
                                type={'secondary'}
                                size={'small'}
                                buttonStyle={'filled'}
                                text={'Drucken'}
                                secondIcon={<Icon type={'ChevronDown'} />}
                            />
                        }
                        alignItemsRight={true}
                    >
                        <div onClick={onClickPrintCourses}>Qualifizierungsangebote</div>
                    </DropDown>
                </div>
            </div>

            {showCoursesPrintModal && <CoursesPrintModal onClose={onClosePrintCoursesModal} />}
        </div>
    );
};
