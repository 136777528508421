import { PresenceType } from '../../models/Presence/Types';
import './Attendance.css';

/**
 * Props for the Attendance component.
 */
export interface AttendanceProps {
    // state that defines the attendance
    category: PresenceType | undefined;

    // text that is above the box
    label?: string;

    // Called if the user clicks the attendance
    onClick?: (state: PresenceType | undefined) => void;
}

export const Attendance = (props: AttendanceProps) => {
    /**
     * On click callback to mark as selected or not
     */
    const onClickAttendance = () => {
        if (props.onClick) {
            props.onClick(props.category);
        }
    };

    const attendanceMap = {
        present: 'A',
        delayed: 'A+',
        absent_unexplained: 'UE',
        absent_explained: 'E',
        instruction_free_time: 'U',
        sickness: 'K',
        other: 'S',
        interview: 'VG',
        practicum: 'P',
        measure_end: 'M'
    };

    return (
        <div className={'attendance-container'} onClick={onClickAttendance}>
            <div className="attendance-label">{props.label}</div>
            <div className={`attendance attendance-${props.category === undefined ? 'null' : props.category}`}>
                {props.category && attendanceMap[props.category]}
            </div>
        </div>
    );
};
