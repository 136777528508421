import { LoginForm } from '@d4us1/ez-on-rails-react';
import React from 'react';
import SoRocketImg from '../../assets/img/sorocket_platform_logo.png';
import { AppRoutes } from '../../configuration/AppRoutes';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import { UserPageLinks } from '../../components/UserPageLinks/UserPageLinks';

/**
 * Page that shows up the login screen.
 * After successfull login, the user will be saved to the redux state and redirected to the welcome page.
 */
export const LoginPage = () => {
    const navigate = useNavigate();

    /**
     * Called if the login fails. Shows up an error message.
     */
    const onLoginError = () => {
        alert('Login fehlgeschlagen');
    };

    /**
     * Called after the login was successfull.
     * Creates a user object and saves it to the redux state.
     * Also saves the authInfo for the next request to the redux state.
     *
     * @param email
     *
     */
    const onLoginSuccess = async () => {
        navigate(AppRoutes.tasks);
    };

    return (
        <div className="login-page-container">
            <div className="login-page">
                <h3>Willkommen bei</h3>
                <img src={SoRocketImg} alt={'platform logo'} />
                <LoginForm
                    onLoginError={onLoginError}
                    onLoginSuccess={onLoginSuccess}
                    fieldLabelClassName={'login-page-container-label'}
                    fieldInputClassName={'login-page-container-input'}
                    fieldContainerClassName={'login-page-container-input-wrapper'}
                    submitButtonContainerClassName={'login-page-container-button-login'}
                    submitButtonClassName={'login-page-button-login'}
                    labelSubmitButton={'Einloggen'}
                    hideStayLoggedIn={true}
                    fieldErrorClassName={'login-page-error-text'}
                    labelEmail={'E-Mail'}
                    containerClassName={'login-page-container-field'}
                />
                <UserPageLinks lostPassword={true} />
            </div>
        </div>
    );
};
