import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { useAppSelector } from '../../hooks';
import { NeedForAction, NeedForActionSuggestion, NeedForActionSuggestionTypes } from '../../models/NeedForAction/Type';
import { ObjectiveAgreementWithNeedForActionsAndSupportPlan } from '../../models/ObjectiveAgreement/Type';
import { Button } from '../Button/Button';
import { CreateNeedForAction } from '../CreateNeedForAction/CreateNeedForAction';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { NeedForActionCard } from '../NeedForAction/NeedForActionCard';
import { ObjectList } from '../ObjectList/ObjectList';
import { ObjectListBody } from '../ObjectListBody/ObjectListBody';
import { ObjectListItem } from '../ObjectListItem/ObjectListItem';
import { UpdateNeedForAction } from '../UpdateNeedForAction/UpdateNeedForAction';
import './ParticipantNeedForActionModal.css';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { formatDate } from '../../models/Date/Date';

interface ParticipantNeedForActionModalProps {
    show: boolean;
    onClose: () => void;
}

export const ParticipantNeedForActionModal = (props: ParticipantNeedForActionModalProps) => {
    const participantId = useAppSelector((x) => x.participant.selectedParticipant?.id);
    const measureId = useAppSelector((x) => x.measure.selectedMeasure?.id);
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [createNeedForAction, setCreateNeedForAction] = useState<{
        action: NeedForActionSuggestionTypes;
        suggestion: NeedForActionSuggestion[] | undefined;
    }>();
    const [category, setCategory] = useState<string>('Qualifikation');
    const [selection, setSelection] = useState<NeedForActionSuggestion[]>();
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { data: suggestions } = useSWR<NeedForActionSuggestion[]>([
        backendUrl,
        'need_for_actions/suggestions',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: needForActions } = useSWR<NeedForAction[]>(
        [
            backendUrl,
            `participants/${participantId}/need_for_actions`,
            'GET',
            { measureId: measureId },
            authInfo,
            apiVersion
        ],
        { refreshInterval: 10000 }
    );
    const { data: rawObjectiveAgreements } = useSWR<ObjectiveAgreementWithNeedForActionsAndSupportPlan[]>([
        backendUrl,
        `participants/${participantId}/objective_agreements`,
        'GET',
        { measureId: measureId },
        authInfo,
        apiVersion
    ]);
    const [openExistingNeedForAction, setOpenExistingNeedForAction] = useState<NeedForAction>();
    const [searchValue, setSearchValue] = useState<string>('');

    /**
     * Called if the objectiveAgreements were fetched.
     * Converts the names from the objectiveAgreements support plans to strings if they are dates.
     * This is needed because the ez-on-rails-react client will automatically convert strings having a date format
     * to a date object. Since the support plans are named like dates here, we need to convert it.
     */
    const objectiveAgreements = useMemo(() => {
        if (!rawObjectiveAgreements) return null;

        return rawObjectiveAgreements.map((objectiveAgreement) => {
            if (typeof objectiveAgreement.supportPlan.name === 'string') {
                return objectiveAgreement;
            } else {
                return {
                    ...objectiveAgreement,
                    supportPlan: {
                        ...objectiveAgreement.supportPlan,
                        name: formatDate(objectiveAgreement.supportPlan.name)
                    }
                };
            }
        });
    }, [rawObjectiveAgreements]);

    /**
     * Open the search bar
     */
    const onClickOpenSearch = () => {
        setOpenSearch(!openSearch);
    };

    /**
     * Open existing need for action
     * @param needForAction
     */
    const onOpenExistingNeedForAction = (needForAction: NeedForAction | undefined) => {
        if (!needForAction) return;

        setOpenExistingNeedForAction(needForAction);
    };

    /**
     * Manual search
     * @param event
     * @param value
     */
    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    /**
     * Change which need for actions should be shown to the user based on the search value input
     */
    useEffect(() => {
        const filter: NeedForActionSuggestion[] = [];
        // create the filtered items based on the user's input
        suggestions?.forEach((suggestion) => {
            const elements = suggestion.needForActionTypes.filter((actionType) =>
                actionType.needForActionType.toLowerCase().includes(searchValue?.toLowerCase())
            );
            if (elements.length > 0) {
                filter.push({ category: suggestion.category, needForActionTypes: elements });
            }
        });
        if (filter.length === 1) {
            setCategory(filter[0].category);
        }

        setSelection(filter);
    }, [searchValue, suggestions]);

    /**
     * Filter state that should be updated when the suggestions are fetched
     */
    useEffect(() => {
        setSelection(suggestions);
    }, [suggestions]);

    /**
     * Create new need for action
     * @param action
     * @param suggestion
     */
    const onCreateNeedForAction = (
        nfa: NeedForAction | undefined,
        action: NeedForActionSuggestionTypes | undefined,
        suggestion: NeedForActionSuggestion[] | undefined
    ) => {
        if (!action) return;

        setCreateNeedForAction({ action: action, suggestion: suggestion });
    };

    /**
     * Render the suggestions so that the user can select them. If a need for action of a specific category already exists,
     * this need for action should be rendered
     */
    const renderNeedForActions = () => {
        const suggestion = selection?.filter((suggestion) => suggestion.category === category);
        return suggestion && suggestion.length > 0
            ? suggestion[0].needForActionTypes.map((action, index) => {
                  if (
                      needForActions?.some(
                          (needForAction: NeedForAction) => needForAction.needForActionType === action.needForActionType
                      ) &&
                      needForActions?.some(
                          (needForAction: NeedForAction) =>
                              needForAction.needForActionType === action.needForActionType && !needForAction.closed
                      )
                  ) {
                      return (
                          <NeedForActionCard
                              key={index}
                              objectiveAgreement={objectiveAgreements?.filter(
                                  (agreement) =>
                                      agreement.needForAction.id ===
                                      needForActions?.find(
                                          (needForAction) =>
                                              needForAction.needForActionType === action.needForActionType
                                      )?.id
                              )}
                              onClick={onOpenExistingNeedForAction}
                              needForAction={needForActions?.find(
                                  (needForAction) =>
                                      needForAction.needForActionType === action.needForActionType &&
                                      !needForAction.closed
                              )}
                              type={action}
                          />
                      );
                  } else {
                      return (
                          <NeedForActionCard
                              key={index}
                              onClick={onCreateNeedForAction}
                              suggestion={suggestion}
                              type={action}
                          />
                      );
                  }
              })
            : null;
    };

    const onClickCategory = (id: number | string, category: string) => {
        setCategory(category);
    };

    const onClose = () => {
        if (createNeedForAction) {
            setCreateNeedForAction(undefined);
        } else if (openExistingNeedForAction) {
            setOpenExistingNeedForAction(undefined);
        }

        props.onClose();
    };

    const onReset = () => {
        if (createNeedForAction) {
            setCreateNeedForAction(undefined);
        } else if (openExistingNeedForAction) {
            setOpenExistingNeedForAction(undefined);
        }
    };

    return props.show && !createNeedForAction && !openExistingNeedForAction ? (
        <div className={'participant-need-for-action-modal-container'}>
            <div className="qm-document-template-form-background" />
            <div className="create-task-container">
                <div className="create-tasks">
                    <div className="participant-need-for-action-modal">
                        <div className="participant-need-for-action-modal-left-side">
                            <ObjectList>
                                <ObjectList.Head>
                                    <div className="measure-list-head-container">
                                        <div className="measure-list-head">
                                            <div>Handlungsfelder</div>
                                            <div className="measure-list-head-search">
                                                <Button
                                                    type={'secondary'}
                                                    buttonStyle={'link'}
                                                    size={'small'}
                                                    firstIcon={<Icon type={'Search'} />}
                                                    onClick={onClickOpenSearch}
                                                />
                                            </div>
                                        </div>
                                        {openSearch && (
                                            <Input
                                                icon={<Icon type={'Search'} />}
                                                value={searchValue}
                                                placeholder={'Suchen'}
                                                onChange={onChangeSearch}
                                            />
                                        )}
                                    </div>
                                </ObjectList.Head>
                                <ObjectListBody>
                                    {selection?.map((suggestion, index) => {
                                        return (
                                            <ObjectListItem
                                                key={index}
                                                value={{ index: index, item: suggestion.category }}
                                                selected={category === suggestion.category}
                                                onClick={onClickCategory}
                                            >
                                                <div key={index}>{suggestion.category}</div>
                                            </ObjectListItem>
                                        );
                                    })}
                                </ObjectListBody>
                            </ObjectList>
                        </div>
                        <div className="participant-need-for-action-modal-right-side">{renderNeedForActions()}</div>
                    </div>
                    <div className="participant-need-for-action-modal-footer">
                        <Button
                            type={'primary'}
                            size={'medium'}
                            buttonStyle={'filled'}
                            text={'Abbrechen'}
                            onClick={onClose}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : props.show && createNeedForAction ? (
        <CreateNeedForAction
            onClose={onClose}
            multipleAnswers={createNeedForAction.action?.needForActionType.includes('Mehrfachnennung möglich')}
            needForAction={createNeedForAction}
            onGoBack={onReset}
        />
    ) : props.show && openExistingNeedForAction ? (
        <UpdateNeedForAction
            onClose={onClose}
            multipleAnswers={openExistingNeedForAction.needForActionType.includes('Mehrfachnennung möglich')}
            objectiveAgreements={objectiveAgreements?.filter(
                (agreement) => agreement.needForAction.id === openExistingNeedForAction?.id
            )}
            correspondingSuggestion={suggestions
                ?.find((suggestion) =>
                    suggestion.needForActionTypes.find(
                        (type) => type.needForActionType === openExistingNeedForAction?.needForActionType
                    )
                )
                ?.needForActionTypes.find(
                    (type) => type.needForActionType === openExistingNeedForAction?.needForActionType
                )}
            needForAction={openExistingNeedForAction}
            onGoBack={onReset}
        />
    ) : null;
};
