import React, { ChangeEvent, FocusEvent, ClipboardEvent, Ref } from 'react';
import { Link } from 'react-router-dom';
import { Icon, IconType } from '../Icon/Icon';
import { Label } from '../Label/Label';
import './FormInput.css';
import { KeyboardEvent } from 'react';
import { FormikHandleChange } from '../../models/Formik/Type';

interface FormInputProps {
    // components size
    size: 'small' | 'medium' | 'large';
    // callback for click event
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    // custom styling of input field
    className?: string;
    // error message and triggers error styling
    error?: string;
    // Label or Link
    children?: React.ReactElement | React.ReactElement[];
    // small helper text underneath the input field
    helperText?: string;
    // value that is being hold
    value?: string | number;
    // placeholder that will be shown inside the input field
    placeholder?: string;
    // if input field is required
    required?: boolean;
    // field name for e.g. Formik
    name?: string;
    onClick?: () => void;
    ref?: Ref<HTMLDivElement>;
    readonly?: boolean;
    autoFocus?: boolean;
    onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
    iconRight?: IconType;
    iconLeft?: IconType;
    handleChange?: FormikHandleChange;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    onPaste?: (event: ClipboardEvent) => void;
    onClickIconLeft?: () => void;
    onClickIconRight?: () => void;
    tabindex?: number;
    type?: string;
}

export const FormInput = (props: FormInputProps) => {
    return (
        <div className="form-input" onClick={props.onClick} ref={props.ref}>
            {props.children ? (
                Array.isArray(props.children) ? (
                    props.children.some((child) => child.type === Link || child.type === Label) ? (
                        <div className="form-input-top">
                            {props.children?.find((child) => child.type === Label)}
                            {props.children?.find((child) => child.type === Link)}
                        </div>
                    ) : null
                ) : props.children && props.children.type === Label ? (
                    <div className="form-input-top">{props.children}</div>
                ) : null
            ) : null}
            <div
                className={`form-input-field ${props.iconRight && 'form-input-field-icon-right'} ${
                    props.iconLeft && 'form-input-field-icon-left'
                }`}
            >
                <Icon
                    type={props.iconLeft}
                    className="form-input-field-icon-left-icon"
                    onClick={props.onClickIconLeft}
                />
                <input
                    type={props.type}
                    tabIndex={props.tabindex}
                    onPaste={props.onPaste}
                    autoFocus={props.autoFocus}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    readOnly={props.readonly}
                    className={`${props.size} ${props.className} ${props.value && 'value'} ${props.error && 'error'}`}
                    onChange={props.onChange}
                    value={props.value}
                    placeholder={props.placeholder}
                    required={props.required}
                    name={props.name}
                    onKeyDown={props.onKeyDown}
                />
                <Icon
                    type={props.iconRight}
                    className="form-input-field-icon-right-icon"
                    onClick={props.onClickIconRight}
                />
            </div>
            {(props.helperText || props.error) && (
                <div className="input-helper-error-container">
                    <div className="helper">{props.helperText}</div>
                    <div className="error">{props.error}</div>
                </div>
            )}
        </div>
    );
};
