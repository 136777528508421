import { Formik } from 'formik';
import * as Yup from 'yup';
import { InternshipCompanySubmit } from '../../models/InternshipCompanies/Type';
import { CreateNewCompanyForm } from '../CreateNewCompanyForm/CreateNewCompanyForm';
import './CreateNewCompany.css';

interface CreateNewCompanyProps {
    onSubmit?: (values: InternshipCompanySubmit) => void;
    onClose: () => void;
    className?: string;
    submitError?: string;
    reloadCompanies: () => void;
}

export const CreateNewCompany = (props: CreateNewCompanyProps) => {
    const initialFormValues: InternshipCompanySubmit = {
        city: '',
        contact: '',
        email: '',
        fax: '',
        name: '',
        notes: '',
        phone: '',
        sector: '',
        state: '',
        streetAndNumber: '',
        zipCode: ''
    };
    const phoneRegx = /[0-9]/;
    const CreateNewCompanyValidationScheme = Yup.object()
        .shape({
            name: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            contact: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            city: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            email: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .email('Bitten geben Sie eine gültige E-Mail Adresse ein'),
            fax: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .matches(phoneRegx, 'Dies scheint keine gültige Faxnummmer zu sein'),
            phone: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .matches(phoneRegx, 'Dies scheint keine gültige Telefonnummer zu sein'),
            state: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            streetAndNumber: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            ziCode: Yup.string().required('Dieses Feld muss ausgefüllt sein.')
        })
        .defined();

    return (
        <Formik
            initialValues={initialFormValues}
            validateOnMount={false}
            enableReinitialize={false}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={CreateNewCompanyValidationScheme}
            onSubmit={() => console.log()}
        >
            {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                touched,
                validateForm,
                handleBlur,
                setTouched,
                handleReset
            }) => (
                <CreateNewCompanyForm
                    reloadCompanies={props.reloadCompanies}
                    onClose={props.onClose}
                    submitError={props.submitError}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    handleReset={handleReset}
                    validateForm={validateForm}
                    setTouched={setTouched}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    values={values}
                />
            )}
        </Formik>
    );
};
