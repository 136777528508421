import { ListElement, ListElementProps } from '../ListElement/ListElement';
import './List.css';

interface ListProps {
    options: ListElementProps | ListElementProps[] | undefined;
    readonly?: boolean;
    className?: string;
}

export const List = (props: ListProps) => {
    return (
        <div className={`list ${props.className}`}>
            {' '}
            {props.options &&
                Array.isArray(props.options) &&
                props.options.map((option: ListElementProps, index) => {
                    return (
                        <ListElement
                            onKeyDown={option.onKeyDown}
                            error={option.error}
                            input={option.input}
                            valueClassName={option.valueClassName}
                            readOnly={props.readonly || option.readOnly}
                            onChange={option.onChange}
                            name={option.name}
                            onBlur={option.onBlur}
                            value={option.value}
                            key={index}
                            label={option.label}
                        />
                    );
                })}
        </div>
    );
};
