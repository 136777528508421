import { UserObject } from '../models/Employee/Types';
import { Measure, UserMeasure } from '../models/Measure/Types';
import { CreatableUser, CreatedUser, UserGroup } from '../models/User/Types';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Returns methods to communicate with the backend related to users.
 */
export const useUsersApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * Gets the profile of the current signed in user.
     */
    const apiGetOwnProfile = useCallback(async (): Promise<UserObject> => {
        return call<void, UserObject>(`users/own_profile`, 'GET');
    }, [call]);

    /**
     * Get user information based on ID
     *
     * @param id
     */
    const apiGetUser = useCallback(
        async (id: number): Promise<UserObject> => {
            return call<void, UserObject>(`users/${id}/profile`, 'GET');
        },
        [call]
    );

    /**
     * Get all active measures to a specific user id
     *
     * @param id
     */
    const apiGetUserActiveMeasure = useCallback(
        async (id: number): Promise<Measure[]> => {
            return call<void, Measure[]>(`users/${id}/active_in_measures`, 'GET');
        },
        [call]
    );

    /**
     * Get all archived measures to a specific user id
     *
     * @param id
     */
    const apiGetUserArchivedMeasure = useCallback(
        async (id: number): Promise<Measure[]> => {
            return call<void, Measure[]>(`users/${id}/archived_in_measures`, 'GET');
        },
        [call]
    );

    /**
     * Update the user profile
     *
     * @param id
     * @param profile
     */
    const apiUpdateUser = useCallback(
        async (id: number, profile: Partial<UserObject>) => {
            return call<{ profile: Partial<UserObject> }, unknown>(`users/${id}/profile`, 'PUT', { profile });
        },
        [call]
    );

    /**
     * Create a new user
     *
     * @param user
     */
    const apiCreateUser = useCallback(
        async (user: CreatableUser): Promise<CreatedUser> => {
            return call<CreatableUser, CreatedUser>('users', 'POST', user);
        },
        [call]
    );

    /**
     * API to delete user from a measure
     *
     * @param measureId
     * @param userId
     */
    const apiRemoveUserFromMeasure = useCallback(
        async (measureId: number, userId: number) => {
            return call<void, unknown>(`measures/${measureId}/users/${userId}`, 'DELETE');
        },
        [call]
    );

    /**
     * API to archive user in measure
     *
     * @param measureId
     * @param userId
     */
    const apiArchiveUserInMeasure = useCallback(
        async (measureId: number, userId: number) => {
            if (!measureId) return;

            return call<void, unknown>(`measures/${measureId}/users/${userId}/archive`, 'DELETE');
        },
        [call]
    );

    /**
     * API to assign a group to an user
     *
     * @param userId
     * @param group
     */
    const apiAssignUserToGroup = useCallback(
        async (userId: number, group: UserGroup) => {
            return call<{ group: UserGroup }, unknown>(`users/${userId}/add_group`, 'POST', { group: group });
        },
        [call]
    );

    /**
     * API to remove user from his group
     *
     * @param userId
     * @param group
     */
    const apiRemoveUserFromGroup = useCallback(
        async (userId: number | undefined, group: UserGroup) => {
            return call<void, unknown>(`users/${userId}/remove_group?group=${group}`, 'DELETE');
        },
        [call]
    );

    /**
     * API to archive user
     *
     * @param userId
     */
    const apiArchiveUser = useCallback(
        async (userId: number) => {
            return call<void, unknown>(`users/${userId}/archive`, 'DELETE');
        },
        [call]
    );

    /**
     * Activate an archived user again
     *
     * @param userId
     */
    const apiActivateUserAgain = useCallback(
        async (userId: number) => {
            return call<void, unknown>(`users/${userId}/activate`, 'POST');
        },
        [call]
    );

    /**
     * API get all user_measures of an user
     *
     * @param userId
     */
    const apiGetAllMeasureUsersOfUser = useCallback(
        async (userId: number): Promise<UserMeasure[]> => {
            return call<void, UserMeasure[]>(`measures_users/of_user/${userId},`, 'GET');
        },
        [call]
    );

    return {
        apiGetOwnProfile: apiGetOwnProfile,
        apiGetUser: apiGetUser,
        apiGetUserActiveMeasure: apiGetUserActiveMeasure,
        apiGetUserArchivedMeasure: apiGetUserArchivedMeasure,
        apiUpdateUser: apiUpdateUser,
        apiCreateUser: apiCreateUser,
        apiRemoveUserFromMeasure: apiRemoveUserFromMeasure,
        apiArchiveUserInMeasure: apiArchiveUserInMeasure,
        apiAssignUserToGroup: apiAssignUserToGroup,
        apiRemoveUserFromGroup: apiRemoveUserFromGroup,
        apiArchiveUser: apiArchiveUser,
        apiActivateUserAgain: apiActivateUserAgain,
        apiGetAllMeasureUsersOfUser: apiGetAllMeasureUsersOfUser
    };
};
