import React, { ReactNode } from 'react';
import styles from './FullScreenModal.module.css';

/**
 * Props for the FullScreenModal component.
 */
interface FullScreenModalProps {
    // The content of the Modal
    children: ReactNode;

    //Optional footer shown at the end of the modal
    footer?: ReactNode;

    // The optional css classes that are passed to the container holding the content
    containerClassName?: string;

    // Optional css classes of the body container
    bodyClassName?: string;

    // Optional css classes of the footer container
    footerClassName?: string;
}

/**
 * Shows a modal being on the whole screen.
 *
 * @param props
 * @constructor
 */
export const FullScreenModal = (props: FullScreenModalProps) => {
    return (
        <div className={styles.outerContainer}>
            <div className={`${styles.container} ${props.containerClassName || ''}`}>
                <div>
                    <div className={`${styles.bodyContainer} ${props.bodyClassName || ''}`}>{props.children}</div>
                    <div className={`${styles.footerContainer} ${props.footerClassName || ''}`}>{props.footer}</div>
                </div>
            </div>
        </div>
    );
};
