
const digest = 'be3385764f803aed5b82c967a07b82840fd87ab30a0e231580f1850553ee2747';
const css = `/* font *//* background *//* borders *//* fonts *//* paddings *//* margins and gaps *//* borders */

._container_1c0c5_6 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    border: grey 1px solid;
    border-radius: 0.6rem;
    max-width: 100%;
}

._editor_1c0c5_16 {
    padding: 0.5rem;
    overflow: scroll;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"colors":"\"../../shared/styles/colors.module.css\"","distances":"\"../../shared/styles/distances.module.css\"","colorBorderDark":"grey","sizeBorderSmall":"1px","radiusBorderMedium":"0.6rem","paddingMedium":"0.5rem","container":"_container_1c0c5_6","editor":"_editor_1c0c5_16"};
export { css, digest };
  