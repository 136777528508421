import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Measure } from '../../models/Measure/Types';
import { SideBarBody } from '../SideBarBody/SideBarBody';
import { SideBarFooter } from '../SideBarFooter/SideBarFooter';
import { SideBarHead } from '../SideBarHead/SideBarHead';
import './SideBar.css';
import { useAppDispatch } from '../../hooks';
import { setSelectedMeasure } from '../../models/Measure/Slice';

interface SideNavigationProps {
    openNavigation: boolean;
    onClickCloseSideNavigation: () => void;
    activeMeasures?: Measure[];
}

export const SideBar = ({ onClickCloseSideNavigation, openNavigation, activeMeasures }: SideNavigationProps) => {
    const [sidebarPosition, setSidebarPosition] = useState<number>(0);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [menuItemSelected, setMenuItemSelected] = useState<string>('');
    const dispatch = useAppDispatch();

    const onChangeMenuItem = (item: string) => {
        dispatch(setSelectedMeasure(undefined));
        setMenuItemSelected(item);
    };

    /**
     * Call back for close component to move the sidebar out of the viewport
     */
    const onClickCloseSidebar = useCallback(() => {
        onClickCloseSideNavigation();
    }, [onClickCloseSideNavigation]);

    const onClickOutside = useCallback(
        (event: MouseEvent) => {
            if (sidebarRef.current && sidebarRef.current.contains(event.target as Node)) {
                return;
            }

            if (sidebarPosition === 0) {
                onClickCloseSidebar();
            }
        },
        [onClickCloseSidebar, sidebarRef, sidebarPosition]
    );

    /**
     * Click on outside burger menu should trigger the menu to open
     */
    useLayoutEffect(() => {
        if (sidebarRef.current) {
            if (openNavigation) {
                setSidebarPosition(0);
            } else {
                setSidebarPosition(-284);
            }
        }
    }, [openNavigation]);

    /**
     * Register outside click to close the sidebar
     */
    useEffect(() => {
        document.addEventListener('click', onClickOutside);

        return () => {
            document.removeEventListener('click', onClickOutside);
        };
    }, [onClickOutside, sidebarPosition]);

    return (
        <div className="side-bar-container" style={{ top: 0, left: sidebarPosition }} ref={sidebarRef}>
            <SideBarHead
                onChangeMenuItem={onChangeMenuItem}
                menuItemSelected={menuItemSelected}
                onClickClose={onClickCloseSidebar}
            />
            <SideBarBody
                onChangeMenuItem={onChangeMenuItem}
                menuItemSelected={menuItemSelected}
                onClickClose={onClickCloseSidebar}
                activeMeasures={activeMeasures}
            />
            <SideBarFooter />
        </div>
    );
};
