import { FormikErrors } from 'formik';
import React from 'react';
import { formatDate } from '../../models/Date/Date';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { mapQmDocumentNeededAt } from '../../models/QmDocumentTemplate/QmDocumentTemplate';
import { QmDocumentTemplate, QmDocumentTemplateFormValues } from '../../models/QmDocumentTemplate/Types';
import { ActiveStorageDropzone } from '../ActiveStorageDropzone/ActiveStorageDropzone';
import { DropDown } from '../DropDown/DropDown';
import { List } from '../List/List';
import { ValueCheckbox } from '../ValueCheckbox/ValueCheckbox';

interface QmDocumentTemplatesFormProps {
    values: QmDocumentTemplate | QmDocumentTemplateFormValues;
    handleChange: FormikHandleChange;
    errors: FormikErrors<QmDocumentTemplateFormValues | QmDocumentTemplate>;
    setFieldValue: FormikSetFieldValue;
    isEdit?: boolean;
}

export const QmDocumentTemplatesForm = (props: QmDocumentTemplatesFormProps) => {
    const onChangeNeededAt = (value: string) => {
        props.setFieldValue('neededAt', value);
    };

    const onChangeHasCompanyInternshipFields = () => {
        props.setFieldValue('hasInternshipCompanyFields', !props.values.hasInternshipCompanyFields);
    };

    return (
        <>
            {'id' in props.values ? (
                <List
                    readonly={!props.isEdit}
                    options={[
                        {
                            label: 'Titel',
                            value: props.values.name,
                            name: 'name',
                            onChange: props.handleChange,
                            error: props.errors.name
                        },
                        {
                            label: 'Ausdruck',
                            error: props.errors.name,
                            input: (
                                <DropDown
                                    title={<div>{mapQmDocumentNeededAt(props.values.neededAt)}</div>}
                                    titleClassName={'dropdown-list-title'}
                                    disabled={!props.isEdit}
                                    className={'dropdown-list'}
                                >
                                    <div onClick={() => onChangeNeededAt('needed_at_request')}>
                                        {mapQmDocumentNeededAt('needed_at_request')}
                                    </div>
                                    <div onClick={() => onChangeNeededAt('needed_at_participant_creation')}>
                                        {mapQmDocumentNeededAt('needed_at_participant_creation')}
                                    </div>
                                </DropDown>
                            )
                        },
                        {
                            label: 'Zuletzt aktualisiert am',
                            value: formatDate(new Date(props.values.updatedAt)),
                            name: 'neededAt',
                            onChange: props.handleChange,
                            error: props.errors.name
                        },
                        {
                            label: 'Zuletzt aktualisiert von',
                            value: `${props.values.updatedBy.firstName} ${props.values.updatedBy.lastName}`,
                            name: 'neededAt',
                            onChange: props.handleChange,
                            error: props.errors.name
                        },
                        {
                            label: 'beinhaltet Informationen zu Praktikum',
                            input: (
                                <ValueCheckbox
                                    disabled={!props.isEdit}
                                    className={'editable-readonly-item'}
                                    checked={props.values.hasInternshipCompanyFields}
                                    onChange={onChangeHasCompanyInternshipFields}
                                    value={props.values.hasInternshipCompanyFields}
                                />
                            )
                        }
                    ]}
                />
            ) : (
                <List
                    readonly={!props.isEdit}
                    options={[
                        {
                            label: 'Titel',
                            value: props.values.name,
                            name: 'name',
                            onChange: props.handleChange,
                            error: props.errors.name
                        },
                        {
                            label: 'Ausdruck',
                            input: (
                                <DropDown
                                    title={<div>{mapQmDocumentNeededAt(props.values.neededAt)}</div>}
                                    titleClassName={'dropdown-list-title'}
                                    disabled={!props.isEdit}
                                    className={'dropdown-list'}
                                >
                                    <div onClick={() => onChangeNeededAt('needed_at_request')}>
                                        {mapQmDocumentNeededAt('needed_at_request')}
                                    </div>
                                    <div onClick={() => onChangeNeededAt('needed_at_participant_creation')}>
                                        {mapQmDocumentNeededAt('needed_at_participant_creation')}
                                    </div>
                                </DropDown>
                            )
                        },
                        {
                            label: 'beinhaltet Informationen zu Praktikum',
                            input: (
                                <ValueCheckbox
                                    disabled={!props.isEdit}
                                    checked={props.values.hasInternshipCompanyFields}
                                    onChange={onChangeHasCompanyInternshipFields}
                                    value={props.values.hasInternshipCompanyFields}
                                />
                            )
                        }
                    ]}
                />
            )}
            {props.isEdit && (
                <ActiveStorageDropzone
                    className={'qm-document-template-form-upload'}
                    onChange={(files) => props.setFieldValue('document', files.length > 0 ? files[0].signedId : '')}
                    attributeName="document"
                    textDropzone={props.isEdit ? 'Ändern Sie hier Ihre Datei' : 'Laden Sie hier eine neue Datei hoch'}
                    files={props.values.document ? [{ signedId: props.values.document }] : []}
                    multiple={false}
                    maxFiles={1}
                    onMaxFilesError={() => alert('Es ist nur eine Datei erlaubt')}
                    maxSize={10485760}
                    onMaxSizeError={() => alert('Die Datei ist zu groß. Sie darf max. 10MB groß sein.')}
                />
            )}
        </>
    );
};
