import { Formik } from 'formik';
import React from 'react';
import { Measure } from '../../models/Measure/Types';
import { TaskSubmit } from '../../models/Task/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { useUser } from '../../models/User/State';
import { Button } from '../Button/Button';
import { CreateTasksForm } from '../CreateTasksForm/CreateTasksForm';
import './CreateTasks.css';
import { useTasksApi } from '../../api/useTasksApi';

interface CreateTasksProps {
    onSubmit?: () => void;
    measures: Measure[] | undefined;
    mutate: () => void;
    onClose: () => void;
}

export const CreateTasks = (props: CreateTasksProps) => {
    const { apiCreateTask, apiAssignTask, apiCreateTaskComment } = useTasksApi();
    const currentUser = useUser((x) => x.currentUser);

    /**
     * Backend call to create task
     * @param values
     */
    const onCreateTask = async (values: TaskSubmit) => {
        try {
            const task = await apiCreateTask(values);
            if (task) {
                if (values.assignedToId) {
                    await apiAssignTask(task.id, values.assignedToId);
                }

                if (values.text && values.text.length > 0) {
                    await apiCreateTaskComment(values.text, new Date(), task.id);
                }

                props.mutate();
                showSuccessMessage('📝 Aufgabe wurde erstellt');
                props.onClose();
            }
        } catch (e) {
            throwError('Fehler beim Erstellen einer Aufgabe');
        }
    };

    /**
     * initial create task values
     */
    const initialFormValues: TaskSubmit = {
        measureId: undefined,
        name: '',
        text: '',
        deadline: new Date(),
        participantId: null,
        createdById: currentUser?.id,
        assignedToId: undefined,
        notificationAt: new Date(),
        finishedAt: null
    };

    return (
        <div className="create-tasks-foreground">
            <Formik
                initialValues={initialFormValues}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                    onCreateTask(values).then();
                }}
            >
                {({ values, handleSubmit, setFieldValue }) => (
                    <>
                        <div className="qm-document-template-form-background" />
                        <div className="create-task-container">
                            <div className="create-tasks">
                                <CreateTasksForm
                                    setFieldValue={setFieldValue}
                                    measures={props.measures}
                                    values={values}
                                    handleSubmit={handleSubmit}
                                />
                                <div className="create-tasks-footer">
                                    <Button
                                        type={'primary'}
                                        size={'medium'}
                                        buttonStyle={'filled'}
                                        text={'Schließen'}
                                        onClick={props.onClose}
                                    />
                                    <Button
                                        type={'primary'}
                                        size={'medium'}
                                        buttonStyle={'filled'}
                                        text={'Aufgabe erstellen'}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        </div>
    );
};
