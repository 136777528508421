import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { Button } from '../../components/Button/Button';
import { CreateTasks } from '../../components/CreateTasks/CreateTasks';
import { DropDown } from '../../components/DropDown/DropDown';
import { Icon } from '../../components/Icon/Icon';
import { UserTasksCard, UserTasksCardFilter } from '../../components/UserTasksCard/UserTasksCard';
import { UserObject } from '../../models/Employee/Types';
import { Measure } from '../../models/Measure/Types';
import { Task } from '../../models/Task/Types';
import { throwError } from '../../models/Toasts/Toasts';
import './UserTasks.css';
import { useTasksApi } from '../../api/useTasksApi';
import { useMeasuresApi } from '../../api/useMeasuresApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { useUser } from '../../models/User/State';

/**
 * Shows all user tasks of all measures the current user is assigned to.
 *
 * @constructor
 */
export const UserTasks = () => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const [tasks, setTasks] = useState<Task[]>();
    const [users, setUsers] = useState<UserObject[] | undefined>();
    const currentUser = useUser((x) => x.currentUser);
    const [filter, setFilter] = useState<UserTasksCardFilter>({
        measureId: undefined,
        status: false,
        assignee: currentUser?.id
    });
    const [showCreateTask, setShowCreateTask] = useState<boolean>(false);
    const { apiUpdateTask, apiGetAllTasksByMeasure } = useTasksApi();
    const { apiGetActiveUsersOfMeasure } = useMeasuresApi();

    /**
     * Fetch all measures the user is in
     */
    const { data: measures } = useSWR<Measure[]>([
        backendUrl,
        `users/${currentUser?.id}/active_in_measures`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    /**
     * Fetches all tasks for all measures, combines them and saves them to the tasks state.
     */
    const fetchTasks = useCallback(async () => {
        if (measures) {
            const tasks = await Promise.all(measures.map((measure) => apiGetAllTasksByMeasure(measure.id)));
            setTasks([...tasks.flat()]);
        }
    }, [apiGetAllTasksByMeasure, measures]);

    /**
     * Called if fetchTasks method changes.
     * Calls the method to fetch all the tasks.
     */
    useEffect(() => {
        (async () => {
            await fetchTasks();
        })();
    }, [fetchTasks]);

    /**
     * Fetches all users of all measures, combines them and saves them to the state.
     */
    const fetchUsers = useCallback(async () => {
        if (!measures) return;

        const usersInMeasure = (
            await Promise.all(measures.map((measure) => apiGetActiveUsersOfMeasure(measure.id)))
        ).flat();

        const filteredUsers: UserObject[] = [];

        usersInMeasure.forEach((user) => {
            if (user.id !== currentUser?.id && !filteredUsers.some((filtered) => filtered.id === user.id)) {
                filteredUsers.push(user);
            }
        });

        setUsers(filteredUsers);
    }, [apiGetActiveUsersOfMeasure, currentUser, measures]);

    /**
     * fetchUsers on initial load
     */
    useEffect(() => {
        fetchUsers().then();
    }, [fetchUsers]);

    /**
     * Show create task view
     */
    const onShowCreateTask = () => {
        setShowCreateTask(!showCreateTask);
    };

    /**
     * Set filter with measure id
     * @param id
     */
    const onFilterTaskName = (id: number | string | undefined) => {
        if (filter.measureId === id) {
            filter.measureId = undefined;
        } else {
            filter.measureId = id;
        }

        setFilter({ ...filter });
    };

    /**
     * set filter with status
     * @param status
     */
    const onFilterStatus = (status: boolean) => {
        filter.status = status;
        setFilter({ ...filter });
    };

    /**
     * set filter with assignee
     * @param assignee
     */
    const onFilterAssignee = (assignee: number | undefined) => {
        filter.assignee = assignee;
        setFilter({ ...filter });
    };

    /**
     * map assignee id to name
     * @param id
     */
    const mapAssignee = (id: number | undefined) => {
        if (id === currentUser?.id) {
            return `Meine Aufgaben`;
        } else if (id) {
            const name = users?.find((user) => user.id === id);
            return `${name?.firstName} ${name?.lastName}`;
        }
    };

    /**
     * Filter tasks based on filter object
     */
    const filteredTasks = useMemo(() => {
        if (filter.assignee || filter.status || filter.measureId) {
            return tasks?.filter(
                (task) =>
                    task.assignments &&
                    task.assignments.length > 0 &&
                    task.assignments[0].assignedTo.id === filter.assignee &&
                    (filter.status ? task.finishedAt : task.finishedAt === null) &&
                    (!filter.measureId || task.measure.id === filter.measureId)
            );
        } else {
            return tasks;
        }
    }, [filter, tasks]);

    /**
     * set current auth info id predefined as filter
     */
    useEffect(() => {
        if (currentUser?.id) {
            setFilter((prevState) => {
                return { ...prevState, ...{ assignee: currentUser?.id } };
            });
        }
    }, [currentUser]);

    /**
     * Update task to set the finishedAt date
     * @param task
     */
    const onFinishTask = async (task: Task) => {
        try {
            await apiUpdateTask(task.id, { ...task, ...{ finishedAt: task.finishedAt ? null : new Date() } });
            fetchTasks().then();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    return (
        <div className="user-tasks">
            {showCreateTask && (
                <CreateTasks
                    mutate={fetchTasks}
                    onClose={onShowCreateTask}
                    measures={measures?.filter((measure) => !measure.archived)}
                />
            )}
            <div className="user-tasks-head">
                <div className="user-tasks-measures">
                    {measures
                        ?.filter((measure) => !measure.archived)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((measure) => {
                            return (
                                <div
                                    key={measure.id}
                                    className={`user-tasks-measure ${filter.measureId === measure.id ? 'active' : ''}`}
                                    onClick={() => onFilterTaskName(measure.id)}
                                >
                                    {measure.name} {measure.children && measure.children.length > 0 && '(alt)'}
                                </div>
                            );
                        })}
                </div>
                <Button
                    type={'primary'}
                    size={'medium'}
                    buttonStyle={'outline'}
                    text={'Aufgabe hinzufügen'}
                    firstIcon={<Icon type={'CheckCircle'} />}
                    onClick={onShowCreateTask}
                />
            </div>
            <div className="user-tasks-card">
                <div className="user-tasks-filter-dropdown">
                    <DropDown
                        title={
                            <>
                                <div>{filter.status === true ? 'erledigte Aufgaben' : 'unerledigte Aufgaben'}</div>
                                <Icon type={'ChevronDown'} />
                            </>
                        }
                        titleClassName={'user-tasks-dropdown-head'}
                    >
                        <div onClick={() => onFilterStatus(false)}>unerledigte Aufgaben</div>
                        <div onClick={() => onFilterStatus(true)}>erledigte Aufgaben</div>
                    </DropDown>
                    <DropDown
                        title={
                            <>
                                <div>{mapAssignee(filter.assignee)}</div>
                                <Icon type={'ChevronDown'} />
                            </>
                        }
                        titleClassName={'user-tasks-dropdown-head'}
                    >
                        <div className={'user-tasks-dropdown-first-user'}>
                            <div onClick={() => onFilterAssignee(currentUser?.id)}>{mapAssignee(currentUser?.id)}</div>
                            <div className="user-tasks-dropdown-user" />
                        </div>
                        <Fragment>
                            {users && users.length > 0
                                ? users.map((user: UserObject, index: number) => {
                                      return (
                                          <div
                                              key={index}
                                              onClick={() => onFilterAssignee(user?.id)}
                                              className={index === 1 ? 'user-tasks-dropdown-second-user' : ''}
                                          >
                                              {mapAssignee(user?.id)}
                                          </div>
                                      );
                                  })
                                : null}
                        </Fragment>
                    </DropDown>
                </div>
                <UserTasksCard
                    mutateExistingTasks={fetchTasks}
                    onFinishTask={onFinishTask}
                    filter={filter}
                    tasks={filteredTasks}
                />
            </div>
        </div>
    );
};
