import { UserPageLink } from '../UserPageLink/UserPageLink';

import styles from './UserPageLinks.module.css';
import { AppRoutes } from '../../configuration/AppRoutes';

/**
 * Props for the UserPageLinks component.
 */
interface UsersLinksProps {
    // shows the link to the login form
    login?: boolean;

    // shows the link to form to get password reset instructions via email
    lostPassword?: boolean;
}

/**
 * Shows a box having those links related to the user pages area that are activated
 * in the props.
 *
 * @param props
 * @constructor
 */
export const UserPageLinks = (props: UsersLinksProps) => {
    return (
        <div className={styles.container}>
            {props.login && <UserPageLink linkText="Einloggen" href={AppRoutes.login} />}
            {props.lostPassword && <UserPageLink linkText="Passwort vergessen" href={AppRoutes.lostPassword} />}
        </div>
    );
};
