import { Formik, FormikErrors } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { CompanyDetailsForm } from '../../components/CompanyDetailsForm/CompanyDetailsForm';
import { CreateNewInternshipModal } from '../../components/CreateNewInternshipModal/CreateNewInternshipModal';
import { Icon } from '../../components/Icon/Icon';
import { Label } from '../../components/Label/Label';
import { isObjectEmpty, phoneRegx } from '../../helpers';
import { InternshipCompany } from '../../models/InternshipCompanies/Type';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import './CompanyDetails.css';
import { KeyedMutator } from 'swr';
import { Internship } from '../../models/Internship/Types';
import { useInternshipCompaniesApi } from '../../api/useInternshipCompaniesApi';

interface CompanyDetailsProps {
    company: InternshipCompany | undefined;
    reloadCompanies: () => void;
    reloadInternships: KeyedMutator<Internship[]>;
}

export const CompanyDetails = (props: CompanyDetailsProps) => {
    const [createNewInternship, setCreateNewInternship] = useState<boolean>(false);
    const { apiUpdateInternshipCompany } = useInternshipCompaniesApi();

    const onBlurUpdate = async (values: InternshipCompany, error: FormikErrors<InternshipCompany>) => {
        console.log(error);
        if (isObjectEmpty(error)) {
            try {
                if (props.company?.id && JSON.stringify(props.company) !== JSON.stringify(values)) {
                    await apiUpdateInternshipCompany(props.company?.id, values);
                    showSuccessMessage('Betrieb aktualisiert');
                    props.reloadCompanies();
                }
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    const onCreateNewInternship = () => {
        setCreateNewInternship(!createNewInternship);
    };

    const CompanyValidationScheme = Yup.object()
        .shape({
            name: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            contact: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            city: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            email: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .email('Bitten geben Sie eine gültige E-Mail Adresse ein'),
            fax: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .matches(phoneRegx, 'Dies scheint keine gültige Faxnummmer zu sein'),
            phone: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .matches(phoneRegx, 'Dies scheint keine gültige Telefonnummer zu sein'),
            state: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            streetAndNumber: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            zipCode: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            sector: Yup.string().required('Dieses Feld muss ausgefüllt sein.')
        })
        .defined();

    return (
        <div className="user-details-container">
            {props.company && (
                <CreateNewInternshipModal
                    reloadInternship={props.reloadInternships}
                    onClose={onCreateNewInternship}
                    show={createNewInternship}
                    company={props.company}
                />
            )}
            <div className="company-details-head">
                <h5>{props.company?.name}</h5>
                <Button
                    type={'primary'}
                    size={'medium'}
                    buttonStyle={'outline'}
                    firstIcon={<Icon type="Calendar" />}
                    text={'Praktikum buchen'}
                    onClick={onCreateNewInternship}
                />
            </div>
            <Card>
                <div className="user-details-box">
                    {props.company && (
                        <Formik
                            initialValues={props.company}
                            validationSchema={CompanyValidationScheme}
                            // We need to pass this, because formik needs an onSubmit handler, but we do not use the submit mechanism of formik
                            onSubmit={() => console.log('Form submit')}
                            enableReinitialize
                        >
                            {({ values, errors, handleChange, setFieldValue }) => (
                                <>
                                    <div className="user-details-box-details">
                                        <Label className="user-details-general-title" size={2}>
                                            ALLGEMEINE INFORMATIONEN
                                        </Label>
                                        {props.company && (
                                            <div className="user-details-general-information">
                                                <div className="user-details-general-information-container-text">
                                                    <form>
                                                        <CompanyDetailsForm
                                                            company={props.company}
                                                            errors={errors}
                                                            handleChange={handleChange}
                                                            setFieldValue={setFieldValue}
                                                            onBlur={onBlurUpdate}
                                                            values={values}
                                                            reloadCompanies={props.reloadCompanies}
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </Formik>
                    )}
                </div>
            </Card>
        </div>
    );
};
