
const digest = '2ca14af94c2a17bef870bc56e039ce593cbb57d49a735ebba85f053fb1c7b0ff';
const css = `
/* font */
/* background */
/* borders */
/* fonts */
/* paddings */
/* margins and gaps */
/* borders */
/* base container that can be used in markdown editor and view to restyle html elements inside the container */
._container_1d1ld_9 {
    font-family: Arial, serif;
}

._container_1d1ld_9 blockquote {
    margin-left: 1rem;
    padding-left: 0.5rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-left: 5px solid lightgrey;
}

._container_1d1ld_9 blockquote:before {
    content: "„"
}

._container_1d1ld_9 blockquote:after {
    content: "“"
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"fonts":"\"../../shared/styles/fonts.module.css\"","colors":"\"../../shared/styles/colors.module.css\"","distances":"\"../../shared/styles/distances.module.css\"","fontFamilyDefault":"Arial, serif","colorBorderLight":"lightgrey","sizeBorderLarge":"5px","marginLarge":"1rem","paddingMedium":"0.5rem","paddingSmall":"0.2rem","container":"_container_1d1ld_9"};
export { css, digest };
  