import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ParticipantState } from './State';
import { ParticipantWithOneMeasure } from './Types';

/**
 * Slice state for redux work space
 */
export const ParticipantSlice = createSlice({
    name: 'participant',
    initialState: {
        selectedParticipant: undefined
    } as ParticipantState,
    reducers: {
        /**
         * Sets the given user to the state.
         *
         * @param state
         * @param action
         */
        setSelectedParticipant: (state, action: PayloadAction<ParticipantWithOneMeasure | undefined>) => {
            state.selectedParticipant = action.payload;
        }
    }
});

export const { setSelectedParticipant } = ParticipantSlice.actions;
