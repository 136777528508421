import { useState } from 'react';
import { getLocaleMonth } from '../../models/Date/Date';
import { ParticipantWithOneMeasure, ParticipantWithPresence } from '../../models/Participant/Types';
import { mapPresenceType } from '../../models/ParticipantMeasure/ParticipantMeasure';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
import { DropDown } from '../DropDown/DropDown';
import { Icon } from '../Icon/Icon';
import { PresenceCalendar } from '../PresenceCalendar/PresenceCalendar';
import './AttendanceCard.css';
import { KeyedMutator } from 'swr';
import { Presence } from '../../models/Presence/Types';
import { AttendanceLegend } from '../AttendanceKeysInfo/AttendanceLegend';

/**
 * Props for the AttendanceCard component.
 */
interface AttendanceCardProps {
    // Mutate method to force refetch of records using swr
    mutate: KeyedMutator<Presence[]> | KeyedMutator<ParticipantWithPresence[]>;

    // The participant that presences should be shown
    participant: ParticipantWithOneMeasure | undefined;
}

/**
 * Shows the presences of the participant specified by the props.
 * Also shows a selector for the month the presences should be shown.
 *
 * @param props
 * @constructor
 */
export const AttendanceCard = (props: AttendanceCardProps) => {
    const [month, setMonth] = useState<Date>(new Date());

    // Shows the legend for category letters to categories, for instance A = Available
    const [showLegend, setShowLegend] = useState<boolean>(false);

    /**
     * Called if the user changes the month.
     * Updates the current month to the previous, current or next month depedning on the selection.
     * Current means the month of the current day.
     *
     * @param newMonth
     */
    const onChangeMonth = (newMonth: 'previous' | 'current' | 'next') => {
        const today = new Date();

        switch (newMonth) {
            case 'current':
                setMonth(new Date(today.getFullYear(), today.getMonth(), 1));
                break;
            case 'next':
                if (new Date().getMonth() === 11) {
                    setMonth(new Date(new Date().getFullYear() + 1, 0, 1));
                } else {
                    setMonth(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1));
                }

                break;
            case 'previous':
                if (new Date().getMonth() === 0) {
                    setMonth(new Date(today.getFullYear() - 1, 11, 1));
                } else {
                    setMonth(new Date(today.getFullYear(), today.getMonth() - 1, 1));
                }
        }
    };

    /**
     * Called if the user clicks the button to show / hide the legend.
     * Shows or hides the legend, depending on the current state.
     */
    const onClickShowLegend = () => {
        setShowLegend(!showLegend);
    };

    return (
        <Card key={props.participant?.id}>
            <div className="attendance-card">
                <div className="attendance-card-title p2-medium">
                    Anwesenheit ({mapPresenceType(props.participant?.measuresParticipants.presenceType)})
                    <Button
                        onClick={onClickShowLegend}
                        type={'secondary'}
                        size={'medium'}
                        buttonStyle={'outline'}
                        firstIcon={<Icon type={'Information'} />}
                        text={'Legende anzeigen'}
                    />
                </div>
                {showLegend && <AttendanceLegend />}
                <div className="ml-auto user-measure-attendance-management-buttons">
                    <DropDown
                        title={
                            <>
                                <div>{getLocaleMonth(month.getMonth())}</div>
                                <Icon type={'ChevronDown'} />
                            </>
                        }
                        titleClassName="user-measure-attendance-management-title"
                    >
                        <div onClick={() => onChangeMonth('next')}>Nächster Monat</div>
                        <div onClick={() => onChangeMonth('current')}>Diesen Monat</div>
                        <div onClick={() => onChangeMonth('previous')}>Letzten Monat</div>
                    </DropDown>
                </div>
                <PresenceCalendar
                    participants={props.participant ? [props.participant] : undefined}
                    mutate={props.mutate}
                    month={month}
                />
            </div>
        </Card>
    );
};
