import { FormikErrors } from 'formik';
import { CourseSubmit } from '../../models/Course/Type';
import { UserObject } from '../../models/Employee/Types';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { Participant } from '../../models/Participant/Types';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
import { CourseDetailsForm } from '../CourseDetailsForm/CourseDetailsForm';
import { CourseDetailsUsers } from '../CourseDetailsUsers/CourseDetailsUsers';
import { Label } from '../Label/Label';

interface NewCourseDetailsFormProps {
    course: CourseSubmit | undefined;
    onSubmit: (arg: CourseSubmit) => void;
    handleSubmit: () => void;
    setFieldValue: FormikSetFieldValue;
    handleChange: FormikHandleChange;
    errors: FormikErrors<CourseSubmit>;
    values: CourseSubmit;
    allUsers: UserObject[] | undefined;
    allParticipants: Participant[] | undefined;
}

export const NewCourseDetailsForm = (props: NewCourseDetailsFormProps) => {
    const onAddUserToCourse = (userId: number | string | undefined) => {
        if (props.values.users && Array.isArray(props.values.users)) {
            const userBuffer: UserObject[] | undefined = props.values.users;
            const userToAdd = props.allUsers?.find((user) => user.id === userId);
            if (userToAdd) {
                userBuffer?.push(userToAdd);
                props.setFieldValue('users', userBuffer);
            }
        } else {
            const userBuffer: UserObject[] = [];
            const user = props.allUsers?.find((user) => user.id === userId);
            if (user) {
                userBuffer.push(user);
                props.setFieldValue('users', userBuffer);
            }
        }
    };

    const onAddParticipantsToCourse = (participantId: number | string | undefined) => {
        if (props.values.participants && Array.isArray(props.values.participants)) {
            const participantBuffer: Participant[] | undefined = props.values.participants;
            const participantToAdd = props.allParticipants?.find((participant) => participant.id === participantId);
            if (participantToAdd) {
                participantBuffer.push(participantToAdd);
                props.setFieldValue('participants', participantBuffer);
            }
        } else {
            const participantBuffer: Participant[] = [];
            const participant = props.allParticipants?.find((participant) => participant.id === participantId);
            if (participant) {
                participantBuffer.push(participant);
                props.setFieldValue('participants', participantBuffer);
            }
        }
    };

    const onRemoveParticipant = (id: number | string | undefined) => {
        if (id && props.values.participants) {
            const participantsInCourse = props.values.participants;
            const indexInCourse = props.values.participants.findIndex((participant) => participant.id === id);
            if (indexInCourse >= 0) {
                participantsInCourse.splice(indexInCourse, 1);
                props.setFieldValue('participant', participantsInCourse);
            }
        }
    };

    const onRemoveUser = (id: number | string | undefined) => {
        if (id && props.values.users) {
            const usersInCourse = props.values.users;
            const indexInCourse = props.values.users.findIndex((participant) => participant.id === id);
            if (indexInCourse >= 0) {
                usersInCourse.splice(indexInCourse, 1);
                props.setFieldValue('users', usersInCourse);
            }
        }
    };

    return (
        <>
            <div className="course-details-head">
                <h5>&quot;{props.values.name}&quot;</h5>
                <Button
                    type={'primary'}
                    size={'medium'}
                    buttonStyle={'outline'}
                    text={'Speichern'}
                    onClick={props.handleSubmit}
                />
            </div>
            <Card>
                <div className="course-details">
                    <div className="user-details-box">
                        <div className="user-details-box-details">
                            <Label className="user-details-general-title" size={2}>
                                ALLGEMEINE INFORMATIONEN
                            </Label>
                            {props.course && (
                                <div className="user-details-general-information">
                                    <div className="user-details-general-information-container-text">
                                        <form>
                                            <CourseDetailsForm
                                                newCourse={props.course}
                                                errors={props.errors}
                                                handleChange={props.handleChange}
                                                setFieldValue={props.setFieldValue}
                                                values={props.values}
                                            />
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {props.allParticipants && (
                    <CourseDetailsUsers
                        label={'Teilnehmer'}
                        course={props.course}
                        allParticipants={props.allParticipants}
                        assignedParticipants={props.values.participants}
                        onAddUserToCourse={onAddParticipantsToCourse}
                        onRemoveParticipant={onRemoveParticipant}
                    />
                )}
                {props.allUsers && (
                    <CourseDetailsUsers
                        label={'Mitarbeiter'}
                        course={props.course}
                        allUsers={props.allUsers.filter((user) => !user.archived)}
                        assignedUsers={props.values.users}
                        onAddUserToCourse={onAddUserToCourse}
                        onRemoveUser={onRemoveUser}
                    />
                )}
            </Card>
        </>
    );
};
