import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SWRConfig } from 'swr';
import './App.css';
import './components/QmDocumentTemplateForm/QmDocumentTemplateForm.css';
import { Configuration } from './configuration/Configuration';
import { AppContainer } from './pages/AppContainer/AppContainer';
import './styles/Animation.css';
import './styles/Colors.css';
import './styles/Typogrophy.css';
import { AuthAndUserStateManager } from './stateManages/AuthAndUserStateManager';

const App = () => {
    return (
        <SWRConfig value={Configuration.swrConfig}>
            <ToastContainer />
            <Router>
                <AuthAndUserStateManager />

                <AppContainer />
            </Router>
        </SWRConfig>
    );
};

export default App;
