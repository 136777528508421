import { Formik } from 'formik';
import React, { useState } from 'react';
import { KeyedMutator } from 'swr';
import { LetterTemplate, LetterTemplateSubmit } from '../../models/LetterTemplates/Type';
import { showSuccessMessage } from '../../models/Toasts/Toasts';
import { DeleteQmDocumentTemplateModal } from '../DeleteQmDocumentTemplateModal/DeleteQmDocumentTemplateModal';
import { LetterTemplateDetailsForm } from '../LetterTemplateDetailsForm/LetterTemplateDetailsForm';
import { NewLetterTemplateDetailsForm } from '../NewLetterTemplateDetailsForm/NewLetterTemplateDetailsForm';
import { useLetterTemplatesApi } from '../../api/useLetterTemplatesApi';

interface LetterTemplateDetailsProps {
    letterTemplate?: LetterTemplate;
    newLetterTemplate?: LetterTemplateSubmit;
    mutate: KeyedMutator<LetterTemplate[]>;
    onResetNewLetterTemplate?: () => void;
    resetLetterTemplate?: () => void;
    onSubmit: (arg: LetterTemplateSubmit | LetterTemplate) => void;
}

export const LetterTemplateDetails = (props: LetterTemplateDetailsProps) => {
    const { apiDeleteLetterTemplate } = useLetterTemplatesApi();

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const onShowDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal);
    };

    const onDelete = async () => {
        try {
            if (props.newLetterTemplate && props.onResetNewLetterTemplate) {
                props.onResetNewLetterTemplate();
            } else if (props.letterTemplate) {
                await apiDeleteLetterTemplate(props.letterTemplate.id);
                if (props.resetLetterTemplate) {
                    props.resetLetterTemplate();
                }
            }

            onShowDeleteModal();
            showSuccessMessage();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {' '}
            <DeleteQmDocumentTemplateModal
                show={showDeleteModal}
                onClose={onShowDeleteModal}
                document={props.letterTemplate || props.newLetterTemplate}
                onClickDelete={onDelete}
            />
            {props.letterTemplate && (
                <Formik
                    initialValues={props.letterTemplate}
                    onSubmit={async (values) => {
                        await props.onSubmit(values);
                    }}
                    enableReinitialize
                >
                    {({ handleSubmit, values, errors, setFieldValue, handleChange, resetForm }) =>
                        props.letterTemplate && (
                            <LetterTemplateDetailsForm
                                onShowDeleteModal={onShowDeleteModal}
                                resetForm={resetForm}
                                errors={errors}
                                handleChange={handleChange}
                                handleSubmit={handleSubmit}
                                setFieldValue={setFieldValue}
                                values={values}
                                letterTemplate={props.letterTemplate}
                            />
                        )
                    }
                </Formik>
            )}
            {props.newLetterTemplate && (
                <Formik
                    initialValues={props.newLetterTemplate}
                    onSubmit={async (values) => {
                        await props.onSubmit(values);
                    }}
                >
                    {({ handleSubmit, values, errors, setFieldValue, handleChange }) => (
                        <NewLetterTemplateDetailsForm
                            errors={errors}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            setFieldValue={setFieldValue}
                            values={values}
                            newLetterTemplate={props.newLetterTemplate}
                        />
                    )}
                </Formik>
            )}
        </>
    );
};
