import React from 'react';
import { Modal } from '../Modal/Modal';

interface CancelQmDocumentTemplateModalProps {
    show: boolean;
    onClickClose: () => void;
    onClickCancelQmDocument: () => void;
}

export const CancelQmDocumentTemplateModal = (props: CancelQmDocumentTemplateModalProps) => {
    return (
        <Modal
            show={props.show}
            header={'Dokument verwerfen'}
            buttons={{
                primary: {
                    text: 'Beenden',
                    onClick: props.onClickCancelQmDocument
                },
                secondary: {
                    text: 'Abbrechen',
                    onClick: props.onClickClose
                }
            }}
        >
            <div>Möchten Sie die Erstellung des Dokuments beenden?</div>
        </Modal>
    );
};
