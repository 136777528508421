import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { KeyedMutator } from 'swr';
import * as Yup from 'yup';
import avatar from '../../assets/img/user.png';
import { platformUrl } from '../../helpers';
import { UserObject } from '../../models/Employee/Types';
import { Measure } from '../../models/Measure/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { ActiveStorageDropzone } from '../ActiveStorageDropzone/ActiveStorageDropzone';
import { Card } from '../Card/Card';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';
import { UserDetailsFormList } from '../UserDetailsFormList/UserDetailsFormList';
import { UserDetailsMeasureTable } from '../UserDetailsMeasureTable/UserDetailsMeasureTable';
import './UserDetails.css';
import { useUsersApi } from '../../api/useUsersApi';
import { ObjectShape } from 'yup';

interface UserDetailsProps {
    user?: UserObject;
    reloadUserList: KeyedMutator<UserObject[]>;
    reloadUser: () => void;
}

export const UserDetails = (props: UserDetailsProps) => {
    const [menuSelected, setMenuSelected] = useState<string>('active');
    const [user, setUser] = useState<UserObject>();

    const initialUserDetailsFormValues: UserObject | undefined = user;

    const [activeMeasure, setActiveMeasure] = useState<Measure[] | undefined>();
    const [archivedMeasure, setArchivedMeasure] = useState<Measure[] | undefined>();

    const { apiGetUserActiveMeasure, apiGetUserArchivedMeasure, apiGetUser, apiUpdateUser } = useUsersApi();

    /**
     * fetch measures that an user is assigned to
     */
    const getUserMeasure = useCallback(async () => {
        if (user?.id) {
            try {
                setActiveMeasure(await apiGetUserActiveMeasure(user.id));
                setArchivedMeasure(await apiGetUserArchivedMeasure(user.id));
            } catch (e) {
                console.log(e);
            }
        }
    }, [apiGetUserActiveMeasure, apiGetUserArchivedMeasure, user]);

    /**
     * after initial load or if getUserMeasure changes, fetch the user measure again
     */
    useEffect(() => {
        getUserMeasure().then();
    }, [getUserMeasure]);

    /**
     * Fetch user information
     */
    const fetchUser = useCallback(async () => {
        try {
            if (props?.user?.id) {
                const returnedUser = await apiGetUser(props.user.id);
                if (returnedUser) {
                    setUser(returnedUser);
                }
            }
        } catch (e) {
            throwError('Beim Laden des Nutzers ist ein Fehler aufgetreten.');
            console.log(e);
        }
    }, [apiGetUser, props.user]);

    useEffect(() => {
        fetchUser().then();
    }, [fetchUser]);

    const validationSchema = (user: UserObject | undefined) => {
        const returnObject: ObjectShape = {};
        if (user) {
            Object.keys(user).forEach((key) => {
                if (key === 'mobilePhone' || key === 'phone') {
                    returnObject[key] = Yup.string()
                        .required('Dieses Feld muss ausgefüllt sein.')
                        .matches(phoneRegx, 'Dies scheint keine gültige Telefonnummer zu sein');
                } else if (key !== 'groups' && key !== 'avatar' && key !== 'avatarUrl') {
                    returnObject[key] = Yup.string().required('Dieses Feld muss ausgefüllt sein.');
                }
            });
        }

        return returnObject;
    };

    const phoneRegx = /[0-9]/;
    const UserDetailsValidationScheme = Yup.object().shape(validationSchema(user)).defined();

    /**
     * Change/update user
     * @param values
     */
    const requestToChangeUser = async (values: UserObject) => {
        if (props.user?.id && JSON.stringify(values) !== JSON.stringify(user)) {
            try {
                const changedUser = await apiUpdateUser(props.user.id, values);
                if (changedUser) {
                    showSuccessMessage('Benutzer wurde geändert');
                    fetchUser().then();
                    props.reloadUserList().then();
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onChangeAvatar = async (avatarUrl: string | undefined) => {
        try {
            if (props.user && avatarUrl) {
                await apiUpdateUser(props.user?.id, {
                    ...props.user,
                    ...{ avatar: avatarUrl, avatarUrl: null }
                });
            } else if (props.user) {
                await apiUpdateUser(props.user?.id, { ...props.user, ...{ avatar: null, avatarUrl: null } });
            }

            fetchUser().then();
            showSuccessMessage('Bild geändert');
            props.reloadUserList().then();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    const onRemoveAvatar = async (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        try {
            if (props.user) {
                await apiUpdateUser(props.user?.id, { avatar: null });
                fetchUser().then();
                props.reloadUserList().then();
                showSuccessMessage('Bild entfernt');
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    return (
        <div className="user-details-container">
            <Card>
                <div className="user-details-box">
                    {initialUserDetailsFormValues && (
                        <Formik
                            initialValues={initialUserDetailsFormValues}
                            validationSchema={UserDetailsValidationScheme}
                            // We need this because submit expects onSubmit, but we dont use formiks submit mechanism
                            onSubmit={() => console.log('Form submit')}
                            enableReinitialize
                        >
                            {({ values, errors, handleChange, setFieldValue }) => (
                                <>
                                    <div className="user-details-box-details">
                                        <Label className="user-details-general-title" size={2}>
                                            ALLGEMEINE INFORMATIONEN
                                        </Label>
                                        {props.user && (
                                            <div className="user-details-general-information">
                                                <div className="user-details-general-information-container-text">
                                                    <form>
                                                        <UserDetailsFormList
                                                            errors={errors}
                                                            user={user}
                                                            setFieldValue={setFieldValue}
                                                            refresh={fetchUser}
                                                            values={values}
                                                            handleChange={handleChange}
                                                            requestToChangeUser={requestToChangeUser}
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={'user-details-general-information-container'}>
                                        <div className="user-details-general-information-container-image">
                                            {!user?.archived ? (
                                                <ActiveStorageDropzone
                                                    className={'qm-document-template-form-upload'}
                                                    overwriteOldFile={true}
                                                    accept={{ 'image/*': [] }}
                                                    onChange={(files) =>
                                                        onChangeAvatar(files.length > 0 ? files[0].signedId : '')
                                                    }
                                                    attributeName="document"
                                                    textDropzone={'Bild ändern'}
                                                    files={values.avatar ? [{ signedId: values.avatar }] : []}
                                                    multiple={false}
                                                    maxFiles={1}
                                                    onMaxFilesError={() => alert('Es ist nur eine Datei erlaubt')}
                                                    maxSize={10485760}
                                                    onMaxSizeError={() =>
                                                        alert('Die Datei ist zu groß. Sie darf max. 10MB groß sein.')
                                                    }
                                                >
                                                    <div className="user-details-general-picture">
                                                        <img
                                                            alt={'user profile'}
                                                            src={user?.avatarUrl ? platformUrl(user.avatarUrl) : avatar}
                                                        />
                                                        <div
                                                            className={'user-details-general-picture-remove'}
                                                            onClick={onRemoveAvatar}
                                                        >
                                                            <Icon type={'Delete'} />
                                                        </div>
                                                        <div className="user-details-general-picture-upload">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="32"
                                                                height="32"
                                                                fill="currentColor"
                                                                className="bi bi-cloud-arrow-up"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                                                                />
                                                                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </ActiveStorageDropzone>
                                            ) : (
                                                <div className="user-details-general-picture">
                                                    <img
                                                        alt={'user profile'}
                                                        src={user?.avatarUrl ? platformUrl(user.avatarUrl) : avatar}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </Formik>
                    )}
                </div>
            </Card>
            <div className="user-details-measures">
                <div className="p2-medium">Maßnahmen</div>
                <div className="user-details-navigation">
                    <div
                        className={`user-details-navigation-item label-2 ${menuSelected === 'active' && 'active'}`}
                        onClick={() => setMenuSelected('active')}
                    >
                        AKTIV {activeMeasure && `(${activeMeasure.length})`}
                    </div>
                    <div
                        className={`user-details-navigation-item label-2 ${menuSelected === 'inactive' && 'active'}`}
                        onClick={() => setMenuSelected('inactive')}
                    >
                        BISHERIGE {archivedMeasure && `(${archivedMeasure.length})`}
                    </div>
                </div>
                <UserDetailsMeasureTable
                    mutateUserMeasure={getUserMeasure}
                    archivedMeasure={archivedMeasure}
                    user={props.user}
                    isArchived={menuSelected !== 'active'}
                    userMeasures={menuSelected === 'active' ? activeMeasure : archivedMeasure}
                />
            </div>
        </div>
    );
};
