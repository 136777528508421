import { useState } from 'react';
import { KeyedMutator } from 'swr';
import { Button } from '../../components/Button/Button';
import { Icon } from '../../components/Icon/Icon';
import { MeasureDetails } from '../../components/MeasureDetails/MeasureDetails';
import { Modal } from '../../components/Modal/Modal';
import { Measure } from '../../models/Measure/Types';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import './MeasureInformation.css';
import { MeasureCopy } from '../../components/MeasureCopy/MeasureCopy';
import { useMeasuresApi } from '../../api/useMeasuresApi';
import { useUsersApi } from '../../api/useUsersApi';

interface MeasureInformationProps {
    measure: Measure | undefined;
    mutate: KeyedMutator<Measure[]>;
    resetDataSelected: () => void;
}

export const MeasureInformation = (props: MeasureInformationProps) => {
    const [showArchiveModal, setShowArchiveModal] = useState<{
        open: boolean;
        participants: ParticipantWithPresence[] | undefined;
    }>();
    const [isCopyModalShown, setIsCopyModalShown] = useState<boolean>(false);
    const { apiUpdateMeasure, apiGetActiveUsersOfMeasure, apiGetActiveParticipantsInMeasure } = useMeasuresApi();
    const { apiArchiveUserInMeasure } = useUsersApi();

    const onClickArchive = async () => {
        if (props.measure) {
            try {
                // check if there are active users in the measure, if so archive them for the measure
                const users = await apiGetActiveUsersOfMeasure(props.measure.id);
                if (users && props.measure && props.measure) {
                    await Promise.all(users.map((user) => apiArchiveUserInMeasure(props.measure!.id, user.id)));
                }

                // archive measure
                const archive = await apiUpdateMeasure({ archived: true }, props.measure.id);
                if (archive) {
                    props.mutate().then();
                    onClickCloseArchive();
                    props.resetDataSelected();
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onClickShowArchive = async () => {
        if (props.measure) {
            try {
                // check if there are participants the measure
                const participants = await apiGetActiveParticipantsInMeasure(props.measure?.id);
                if (participants) {
                    setShowArchiveModal({ open: true, participants: participants });
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onClickCloseArchive = () => {
        setShowArchiveModal({ open: false, participants: undefined });
    };

    const onClickActivate = async () => {
        if (props.measure) {
            try {
                const archive = await apiUpdateMeasure({ archived: false }, props.measure.id);
                if (archive) {
                    props.mutate().then();
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    /**
     * Called, if the button to copy a measure was pressed
     */
    const onClickCopy = async () => {
        setIsCopyModalShown(true);
    };

    /**
     * Called when the copy process has finished
     */
    const onCopyDone = () => {
        setIsCopyModalShown(false);
        props.mutate().then();
    };

    return (
        <div className="measure-information-container">
            {props.measure && (
                <>
                    <div className="measure-information-container-head">
                        <h5>{props.measure?.name}</h5>
                        <Button
                            className={'measure-information-button-copy'}
                            type={'primary'}
                            size={'medium'}
                            buttonStyle={'outline'}
                            firstIcon={<Icon type={'Plus'} />}
                            text={'Kopieren'}
                            onClick={onClickCopy}
                        />
                        {!props.measure?.archived && (
                            <Button
                                type={'primary'}
                                size={'medium'}
                                buttonStyle={'outline'}
                                firstIcon={<Icon type={'Archive'} />}
                                text={'Archivieren'}
                                onClick={onClickShowArchive}
                            />
                        )}
                        {props.measure?.archived && (
                            <Button
                                type={'primary'}
                                size={'medium'}
                                buttonStyle={'outline'}
                                firstIcon={<Icon type={'Archive'} />}
                                text={'Aktivieren'}
                                onClick={onClickActivate}
                            />
                        )}
                    </div>
                    <MeasureDetails mutate={props.mutate} measure={props.measure} />
                    <Modal
                        show={showArchiveModal?.open}
                        header={'Maßnahme archivieren'}
                        buttons={{
                            primary:
                                showArchiveModal?.participants && showArchiveModal.participants.length > 0
                                    ? undefined
                                    : {
                                          text: 'Maßnahme archivieren',
                                          onClick: onClickArchive
                                      },
                            secondary: {
                                text: 'Abbrechen',
                                onClick: onClickCloseArchive
                            }
                        }}
                    >
                        {showArchiveModal?.participants && showArchiveModal.participants.length > 0 ? (
                            <div>
                                <div>Bitte beachten Sie:</div>
                                <br />
                                <div>
                                    Um diese Maßnahme archivieren zu können, müssen zuvor alle Teilnehmer deaktivert
                                    sein. Bitte stellen Sie sicher, dass es keine aktiven Teilnehmer mehr in dieser
                                    Maßnahme gibt. Wenden Sie sich im Zweifelsfall an die zuständigen Mitarbeiter.
                                </div>
                                <br />
                                <div>
                                    {' '}
                                    Diese Maßnahme wird bei Archivierung schreibgeschützt. Es können danach keine
                                    Änderungen an dieser Maßnahme vorgenommen werden.
                                </div>
                            </div>
                        ) : (
                            <div>Wollen Sie die Maßnahme wirklich archivieren?</div>
                        )}
                    </Modal>
                    <Modal
                        show={isCopyModalShown}
                        className={'measure-copy-modal'}
                        header={'Maßnahme kopieren'}
                        buttons={{
                            secondary: {
                                text: 'Abbrechen',
                                onClick: () => {
                                    setIsCopyModalShown(false);
                                }
                            }
                        }}
                    >
                        <MeasureCopy measure={props.measure} onCopyDone={onCopyDone} />
                    </Modal>{' '}
                </>
            )}
        </div>
    );
};
