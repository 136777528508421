import logo from '../../assets/img/sorocket_logo.png';
import './CompanyLogo.css';

export const CompanyLogo = () => {
    return (
        <div className="company-logo">
            <img alt="SoRocket Company Logo" src={logo} />
        </div>
    );
};
