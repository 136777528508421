import { useCallback, useEffect } from 'react';
import { Measure } from '../../models/Measure/Types';
import { ObjectList } from '../ObjectList/ObjectList';
import './MeasureListElements.css';

interface MeasureListElementsProps {
    elements: Measure[] | undefined;

    // Called when a measure item in the list was selected
    onSelectMeasure: (measure: Measure) => void;

    selectedMeasure: Measure | undefined;
}

export const MeasureListElements = (props: MeasureListElementsProps) => {
    /**
     * Called when an item in the measures list was selected.
     */
    const onClickMeasureItem = useCallback(
        (index: number | string, item: number) => {
            if (!props.elements) return;

            const selectedMeasure = props.elements.find((m: Measure) => {
                return m.id === item;
            });
            if (!selectedMeasure) return;

            props.onSelectMeasure(selectedMeasure);
        },

        // This complains because "this" is available in selectedMeasure and possibly targeting stale props,
        // but we do not access this. Hence it is ok to exclude it here
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.onSelectMeasure, props.elements]
    );

    /**
     * If no item of the current list (active/inactive) is selected, select the first item.
     */
    useEffect(() => {
        if (!props.elements || props.elements.length === 0) return;

        if (
            props.elements.some((m: Measure) => {
                return props.selectedMeasure && m.id === props.selectedMeasure.id;
            })
        ) {
            return;
        }

        props.onSelectMeasure(props.elements[0]);

        // This complains because "this" is available in selectedMeasure and possibly targeting stale props,
        // but we do not access this. Hence it is ok to exclude it here
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.elements, props.selectedMeasure]);

    return (
        <>
            {props.elements?.map((measure, index) => {
                return (
                    <ObjectList.Item
                        key={index}
                        onClick={onClickMeasureItem}
                        value={{ index: index, item: measure.id }}
                        selected={props.selectedMeasure && measure.id === props.selectedMeasure.id}
                    >
                        <div className="measure-list-object-list-item-container">
                            <div className="measure-list-object-list-item">
                                <div className="measure-list-object-list-item-text-description">
                                    <div>{measure.name}</div>
                                </div>
                            </div>
                            <div>{new Date(measure.startAt).getFullYear()}</div>
                        </div>
                    </ObjectList.Item>
                );
            })}
        </>
    );
};
