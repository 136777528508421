import { FormikValues } from 'formik';
import { Datepicker } from '../../../components/Datepicker/Datepicker';
import './CreateMeasureEnd.css';

interface CreateMeasureEndProps {
    setFieldValue: (arg: string, arg1: unknown) => void;
    values: FormikValues;
    name?: string;
}

export const CreateMeasureEnd = (props: CreateMeasureEndProps) => {
    const changeDateValueInForm = (date: Date) => {
        props.setFieldValue('endAt', date);
    };

    return (
        <>
            <h5 className="create-measure-end-title">Wann endet die Maßnahme?</h5>
            <Datepicker
                label={'Ende der Maßnahme'}
                onChange={changeDateValueInForm}
                initialDate={props.values.endAt}
                value={props.values.endAt}
            />
        </>
    );
};
