import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { Label } from '../Label/Label';
import './FormTextArea.css';

interface FormTextAreaProps {
    size: 'small' | 'medium' | 'large';
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    className?: string;
    error?: string;
    children?: React.ReactElement | React.ReactElement[];
    helperText?: string;
    value?: string | number;
    placeholder?: string;
    required?: boolean;
    name?: string;
    readonly?: boolean;
}

export const FormTextArea = (props: FormTextAreaProps) => {
    return (
        <div className="form-text-area">
            {props.children ? (
                Array.isArray(props.children) ? (
                    <div className="form-text-area-top">
                        {props.children?.find((child) => child.type === Label)}
                        {props.children?.find((child) => child.type === Link)}
                    </div>
                ) : props.children && props.children.type === Label ? (
                    props.children
                ) : null
            ) : null}
            <textarea
                readOnly={props.readonly}
                name={props.name}
                className={`${props.size} ${props.className} ${props.value && 'value'} ${props.error && 'error'}`}
                onChange={props.onChange}
                value={props.value}
                placeholder={props.placeholder}
                required={props.required}
            />
            <div className="input-helper-error-container">
                <div className="helper">{props.helperText}</div>
                <div className="error">{props.error}</div>
            </div>
        </div>
    );
};
