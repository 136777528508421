import { Formik } from 'formik';
import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { KeyedMutator } from 'swr';
import { Course } from '../../models/Course/Type';
import { UserObject } from '../../models/Employee/Types';
import { Participant } from '../../models/Participant/Types';
import { throwError } from '../../models/Toasts/Toasts';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
import { CourseArchiveModal } from '../CourseArchiveModal/CourseArchiveModal';
import { CourseDetailsForm } from '../CourseDetailsForm/CourseDetailsForm';
import { CourseDetailsUsers } from '../CourseDetailsUsers/CourseDetailsUsers';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';
import './CourseDetails.css';
import { useCoursesApi } from '../../api/useCourseApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface CourseDetailsProps {
    course: Course | undefined;
    allUsers: UserObject[] | undefined;
    allParticipants: Participant[] | undefined;
    reloadCourses: KeyedMutator<Course[]>;
}

export const CourseDetails = (props: CourseDetailsProps) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { data: course, mutate: reloadCourse } = useSWR<Course>([
        backendUrl,
        `courses/${props.course?.id}`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: usersInCourse, mutate: reloadUsersInCourse } = useSWR<UserObject[]>([
        backendUrl,
        `courses/${props.course?.id}/users`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: participantsInCourse, mutate: reloadParticipantsInCourse } = useSWR<Participant[]>([
        backendUrl,
        `courses/${props.course?.id}/participants`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const [showArchive, setShowArchive] = useState<boolean>(false);
    const {
        apiRemoveUserFromCourse,
        apiAddUserToCourse,
        apiAddParticipantToCourse,
        apiRemoveParticipantFromCourse,
        apiActivateCourse
    } = useCoursesApi();

    /**
     * Remove an user from a course
     * @param userId
     */
    const onRemoveUser = async (userId: number | undefined) => {
        try {
            if (userId && course) {
                await apiRemoveUserFromCourse(course.id, userId);
                reloadUsersInCourse().then();
            }
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Add an user to a course
     * @param userId
     */
    const onAddUserToCourse = async (userId: number | undefined) => {
        try {
            if (props.course && userId) {
                await apiAddUserToCourse(props.course?.id, userId);
                reloadUsersInCourse().then();
            }
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Add an participant to a course
     * @param participantId
     */
    const onAddParticipantToCourse = async (participantId: number | undefined) => {
        try {
            if (props.course && participantId) {
                await apiAddParticipantToCourse(props.course?.id, participantId);
                reloadParticipantsInCourse().then();
            }
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Remove a participant from a course
     * @param userId
     */
    const onRemoveParticipant = async (userId: number | undefined) => {
        try {
            if (userId && course) {
                await apiRemoveParticipantFromCourse(course.id, userId);
                reloadParticipantsInCourse().then();
            }
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Show the archive modal to archive a course
     */
    const onShowArchive = () => {
        setShowArchive(!showArchive);
    };

    /**
     * Activate an archived course
     */
    const onActivateCourse = async () => {
        try {
            if (course) {
                await apiActivateCourse(course.id);
                reloadCourse().then();
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Filters the selectable users by only active users.
     */
    const activeUsers = useMemo(() => {
        return props.allUsers?.filter((user) => !user.archived);
    }, [props.allUsers]);

    return (
        <>
            <div className="course-details-head">
                <h5>&quot;{props.course?.name}&quot;</h5>
                {!course?.archived ? (
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'outline'}
                        firstIcon={<Icon type={'Archive'} />}
                        text={'Archivieren'}
                        onClick={onShowArchive}
                    />
                ) : (
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'outline'}
                        text={'Aktivieren'}
                        onClick={onActivateCourse}
                    />
                )}
            </div>
            <Card>
                <div className="course-details">
                    <div className="user-details-box course-details-box">
                        {props.course && (
                            <Formik
                                initialValues={props.course}
                                validationSchema={null}
                                enableReinitialize
                                onSubmit={() => console.log()}
                            >
                                {({ values, errors, handleChange, setFieldValue }) => (
                                    <>
                                        <div className="user-details-box-details course-details-box-details">
                                            <Label className="user-details-general-title" size={2}>
                                                ALLGEMEINE INFORMATIONEN
                                            </Label>
                                            {props.course && (
                                                <div className="user-details-general-information">
                                                    <div className="user-details-general-information-container-text">
                                                        <form>
                                                            <CourseDetailsForm
                                                                course={course}
                                                                errors={errors}
                                                                handleChange={handleChange}
                                                                setFieldValue={setFieldValue}
                                                                values={values}
                                                                reloadCourse={reloadCourse}
                                                                reloadAllCourses={props.reloadCourses}
                                                            />
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </Formik>
                        )}
                        {usersInCourse && (
                            <CourseDetailsUsers
                                label={'Teilnehmer'}
                                course={course}
                                showOnlyNames
                                allParticipants={props.allParticipants}
                                assignedParticipants={participantsInCourse}
                                onAddUserToCourse={onAddParticipantToCourse}
                                onRemoveParticipant={onRemoveParticipant}
                                customButton={(onShowCourse) => (
                                    <Button
                                        type={'primary'}
                                        size={'medium'}
                                        buttonStyle={'link'}
                                        text={'Teilnehmer hinzufügen'}
                                        firstIcon={<Icon type={'Plus'} />}
                                        onClick={onShowCourse}
                                    />
                                )}
                            />
                        )}
                    </div>
                    <CourseDetailsUsers
                        label={'Mitarbeiter'}
                        course={course}
                        allUsers={activeUsers}
                        assignedUsers={usersInCourse}
                        onAddUserToCourse={onAddUserToCourse}
                        onRemoveUser={onRemoveUser}
                    />
                </div>
                <CourseArchiveModal
                    reloadCourse={reloadCourse}
                    course={course}
                    onClose={onShowArchive}
                    show={showArchive}
                />
            </Card>
        </>
    );
};
