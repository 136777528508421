import { Form, Formik, FormikValues } from 'formik';
import { Measure } from '../../models/Measure/Types';
import { DetailsFormList } from '../DetailsFormList/DetailsFormList';
import './MeasureCopy.css';
import { ParticipantsManagement } from '../ParticipantsManagement/ParticipantsManagement';
import { DetailsValidationScheme } from '../../models/Measure/Measure';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { useState } from 'react';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { useMeasuresApi } from '../../api/useMeasuresApi';

interface MeasureCopyProps {
    measure: Measure;

    // Called when the copy process has finished
    onCopyDone: () => void;
}

export const MeasureCopy = (props: MeasureCopyProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { apiCopyMeasure } = useMeasuresApi();

    /**
     * Called by the formik submit callback
     * @param measureAndParticipants
     */
    const onSubmit = async (measureAndParticipants: FormikValues) => {
        try {
            const { participantsIds, ...measure } = measureAndParticipants;

            // Since the ids returned by the formik submit are strings (due to dom limitations of the checkbox 'value'),
            // they have to be converted to integers
            const participantsIdsIntegers = participantsIds.map((idString: string) => parseInt(idString, 10));
            await apiCopyMeasure(props.measure.id, measure, participantsIdsIntegers);

            showSuccessMessage('Maßnahme wurde kopiert');
            props.onCopyDone();
        } catch (e) {
            throwError('Maßnahme konnte nicht kopiert werden.');
            console.log(e);
        }
    };

    return (
        <div className={'measure-copy'}>
            <Formik
                initialValues={{ ...props.measure, participantsIds: [] }}
                validationSchema={DetailsValidationScheme}
                onSubmit={async (values) => {
                    setIsSubmitting(true);
                    await onSubmit(values);
                    setIsSubmitting(false);
                }}
            >
                {({ values, errors, handleChange, setFieldValue }) => (
                    <Form>
                        <DetailsFormList
                            measure={props.measure}
                            setFieldValue={setFieldValue}
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            isUpdateOnChangeEnabled={false}
                        />
                        <div className="measure-copy-participant-selection">
                            {props.measure && (
                                <ParticipantsManagement measure={props.measure} areCheckboxesEnabled={true} />
                            )}
                        </div>
                        {isSubmitting ? (
                            <LoadingSpinner />
                        ) : (
                            <button type="submit" className={'measure-copy-submit'}>
                                Bestätigen
                            </button>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};
