export const countries: string[] = [
    'afghanisch',
    'ägyptisch',
    'albanisch',
    'algerisch',
    'andorranisch',
    'angolanisch',
    'antiguanisch',
    'äquatorial',
    'guineisch',
    'argentinisch',
    'armenisch',
    'aserbaidschanisch',
    'äthiopisch',
    'australisch',
    'bahamaisch',
    'bahrainisch',
    'bangladeschisch',
    'barbadisch',
    'belarussisch',
    'belgisch',
    'belizisch',
    'beninisch',
    'bhutanisch',
    'bolivianisch',
    'bosnisch',
    'herzegowinisch',
    'botsuanisch',
    'brasilianisch',
    'bruneiisch',
    'bulgarisch',
    'burkinisch',
    'burundisch',
    'chilenisch',
    'chinesisch',
    'costa-ricanisch',
    'ivorisch',
    'dänisch',
    'deutsch',
    'dominicanisch',
    'dominikanisch',
    'dschibutisch',
    'ecuadorianisch',
    'salvadorianisch',
    'eritreisch',
    'estnisch',
    'fidschianisch',
    'finnisch',
    'französisch',
    'gabunisch',
    'gambisch',
    'georgisch',
    'ghanaisch',
    'grenadisch',
    'griechisch',
    'guatemaltekisch',
    'guineisch',
    'guinea-bissauisch',
    'guyanisch',
    'haitianisch',
    'honduranisch',
    'indisch',
    'indonesisch',
    'irakisch',
    'iranisch',
    'irisch',
    'isländisch',
    'israelisch',
    'italienisch',
    'jamaikanisch',
    'japanisch',
    'jemenitisch',
    'jordanisch',
    'kambodschanisch',
    'kamerunisch',
    'kanadisch',
    'kasachisch',
    'katarisch',
    'kenianisch',
    'kirgisisch',
    'kiribatisch',
    'kolumbianisch',
    'komorisch',
    'kongolesisch',
    'koreanisch',
    'kosovarisch',
    'kroatisch',
    'kubanisch',
    'kuwaitisch',
    'laotisch',
    'lesothisch',
    'lettisch',
    'libanesisch',
    'liberianisch',
    'libysch',
    'liechtensteinisch',
    'litauisch',
    'luxemburgisch',
    'madagassisch',
    'malawisch',
    'malaysisch',
    'maledivisch',
    'malisch',
    'maltesisch',
    'marokkanisch',
    'marshallisch',
    'mauretanisch',
    'mauritisch',
    'mexikanisch',
    'mikronesisch',
    'moldauisch',
    'monegassisch',
    'mongolisch',
    'montenegrinisch',
    'mosambikanisch',
    'myanmarisch',
    'namibisch',
    'nauruisch',
    'nepalesisch',
    'neuseeländisch',
    'nicaraguanisch',
    'niederländisch',
    'nigrisch',
    'nigerianisch',
    'niueanisch',
    'norwegisch',
    'omanisch',
    'österreichisch',
    'pakistanisch',
    'palauisch',
    'panamaisch',
    'papua-neuguineisch,paraguayisch',
    'peruanisch',
    'philippinisch',
    'polnisch',
    'portugiesisch',
    'ruandisch',
    'rumänisch',
    'russisch',
    'salomonisch',
    'sambisch',
    'samoanisch',
    'san-marinesisch',
    'são-toméisch',
    'saudi-arabisch',
    'schwedisch',
    'schweizerisch',
    'senegalesisch',
    'serbisch',
    'seychellisch',
    'sierra-leonisch',
    'simbabwisch',
    'singapurisch',
    'slowakisch',
    'slowenisch',
    'somalisch',
    'spanisch',
    'sri-lankisch',
    'lucianisch',
    'vincentisch',
    'südafrikanisch',
    'sudanesisch',
    'südsudanesisch',
    'surinamisch',
    'syrisch',
    'tadschikisch',
    'tansanisch',
    'thailändisch',
    'togoisch',
    'tongaisch',
    'tschadisch',
    'tschechisch',
    'tunesisch',
    'türkisch',
    'turkmenisch',
    'tuvaluisch',
    'ugandisch',
    'ukrainisch',
    'ungarisch',
    'uruguayisch',
    'usbekisch',
    'vanuatuisch',
    'vatikanisch',
    'venezolanisch',
    'amerikanisch',
    'britisch',
    'vietnamesisch',
    'zentralafrikanisch',
    'zyprisch',
    'staatenlos'
];
