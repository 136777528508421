import { CourseCategory } from './Type';

export const courseCategories: CourseCategory[] = [
    'educational_basic_qualification',
    'practical_and_theoretical_basic_qualification',
    'career_orientation_and_application_training',
    'key_competences',
    'life_practical_training',
    'basics_financial_lifestyle',
    'europe_and_i',
    'media_competence',
    'health_and_fitness',
    'external_shooling'
];

export const mapCourseCategories = (course: CourseCategory) => {
    switch (course) {
        case 'basics_financial_lifestyle':
            return 'Grundlagen finanzieller Lebensführung';
        case 'career_orientation_and_application_training':
            return 'Berufswahl/Berufsorientierung/Bewerbungstraining';
        case 'educational_basic_qualification':
            return 'Schulische Basisqualifikation';
        case 'europe_and_i':
            return 'Europa und Ich';
        case 'external_shooling':
            return 'Externe Schulung';
        case 'health_and_fitness':
            return 'Gesundheit und Fitness';
        case 'key_competences':
            return 'Schlüsselkompetenzen';
        case 'life_practical_training':
            return 'Lebenspraktisches Training';
        case 'media_competence':
            return 'Medienkompetenz';
        case 'practical_and_theoretical_basic_qualification':
            return 'Arbeitspraktische und –theoretische Basisqualifikation';
    }
};
