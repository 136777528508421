import React, { useState } from 'react';
import { Button } from '../Button/Button';
import { Label } from '../Label/Label';
import { Modal } from '../Modal/Modal';
import './CreatedUserPasswordModal.css';

interface CreatedUserPasswordModalProps {
    password: string;
    onClose: () => void;
    show: boolean;
}

export const CreatedUserPasswordModal = (props: CreatedUserPasswordModalProps) => {
    const [wasCopied, setWasCopied] = useState<boolean>(false);

    const onClickCopy = async () => {
        await navigator.clipboard.writeText(props.password);
        setWasCopied(true);
    };

    const onClickClose = () => {
        setWasCopied(false);
        props.onClose();
    };

    return (
        <Modal
            show={props.show}
            header={'Benutzer wurde erfolgreich erstellt'}
            buttons={{
                primary: {
                    text: 'Verstanden',
                    onClick: onClickClose
                }
            }}
        >
            <div className="created-user-password-modal-body">
                <div>
                    <div>Das Passwort des Benutzers lautet: </div>
                    <div className="created-user-password-modal-password">
                        <div className="p1-bold"> {props.password}</div>
                        <Button
                            type={'secondary'}
                            size={'small'}
                            buttonStyle={'outline'}
                            text={'Passwort kopieren'}
                            onClick={onClickCopy}
                        />
                    </div>
                    {wasCopied ? (
                        <div className="created-user-password-modal-password-copied">
                            {' '}
                            <Label color={'#10B981'} size={2}>
                                Passwort wurde in die Zwischenablage kopiert.
                            </Label>{' '}
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
                <div>
                    <div>Bitte teilen Sie das Passwort Ihrem Mitarbeiter/Ihrer Mitarbeiterin mit.</div>
                    <div> Nachdem dieses Fenster geschlossen wurde, können Sie das Passwort nicht erneut aufrufen.</div>
                    <div>
                        Der Mitarbeiter/Die Mitarbeiterin kann jedoch sein/ihr Passwort über die &quot;Passwort
                        zurücksetzen&quot; Funktion ändern.
                    </div>
                </div>
            </div>
        </Modal>
    );
};
