import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from './State';
import { User } from './Types';

/**
 * The slice for the redux work space state.
 */
export const UserSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: null
    } as UserState,
    reducers: {
        /**
         * Sets the given user to the state.
         *
         * @param state
         * @param action
         */
        setCurrentUser: (state, action: PayloadAction<User | null>) => {
            state.currentUser = action.payload;
        }
    }
});

export const { setCurrentUser } = UserSlice.actions;
