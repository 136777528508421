import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { Button } from '../../components/Button/Button';
import { CancelQmDocumentTemplateModal } from '../../components/CancelQmDocumentTemplateModal/CancelQmDocumentTemplateModal';
import { Icon } from '../../components/Icon/Icon';
import { Input } from '../../components/Input/Input';
import { ObjectList } from '../../components/ObjectList/ObjectList';
import { ObjectListItem } from '../../components/ObjectListItem/ObjectListItem';
import { QmDocumentTemplateDetails } from '../../components/QmDocumentTemplateDetails/QmDocumentTemplateDetails';
import { StandardView } from '../../components/StandardView/StandardView';
import { QmDocumentTemplate, QmDocumentTemplateFormValues } from '../../models/QmDocumentTemplate/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import './AdminQMDocuments.css';
import { useQmDocumentTemplatesApi } from '../../api/useQmDocumentTemplatesApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

export const AdminQMDocuments = () => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const { data: qmDocumentTemplates, mutate: mutateQmDocumentTemplates } = useSWR<QmDocumentTemplate[]>([
        backendUrl,
        'qm_document_templates',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const [selectedQmDocumentTemplate, setSelectedQmDocumentTemplate] = useState<
        QmDocumentTemplate | QmDocumentTemplateFormValues
    >();
    const [createNewQmDocumentTemplate, setCreateNewQmDocumentTemplate] = useState<QmDocumentTemplateFormValues>();
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [filteredQmDocumentTemplates, setFilteredQmDocumentTemplates] = useState<QmDocumentTemplate[]>();
    const { apiDeleteQmDocumentTemplate, apiCreateQmDocumentTemplate, apiUpdateQmDocumentTemplate } =
        useQmDocumentTemplatesApi();

    /**
     * If QM Documents templates are fetched again, set them as the filtered templates
     */
    useEffect(() => {
        setFilteredQmDocumentTemplates(qmDocumentTemplates);
    }, [qmDocumentTemplates]);

    /**
     * Set selected qmDocument to state. If there is a new qm document to be created, show a modal
     * @param index
     * @param qmDocument
     */
    const onSelectedQmDocumentTemplate = (
        index: string | number,
        qmDocument: QmDocumentTemplate | QmDocumentTemplateFormValues
    ) => {
        if (createNewQmDocumentTemplate) {
            onClickOpenModal();
        }

        setSelectedQmDocumentTemplate(qmDocument);
    };

    /**
     * Callback to open the cancel qm document creation process
     */
    const onClickOpenModal = () => {
        setShowCancelModal(!showCancelModal);
    };

    /**
     * callback to cancel the qm document creation of a new document
     */
    const onClickCancelQmDocument = () => {
        setCreateNewQmDocumentTemplate(undefined);
        onClickOpenModal();
    };

    /**
     * Open/Close Searchbar
     */
    const onClickOpenSearch = () => {
        setShowSearch(!showSearch);
    };

    /**
     * Call back if user clicks on create a new document button. Sets new document properties into a state
     */
    const onCreateNewQmDocumentTemplate = () => {
        if (createNewQmDocumentTemplate) {
            onClickOpenModal();
        } else {
            setCreateNewQmDocumentTemplate({
                name: 'Neues Dokument',
                document: undefined,
                neededAt: 'needed_at_request',
                hasInternshipCompanyFields: false
            });
        }
    };

    /**
     * if an user creates a new document set its information to the selected qm document template state
     */
    useEffect(() => {
        if (createNewQmDocumentTemplate) {
            setSelectedQmDocumentTemplate(createNewQmDocumentTemplate);
        }
    }, [createNewQmDocumentTemplate]);

    const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilteredQmDocumentTemplates(
            qmDocumentTemplates?.filter((qmDocumentTemplate) =>
                qmDocumentTemplate.name.toLowerCase().includes(event.target.value.toLowerCase() as string)
            )
        );
    };

    /**
     * Called if the form was submitted.
     * If some qmDocumentTemplate was passed in the props, the update action will be called.
     * Otherwise a new document having the specified values will be created.
     * In both cases the onSubmit callback in the props will be called.
     *
     * @param values
     */
    const onSubmit = async (values: QmDocumentTemplateFormValues | QmDocumentTemplate) => {
        if (createNewQmDocumentTemplate) {
            try {
                const newDocument = await apiCreateQmDocumentTemplate(values);
                mutateQmDocumentTemplates().then();
                showSuccessMessage('QM-Dokument erfolgreich gespeichert');
                setCreateNewQmDocumentTemplate(undefined);
                setSelectedQmDocumentTemplate(newDocument);
            } catch (e) {
                throwError('Das hat leider nicht funktioniert.');
                console.log(e);
            }
        } else if ('id' in values) {
            try {
                const document = await apiUpdateQmDocumentTemplate(values.id, values);
                showSuccessMessage('QM-Dokument erfolgreich aktualisiert');
                mutateQmDocumentTemplates().then();
                setSelectedQmDocumentTemplate(document);
            } catch (e) {
                throwError('Das hat leider nicht funktioniert.');
                console.log(e);
            }
        }
    };

    /**
     * Delete QM Document template. If there is a new qm document the state is going to be reset. If an existing document
     * is going to be deleted it will be removed from the database
     */
    const onClickDelete = async () => {
        if (createNewQmDocumentTemplate) {
            setCreateNewQmDocumentTemplate(undefined);
            resetSelectedQmDocumentTemplate();
        } else if (selectedQmDocumentTemplate && 'id' in selectedQmDocumentTemplate) {
            try {
                await apiDeleteQmDocumentTemplate(selectedQmDocumentTemplate.id);
                showSuccessMessage('QM-Dokument wurde gelöscht');
                mutateQmDocumentTemplates().then();
                resetSelectedQmDocumentTemplate();
            } catch (e) {
                throwError('Das hat leider nicht funktioniert');
                console.log(e);
            }
        }
    };

    /**
     * Reset which document should be shown to the user
     */
    const resetSelectedQmDocumentTemplate = () => {
        if (qmDocumentTemplates) {
            setSelectedQmDocumentTemplate(qmDocumentTemplates[0]);
        } else {
            setSelectedQmDocumentTemplate(undefined);
        }
    };

    return (
        <div className="admin-qm-documents">
            <CancelQmDocumentTemplateModal
                show={showCancelModal}
                onClickCancelQmDocument={onClickCancelQmDocument}
                onClickClose={onClickOpenModal}
            />
            <StandardView>
                <StandardView.Left>
                    <ObjectList>
                        <ObjectList.Head>
                            <div className="user-list-head-container" style={{ marginBottom: '24px' }}>
                                <div className={'user-list-head'}>
                                    <div className="p2-medium">QM-Dokumente</div>
                                    <div className="user-list-head-search">
                                        <Button
                                            type={'secondary'}
                                            buttonStyle={'link'}
                                            size={'small'}
                                            firstIcon={<Icon type={'Search'} />}
                                            onClick={onClickOpenSearch}
                                        />
                                        <Button
                                            type={'primary'}
                                            size={'small'}
                                            buttonStyle={'filled'}
                                            firstIcon={<Icon type={'Plus'} />}
                                            onClick={onCreateNewQmDocumentTemplate}
                                        />
                                    </div>
                                </div>
                                {showSearch && (
                                    <Input
                                        icon={<Icon type={'Search'} />}
                                        placeholder={'QM-Dokument suchen'}
                                        onChange={onChangeSearch}
                                    />
                                )}
                            </div>
                        </ObjectList.Head>
                        <ObjectList.Body>
                            <div>
                                {createNewQmDocumentTemplate && (
                                    <ObjectListItem
                                        value={{ index: -1, item: createNewQmDocumentTemplate }}
                                        selected={selectedQmDocumentTemplate === createNewQmDocumentTemplate}
                                        onClick={onSelectedQmDocumentTemplate}
                                    >
                                        <div>{createNewQmDocumentTemplate.name}</div>
                                    </ObjectListItem>
                                )}
                                {filteredQmDocumentTemplates?.map((qmDocumentTemplate, index) => {
                                    return (
                                        <ObjectListItem
                                            key={qmDocumentTemplate.id}
                                            value={{ index: index, item: qmDocumentTemplate }}
                                            selected={
                                                selectedQmDocumentTemplate && 'id' in selectedQmDocumentTemplate
                                                    ? selectedQmDocumentTemplate?.id === qmDocumentTemplate.id
                                                    : selectedQmDocumentTemplate === qmDocumentTemplate
                                            }
                                            onClick={onSelectedQmDocumentTemplate}
                                        >
                                            <div>{qmDocumentTemplate.name}</div>
                                        </ObjectListItem>
                                    );
                                })}
                            </div>
                        </ObjectList.Body>
                    </ObjectList>
                </StandardView.Left>
                <StandardView.Right>
                    {selectedQmDocumentTemplate && (
                        <Formik
                            initialValues={selectedQmDocumentTemplate}
                            validationSchema={null}
                            onSubmit={(values) => onSubmit(values)}
                            enableReinitialize
                        >
                            {({ values, errors, handleChange, setFieldValue, handleSubmit, resetForm }) => (
                                <QmDocumentTemplateDetails
                                    isEdit={!!createNewQmDocumentTemplate}
                                    qmDocumentTemplate={selectedQmDocumentTemplate}
                                    values={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    handleSubmit={handleSubmit}
                                    onClickDelete={onClickDelete}
                                    resetForm={resetForm}
                                />
                            )}
                        </Formik>
                    )}
                </StandardView.Right>
            </StandardView>
        </div>
    );
};
