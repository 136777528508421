import { KeyedMutator } from 'swr';
import { Button } from '../../components/Button/Button';
import { Icon } from '../../components/Icon/Icon';
import { UserObject } from '../../models/Employee/Types';
import { throwWarning } from '../../models/Toasts/Toasts';
import { UserDetails } from '../UserDetails/UserDetails';
import './UserInformation.css';
import { useUsersApi } from '../../api/useUsersApi';

interface UserInformationProps {
    user: UserObject | undefined;
    reloadUserList: KeyedMutator<UserObject[]>;
    reloadUser: () => void;
    resetUser: () => void;
}

export const UserInformation = ({ user, reloadUserList, resetUser, reloadUser }: UserInformationProps) => {
    const { apiGetUserActiveMeasure, apiArchiveUserInMeasure, apiArchiveUser, apiActivateUserAgain } = useUsersApi();

    /**
     * Archive user
     */
    const onArchiveUser = async () => {
        if (user && user.id) {
            try {
                const measures = await apiGetUserActiveMeasure(user.id);
                if (measures.length > 0) {
                    await Promise.all(measures.map((measure) => apiArchiveUserInMeasure(measure.id, user.id)));
                }

                await apiArchiveUser(user.id);
                reloadUserList().then();
                resetUser();
            } catch (e) {
                console.log(e);
                throwWarning('Dieser Nutzer kann nicht archiviert werden');
            }
        }
    };

    /**
     * Activate user
     */
    const onActivateUser = async () => {
        if (user && user.id) {
            try {
                await apiActivateUserAgain(user.id);
                reloadUserList().then();
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <div className="user-information-container">
            <div className="user-information-container-head">
                <h5>
                    {user?.firstName} {user?.lastName}
                </h5>
                {!user?.archived ? (
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'outline'}
                        firstIcon={<Icon type={'Archive'} />}
                        text={'Archivieren'}
                        onClick={onArchiveUser}
                    />
                ) : (
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'outline'}
                        firstIcon={<Icon type={'Archive'} />}
                        text={'Aktivieren'}
                        onClick={onActivateUser}
                    />
                )}
            </div>
            <UserDetails reloadUser={reloadUser} reloadUserList={reloadUserList} user={user} />
        </div>
    );
};
