import './FullscreenSidebarItem.css';

interface FullscreenSidebarItemProps {
    name: string;
    selected?: boolean;
    finished?: boolean;
    onClick?: () => void;
}
export const FullscreenSidebarItem = (props: FullscreenSidebarItemProps) => {
    return (
        <div
            className={`full-screen-side-bar-item ${props.selected ? 'selected' : props.finished ? 'finished' : null}`}
            onClick={props.onClick}
        >
            {!props.selected && !props.finished && (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99967 4.58331C7.00813 4.58331 4.58301 7.00844 4.58301 9.99998C4.58301 12.9915 7.00813 15.4166 9.99967 15.4166C12.9912 15.4166 15.4163 12.9915 15.4163 9.99998C15.4163 7.00844 12.9912 4.58331 9.99967 4.58331ZM3.33301 9.99998C3.33301 6.31808 6.31778 3.33331 9.99967 3.33331C13.6816 3.33331 16.6663 6.31808 16.6663 9.99998C16.6663 13.6819 13.6816 16.6666 9.99967 16.6666C6.31778 16.6666 3.33301 13.6819 3.33301 9.99998Z"
                        fill="#71717A"
                    />
                </svg>
            )}
            {props.selected && !props.finished && (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.0059 5.19345C11.2442 4.94377 11.6399 4.93457 11.8896 5.1729L16.4729 9.5479C16.5964 9.66584 16.6663 9.8292 16.6663 10C16.6663 10.1708 16.5964 10.3342 16.4729 10.4521L11.8896 14.8271C11.6399 15.0654 11.2442 15.0562 11.0059 14.8065C10.7676 14.5569 10.7768 14.1612 11.0265 13.9229L14.4814 10.625H3.95801C3.61283 10.625 3.33301 10.3452 3.33301 10C3.33301 9.65482 3.61283 9.375 3.95801 9.375L14.4814 9.375L11.0265 6.0771C10.7768 5.83876 10.7676 5.44314 11.0059 5.19345Z"
                        fill="#EA580C"
                    />
                </svg>
            )}
            {props.finished && (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99967 3.33331C6.31778 3.33331 3.33301 6.31808 3.33301 9.99998C3.33301 13.6819 6.31778 16.6666 9.99967 16.6666C13.6816 16.6666 16.6663 13.6819 16.6663 9.99998C16.6663 6.31808 13.6816 3.33331 9.99967 3.33331ZM12.9798 8.5251C13.2008 8.25992 13.165 7.86582 12.8998 7.64484C12.6346 7.42387 12.2405 7.45969 12.0195 7.72487L9.30024 10.988L8.32308 10.1504C8.06101 9.92581 7.66644 9.95616 7.4418 10.2182C7.21717 10.4803 7.24752 10.8749 7.50959 11.0995L8.96793 12.3495C9.09492 12.4584 9.26025 12.5118 9.42693 12.4978C9.59361 12.4838 9.74773 12.4036 9.85481 12.2751L12.9798 8.5251Z"
                        fill="#71717A"
                    />
                </svg>
            )}
            <div className="p4-medium">{props.name}</div>
        </div>
    );
};
