import { QmDocument, QmDocumentDocx, QmDocumentSearch } from '../models/QmDocument/Types';
import { EzOnRailsSearchFilterRequest } from '../models/Search/Type';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Returns methods to communicate with the backend related to QmDocuments.
 */
export const useQmDocumentsApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API to get all QM Documents.
     *
     *
     */
    const apiGetQmDocuments = useCallback(async (): Promise<QmDocument[]> => {
        return call<void, QmDocument[]>('qm_documents', 'GET');
    }, [call]);

    /**
     * API to create a QM document belonging to a measure
     *
     * @param name
     * @param measureId
     * @param qmDocumentTemplateId
     */
    const apiAddQmDocumentToMeasure = useCallback(
        async (name: string, measureId: number, qmDocumentTemplateId: number): Promise<QmDocument[]> => {
            return call<{ name: string; measureId: number; qmDocumentTemplateId: number }, QmDocument[]>(
                'qm_documents',
                'POST',
                { name: name, measureId: measureId, qmDocumentTemplateId: qmDocumentTemplateId }
            );
        },
        [call]
    );

    /**
     * Delete a QM Document
     *
     * @param documentId
     */
    const apiDeleteQmDocument = useCallback(
        async (documentId: number) => {
            return call<void, unknown>(`qm_documents/${documentId}`, 'DELETE');
        },
        [call]
    );

    /**
     * API to create a docx from a document
     *
     * @param documentId
     */
    const apiCreateQmDocumentDocx = useCallback(
        async (documentId: number): Promise<QmDocumentDocx> => {
            return call<void, QmDocumentDocx>(`qm_documents/${documentId}/create_doc`, 'POST');
        },
        [call]
    );

    /**
     * Search QM Documents
     *
     * @param filter
     */
    const apiSearchQmDocument = useCallback(
        async (filter: EzOnRailsSearchFilterRequest): Promise<QmDocumentSearch> => {
            return call<EzOnRailsSearchFilterRequest, QmDocumentSearch>('qm_documents/search', 'POST', filter);
        },
        [call]
    );

    const apiSearchQmDocumentsForPrinting = useCallback(
        async (measureId: number, qmDocumentTemplateId: number): Promise<QmDocumentSearch> => {
            return call<EzOnRailsSearchFilterRequest, QmDocumentSearch>('qm_documents/search', 'POST', {
                filter: {
                    logic: 'and',
                    filters: [
                        { field: 'measure_id', operator: 'eq', value: measureId },
                        { field: 'qm_document_template_id', operator: 'eq', value: qmDocumentTemplateId }
                    ]
                }
            });
        },
        [call]
    );

    const apiUpdateQmDocument = useCallback(
        async (qmDocumentId: number, document: Partial<QmDocument>): Promise<QmDocument> => {
            return call<Partial<QmDocument>, QmDocument>(`qm_documents/${qmDocumentId}`, 'PATCH', document);
        },
        [call]
    );

    return {
        apiGetQmDocuments: apiGetQmDocuments,
        apiAddQmDocumentToMeasure: apiAddQmDocumentToMeasure,
        apiDeleteQmDocument: apiDeleteQmDocument,
        apiCreateQmDocumentDocx: apiCreateQmDocumentDocx,
        apiSearchQmDocument: apiSearchQmDocument,
        apiSearchQmDocumentsForPrinting: apiSearchQmDocumentsForPrinting,
        apiUpdateQmDocument: apiUpdateQmDocument
    };
};
