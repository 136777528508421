import { FormikErrors } from 'formik';
import React, { useEffect } from 'react';
import { isObjectEmpty } from '../../helpers';
import { UserObject } from '../../models/Employee/Types';
import { usePrevious } from '../../models/Hooks/Hooks';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { UserGender, UserGroup } from '../../models/User/Types';
import { mapGender, mapRights } from '../../models/User/User';
import { DropDown } from '../DropDown/DropDown';
import { List } from '../List/List';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { useUsersApi } from '../../api/useUsersApi';

interface UserDetailsFormListProps {
    values: UserObject;
    handleChange: FormikHandleChange;
    requestToChangeUser: (values: UserObject) => void;
    refresh: () => void;
    setFieldValue: FormikSetFieldValue;
    user: UserObject | undefined;
    errors: FormikErrors<UserObject>;
}

export const UserDetailsFormList = (props: UserDetailsFormListProps) => {
    const { values, handleChange, requestToChangeUser, refresh, setFieldValue, user } = props;
    const previousValueGender: UserGender | undefined = usePrevious(values.gender);
    const { apiAssignUserToGroup, apiRemoveUserFromGroup } = useUsersApi();

    /**
     * Change value when text field is blurred
     */
    const onChangeBlur = () => {
        if (values && isObjectEmpty(props.errors) && JSON.stringify(user) !== JSON.stringify(values)) {
            requestToChangeUser(values);
        }
    };

    /**
     * Check if gender value has changed, if so send the new user object to the backend
     */
    useEffect(() => {
        if (previousValueGender !== values.gender) {
            requestToChangeUser(values);
        }
    }, [previousValueGender, requestToChangeUser, values]);

    /**
     * Change gender of user
     * @param gender
     */
    const onChangeGender = (gender: UserGender) => {
        setFieldValue('gender', gender);
    };

    /**
     * Change user group. Make an api call to send the information to the backend and refresh the user.
     * @param values
     * @param group
     */
    const onChangeRights = async (values: UserObject, group: UserGroup) => {
        if (values.id && isObjectEmpty(props.errors)) {
            await onRemoveRights(values);
            try {
                await apiAssignUserToGroup(values.id, group);
                showSuccessMessage('Benutzer wurde geändert 🙂');
                refresh();
            } catch (e) {
                console.log(e);
            }
        }
    };

    /**
     * Remove old user group from user
     * @param values
     */
    const onRemoveRights = async (values: UserObject) => {
        if (values.id && isObjectEmpty(props.errors)) {
            try {
                await Promise.all(
                    values.groups.map((group) => {
                        return apiRemoveUserFromGroup(values.id, group);
                    })
                );
            } catch (e) {
                console.log(e);
                throwError();
            }
        }
    };

    const onKeyEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    };

    return (
        <List
            readonly={user?.archived}
            options={[
                {
                    label: 'Vorname',
                    value: values.firstName,
                    name: 'firstName',
                    onChange: handleChange,
                    onBlur: onChangeBlur,
                    error: props.errors.firstName,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Nachname',
                    value: values.lastName,
                    onChange: handleChange,
                    name: 'lastName',
                    onBlur: onChangeBlur,
                    error: props.errors.lastName,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Geschlecht',
                    input: user?.archived ? (
                        <div className={'editable-readonly-item'}>{mapGender(values.gender)}</div>
                    ) : (
                        <DropDown
                            title={<div>{mapGender(values.gender)}</div>}
                            titleClassName={'dropdown-list-title'}
                            className={'dropdown-list'}
                        >
                            <div onClick={() => onChangeGender('male')}>männlich</div>
                            <div onClick={() => onChangeGender('female')}>weiblich</div>
                            <div onClick={() => onChangeGender('diverse')}>divers</div>
                        </DropDown>
                    )
                },
                {
                    label: 'Telefon',
                    value: values.phone,
                    onChange: handleChange,
                    name: 'phone',
                    onBlur: onChangeBlur,
                    error: props.errors.phone,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Fax',
                    value: values.fax,
                    onChange: handleChange,
                    name: 'fax',
                    onBlur: onChangeBlur,
                    error: props.errors.fax,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'E-Mail',
                    value: values.email,
                    onChange: handleChange,
                    name: 'email',
                    onBlur: onChangeBlur,
                    error: props.errors.email,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Rechte',
                    input: user?.archived ? (
                        <div className={'editable-readonly-item'}>{mapRights(values.groups[0])}</div>
                    ) : (
                        <DropDown
                            title={<div>{mapRights(values.groups[0])}</div>}
                            titleClassName={'dropdown-list-title'}
                            className={'dropdown-list'}
                        >
                            <div onClick={() => onChangeRights(values, 'QmManager')}>QM Beauftragter</div>
                            <div onClick={() => onChangeRights(values, 'Administrator')}>Administrator</div>
                            <div onClick={() => onChangeRights(values, 'Employee')}>Mitarbeiter</div>
                        </DropDown>
                    )
                }
            ]}
        />
    );
};
