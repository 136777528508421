import useSWR from 'swr';
import { UserObject } from '../../models/Employee/Types';
import { Measure } from '../../models/Measure/Types';
import { throwError } from '../../models/Toasts/Toasts';
import { AddToMeasure } from '../AddToMeasure/AddToMeasure';
import { Avatar } from '../Avatar/Avatar';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';
import './MeasuerUser.css';
import { useUsersApi } from '../../api/useUsersApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface MeasureUserProps {
    measure: Measure;
}

export const MeasureUser = (props: MeasureUserProps) => {
    // authentication information
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { data: users } = useSWR([backendUrl, 'users', 'GET', null, authInfo, apiVersion]);
    const { apiArchiveUserInMeasure } = useUsersApi();

    /**
     * Fetch of active users of a measure
     */
    const { data: measureUsers, mutate } = useSWR<UserObject[]>([
        backendUrl,
        `measures/${props.measure.id}/active_users`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    /**
     * Archive user onClick
     * @param userId
     */
    const archiveUser = async (userId: number | undefined) => {
        try {
            if (props.measure && userId) {
                await apiArchiveUserInMeasure(props.measure?.id, userId);
                mutate().then();
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    return (
        <div className="measure-user">
            <Label size={2} color={'#A1A1AA'}>
                MITARBEITER
            </Label>
            <div className="measure-user-show-users">
                {measureUsers?.map((user) => {
                    return (
                        <div key={user.id} className="measure-user-avatar">
                            <Avatar size={'small'} url={user.avatarUrl} />
                            <Icon
                                type={'CloseCircle'}
                                className="measure-user-avatar-remove"
                                onClick={() => archiveUser(user.id)}
                            />
                            <div className="measure-user-avatar-hover-name">
                                {user.firstName} {user.lastName}
                            </div>
                        </div>
                    );
                })}
                <AddToMeasure allUsers={users} mutate={mutate} user={measureUsers} measure={props.measure} />
            </div>
        </div>
    );
};
