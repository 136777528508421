import { useState } from 'react';
import useSWR from 'swr';
import { CreatedUserPasswordModal } from '../../components/CreatedUserPasswordModal/CreatedUserPasswordModal';
import { LoadingView } from '../../components/LoadingView/LoadingView';
import { UserInformation } from '../../components/UserInformation/UserInformation';
import { UserList } from '../../components/UserList/UserList';
import { UserObject } from '../../models/Employee/Types';
import { throwError } from '../../models/Toasts/Toasts';
import { CreatableUser } from '../../models/User/Types';
import { AdminCreateUser } from '../AdminCreateUser/AdminCreateUser';
import './AdminUserTab.css';
import { useUsersApi } from '../../api/useUsersApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

export const AdminUserTab = () => {
    const [selectedUser, setSelectedUser] = useState<UserObject>();
    const [wasSubmitted, setWasSubmitted] = useState<boolean>(true);
    const [submitError, setSubmitError] = useState<string>('');
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const [showCreateUser, setShowCreateUser] = useState<boolean>(false);
    const [newUserPassword, setNewUserPassword] = useState<string>('');
    const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
    const { apiAssignUserToGroup, apiCreateUser } = useUsersApi();

    /**
     * Fetch of all users
     */
    const { data: users, mutate: reloadUserList } = useSWR<UserObject[]>([
        backendUrl,
        'users',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    const reloadUser = async () => {
        await reloadUserList();
        setSelectedUser(users?.find((user) => user.id === selectedUser?.id));
    };

    /**
     * Callback to write user into state that was selected
     * @param user
     */
    const onClickUserInList = (user: UserObject) => {
        setSelectedUser(user);
    };

    /**
     * Close/open create new user view
     */
    const onShowCreateUser = () => {
        setShowCreateUser(!showCreateUser);
    };

    /**
     * Call back to create user when information is submitted
     * @param user
     */
    const onSubmitCreateUser = async (user: CreatableUser) => {
        setWasSubmitted(false);
        try {
            const createdUser = await apiCreateUser(user);
            if (createdUser) {
                if (user.group && createdUser.profile.id) {
                    await apiAssignUserToGroup(createdUser.profile.id, user.group);
                }

                if (createdUser.password) {
                    setNewUserPassword(createdUser.password);
                }

                onShowCreateUser();
                setShowPasswordModal(true);
                setSubmitError('');
                setWasSubmitted(true);
                reloadUserList().then();
            }
        } catch (e) {
            setWasSubmitted(true);
            throwError(
                'Leider ist bei der Erstellung ein Fehler aufgetreten.' +
                    'Bitte überprüfen Sie Ihre Eingaben.' +
                    'Achten Sie darauf, dass Sie eine eindeutige E-Mail eingeben.' +
                    'Sollte der Fehler öfter auftreten, kontaktieren Sie bitte Ihren Administrator.'
            );
            console.log(e);
        }
    };

    /**
     * Close Modal
     */
    const onCloseModal = () => {
        setShowPasswordModal(false);
        setNewUserPassword('');
    };

    const resetUser = () => {
        if (users) {
            setSelectedUser(users.find((user) => !user.archived));
        }
    };

    return (
        <div className={'admin-user-tab'}>
            <CreatedUserPasswordModal show={showPasswordModal} onClose={onCloseModal} password={newUserPassword} />
            {showCreateUser ? (
                <>
                    {!wasSubmitted && <LoadingView />}
                    <AdminCreateUser
                        users={users}
                        onClose={onShowCreateUser}
                        onSubmit={onSubmitCreateUser}
                        submitError={submitError}
                    />{' '}
                </>
            ) : (
                <>
                    <UserList
                        selectedUser={selectedUser}
                        users={users}
                        onGetUser={onClickUserInList}
                        onCreateNewUser={onShowCreateUser}
                    />
                    <UserInformation
                        resetUser={resetUser}
                        reloadUserList={reloadUserList}
                        reloadUser={reloadUser}
                        user={selectedUser}
                    />
                </>
            )}
        </div>
    );
};
