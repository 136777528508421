import { ReactElement } from 'react';
import './ObjectListHead.css';

interface ObjectListHeadProps {
    children: ReactElement | ReactElement[];
}

export const ObjectListHead = (props: ObjectListHeadProps) => {
    return <div className={'object-list-head'}>{props.children}</div>;
};
