import { useState } from 'react';
import './FormRadioButton.css';

interface FormRadioButtonProps<T> {
    // radiobutton text
    text: string;
    // click callback
    onChange: (value: T) => void;
    // radiobutton name (needed for html radiobutton - probably obsolete)
    name?: string;
    // form value
    value: T;
    // if radiobutton is required
    required?: boolean;
    // outside click check - for cases in which only one radiobutton should be checked
    checked?: boolean | undefined;
    // error message from outside
    error?: string;
}

export const FormRadioButton = <T,>(props: FormRadioButtonProps<T>) => {
    /**
     * State to handle internally if radiobutton was checked
     */
    const [radioButtonIsChecked, setRadioButtonIsChecked] = useState<boolean>(false);

    const onChange = () => {
        if (props.checked === undefined) {
            setRadioButtonIsChecked(!radioButtonIsChecked);
        }

        props.onChange(props.value);
    };

    return (
        <div className="form-radiobutton">
            <div onClick={onChange}>
                <div
                    className={`radiobutton ${radioButtonIsChecked || props.checked ? 'checked' : ''} ${
                        props.error && 'radiobutton-error'
                    }`}
                >
                    {(radioButtonIsChecked || props.checked) && <div className="radiobutton-inner" />}
                </div>
                <div className="radiobutton-text">{props.text}</div>
            </div>
        </div>
    );
};
