import { formatDate } from '../../models/Date/Date';
import { Datepicker } from '../Datepicker/Datepicker';
import { Icon } from '../Icon/Icon';

interface EmptyDatepickerProps {
    onChange: (date: Date) => void;
    initialDate: Date;
    value?: Date;
}

export const EmptyDatepicker = (props: EmptyDatepickerProps) => {
    return (
        <div className={'empty'}>
            <Datepicker
                onChange={props.onChange}
                initialDate={props.initialDate}
                value={props.value}
                position={'fixed'}
            >
                {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                    <>
                        <Icon className="update-need-for-action-deadline-icon" type={'Calendar'} />
                        {props.value ? (
                            <input
                                className="input"
                                onFocus={openDatepicker}
                                value={formatDate(props.value)}
                                onChange={onChangeDateInForm}
                                onPaste={onPasteDate}
                                onKeyDown={onPressEnterSubmit}
                            />
                        ) : (
                            <input className="input" onFocus={openDatepicker} placeholder={'Zielfrist'} />
                        )}
                    </>
                )}
            </Datepicker>
        </div>
    );
};
