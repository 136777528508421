import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { useCallback, useEffect } from 'react';

interface CreateProcessKeyboardEnterProps {
    values: FormikValues;
    onClickEnter: (
        event: KeyboardEvent,
        values: FormikValues,
        errors: FormikErrors<unknown>,
        validateForm: () => void,
        setTouched: (t: FormikTouched<unknown>) => void,
        touched: FormikTouched<unknown>
    ) => void;
    errors: FormikErrors<unknown>;
    validateForm: () => void;
    touched: FormikTouched<unknown>;
    setTouched: (t: FormikTouched<unknown>) => void;
}

/**
 * This component is basically a component to bring some formik events to outside functions
 * @constructor
 * @param props
 */
export const CreateProcessKeyboardEnter = (props: CreateProcessKeyboardEnterProps) => {
    const { onClickEnter, values, errors, validateForm, touched, setTouched } = props;

    const eventListenerFunction = useCallback(
        (event: KeyboardEvent) => {
            onClickEnter(event, values, errors, validateForm, setTouched, touched);
        },
        [errors, onClickEnter, setTouched, touched, validateForm, values]
    );

    // register event listener for enter click
    useEffect(() => {
        document.addEventListener('keypress', eventListenerFunction);

        return () => document.removeEventListener('keypress', eventListenerFunction);
    }, [eventListenerFunction]);

    return null;
};
