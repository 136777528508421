import { LostPasswordForm } from '@d4us1/ez-on-rails-react';
import React from 'react';
import SoRocketImg from '../../assets/img/sorocket_platform_logo.png';
import './LostPasswordPage.css';
import { useNavigate } from 'react-router-dom';
import { UserPageLinks } from '../../components/UserPageLinks/UserPageLinks';

/**
 * Page that shows up a welcome screen.
 * This is the entry point of the app. If the user is not signed in, he will be redirected to the login page.
 */
export const LostPasswordPage = () => {
    const navigate = useNavigate();

    /**
     * Called if the lost password request fails.
     * Shows up an error message.
     */
    const onPasswordResetSuccess = () => {
        alert('Ihnen wurde eine E-Mail mit weiteren Instruktionen gesendet.');
        navigate('/');
    };

    /**
     * Called if the lost password request fails.
     * Shows up an error message.
     */
    const onPasswordResetError = () => {
        alert('Anfrage fehlgeschlagen.');
    };

    return (
        <div>
            <div className="login-page-container">
                <div className="login-page">
                    <h3>Passwort zurücksetzen</h3>
                    <img src={SoRocketImg} alt={'platform logo'} />
                    <LostPasswordForm
                        onLostPasswordSuccess={onPasswordResetSuccess}
                        onLostPasswordError={onPasswordResetError}
                        submitButtonClassName={'reset-password-page-container-button'}
                        submitButtonContainerClassName={'login-page-container-button-login'}
                        containerClassName={'lost-password-container'}
                    />
                    <UserPageLinks login={true} />
                </div>
            </div>
        </div>
    );
};
