import { useState } from 'react';
import useSWR from 'swr';
import { useAppSelector } from '../../hooks';
import { formatDate } from '../../models/Date/Date';
import { ObjectiveAgreement } from '../../models/ObjectiveAgreement/Type';
import { ParticipantWithOneMeasure } from '../../models/Participant/Types';
import { SupportPlan } from '../../models/SupportPlans/Type';
import { showSuccessMessage, throwError, throwInfo } from '../../models/Toasts/Toasts';
import { Button } from '../Button/Button';
import { CreateObjectiveAgreement } from '../CreateObjecteAgreement/CreateObjectiveAgreement';
import { Datepicker } from '../Datepicker/Datepicker';
import { Icon } from '../Icon/Icon';
import { List } from '../List/List';
import { ObjectiveAgreementCard } from '../ObjectiveAgreementCard/ObjectiveAgreementCard';
import './SupportPlanDetails.css';
import { useSupportPlansApi } from '../../api/useSupportPlansApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface SupportPlanDetailsProps {
    supportPlan: SupportPlan;
    participant: ParticipantWithOneMeasure | undefined;
    reload: () => Promise<void>;
    onAddObjectiveAgreementToNewSupportPlan?: (arg: ObjectiveAgreement) => void;
}

export const SupportPlanDetails = (props: SupportPlanDetailsProps) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const participant = useAppSelector((x) => x.participant.selectedParticipant);
    const [showCreateObjectiveAgreement, setShowCreateObjectiveAgreement] = useState<boolean>(false);
    const { data: objectiveAgreements, mutate: reloadObjectiveAgreements } = useSWR<ObjectiveAgreement[]>([
        backendUrl,
        `support_plans/${props.supportPlan.id}/objective_agreements`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { apiDeleteSupportPlan, apiUpdateSupportPlan } = useSupportPlansApi();

    const onShowCreateObjectiveAgreement = () => {
        setShowCreateObjectiveAgreement(!showCreateObjectiveAgreement);
    };

    const onDelete = async () => {
        try {
            await apiDeleteSupportPlan(props.supportPlan?.id);
            showSuccessMessage('Förderplan gelöscht.');
            props.reload().then();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    const onSolveSupportPlan = async () => {
        if (objectiveAgreements?.some((objectiveAgreement) => objectiveAgreement.achieved === 'open')) {
            throwInfo('Es sind noch offene Ziele vorhanden. Bitte schließen Sie diese ab.');
            return;
        } else {
            try {
                await apiUpdateSupportPlan(props.supportPlan.id, { closed: true });
                props.reload().then();
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    const onAddObjectiveAgreementToNewSupportPlan = (objectiveAgreement: ObjectiveAgreement) => {
        if (props.onAddObjectiveAgreementToNewSupportPlan) {
            props.onAddObjectiveAgreementToNewSupportPlan(objectiveAgreement);
        }
    };

    /**
     * Change support plan name which is basically a date
     * @param name
     */
    const onChangeSupportplanName = async (name: Date | string) => {
        const convertedName = name instanceof Date ? name.toString() : name;

        try {
            await apiUpdateSupportPlan(props.supportPlan.id, {
                ...props.supportPlan,
                ...{ name: convertedName }
            });
            props.reload();
            showSuccessMessage();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    const checkSupportPlanName = (value: string | number) => {
        const regex = new RegExp('\\d\\d\\.\\d\\d\\.\\d\\d\\d\\d');
        if (typeof value === 'string' && regex.test(value)) {
            return `${value[3]}${value[4]}.${value[0]}${value[1]}.${value[6]}${value[7]}${value[8]}${value[8]}`;
        } else {
            return value;
        }
    };

    const checkSupportPlanNameDate = (value: string | number) => {
        const regex = new RegExp('\\d\\d\\.\\d\\d\\.\\d\\d\\d\\d');
        if (typeof value === 'string' && regex.test(value)) {
            // tslint:disable-next-line:radix
            return `${value[6]}${value[7]}${value[8]}${value[8]}, ${value[3]}${value[4]}, ${value[0]}${value[1]}`;
        } else {
            return value;
        }
    };

    return (
        <div key={props.supportPlan.id}>
            {showCreateObjectiveAgreement && (
                <CreateObjectiveAgreement
                    reloadObjectiveAgreements={reloadObjectiveAgreements}
                    participant={props.participant}
                    supportPlan={props.supportPlan}
                    onClose={onShowCreateObjectiveAgreement}
                />
            )}
            <div className="support-plan-details-title">
                <h5>Förderplan vom {formatDate(new Date(checkSupportPlanName(props.supportPlan?.name)))}</h5>
                <div style={{ display: 'flex', gap: '13px' }}>
                    {!props.supportPlan.closed && !participant?.measuresParticipants?.inactive ? (
                        <Button
                            type={'primary'}
                            size={'medium'}
                            buttonStyle={'outline'}
                            firstIcon={<Icon type={'Plus'} />}
                            text={'Neues Ziel hinzufügen'}
                            onClick={onShowCreateObjectiveAgreement}
                        />
                    ) : (
                        <div>Dieser Förderplan ist bereits geschlossen</div>
                    )}
                    {props.supportPlan &&
                    !props.supportPlan.closed &&
                    objectiveAgreements &&
                    objectiveAgreements.length > 0 ? (
                        !participant?.measuresParticipants?.inactive ? (
                            <Button
                                type={'primary'}
                                size={'medium'}
                                buttonStyle={'outline'}
                                firstIcon={<Icon type={'ArrowRight'} />}
                                text={'Förderplan schließen'}
                                onClick={onSolveSupportPlan}
                            />
                        ) : null
                    ) : null}
                    {!props.supportPlan.closed ? (
                        !participant?.measuresParticipants?.inactive ? (
                            <Button
                                type={'secondary'}
                                size={'small'}
                                buttonStyle={'outline'}
                                firstIcon={<Icon type={'Delete'} className={'delete-red'} />}
                                onClick={onDelete}
                            />
                        ) : null
                    ) : null}
                </div>
            </div>
            <List
                className={'support-plan-details-calendar'}
                options={[
                    {
                        label: 'Datum der Vereinbarung',
                        value: formatDate(new Date(checkSupportPlanNameDate(props.supportPlan.name))),
                        input: (
                            <Datepicker
                                value={new Date(checkSupportPlanNameDate(props.supportPlan.name))}
                                onChange={onChangeSupportplanName}
                                returnInputFieldDate={onChangeSupportplanName}
                            >
                                {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                    <div className="details-form-list-calendar" onClick={openDatepicker}>
                                        <input
                                            className={'editable-item'}
                                            value={formatDate(
                                                new Date(checkSupportPlanNameDate(props.supportPlan.name))
                                            )}
                                            onChange={onChangeDateInForm}
                                            onPaste={onPasteDate}
                                            onKeyDown={onPressEnterSubmit}
                                        />
                                    </div>
                                )}
                            </Datepicker>
                        )
                    }
                ]}
            />
            <div className="support-plan-details-objective-agreements">
                {objectiveAgreements?.map((objectiveAgreement) => {
                    return (
                        <ObjectiveAgreementCard
                            key={objectiveAgreement.id}
                            dontShowGoBack
                            onAddObjectiveAgreementToNewSupportPlan={onAddObjectiveAgreementToNewSupportPlan}
                            supportPlan={props.supportPlan}
                            reloadObjectiveAgreements={reloadObjectiveAgreements}
                            objectiveAgreement={objectiveAgreement}
                        />
                    );
                })}
            </div>
        </div>
    );
};
