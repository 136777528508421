import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { KeyedMutator } from 'swr';
import { useOnClickOutside } from 'usehooks-ts';
import { UserObject } from '../../models/Employee/Types';
import { Measure } from '../../models/Measure/Types';
import { Avatar } from '../Avatar/Avatar';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { InputSelect } from '../InputSelect/InputSelect';
import './AddToMeasure.css';
import { useUsersApi } from '../../api/useUsersApi';
import { useMeasuresApi } from '../../api/useMeasuresApi';

interface AddToMeasureProps {
    // measure object to show the information
    measure: Measure | undefined;
    user: UserObject[] | undefined;
    allUsers: UserObject[] | undefined;
    mutate: KeyedMutator<UserObject[]>;
}

export const AddToMeasure = (props: AddToMeasureProps) => {
    // selected users which should be added to a measure
    const [usersToAdd, setUsersToAdd] = useState<number>();
    // users that are registered in the app and not already assigned to the measure
    const [usersForSelection, setUsersForSelection] = useState<UserObject[] | undefined>();
    // authentication information
    const [showAddToMeasure, setShowAddToMeasure] = useState<boolean>(false);

    // Create a ref that we add to the element for which we want to detect outside clicks
    const ref = useRef<HTMLDivElement>(null);
    // Call hook passing in the ref and a function to call on outside click
    useOnClickOutside(ref, () => setShowAddToMeasure(false));
    const { apiGetAllMeasureUsersOfUser } = useUsersApi();
    const { apiReactivateArchivedUserInMeasure, apiAddUserToMeasure } = useMeasuresApi();

    /**
     * show add user to measure button
     */
    const onShowMeasure = () => {
        setShowAddToMeasure(!showAddToMeasure);
    };

    /**
     * Reset button when measure changes
     */
    useEffect(() => {
        setShowAddToMeasure(false);
    }, [props.measure]);

    /**
     * add user id that was selected to an array
     * @param id
     */
    const addUser = (id: number | undefined) => {
        if (!id) return;

        setUsersToAdd(id);
    };

    const userWasInMeasureBefore = async () => {
        if (usersToAdd) {
            const userMeasures = await apiGetAllMeasureUsersOfUser(usersToAdd);
            if (userMeasures) {
                return userMeasures.find((userMeasure) => userMeasure.measureId === props.measure?.id)?.archived;
            }
        }
    };

    /**
     * Backend call to add users to a measure
     */
    const addUserToMeasure = async () => {
        try {
            if (props.measure?.id && usersToAdd) {
                if (await userWasInMeasureBefore()) {
                    await apiReactivateArchivedUserInMeasure(props.measure.id, usersToAdd);
                } else {
                    const response = await apiAddUserToMeasure(usersToAdd, props.measure.id);
                    if (response) {
                    }
                }

                props.mutate().then();
            }
        } catch (e) {
            console.log(e);
        }

        onShowMeasure();
    };

    /**
     * Filter which users are currently not assigned to the measure
     */
    useLayoutEffect(() => {
        setUsersForSelection(
            props.allUsers?.filter(
                (singleUser) => !props.user?.some((user) => user.id === singleUser.id) && !singleUser.archived
            )
        );
    }, [props.allUsers, props.user]);

    return (
        <>
            {usersForSelection && usersForSelection.length > 0 && !props.measure?.archived ? (
                !showAddToMeasure ? (
                    <div className="measure-user-add-user-button" ref={ref} onClick={onShowMeasure}>
                        {<Icon type={'Plus'} />}{' '}
                    </div>
                ) : (
                    <div className="add-user-to-measure">
                        <InputSelect
                            size={'small'}
                            dropdownOptions={usersForSelection?.map((registeredUser) => {
                                return {
                                    icon: <Avatar size={'small'} url={registeredUser.avatarUrl} />,
                                    value: `${registeredUser.firstName} ${registeredUser.lastName}`,
                                    id: registeredUser.id
                                };
                            })}
                            onChange={addUser}
                        />
                        <Button
                            text={'Hinzufügen'}
                            type={'primary'}
                            size={'small'}
                            buttonStyle={'filled'}
                            onClick={addUserToMeasure}
                        />
                    </div>
                )
            ) : null}
        </>
    );
};
