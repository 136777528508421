
const digest = '3b40b57da3a63bbe88792bcd29aa49a0d40059f3102438a589ca7831c00db4d8';
const css = `._container_1kagm_1 {
    flex: 1;
    display: flex;
    flex-direction: column;
}

._hrefInput_1kagm_7 {
    min-width: 15rem;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"container":"_container_1kagm_1","hrefInput":"_hrefInput_1kagm_7"};
export { css, digest };
  