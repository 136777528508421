import { Formik } from 'formik';
import { ParticipantWithOneMeasure, ParticipantWithPresence } from '../../models/Participant/Types';
import { Label } from '../Label/Label';
import React from 'react';
import { Card } from '../Card/Card';
import { ParticipantLifeInformationForm } from '../ParticipantLifeInformationForm/ParticipantLifeInformationForm';
import { KeyedMutator } from 'swr';

interface ParticipantLifeInformationCardProps {
    reloadParticipant: KeyedMutator<ParticipantWithPresence[]>;
    participant: ParticipantWithOneMeasure | undefined;
}

export const ParticipantLifeInformationCard = (props: ParticipantLifeInformationCardProps) => {
    const initialUserDetailsFormValues = props.participant;

    return (
        <Card key={props.participant?.id}>
            {initialUserDetailsFormValues && (
                <Formik
                    initialValues={initialUserDetailsFormValues}
                    // We need to pass an handler here because formik expects one but we do not want to use the formik submit handling
                    onSubmit={() => console.log('Form submit.')}
                    enableReinitialize
                >
                    {({ values, errors, handleChange, setFieldValue }) => (
                        <div className="participant-details-container">
                            <div>
                                <Label className="user-details-general-title" size={2}>
                                    LEBENSSITUATION
                                </Label>
                                <div className="participant-details">
                                    <div className="participant-details-form">
                                        <ParticipantLifeInformationForm
                                            reloadParticipant={props.reloadParticipant}
                                            errors={errors}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            values={values}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Card>
    );
};
