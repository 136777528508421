import { Avatar } from '../Avatar/Avatar';
import './SideBarFooter.css';
import { Icon } from '../Icon/Icon';
import React, { useState } from 'react';
import { VersionModal } from '../VersionModal/VersionModal';
import { useSession } from '../../hooks/useSession';
import { useUser } from '../../models/User/State';

export const SideBarFooter = () => {
    const [showVersion, setShowVersion] = useState<boolean>(false);
    const { logOut } = useSession();
    const currentUser = useUser((x) => x.currentUser);

    const onLogOutUser = () => {
        logOut();
    };

    const onShowVersion = (event?: React.MouseEvent<HTMLDivElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        setShowVersion(!showVersion);
    };

    return (
        <div className="side-bar-footer">
            <div className="side-bar-footer-avatar-name">
                <Avatar url={currentUser?.avatarUrl ? currentUser?.avatarUrl : currentUser?.id} size={'large'} />
                <div>
                    <div className="p4-regular side-bar-footer-name">
                        {currentUser?.firstName} {currentUser?.lastName}
                    </div>
                    <div className="p5-regular side-bar-footer-logout" onClick={onLogOutUser}>
                        <span>Ausloggen</span>
                    </div>
                </div>
            </div>
            <Icon type={'DotsVertical'} onClick={onShowVersion} />
            <VersionModal show={showVersion} onClose={onShowVersion} />
        </div>
    );
};
