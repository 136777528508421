import { ObjectiveAgreementStatus } from './Type';

export const mapObjectiveAgreementStatus = (status: ObjectiveAgreementStatus | undefined) => {
    switch (status) {
        case 'full_achieved':
            return 'Erfolgreich abgeschlossen';
        case 'not_achieved':
            return 'Nicht abgeschlossen';
        case 'open':
            return 'Offen';
        case 'partially_achieved':
            return 'Teilweise abgeschlossen';
    }
};
