import { ReactNode, useEffect, useState } from 'react';
import { Checkbox } from '../Checkbox/Checkbox';

/**
 * Props for the ValueCheckbox component.
 */
interface ValueCheckboxProps<T> {
    // The checkbox label shown beside the box
    label?: string | ReactNode;

    // Called if the value of the checkbox changes. Passes the value and an indicator whether the checkbox is checked or not
    onChange?: (value: T, checked: boolean) => void;

    // The value that is hold by the checkbox and passed to onChange listener
    value: T;

    // Can be used to manipulate the checked value from outside
    checked?: boolean | undefined;

    // Indicates whether the checkbox should be disabled
    disabled?: boolean;

    // Optional css classes passed to the container
    className?: string;

    // Optional css classes passed to the label container
    labelContainerClassName?: string;
}

/**
 * Checkbox that holds a value and calls an onchange listener passing the value with an indicator whether
 * the checkbox is currently checked or not.
 * The checked prop is can be used to manipulate the checked state from outside.
 *
 * @param props
 * @constructor
 */
export const ValueCheckbox = <T,>(props: ValueCheckboxProps<T>) => {
    const [checkboxIsChecked, setCheckboxIsChecked] = useState<boolean>(false);

    /**
     * If the checked value in the props changes and is not undefined, the checked state will be updated by the value.
     */
    useEffect(() => {
        if (props.checked !== undefined) {
            setCheckboxIsChecked(checkboxIsChecked);
        }
    }, [checkboxIsChecked, props.checked]);

    /**
     * Called if the checkbox was checked or unchecked. Changes the checked state and calls the callback
     * given by the props by passing the value given nby the props and an indicator whether the checkbox
     * is now checked or unchecked.
     */
    const onChange = () => {
        if (props.disabled) return;

        const newCheckedState = !checkboxIsChecked;
        setCheckboxIsChecked(newCheckedState);

        if (props.onChange) {
            props.onChange(props.value, newCheckedState);
        }
    };

    return (
        <Checkbox
            value={props.checked === undefined ? checkboxIsChecked : props.checked}
            onChange={onChange}
            label={props.label}
            disabled={props.disabled}
            className={props.className}
        />
    );
};
