import React from 'react';
import { FormInput } from '../FormInput/FormInput';
import { FormRadioButton } from '../FormRadioButton/FormRadioButton';
import { FormTextArea } from '../FormTextArea/FormTextArea';
import './Form.css';
import { Checkbox } from '../Checkbox/Checkbox';

interface FormProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    onSubmit?: () => void;
}

export const Form = (props: FormProps) => {
    return <form className={`form custom-form ${props.className ? props.className : ''}`}>{props.children}</form>;
};

Form.Checkbox = Checkbox;
Form.Input = FormInput;
Form.TextArea = FormTextArea;
Form.RadioButton = FormRadioButton;
