import { KeyedMutator } from 'swr';
import { formatDate } from '../../models/Date/Date';
import { Internship } from '../../models/Internship/Types';
import { Card } from '../Card/Card';
import { Label } from '../Label/Label';
import './CompanyInternships.css';

interface CompanyInternshipsProps {
    internships: Internship[];
    reloadInternships: KeyedMutator<Internship[]>;
}

export const CompanyInternships = (props: CompanyInternshipsProps) => {
    return (
        <Card>
            <Label className="user-details-general-title" size={2}>
                BISHER GELEISTETE PRAKTIKA
            </Label>
            <div className="company-internships">
                <div className="company-internships-head">
                    <Label size={2} color={'#A1A1AA'}>
                        Teilnehmer
                    </Label>
                    <Label size={2} color={'#A1A1AA'}>
                        Beruf
                    </Label>
                    <Label size={2} color={'#A1A1AA'}>
                        Zeitraum
                    </Label>
                </div>
                <div className="company-internships-body">
                    {props.internships.map((internship) => {
                        return (
                            <div key={internship.id} className="company-internships-body-item">
                                <div className="company-internships-body-item-element">
                                    {internship.participant.lastName}, {internship.participant.firstName}
                                </div>
                                <div className="company-internships-body-item-element">{internship.name}</div>
                                <div className="company-internships-body-item-element">
                                    {formatDate(internship.startDate)}-{formatDate(internship.endDate)}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div></div>
            </div>
        </Card>
    );
};
