import { Modal } from '../Modal/Modal';

interface VersionModalProps {
    show: boolean;
    onClose: () => void;
}

export const VersionModal = (props: VersionModalProps) => {
    return (
        <Modal
            show={props.show}
            header={'Informationen'}
            buttons={{ primary: { text: 'Schließen', onClick: props.onClose } }}
        >
            <div>Version: 1.06012022.2</div>
        </Modal>
    );
};
