import { ReactElement, useState } from 'react';
import './ObjectListItem.css';

interface ObjectListItemProps<T> {
    children: ReactElement | ReactElement[];
    onClick?: (index: number | string, item: T) => void;
    value?: { index: number | string; item: T };
    selected?: boolean;
}

export const ObjectListItem = <T,>(props: ObjectListItemProps<T>) => {
    const [isSelected, setIsSelected] = useState<boolean>(false);

    /**
     * on click event so select the item
     */
    const onClickSelect = () => {
        if (props.selected === undefined) {
            setIsSelected(!isSelected);
        }

        if (props.onClick && props.value) {
            props.onClick(props.value.index, props.value.item);
        }
    };

    return (
        <div
            className={`p4-medium object-list-item ${(isSelected || props.selected) && 'active'}`}
            onClick={onClickSelect}
        >
            {props.children}
        </div>
    );
};
