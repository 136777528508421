
const digest = '2d24c328d96cb040703b07713d6aaa6463da3f54b88122c8024c8a8674df8690';
const css = `/* font *//* background *//* borders *//* fonts *//* paddings *//* margins and gaps *//* borders */

._fileInput_b91nt_6 {
    font-size: large;
}

._progressBar_b91nt_10 {
    background-color: darkblue;
    width: 100%;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"colors":"\"../../../shared/styles/colors.module.css\"","distances":"\"../../../shared/styles/distances.module.css\"","colorBackgroundProgress":"darkblue","sizeFontLarge":"large","fileInput":"_fileInput_b91nt_6","progressBar":"_progressBar_b91nt_10"};
export { css, digest };
  