import React from 'react';
import { formatDate } from '../../models/Date/Date';
import { mapLetterRecipients } from '../../models/LetterTemplates/LetterTemplate';
import { LetterTemplate } from '../../models/LetterTemplates/Type';
import { Card } from '../Card/Card';
import { Label } from '../Label/Label';
import { List } from '../List/List';

interface LetterDetailsProps {
    letterTemplate: LetterTemplate;
}

export const LetterDetails = (props: LetterDetailsProps) => {
    return (
        <>
            <h5>{props.letterTemplate.name}</h5>
            <Card>
                <>
                    <Label className="user-details-general-title" size={2}>
                        ALLGEMEINE INFORMATIONEN
                    </Label>
                    {props.letterTemplate && (
                        <List
                            readonly
                            options={[
                                {
                                    label: 'Titel',
                                    value: props.letterTemplate.name,
                                    name: 'name'
                                },
                                {
                                    label: 'Empfänger',
                                    value: mapLetterRecipients(props.letterTemplate.recipient),
                                    name: 'recipient'
                                },
                                {
                                    label: 'Zuletzt aktualisiert am',
                                    value: formatDate(new Date(props.letterTemplate.updatedAt)),
                                    name: 'updatedAt'
                                },
                                {
                                    label: 'Zuletzt aktualisiert von',
                                    value: `${props.letterTemplate.updatedBy.firstName} ${props.letterTemplate.updatedBy.lastName}`,
                                    name: 'updatedBy'
                                }
                            ]}
                        />
                    )}
                </>
            </Card>
        </>
    );
};
