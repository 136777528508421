import { Button } from '../Button/Button';
import './DeleteButtonModal.css';
import { Label } from '../Label/Label';
import { Icon } from '../Icon/Icon';

interface DeleteButtonModalProps {
    onClick: () => void;
    onClose: () => void;
}

export const DeleteButtonModal = (props: DeleteButtonModalProps) => {
    return (
        <div className={'delete-button-modal'}>
            <Label className={'delete-button-modal-label'} size={1}>
                Sind Sie sicher?
            </Label>
            <div className={'p5-regular'}>Die Notiz kann nicht wiederhergestellt werden.</div>
            <Button
                className={'delete-button-width'}
                type={'danger'}
                size={'small'}
                buttonStyle={'filled'}
                text={'Ja, Notiz löschen'}
                onClick={props.onClick}
                firstIcon={<Icon type={'Information'} />}
            />
        </div>
    );
};
