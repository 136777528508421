import { useCallback, useEffect } from 'react';
import {
    getUserAuthInfoFromSessionStorage,
    updateSessionStorageWithUser
} from '../models/SessionStorage/SessionStorage';
import { setCurrentUser } from '../models/User/Slice';
import { useDispatch } from 'react-redux';
import { useUsersApi } from '../api/useUsersApi';
import { useUser } from '../models/User/State';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { useSession } from '../hooks/useSession';

/**
 * Component that reacts to state and user changes and apply depoending state changes related to auth and user.
 *
 * @constructor
 */
export const AuthAndUserStateManager = () => {
    const dispatch = useDispatch();
    const { apiGetOwnProfile } = useUsersApi();
    const currentUser = useUser((x) => x.currentUser);
    const { authInfo, setOnUnauthorizedCallback, setAuthInfo } = useEzOnRails();
    const { logOut } = useSession();

    /**
     * Called if some request fails with http status 401.
     * Loggs out the user and navigates to the landing page.
     */
    const onUnauthorizedCallback = useCallback(() => {
        logOut();
    }, [logOut]);

    /**
     * Called if the onUnauthorizedCallback changes.
     * Registers the new callback to ezOnRails to react to 401 status related errors.
     */
    useEffect(() => {
        setOnUnauthorizedCallback(onUnauthorizedCallback);
    }, [setOnUnauthorizedCallback, onUnauthorizedCallback]);

    /**
     * Check if user was logged in before aka use information from local storage.
     * Otherwise, if the user logge in, update the session to the storage.
     */
    useEffect(() => {
        if (authInfo) {
            updateSessionStorageWithUser(authInfo);
        } else {
            const localAuthInfo = getUserAuthInfoFromSessionStorage();
            if (localAuthInfo) {
                setAuthInfo(localAuthInfo);
            }
        }
    }, [authInfo, setAuthInfo]);

    /**
     * Check if the authInfo group exists. If not request it
     */
    useEffect(() => {
        (async () => {
            if (authInfo && !currentUser) {
                const user = await apiGetOwnProfile();
                dispatch(setCurrentUser(user));
            }
        })();
    }, [authInfo, dispatch, apiGetOwnProfile, currentUser]);

    return null;
};
