
const digest = '223f694f481d8288df46626be6d65079fb057804aa8ebca8eace51c4bdb63749';
const css = `/* font *//* background *//* borders *//* fonts *//* paddings *//* margins and gaps *//* borders */

._container_ose23_8 {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(100, 100, 100, 0.5);
    font-family: Arial, serif;
}

._innerContainer_ose23_22 {
    position: relative;
    background-color: white;
    border-radius: 1rem;
    font-size: large;
}

._headerContainer_ose23_29 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid grey;
    padding: 1rem;
}

._header_ose23_29 {
    padding: 0.2rem 0.5rem;
    font-size: x-large;
}

._bodyContainer_ose23_43 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    gap: 1rem;
}

._closeButton_ose23_52 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0.2rem;
    border-radius: 0.3rem;
}

._closeButton_ose23_52:hover {
    background-color: #dddddd;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"colors":"\"../../styles/colors.module.css\"","distances":"\"../../styles/distances.module.css\"","fonts":"\"../../styles/fonts.module.css\"","colorBorderDark":"grey","colorBackgroundToolbarActive":"#dddddd","colorBackgroundOverlay":"rgba(100, 100, 100, 0.5)","colorBackgroundModal":"white","paddingSmall":"0.2rem","paddingMedium":"0.5rem","paddingLarge":"1rem","marginLarge":"1rem","sizeBorderSmall":"1px","radiusBorderLarge":"1rem","radiusBorderSmall":"0.3rem","fontFamilyDefault":"Arial, serif","container":"_container_ose23_8","innerContainer":"_innerContainer_ose23_22","headerContainer":"_headerContainer_ose23_29","header":"_header_ose23_29","bodyContainer":"_bodyContainer_ose23_43","closeButton":"_closeButton_ose23_52"};
export { css, digest };
  