import React, { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import { Measure } from '../../models/Measure/Types';
import { QmDocument } from '../../models/QmDocument/Types';
import { QmDocumentTemplate } from '../../models/QmDocumentTemplate/Types';
import { ValueCheckbox } from '../ValueCheckbox/ValueCheckbox';
import { useQmDocumentsApi } from '../../api/useQmDocumentsApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface MeasureDetailsQmDocumentsProps {
    measure: Measure | undefined;
}

export const MeasureDetailsQmDocuments = (props: MeasureDetailsQmDocumentsProps) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const [showDocuments, setShowDocuments] = useState<JSX.Element[]>();
    const { apiDeleteQmDocument, apiAddQmDocumentToMeasure } = useQmDocumentsApi();

    /**
     * Fetch qm document templates
     */
    const { data: qmDocumentsTemplates } = useSWR<QmDocumentTemplate[]>([
        backendUrl,
        `qm_document_templates`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    /**
     * Fetch qm documents
     */
    const { data: qmDocuments, mutate } = useSWR<QmDocument[]>([
        backendUrl,
        `measures/${props.measure?.id}/qm_documents`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    /**
     * Delete document from measure
     */
    const removeDocument = useCallback(
        async (documentId: number | undefined) => {
            if (documentId) {
                try {
                    await apiDeleteQmDocument(documentId);
                    mutate().then();
                } catch (e) {
                    console.log(e);
                }
            }
        },
        [apiDeleteQmDocument, mutate]
    );

    /**
     * Add document to measure
     */
    const addDocument = useCallback(
        async (name: string, measureId: number | undefined, qmDocumentTemplateId: number) => {
            if (measureId) {
                const response = await apiAddQmDocumentToMeasure(name, measureId, qmDocumentTemplateId);
                if (response) {
                    mutate().then();
                }
            }
        },
        [apiAddQmDocumentToMeasure, mutate]
    );

    /**
     * Compare the assigned documents and the templates. If there are assigned/existing documents, mark the templates as checked for the user
     */
    const compareDocuments = useCallback(() => {
        const documentView: JSX.Element[] | undefined = qmDocumentsTemplates?.map((qmDocumentsTemplate) => {
            const find = qmDocuments?.find((document) => document.qmDocumentTemplateId === qmDocumentsTemplate.id);
            if (find) {
                return (
                    <div key={qmDocumentsTemplate.id}>
                        <ValueCheckbox
                            labelContainerClassName={'p4-regular'}
                            label={qmDocumentsTemplate.name}
                            onChange={() => removeDocument(find?.id)}
                            value={qmDocumentsTemplate.id}
                            checked={true}
                        />
                    </div>
                );
            } else {
                return (
                    <div key={qmDocumentsTemplate.id}>
                        <ValueCheckbox
                            labelContainerClassName={'p4-regular'}
                            label={qmDocumentsTemplate.name}
                            onChange={() =>
                                addDocument(qmDocumentsTemplate.name, props.measure?.id, qmDocumentsTemplate.id)
                            }
                            value={qmDocumentsTemplate.id}
                            checked={false}
                        />
                    </div>
                );
            }
        });
        if (documentView) {
            setShowDocuments([...documentView]);
        }
    }, [addDocument, props.measure?.id, qmDocuments, qmDocumentsTemplates, removeDocument]);

    useEffect(() => {
        compareDocuments();
    }, [compareDocuments, qmDocuments]);

    return <div>{showDocuments}</div>;
};
