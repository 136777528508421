import { Formik, FormikErrors, FormikTouched, FormikValues } from 'formik';
import { ChangeEvent, FocusEvent, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Button } from '../../components/Button/Button';
import { CreateProcessInputField } from '../../components/CreateProcessInputField/CreateProcessInputField';
import { CreateProcessKeyboardEnter } from '../../components/CreateProcessKeyboardEnter/CreateProcessKeyboardEnter';
import { Form } from '../../components/Form/Form';
import { FullscreenSidebar } from '../../components/FullscreenSidebar/FullscreenSidebar';
import { FullscreenSidebarItem } from '../../components/FullscreenSidebarItem/FullscreenSidebarItem';
import { Icon } from '../../components/Icon/Icon';
import { UserObject } from '../../models/Employee/Types';
import { CreatableUser } from '../../models/User/Types';
import './AdminCreateUser.css';

interface AdminCreateUserProps {
    onSubmit: (values: CreatableUser) => void;
    onClose: () => void;
    className?: string;
    submitError: string;
    users: UserObject[] | undefined;
}

type FormNavigationItemFormValue = 'firstName' | 'lastName' | 'gender' | 'phone' | 'fax' | 'email' | 'group';

interface FormNavigationItem {
    name: string;
    selected: boolean;
    finished: boolean;
    formValue: FormNavigationItemFormValue;
}

export const AdminCreateUser = (props: AdminCreateUserProps) => {
    const [emailCheck, setEmailCheck] = useState<string>();
    // structure of the form menu
    const formNavigationStructure: FormNavigationItem[] = [
        {
            name: 'Vorname',
            selected: true,
            finished: false,
            formValue: 'firstName'
        },
        {
            name: 'Nachname',
            selected: false,
            finished: false,
            formValue: 'lastName'
        },
        {
            name: 'Geschlecht',
            selected: false,
            finished: false,
            formValue: 'gender'
        },
        {
            name: 'Telefon',
            selected: false,
            finished: false,
            formValue: 'phone'
        },
        {
            name: 'Fax',
            selected: false,
            finished: false,
            formValue: 'fax'
        },
        {
            name: 'E-Mail',
            selected: false,
            finished: false,
            formValue: 'email'
        },
        {
            name: 'Berechtigungen',
            selected: false,
            finished: false,
            formValue: 'group'
        }
    ];

    // state that holds information about what form part should be shown to the user
    const [formContent, setFormContent] = useState<{ name: string; index: number }>({
        name: formNavigationStructure[0].name,
        index: 1
    });
    // state that holds information about structure and states of the form menu
    const [formMenu, setFormMenu] = useState<FormNavigationItem[]>(formNavigationStructure);
    const initialFormValues: CreatableUser = {
        email: '',
        fax: '',
        firstName: '',
        gender: '',
        lastName: '',
        phone: '',
        archived: false,
        group: undefined
    };

    const phoneRegx = /[0-9]/;
    const CreateUserValidationScheme = Yup.object()
        .shape({
            firstName: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            lastName: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            gender: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            email: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .email('Bitten geben Sie eine gültige E-Mail Adresse ein'),
            fax: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .matches(phoneRegx, 'Dies scheint keine gültige Faxnummmer zu sein'),
            phone: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .matches(phoneRegx, 'Dies scheint keine gültige Telefonnummer zu sein'),
            group: Yup.string().required('Dieses Feld muss ausgefüllt sein.')
        })
        .defined();

    /**
     * Function to render the form for the user
     * @param values
     * @param handleChange
     * @param setFieldValue
     * @param handleBlur
     * @param errors
     * @param touched
     */
    const renderFormContent = (
        values: FormikValues,
        handleChange: (e: ChangeEvent<unknown>) => void,
        setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void,
        handleBlur: (e: FocusEvent<unknown>) => void,
        errors: FormikErrors<CreatableUser>,
        touched: FormikTouched<CreatableUser>
    ) => {
        switch (formContent.name) {
            case 'Vorname':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Wie ist der Vorname des/der Mitarbeiters/in?'}
                        label={'Vorname'}
                        touched={touched.firstName}
                        name={'firstName'}
                        value={values.firstName}
                        error={errors.firstName}
                    />
                );
            case 'Nachname':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Bitte geben Sie den Nachnamen des/der Mitarbeiters/in?'}
                        label={'Nachname'}
                        touched={touched.lastName}
                        name={'lastName'}
                        value={values.lastName}
                        error={errors.lastName}
                    />
                );
            case 'Geschlecht':
                return (
                    <CreateProcessInputField
                        options={[
                            { label: 'Weiblich', value: 'female' },
                            {
                                label: 'Männlich',
                                value: 'male'
                            },
                            { label: 'Divers', value: 'diverse' }
                        ]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        title={'Bitte geben Sie das Geschlecht der/des Mitarbeiters/in an?'}
                        label={'Geschlecht'}
                        touched={touched.gender}
                        name={'gender'}
                        value={values.gender}
                        error={errors.gender}
                    />
                );
            case 'Telefon':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Bitte geben Sie eine Telefonnummer des/der Mitarbeiters/in?'}
                        label={'Telefonnummer'}
                        touched={touched.phone}
                        name={'phone'}
                        value={values.phone}
                        error={errors.phone}
                    />
                );
            case 'Fax':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Bitte geben Sie eine Faxnummer des/der Mitarbeiters/in?'}
                        label={'Fax'}
                        touched={touched.fax}
                        name={'fax'}
                        value={values.fax}
                        error={errors.fax}
                    />
                );
            case 'E-Mail':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Bitte geben Sie eine E-Mail Adresse des/der Mitarbeiters/in??'}
                        label={'E-Mail Adresse'}
                        touched={touched.email}
                        name={'email'}
                        value={values.email}
                        error={errors.email || emailCheck}
                        type={'email'}
                    />
                );
            case 'Berechtigungen':
                return (
                    <CreateProcessInputField
                        options={[
                            { label: 'keine besonderen Rechte', value: 'Employee' },
                            {
                                label: 'QM Beauftragter/Beauftragte',
                                value: 'QmManager'
                            },
                            { label: 'Administrator', value: 'Administrator' }
                        ]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        title={
                            'Bitte geben Sie an, ob der Mitarbeiter/die Mitarbeiterin besondere Rechte besitzen soll'
                        }
                        label={'Berechtigungen'}
                        touched={touched.group}
                        name={'group'}
                        value={values.group}
                        error={errors.group}
                    />
                );
        }
    };

    /**
     * On click on the back button change the form
     */
    const onClickBackInForm = () => {
        changeSelectedStateOfMenu(formMenu[formContent.index - 2].name);
        setFormContent({
            name: formMenu[formContent.index - 2].name,
            index: formContent.index - 1
        });
    };

    /**
     * On click function if the user clicks the sidebar menu
     * @param menuName
     * @param index
     */
    const onClickSidebarItem = (menuName: string, index: number) => {
        // change the form content
        setFormContent({ name: menuName, index: index + 1 });

        changeSelectedStateOfMenu(menuName);
    };

    /**
     * Function to change the icon/state if a menu point is selected
     * @param menuName
     * @param index
     */
    const changeSelectedStateOfMenu = useCallback(
        (menuName: string) => {
            // check if there is any menu selected already -> unselect if needed
            const indexOfMenuToUnSelect = formMenu.findIndex((element) => {
                return element.selected;
            });
            formMenu[indexOfMenuToUnSelect].selected = false;

            // check the name of the form content and mark the corresponding menu point as selected
            const indexOfMenuToBeSelected = formMenu.findIndex((element) => {
                return element.name === menuName;
            });
            formMenu[indexOfMenuToBeSelected].selected = true;
            // set form menu state
            setFormMenu([...formMenu]);
        },
        [formMenu]
    );

    /**
     * Validate the formik form manually. Formik validates everything at once and it does not care if the user has visited
     * a field already. Therefore we take are of validation ourselves by telling formik that the current field is touched and
     * if there is an error it can be displayed
     *
     * @param touched
     * @param setTouched
     * @param validateForm
     */
    const validateFormManually = useCallback(
        (
            touched: FormikTouched<CreatableUser>,
            setTouched: (t: FormikTouched<CreatableUser>) => void,
            validateForm: () => void
        ) => {
            // set the field of the current view as touched in Formik so that we know, this field has been visited
            touched[`${formMenu[formContent.index - 1].formValue}`] = true;
            setTouched(touched);
            // validate the form manually
            validateForm();
        },
        [formContent.index, formMenu]
    );

    /**
     * Call back when the user clicks the "next" button -> navigate through the form
     */
    const onClickNext = useCallback(
        (
            values: FormikValues,
            errors: FormikErrors<CreatableUser>,
            validateForm: () => void,
            touched: FormikTouched<CreatableUser>,
            setTouched: (t: FormikTouched<CreatableUser>) => void
        ) => {
            if (formMenu[formContent.index - 1].formValue === 'email') {
                if (errors && props.users?.some((user) => user.email === values.email)) {
                    setEmailCheck('Diese E-Mail ist bereits vergeben');
                    errors.email = 'Diese E-Mail ist bereits vergeben';
                    touched['email'] = true;
                } else if (errors) {
                    setEmailCheck(undefined);
                }
            }

            validateFormManually(touched, setTouched, validateForm);

            // check if there are any errors existing for the current view/field
            if (!errors || (errors && !errors[formMenu[formContent.index - 1].formValue])) {
                // check if values to the current text field is existing, if so mark the menu option as finished
                if (values[formMenu[formContent.index - 1].formValue]) {
                    formMenu[formContent.index - 1].finished = true;
                    setFormMenu([...formMenu]);
                } else {
                    formMenu[formContent.index - 1].finished = false;
                    setFormMenu([...formMenu]);
                    return;
                }

                setFormContent({
                    name: formMenu[formContent.index].name,
                    index: formContent.index + 1
                });
                changeSelectedStateOfMenu(formMenu[formContent.index].name);
            } else {
                return;
            }
        },
        [changeSelectedStateOfMenu, formContent.index, formMenu, props.users, validateFormManually]
    );

    /**
     * Call back in case user clicks the enter button
     * @param event
     */
    const clickEnter = useCallback(
        (
            event: KeyboardEvent,
            values: FormikValues,
            errors: FormikErrors<CreatableUser>,
            validateForm: () => void,
            setTouched: (t: FormikTouched<CreatableUser>) => void,
            touched: FormikTouched<CreatableUser>
        ) => {
            // check if enter key was pressed and if the user aka formContent has not reached the end of the formMenu yet
            if (event.key === 'Enter' && formContent.index < formMenu.length) {
                // prevent default click behaviour
                event.preventDefault();
                // trigger the next view
                onClickNext(values, errors, validateForm, touched, setTouched);
            }
        },
        [formContent.index, formMenu.length, onClickNext]
    );

    /**
     * call back when closeing the form. reset formik and close the form
     * @param handleReset
     */
    const onCloseForm = (handleReset: () => void) => {
        handleReset();
        props.onClose();
    };

    return (
        <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
                props.onSubmit(values);
            }}
            validateOnMount={false}
            enableReinitialize={false}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={CreateUserValidationScheme}
        >
            {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                touched,
                validateForm,
                handleBlur,
                setTouched,
                handleReset
            }) => (
                <Form onSubmit={handleSubmit}>
                    <CreateProcessKeyboardEnter
                        values={values}
                        errors={errors}
                        onClickEnter={clickEnter}
                        validateForm={validateForm}
                        touched={touched}
                        setTouched={setTouched}
                    />
                    <div className={`create-measure ${props.className}`}>
                        <FullscreenSidebar
                            title={'Neue Maßnahme'}
                            onClose={() => onCloseForm(handleReset)}
                            className="create-measure-menu"
                        >
                            {formMenu.map((item, index) => {
                                return (
                                    <FullscreenSidebarItem
                                        key={index}
                                        name={item.name}
                                        selected={item.selected}
                                        finished={item.finished}
                                        onClick={() => onClickSidebarItem(item.name, index)}
                                    />
                                );
                            })}
                        </FullscreenSidebar>
                        <div className="create-measure-detail">
                            <div className="label-3 create-measure-detail-sub-header">{`${
                                formContent.index
                            }. ${formContent.name.toUpperCase()}`}</div>
                            {renderFormContent(values, handleChange, setFieldValue, handleBlur, errors, touched)}
                            {formContent.index < formMenu.length && (
                                <div className="p5-medium create-measure-detail-enter-hint">
                                    Eingabetaste
                                    <Icon
                                        className="create-measure-detail-enter-hint-icon"
                                        iconFile={
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0 2.66667C0 1.19391 1.19391 0 2.66667 0H13.3333C14.8061 0 16 1.19391 16 2.66667V13.3333C16 14.8061 14.8061 16 13.3333 16H2.66667C1.19391 16 0 14.8061 0 13.3333V2.66667Z"
                                                    fill="#E4E4E7"
                                                />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12.8333 3.33301C13.1095 3.33301 13.3333 3.55687 13.3333 3.83301V7.33301C13.3333 8.34553 12.5125 9.16634 11.5 9.16634H4.42427L6.50997 11.1362C6.71073 11.3258 6.71977 11.6422 6.53016 11.843C6.34056 12.0437 6.0241 12.0528 5.82334 11.8632L2.82334 9.02985C2.72333 8.93539 2.66666 8.80391 2.66666 8.66634C2.66666 8.52878 2.72333 8.39729 2.82334 8.30283L5.82334 5.4695C6.0241 5.2799 6.34056 5.28894 6.53016 5.4897C6.71977 5.69045 6.71073 6.00691 6.50997 6.19651L4.42427 8.16634H11.5C11.9602 8.16634 12.3333 7.79325 12.3333 7.33301V3.83301C12.3333 3.55687 12.5572 3.33301 12.8333 3.33301Z"
                                                    fill="#71717A"
                                                    stroke="#71717A"
                                                    strokeWidth="0.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M2.66667 0.666667H13.3333V-0.666667H2.66667V0.666667ZM15.3333 2.66667V13.3333H16.6667V2.66667H15.3333ZM13.3333 15.3333H2.66667V16.6667H13.3333V15.3333ZM0.666667 13.3333V2.66667H-0.666667V13.3333H0.666667ZM2.66667 15.3333C1.5621 15.3333 0.666667 14.4379 0.666667 13.3333H-0.666667C-0.666667 15.1743 0.825718 16.6667 2.66667 16.6667V15.3333ZM15.3333 13.3333C15.3333 14.4379 14.4379 15.3333 13.3333 15.3333V16.6667C15.1743 16.6667 16.6667 15.1743 16.6667 13.3333H15.3333ZM13.3333 0.666667C14.4379 0.666667 15.3333 1.5621 15.3333 2.66667H16.6667C16.6667 0.825718 15.1743 -0.666667 13.3333 -0.666667V0.666667ZM2.66667 -0.666667C0.825718 -0.666667 -0.666667 0.825718 -0.666667 2.66667H0.666667C0.666667 1.5621 1.5621 0.666667 2.66667 0.666667V-0.666667Z"
                                                    fill="#E4E4E7"
                                                />
                                            </svg>
                                        }
                                    />
                                    zum fortfahren
                                </div>
                            )}
                            <div className="create-measure-detail-buttons">
                                {formContent.index > 1 && (
                                    <Button
                                        type={'primary'}
                                        size={'medium'}
                                        buttonStyle={'link'}
                                        firstIcon={<Icon type={'ArrowLeft'} />}
                                        text={'Zurück'}
                                        onClick={onClickBackInForm}
                                    />
                                )}
                                {formContent.index < formMenu.length && (
                                    <Button
                                        type={'primary'}
                                        size={'medium'}
                                        buttonStyle={'filled'}
                                        text={'Weiter'}
                                        className="align-self-end"
                                        onClick={() => onClickNext(values, errors, validateForm, touched, setTouched)}
                                    />
                                )}
                                {formContent.index === formMenu.length && (
                                    <Button
                                        type={'primary'}
                                        size={'medium'}
                                        buttonStyle={'filled'}
                                        text={'Benutzer erstellen'}
                                        className="align-self-end"
                                        onClick={handleSubmit}
                                    />
                                )}
                            </div>
                            {props.submitError && (
                                <div className="create-measure-submit-error">{props.submitError}</div>
                            )}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
