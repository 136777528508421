import React, { ReactElement, useState } from 'react';
import { Course, CourseSubmit } from '../../models/Course/Type';
import { UserObject } from '../../models/Employee/Types';
import { Participant } from '../../models/Participant/Types';
import { Avatar } from '../Avatar/Avatar';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { InputSelect } from '../InputSelect/InputSelect';

interface AddToCourseProps<T extends Participant | UserObject> {
    allUsers: T[] | undefined;
    addTo: (userId: number | undefined) => void;
    usersInCourse: T[] | undefined;
    course: Course | CourseSubmit | undefined;
    customButton?: (onShowCourse: () => void) => ReactElement;
}

export const AddToCourse = <T extends Participant | UserObject>(props: AddToCourseProps<T>) => {
    const [showAddToCourse, setShowAddToCourse] = useState<boolean>(false);
    // selected users which should be added to a measure
    const [usersToAdd, setUsersToAdd] = useState<number>();

    /**
     * show add user to measure button
     */
    const onShowCourse = () => {
        setShowAddToCourse(!showAddToCourse);
    };

    /**
     * add user id that was selected to an array
     * @param id
     */
    const addUser = (id: number | undefined) => {
        if (!id) return;

        setUsersToAdd(id);
    };

    /**
     * Backend call to add users to a measure
     */
    const addUserToCourse = () => {
        props.addTo(usersToAdd);
        onShowCourse();
    };

    return (
        <>
            {props.allUsers &&
            props.allUsers.length !== props.usersInCourse?.length &&
            (props.course ? 'archived' in props.course && !props.course?.archived : true) ? (
                !showAddToCourse ? (
                    props.customButton ? (
                        props.customButton(onShowCourse)
                    ) : (
                        <div className="measure-user-add-user-button" onClick={onShowCourse}>
                            {<Icon type={'Plus'} />}{' '}
                        </div>
                    )
                ) : (
                    <div className="add-user-to-measure">
                        <InputSelect
                            size={'small'}
                            dropdownOptions={props.allUsers
                                ?.filter(
                                    (singleUser) =>
                                        !props.usersInCourse?.some((userInCourse) => userInCourse.id === singleUser.id)
                                )
                                ?.map((registeredUser) => {
                                    return {
                                        icon: <Avatar size={'small'} url={registeredUser.avatarUrl} />,
                                        value: `${registeredUser.firstName} ${registeredUser.lastName}`,
                                        id: registeredUser.id
                                    };
                                })}
                            onChange={addUser}
                        />
                        <Button
                            text={'Hinzufügen'}
                            type={'primary'}
                            size={'small'}
                            buttonStyle={'filled'}
                            onClick={addUserToCourse}
                        />
                    </div>
                )
            ) : null}
        </>
    );
};
