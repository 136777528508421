import React, { useCallback, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { ObjectiveAgreement, ObjectiveAgreementStatus } from '../../models/ObjectiveAgreement/Type';
import { throwError } from '../../models/Toasts/Toasts';
import { Icon, IconType } from '../Icon/Icon';
import { ObjectiveAgreementStatusChange } from '../ObjectiveAgreementStatusChange/ObjetiveAgreementStatusChange';
import { useObjectiveAgreementsApi } from '../../api/useObjectiveAgreementsApi';

interface ObjectiveAgreementStatusProps {
    objective: ObjectiveAgreement;
    reload: () => Promise<void>;
}

export const SingleObjectiveAgreementStatus = (props: ObjectiveAgreementStatusProps) => {
    const [changeStatus, setChangeStatus] = useState<boolean>(false);
    const statusRef = useRef<HTMLDivElement>(null);
    const { apiUpdateObjectiveAgreement } = useObjectiveAgreementsApi();

    useOnClickOutside(
        statusRef,
        useCallback(() => {
            setChangeStatus(false);
        }, [])
    );

    /**
     * Check for achievement icon
     */
    const achievementIcon = (): IconType => {
        switch (props.objective.achieved) {
            case 'full_achieved':
                return 'CheckCircle';
            case 'not_achieved':
                return 'CloseCircle';
            case 'open':
                return 'Circle';
            case 'partially_achieved':
                return 'CheckCircle';
        }
    };

    /**
     * Callback to open/close status view
     */
    const onOpenCloseStatusView = useCallback(() => {
        setChangeStatus(!changeStatus);
    }, [setChangeStatus, changeStatus]);

    /**
     * Change stats of objective agreement
     * @param status
     */
    const onChangeStatus = useCallback(
        async (status: ObjectiveAgreementStatus) => {
            try {
                await apiUpdateObjectiveAgreement(props.objective.id, { achieved: status });
                onOpenCloseStatusView();
                props.reload().then();
            } catch (e) {
                throwError();
                console.log(e);
            }
        },
        [apiUpdateObjectiveAgreement, onOpenCloseStatusView, props]
    );

    return (
        <div style={{ position: 'relative' }} ref={statusRef}>
            <Icon
                className={`single-objective-objective-${props.objective.achieved}
            ${
                new Date(props.objective.deadline) <= new Date() && props.objective.achieved === 'open'
                    ? 'single-objective-deadline-overdue'
                    : ''
            }`}
                type={achievementIcon()}
                onClick={onOpenCloseStatusView}
            />
            {changeStatus && <ObjectiveAgreementStatusChange onClick={onChangeStatus} />}
        </div>
    );
};
