import { ChangeEvent, useEffect, useState } from 'react';
import useSWR from 'swr';
import { Button } from '../../components/Button/Button';
import { CancelQmDocumentTemplateModal } from '../../components/CancelQmDocumentTemplateModal/CancelQmDocumentTemplateModal';
import { Icon } from '../../components/Icon/Icon';
import { Input } from '../../components/Input/Input';
import { LetterTemplateDetails } from '../../components/LetterTemplateDetails/LetterTemplateDetails';
import { ObjectList } from '../../components/ObjectList/ObjectList';
import { ObjectListItem } from '../../components/ObjectListItem/ObjectListItem';
import { StandardView } from '../../components/StandardView/StandardView';
import { mapLetterRecipients } from '../../models/LetterTemplates/LetterTemplate';
import { LetterTemplate, LetterTemplateSubmit } from '../../models/LetterTemplates/Type';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import './Letters.css';
import { useLetterTemplatesApi } from '../../api/useLetterTemplatesApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

export const Letters = () => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { data: letterTemplates, mutate: reloadLetterTemplates } = useSWR<LetterTemplate[]>([
        backendUrl,
        'letter_templates',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const [selectedLetterTemplate, setSelectedLetterTemplate] = useState<LetterTemplate | LetterTemplateSubmit>();
    const [createNewLetterTemplate, setCreateNewLetterTemplate] = useState<LetterTemplateSubmit>();
    const [search, setSearch] = useState<boolean>(false);
    const [filteredLetterTemplates, setFilteredLetterTemplates] = useState<LetterTemplate[] | undefined>([]);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const { apiUpdateLetterTemplate, apiCreateLetterTemplate } = useLetterTemplatesApi();

    const onShowCancelModal = () => {
        setShowCancelModal(!showCancelModal);
    };

    /**
     * callback to cancel the qm document creation of a new document
     */
    const onClickCancelLetterTemplate = () => {
        setCreateNewLetterTemplate(undefined);
        if (filteredLetterTemplates) {
            setSelectedLetterTemplate(filteredLetterTemplates[0]);
        }

        onShowCancelModal();
    };

    const onSelectLetterTemplate = (index: number | string, letterTemplate: LetterTemplate | LetterTemplateSubmit) => {
        if (createNewLetterTemplate) {
            onShowCancelModal();
        } else {
            setSelectedLetterTemplate(letterTemplate);
        }
    };

    /**
     * Callback to create a new letter
     */
    const onCreateNewLetterTemplate = () => {
        if (createNewLetterTemplate) {
            onShowCancelModal();
        } else {
            setCreateNewLetterTemplate({
                name: 'Neuer Brief',
                recipient: 'client',
                document: '',
                notes: ''
            });
        }
    };

    const onResetNewLetterTemplate = () => {
        setCreateNewLetterTemplate(undefined);
        if (filteredLetterTemplates) {
            setSelectedLetterTemplate(filteredLetterTemplates[0]);
        }
    };

    const resetLetterTemplate = async () => {
        await reloadLetterTemplates();
        if (letterTemplates) {
            setSelectedLetterTemplate(letterTemplates[0]);
        }
    };

    /**
     * if a new letter template should be created, set it as selected letter template
     */
    useEffect(() => {
        if (createNewLetterTemplate) {
            setSelectedLetterTemplate(createNewLetterTemplate);
        }
    }, [createNewLetterTemplate]);

    /**
     * If letter templates are fetched and changed respectively, update filtered templates
     */
    useEffect(() => {
        setFilteredLetterTemplates(letterTemplates?.sort((a, b) => a.name.localeCompare(b.name)));
    }, [letterTemplates]);

    /**
     * Open the search bar
     */
    const onClickOpenSearch = () => {
        setSearch(!search);
    };

    /**
     * Change filtered letter templates based on search input
     * @param event
     * @param value
     */
    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setFilteredLetterTemplates(
            letterTemplates?.filter(
                (letterTemplate) =>
                    letterTemplate.name.toLowerCase().includes(event.target.value.toLowerCase() as string) ||
                    letterTemplate.recipient.toLowerCase().includes(event.target.value.toLowerCase() as string)
            )
        );
    };

    /**
     * Callback to submit the letter template
     * @param values
     */
    const onSubmit = async (values: LetterTemplateSubmit | LetterTemplate) => {
        try {
            if (!('id' in values)) {
                // submit new letter template
                const newLetterTemplate = await apiCreateLetterTemplate(values);
                showSuccessMessage('Brief gespeichert');
                reloadLetterTemplates().then();
                setSelectedLetterTemplate(newLetterTemplate);
                setCreateNewLetterTemplate(undefined);
            } else if ('createdAt' in values) {
                // submit changed letter template
                const updatedLetterTemplate = await apiUpdateLetterTemplate(values.id, values);
                showSuccessMessage('Brief geändert');
                reloadLetterTemplates().then();
                setSelectedLetterTemplate(updatedLetterTemplate);
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    return (
        <>
            <StandardView>
                <CancelQmDocumentTemplateModal
                    show={showCancelModal}
                    onClickClose={onShowCancelModal}
                    onClickCancelQmDocument={onClickCancelLetterTemplate}
                />
                <StandardView.Left>
                    <ObjectList>
                        <ObjectList.Head>
                            <div className="measure-list-head-container" style={{ marginBottom: '24px' }}>
                                <div className="measure-list-head">
                                    <div className="p2-medium">Briefvorlagen</div>
                                    <div className="measure-list-head-search">
                                        <Button
                                            type={'secondary'}
                                            buttonStyle={'link'}
                                            size={'small'}
                                            firstIcon={<Icon type={'Search'} />}
                                            onClick={onClickOpenSearch}
                                        />
                                        <Button
                                            type={'primary'}
                                            size={'small'}
                                            buttonStyle={'filled'}
                                            firstIcon={<Icon type={'Plus'} />}
                                            onClick={onCreateNewLetterTemplate}
                                        />
                                    </div>
                                </div>
                                {search && (
                                    <Input
                                        icon={<Icon type={'Search'} />}
                                        placeholder={'Briefvorlage suchen'}
                                        onChange={onChangeSearch}
                                    />
                                )}
                            </div>
                        </ObjectList.Head>
                        <ObjectList.Body>
                            <div className="participant-detail-information-dates" style={{ flexDirection: 'column' }}>
                                {createNewLetterTemplate && (
                                    <ObjectListItem
                                        value={{ index: -1, item: createNewLetterTemplate }}
                                        selected={selectedLetterTemplate === createNewLetterTemplate}
                                        onClick={onSelectLetterTemplate}
                                    >
                                        <div>{createNewLetterTemplate.name}</div>
                                        <div>{mapLetterRecipients(createNewLetterTemplate.recipient)}</div>
                                    </ObjectListItem>
                                )}
                                {filteredLetterTemplates?.map((letterTemplate, index) => {
                                    return (
                                        <ObjectListItem
                                            key={letterTemplate.id}
                                            value={{ index: index, item: letterTemplate }}
                                            selected={
                                                selectedLetterTemplate && 'id' in selectedLetterTemplate
                                                    ? selectedLetterTemplate.id === letterTemplate.id
                                                    : false
                                            }
                                            onClick={onSelectLetterTemplate}
                                        >
                                            <div>{letterTemplate.name}</div>
                                            <div className="courses-selection-name">
                                                {mapLetterRecipients(letterTemplate.recipient)}
                                            </div>
                                        </ObjectListItem>
                                    );
                                })}
                            </div>
                        </ObjectList.Body>
                    </ObjectList>
                </StandardView.Left>
                <StandardView.Right>
                    <>
                        {selectedLetterTemplate ? (
                            'createdAt' in selectedLetterTemplate ? (
                                <LetterTemplateDetails
                                    onSubmit={onSubmit}
                                    resetLetterTemplate={resetLetterTemplate}
                                    mutate={reloadLetterTemplates}
                                    letterTemplate={selectedLetterTemplate}
                                />
                            ) : (
                                <LetterTemplateDetails
                                    onSubmit={onSubmit}
                                    onResetNewLetterTemplate={onResetNewLetterTemplate}
                                    mutate={reloadLetterTemplates}
                                    newLetterTemplate={selectedLetterTemplate}
                                />
                            )
                        ) : null}
                    </>
                </StandardView.Right>
            </StandardView>
        </>
    );
};
