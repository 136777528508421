import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { countries } from '../../models/Countries/Countries';
import {
    mapGraduation,
    mapProfessionalTraining,
    participantGraduation,
    participantProfessionalTraining
} from '../../models/Participant/Participant';
import {
    Participant,
    ParticipantGraduation,
    ParticipantProfessionalTraining,
    ParticipantSubmit
} from '../../models/Participant/Types';
import { QmDocumentDocx, QmDocumentSearch } from '../../models/QmDocument/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Accordion } from '../Accordion/Accordion';
import { Button } from '../Button/Button';
import { CreateProcessInputField } from '../CreateProcessInputField/CreateProcessInputField';
import { Datepicker } from '../Datepicker/Datepicker';
import { Form } from '../Form/Form';
import { FullscreenSidebar } from '../FullscreenSidebar/FullscreenSidebar';
import { FullscreenSidebarItem } from '../FullscreenSidebarItem/FullscreenSidebarItem';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { InputSelect } from '../InputSelect/InputSelect';
import { LoadingView } from '../LoadingView/LoadingView';
import './CreateParticipantForm.css';
import { useMeasuresApi } from '../../api/useMeasuresApi';
import { useQmDocumentTemplatesApi } from '../../api/useQmDocumentTemplatesApi';
import { useQmDocumentsApi } from '../../api/useQmDocumentsApi';
import { useParticipantClientsApi } from '../../api/useParticipantClientsApi';
import { useParticipantsApi } from '../../api/useParticipantsApi';

interface CreateParticipantFormProps {
    values: ParticipantSubmit;
    errors: FormikErrors<ParticipantSubmit>;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: () => void;
    setFieldValue: (arg: string, arg1: unknown) => void;
    touched: FormikTouched<ParticipantSubmit>;
    validateForm: (values?: unknown) => Promise<FormikErrors<unknown>>;
    setTouched: (touched: FormikTouched<unknown>, shouldValidate?: boolean | undefined) => void;
    className?: string;
    onCloseSidebar: () => void;
    mutate: () => void;
    onSetDocuments: (arg: QmDocumentDocx[]) => void;
}

type FormValueName =
    | 'lastName'
    | 'firstName'
    | 'gender'
    | 'birthday'
    | 'birthplace'
    | 'nationality'
    | 'streetAndNumber'
    | 'zipCode'
    | 'city'
    | 'email'
    | 'phone'
    | 'mobilePhone'
    | 'clientCustomerId'
    | 'name'
    | 'legalSphere'
    | 'contact'
    | 'stemGroup'
    | 'employee'
    | 'entranceDate'
    | 'plannedLeaveDate'
    | 'presenceType'
    | 'highestGraduation'
    | 'highestProfessionalGraduation';

interface FormNavigationItem {
    name: string;
    selected: boolean;
    finished: boolean;
    formValue: FormValueName;
    category: string;
}

export const CreateParticipantForm = (props: CreateParticipantFormProps) => {
    const { values, errors, handleChange, handleSubmit, setFieldValue, touched, validateForm, setTouched } = props;
    const measureId = useAppSelector((state) => state.measure.selectedMeasure?.id);
    const [loading, setLoading] = useState({ submit: false, documents: false });

    const dropDownHighestGraduation: { value: string | undefined; id: ParticipantGraduation }[] =
        participantGraduation.map((graduation) => {
            return {
                value: mapGraduation(graduation),
                id: graduation
            };
        });
    const dropDownHighestProfessionalGraduation: { value: string | undefined; id: ParticipantProfessionalTraining }[] =
        participantProfessionalTraining.map((training) => {
            return {
                value: mapProfessionalTraining(training),
                id: training
            };
        });

    // form categories
    const categories = ['Personalien', 'Kontaktdaten', 'Auftraggeber', 'Maßnahmedaten'];
    // structure of the form menu
    const formNavigationStructure: FormNavigationItem[] = [
        {
            name: 'Name',
            selected: true,
            finished: false,
            formValue: 'lastName',
            category: 'Personalien'
        },
        {
            name: 'Vorname',
            selected: false,
            finished: false,
            formValue: 'firstName',
            category: 'Personalien'
        },
        {
            name: 'Geschlecht',
            selected: false,
            finished: false,
            formValue: 'gender',
            category: 'Personalien'
        },
        {
            name: 'Geburtsdatum',
            selected: false,
            finished: false,
            formValue: 'birthday',
            category: 'Personalien'
        },
        {
            name: 'Geburtsort',
            selected: false,
            finished: false,
            formValue: 'birthplace',
            category: 'Personalien'
        },
        {
            name: 'Nationalität',
            selected: false,
            finished: false,
            formValue: 'nationality',
            category: 'Personalien'
        },
        {
            name: 'Straße und Hausnummer',
            selected: false,
            finished: false,
            formValue: 'streetAndNumber',
            category: 'Kontaktdaten'
        },
        {
            name: 'Postleitzahl',
            selected: false,
            finished: false,
            formValue: 'zipCode',
            category: 'Kontaktdaten'
        },
        {
            name: 'Ort',
            selected: false,
            finished: false,
            formValue: 'city',
            category: 'Kontaktdaten'
        },
        {
            name: 'E-Mail',
            selected: false,
            finished: false,
            formValue: 'email',
            category: 'Kontaktdaten'
        },
        {
            name: 'Telefon',
            selected: false,
            finished: false,
            formValue: 'phone',
            category: 'Kontaktdaten'
        },
        {
            name: 'Mobil',
            selected: false,
            finished: false,
            formValue: 'mobilePhone',
            category: 'Kontaktdaten'
        },
        {
            name: 'Kundennummer',
            selected: false,
            finished: false,
            formValue: 'clientCustomerId',
            category: 'Auftraggeber'
        },
        {
            name: 'Auftraggeber',
            selected: false,
            finished: false,
            formValue: 'name',
            category: 'Auftraggeber'
        },
        {
            name: 'Rechtskreis',
            selected: false,
            finished: false,
            formValue: 'legalSphere',
            category: 'Auftraggeber'
        },
        {
            name: 'Kontakt BA/JC',
            selected: false,
            finished: false,
            formValue: 'contact',
            category: 'Auftraggeber'
        },
        {
            name: 'Stammgruppe',
            selected: false,
            finished: false,
            formValue: 'stemGroup',
            category: 'Auftraggeber'
        },
        {
            name: 'Mitarbeiter/in Träger',
            selected: false,
            finished: false,
            formValue: 'employee',
            category: 'Auftraggeber'
        },
        {
            name: 'Eintrittsdatum',
            selected: false,
            finished: false,
            formValue: 'entranceDate',
            category: 'Maßnahmedaten'
        },
        {
            name: 'voraussichtlicher Austritt',
            selected: false,
            finished: false,
            formValue: 'plannedLeaveDate',
            category: 'Maßnahmedaten'
        },
        {
            name: 'Voll- oder Teilzeit',
            selected: false,
            finished: false,
            formValue: 'presenceType',
            category: 'Maßnahmedaten'
        },
        {
            name: 'Höchster Schulabschluss',
            selected: false,
            finished: false,
            formValue: 'highestGraduation',
            category: 'Maßnahmedaten'
        },
        {
            name: 'Höchster Berufsabschluss',
            selected: false,
            finished: false,
            formValue: 'highestProfessionalGraduation',
            category: 'Maßnahmedaten'
        }
    ];

    // state that holds information about what form part should be shown to the user
    const [formContent, setFormContent] = useState<{ name: string; index: number; category: string }>({
        name: formNavigationStructure[0].name,
        index: 1,
        category: categories[0]
    });
    // state that holds information about structure and states of the form menu
    const [formMenu, setFormMenu] = useState<FormNavigationItem[]>(formNavigationStructure);
    const { apiAddParticipantToMeasure } = useMeasuresApi();
    const { apiSearchQmDocumentTemplatesForPrintingNeededAtParticipantCreation } = useQmDocumentTemplatesApi();
    const { apiSearchQmDocumentsForPrinting, apiUpdateQmDocument, apiCreateQmDocumentDocx } = useQmDocumentsApi();
    const { apiCreateParticipantClient } = useParticipantClientsApi();
    const { apiCreateParticipant } = useParticipantsApi();

    /**
     * On click function if the user clicks the sidebar menu
     * @param menuName
     * @param index
     * @param category
     */
    const onClickSidebarItem = (menuName: string, index: number, category: string) => {
        // change the form content
        setFormContent({ name: menuName, index: index + 1, category: category });

        changeSelectedStateOfMenu(menuName);
    };

    /**
     * On click on the back button change the form
     */
    const onClickBackInForm = () => {
        changeSelectedStateOfMenu(formMenu[formContent.index - 2].name);
        setFormContent({
            name: formMenu[formContent.index - 2].name,
            index: formContent.index - 1,
            category: formMenu[formContent.index - 2].category
        });
    };

    /**
     * Validate the formik form manually. Formik validates everything at once and it does not care if the user has visited
     * a field already. Therefore we take are of validation ourselves by telling formik that the current field is touched and
     * if there is an error it can be displayed
     *
     * @param touched
     * @param setTouched
     * @param validateForm
     */
    const validateFormManually = useCallback(() => {
        // set the field of the current view as touched in Formik so that we know, this field has been visited
        touched[`${formMenu[formContent.index - 1].formValue}`] = true;
        setTouched(touched);
        // validate the form manually
        validateForm();
    }, [formContent.index, formMenu, setTouched, touched, validateForm]);

    /**
     * Function to change the icon/state if a menu point is selected
     * @param menuName
     * @param index
     */
    const changeSelectedStateOfMenu = useCallback(
        (menuName: string) => {
            // check if there is any menu selected already -> unselect if needed
            const indexOfMenuToUnSelect = formMenu.findIndex((element) => {
                return element.selected;
            });
            formMenu[indexOfMenuToUnSelect].selected = false;

            // check the name of the form content and mark the corresponding menu point as selected
            const indexOfMenuToBeSelected = formMenu.findIndex((element) => {
                return element.name === menuName;
            });
            formMenu[indexOfMenuToBeSelected].selected = true;
            // set form menu state
            setFormMenu([...formMenu]);
        },
        [formMenu]
    );
    /**
     * Call back when the user clicks the "next" button -> navigate through the form
     */
    const onClickNext = useCallback(() => {
        validateFormManually();
        // check if there are any errors existing for the current view/field
        if (!errors || (errors && !errors[formMenu[formContent.index - 1].formValue])) {
            // check if values to the current text field is existing, if so mark the menu option as finished
            if (values[formMenu[formContent.index - 1].formValue]) {
                formMenu[formContent.index - 1].finished = true;
                setFormMenu([...formMenu]);
            } else {
                formMenu[formContent.index - 1].finished = false;
                setFormMenu([...formMenu]);
                return;
            }

            setFormContent({
                name: formMenu[formContent.index].name,
                index: formContent.index + 1,
                category: formMenu[formContent.index].category
            });
            changeSelectedStateOfMenu(formMenu[formContent.index].name);
        } else {
            return;
        }
    }, [changeSelectedStateOfMenu, errors, formContent.index, formMenu, validateFormManually, values]);

    /**
     * Render the sidebar items
     */
    const renderSidebarItems = () => {
        return categories.map((category, index) => {
            return (
                <Accordion key={index} title={category} isOpen={formContent.category === category}>
                    {renderAccordionItems(category).map((menu, index) => {
                        return (
                            <FullscreenSidebarItem
                                key={index}
                                name={menu.name}
                                selected={menu.selected}
                                finished={menu.finished}
                                onClick={() =>
                                    onClickSidebarItem(
                                        menu.name,
                                        formNavigationStructure.findIndex((item) => item.formValue === menu.formValue),
                                        menu.category
                                    )
                                }
                            />
                        );
                    })}
                </Accordion>
            );
        });
    };

    /**
     * check for specific accordion items in order to render them in the correct accordion component
     * @param category
     */
    const renderAccordionItems = (category: string): FormNavigationItem[] => {
        return formMenu.filter((menu) => menu.category === category);
    };

    /**
     * Change birthday manually
     * @param value
     */
    const onChangeDateValue = (value: Date) => {
        const birthday = value;
        birthday.setHours(10);
        setFieldValue('birthday', birthday);
    };

    /**
     * Change nationality manually
     * @param value
     */
    const onChangeNationality = (value: string | undefined) => {
        setFieldValue('nationality', value);
    };

    const onChangeGraduation = (value: string | undefined) => {
        setFieldValue('highestGraduation', value);
    };

    const onChangeHighestProfessionalGraduation = (value: string | undefined) => {
        setFieldValue('highestProfessionalGraduation', value);
    };

    const onChangePlannedLeaveDateValue = (value: Date) => {
        setFieldValue('plannedLeaveDate', value);
    };

    const onChangeEntranceDateValue = (value: Date) => {
        setFieldValue('entranceDate', value);
    };

    const label = () => {
        switch (formContent.name) {
            case 'Name':
                return 'Bitte geben Sie den Nachnamen des Teilnehmers/der Teilnehmerin an';
            case 'Vorname':
                return 'Bitte geben Sie den Vornamen des Teilnehmers/der Teilnehmerin an';
            case 'Geburtsort':
                return 'Bitte geben Sie den Geburtsort des Teilnehmers/der Teilnehmerin an';
            case 'Straße und Hausnummer':
                return 'Bitte geben Sie die Adresse des Teilnehmers/der Teilnehmerin an';
            case 'Postleitzahl':
                return 'Bitte geben Sie die Adresse des Teilnehmers/der Teilnehmerin an';
            case 'Ort':
                return 'Bitte geben Sie die Adresse des Teilnehmers/der Teilnehmerin an';
            case 'E-Mail':
                return 'Bitte geben Sie eine E-Mail Adresse des Teilnehmers/der Teilnehmerin an';
            case 'Telefon':
                return 'Bitte geben Sie eine Telefonummer des Teilnehmers/der Teilnehmerin an';
            case 'Mobil':
                return 'Bitte geben Sie eine mobile Rufnummer des Teilnehmers/der Teilnehmerin an';
            case 'Kundennummer':
                return 'Bitte geben Sie die Kundennummer, die der Auftraggeber dem Teilnehmer/der Teilnehmerin zugewiesen hat, an';
            case 'Auftraggeber':
                return 'Bitte geben Sie den Auftraggeber an';
            case 'Kontakt BA/JC':
                return 'Bitte geben Sie einen Kontakt an';
            case 'Stammgruppe':
                return 'Bitte geben Sie die Stammgruppe an';
            case 'Mitarbeiter/in Träger':
                return 'Bitte geben Sie den Mitarbeiter/die Mitarbeiterin des Trägers an';
            case 'Voll- oder Teilzeit':
                return 'Bitte geben Sie die Beschäftigungsart an';
        }
    };

    const renderFormContent = () => {
        switch (formContent.name) {
            case 'Geschlecht':
                return (
                    <CreateProcessInputField
                        options={[
                            { label: 'Weiblich', value: 'female' },
                            {
                                label: 'Männlich',
                                value: 'male'
                            },
                            {
                                label: 'Divers',
                                value: 'diverse'
                            }
                        ]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        title={'Bitte geben Sie das Geschlecht der/des Teilnehmerin/Teilnehmers an?'}
                        label={'Geschlecht'}
                        touched={touched.gender}
                        name={'gender'}
                        value={values.gender}
                        error={errors.gender}
                    />
                );
            case 'Nationalität':
                return (
                    <>
                        <h5 className="create-measure-input-title">
                            Bitte geben Sie die Nationalität des Teilnehmers/der Teilnehmerin an
                        </h5>
                        <InputSelect
                            label={'Nationalität'}
                            onChange={onChangeNationality}
                            initialValue={values.nationality}
                            dropdownOptions={countries.map((country) => {
                                return { value: country, id: country };
                            })}
                        />
                    </>
                );
            case 'Rechtskreis':
                return (
                    <CreateProcessInputField
                        options={[
                            { label: 'SGB II', value: 'sgb_2' },
                            { label: 'SGB III', value: 'sgb_3' }
                        ]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        title={'Bitte geben Sie den Rechtskreis an'}
                        label={'Rechtskreis'}
                        touched={touched.legalSphere}
                        name={'legalSphere'}
                        value={values.legalSphere}
                        error={errors.legalSphere}
                    />
                );
            case 'Geburtsdatum':
                return (
                    <>
                        <h5 className="create-measure-input-title">
                            Bitte geben Sie das Geburtsdatum des Teilnehmers/der Teilnehmerin an
                        </h5>
                        <Datepicker
                            startYear={1950}
                            label={'Geburtsdatum'}
                            onChange={onChangeDateValue}
                            initialDate={props.values.birthday}
                            value={props.values.birthday}
                        />
                    </>
                );
            case 'Eintrittsdatum':
                return (
                    <>
                        <h5 className="create-measure-input-title">
                            Bitte geben Sie den Zeitpunkt des Eintritts in die Maßnahme an
                        </h5>
                        <Datepicker
                            label={'Eintritt in die Maßnahme'}
                            onChange={onChangeEntranceDateValue}
                            initialDate={props.values.entranceDate}
                            value={props.values.entranceDate}
                        />
                    </>
                );
            case 'voraussichtlicher Austritt':
                return (
                    <>
                        <h5 className="create-measure-input-title">
                            Bitte geben Sie das voraussichtliche Austrittsdatum an
                        </h5>
                        <Datepicker
                            label={'Voraussichtlicher Austritt aus der Maßnahme'}
                            onChange={onChangePlannedLeaveDateValue}
                            initialDate={props.values.plannedLeaveDate}
                            value={props.values.plannedLeaveDate}
                        />
                    </>
                );
            case 'Höchster Schulabschluss':
                return (
                    <>
                        <h5 className="create-measure-input-title">Bitte geben Sie den höchsten Schulabschluss an</h5>
                        <div className="create-participant-form-inputs">
                            <InputSelect
                                dropdownOptions={dropDownHighestGraduation}
                                onChange={onChangeGraduation}
                                label={'Höchster Schulabschluss'}
                                name={'highestGraduation'}
                                initialValue={mapGraduation(values.highestGraduation)}
                                error={errors.highestGraduation}
                            />
                            {values.highestGraduation === 'other_diploma' && (
                                <Input
                                    label={'Details zum Abschluss'}
                                    value={values.highestGraduationOther}
                                    onChange={handleChange}
                                    name={'highestGraduationOther'}
                                />
                            )}
                        </div>
                    </>
                );
            case 'Höchster Berufsabschluss':
                return (
                    <>
                        <h5 className="create-measure-input-title">Bitte geben Sie den höchsten Berufsabschluss an</h5>
                        <div className="create-participant-form-inputs">
                            <InputSelect
                                label={'Höchster Berufsabschluss'}
                                dropdownOptions={dropDownHighestProfessionalGraduation}
                                initialValue={mapProfessionalTraining(values.highestProfessionalGraduation)}
                                error={errors.highestProfessionalGraduation}
                                onChange={onChangeHighestProfessionalGraduation}
                            />
                            <Input
                                label={'Details zum Abschluss'}
                                placeholder={'z.B. Ausbildung zum Schreiner'}
                                value={values.highestProfessionalGraduationOther}
                                onChange={handleChange}
                                name={'highestProfessionalGraduationOther'}
                            />
                        </div>
                    </>
                );
            case 'Voll- oder Teilzeit':
                return (
                    <CreateProcessInputField
                        options={[
                            { label: 'Vollzeit', value: 'full_time' },
                            { label: 'Teilzeit', value: 'part_time' }
                        ]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        title={'Bitte geben Sie die Arbeitszeit an'}
                        label={'Arbeitszeit'}
                        touched={touched.presenceType}
                        name={'presenceType'}
                        value={values.presenceType}
                        error={errors.presenceType}
                    />
                );
            default: {
                const formNavigationStructureItem = formNavigationStructure.find(
                    (item) => item.name === formContent.name
                );

                return formNavigationStructureItem ? (
                    <CreateProcessInputField
                        title={label()}
                        label={formContent.name}
                        autoFocus
                        // @ts-ignore TODO fix this, the potentially dates in the form values are a problem here
                        value={values[formNavigationStructureItem.formValue]}
                        // @ts-ignore TODO fix this, the potentially dates in the form values are a problem here
                        error={errors[formNavigationStructureItem.formValue]}
                        onChange={handleChange}
                        name={formNavigationStructureItem.formValue}
                        touched={touched[formNavigationStructureItem.formValue]}
                    />
                ) : null;
            }
        }
    };

    /**
     * Call back in case user clicks the enter button
     * @param event
     */
    const clickEnter = useCallback(() => {
        // check if enter key was pressed and if the user aka formContent has not reached the end of the formMenu yet
        if (formContent.index < formMenu.length) {
            // trigger the next view
            onClickNext();
        }
    }, [formContent.index, formMenu.length, onClickNext]);

    /**
     * event listener in case user clicks the enter button
     */
    const eventListenerFunction = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                // prevent default click behaviour
                event.preventDefault();
                clickEnter();
            }
        },
        [clickEnter]
    );

    // register event listener for enter click
    useEffect(() => {
        document.addEventListener('keypress', eventListenerFunction);

        return () => document.removeEventListener('keypress', eventListenerFunction);
    }, [eventListenerFunction]);

    /**
     * Function to print the qm documents after a participant has been created
     * @param participant
     */
    const onPrintQmDocuments = async (participant: Participant) => {
        // start showing the user the loading process
        setLoading({ ...loading, documents: true });
        // search for all templates that has the attribute to get printed
        if (measureId) {
            const qmDocumentTemplatesForPrinting =
                await apiSearchQmDocumentTemplatesForPrintingNeededAtParticipantCreation();
            if (qmDocumentTemplatesForPrinting) {
                // search the corresponding qm documents to the templates for printing
                const qmDocumentsForPrinting: QmDocumentSearch[] = await Promise.all(
                    qmDocumentTemplatesForPrinting.results.map((templateForPrinting) => {
                        return apiSearchQmDocumentsForPrinting(measureId, templateForPrinting.id);
                    })
                );
                // flatten the printable documents array
                const printableDocuments = qmDocumentsForPrinting.map((printing) => printing.results).flat();
                // update the qm documents so that they are assigned to the before created user
                await Promise.all(
                    printableDocuments.map((document) => {
                        return apiUpdateQmDocument(document.id, {
                            ...document,
                            ...{ participantId: participant.id }
                        });
                    })
                );
                // turn qm documents into docx
                const request: QmDocumentDocx[] = await Promise.all(
                    printableDocuments.map((document) => {
                        return apiCreateQmDocumentDocx(document.id);
                    })
                );
                // give the docx to upper component in order to show it to the user
                props.onSetDocuments(request);
            }

            // show the user that the loading is done
            setLoading({ ...loading, documents: false });
        }
    };

    const emptyValues = (values: ParticipantSubmit) => {
        for (const [key, value] of Object.entries(values)) {
            if (
                !value &&
                key !== 'clientLegalSphere' &&
                key !== 'clientNumber' &&
                key !== 'comment' &&
                key !== 'participantClientId' &&
                key !== 'supporter' &&
                key !== 'highestGraduationOther' &&
                key !== 'highestProfessionalGraduationOther'
            ) {
                return true;
            }
        }

        return false;
    };

    /**
     * Create participant in the backend
     */
    const onSubmit = async () => {
        if (!emptyValues(props.values)) {
            try {
                setLoading({ ...loading, submit: true });
                const client = await apiCreateParticipantClient({
                    name: values.name,
                    clientNumber: values.clientCustomerId,
                    legalSphere: values.legalSphere ? values.legalSphere : 'sgb_2',
                    contact: values.contact,
                    stemGroup: values.stemGroup,
                    supporter: values.supporter,
                    employee: values.employee
                });
                if (client) {
                    values.participantClientId = client.id;
                    const participant = await apiCreateParticipant(values);
                    if (measureId) {
                        await apiAddParticipantToMeasure(
                            participant.id,
                            measureId,
                            values.presenceType,
                            values.entranceDate
                        );
                    }

                    setLoading({ ...loading, submit: false });
                    await onPrintQmDocuments(participant);
                    props.mutate();
                    props.onCloseSidebar();
                    showSuccessMessage('Teilnehmer wurde erfolgreich angelegt');
                }
            } catch (e) {
                console.log(e);
                throwError();
            }
        } else {
            throwError('Sie haben nicht alle Felder ausgefüllt');
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            {(loading.submit || loading.documents) && (
                <LoadingView>
                    {loading.submit && <div>Teilnehmer wird erstellt...</div>}
                    {loading.documents && <div>Dokumente werden angelegt...</div>}
                </LoadingView>
            )}
            <div className={`create-measure ${props.className}`}>
                <FullscreenSidebar
                    title={'Neuer Teilnehmer'}
                    onClose={() => props.onCloseSidebar()}
                    className="create-measure-menu"
                >
                    {renderSidebarItems()}
                </FullscreenSidebar>
                <div className="create-measure-detail">
                    <div className="label-3 create-measure-detail-sub-header">{`${
                        formMenu.findIndex((menu) => menu.name === formContent.name) + 1
                    }. ${formContent.name.toUpperCase()}`}</div>
                    {renderFormContent()}
                    {formContent.index < formMenu.length && (
                        <div className="p5-medium create-measure-detail-enter-hint">
                            Eingabetaste
                            <Icon
                                className="create-measure-detail-enter-hint-icon"
                                iconFile={
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0 2.66667C0 1.19391 1.19391 0 2.66667 0H13.3333C14.8061 0 16 1.19391 16 2.66667V13.3333C16 14.8061 14.8061 16 13.3333 16H2.66667C1.19391 16 0 14.8061 0 13.3333V2.66667Z"
                                            fill="#E4E4E7"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M12.8333 3.33301C13.1095 3.33301 13.3333 3.55687 13.3333 3.83301V7.33301C13.3333 8.34553 12.5125 9.16634 11.5 9.16634H4.42427L6.50997 11.1362C6.71073 11.3258 6.71977 11.6422 6.53016 11.843C6.34056 12.0437 6.0241 12.0528 5.82334 11.8632L2.82334 9.02985C2.72333 8.93539 2.66666 8.80391 2.66666 8.66634C2.66666 8.52878 2.72333 8.39729 2.82334 8.30283L5.82334 5.4695C6.0241 5.2799 6.34056 5.28894 6.53016 5.4897C6.71977 5.69045 6.71073 6.00691 6.50997 6.19651L4.42427 8.16634H11.5C11.9602 8.16634 12.3333 7.79325 12.3333 7.33301V3.83301C12.3333 3.55687 12.5572 3.33301 12.8333 3.33301Z"
                                            fill="#71717A"
                                            stroke="#71717A"
                                            strokeWidth="0.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M2.66667 0.666667H13.3333V-0.666667H2.66667V0.666667ZM15.3333 2.66667V13.3333H16.6667V2.66667H15.3333ZM13.3333 15.3333H2.66667V16.6667H13.3333V15.3333ZM0.666667 13.3333V2.66667H-0.666667V13.3333H0.666667ZM2.66667 15.3333C1.5621 15.3333 0.666667 14.4379 0.666667 13.3333H-0.666667C-0.666667 15.1743 0.825718 16.6667 2.66667 16.6667V15.3333ZM15.3333 13.3333C15.3333 14.4379 14.4379 15.3333 13.3333 15.3333V16.6667C15.1743 16.6667 16.6667 15.1743 16.6667 13.3333H15.3333ZM13.3333 0.666667C14.4379 0.666667 15.3333 1.5621 15.3333 2.66667H16.6667C16.6667 0.825718 15.1743 -0.666667 13.3333 -0.666667V0.666667ZM2.66667 -0.666667C0.825718 -0.666667 -0.666667 0.825718 -0.666667 2.66667H0.666667C0.666667 1.5621 1.5621 0.666667 2.66667 0.666667V-0.666667Z"
                                            fill="#E4E4E7"
                                        />
                                    </svg>
                                }
                            />
                            zum fortfahren
                        </div>
                    )}
                    <div className="create-measure-detail-buttons">
                        {formContent.index > 1 && (
                            <Button
                                type={'primary'}
                                size={'medium'}
                                buttonStyle={'link'}
                                firstIcon={<Icon type={'ArrowLeft'} />}
                                text={'Zurück'}
                                onClick={onClickBackInForm}
                            />
                        )}
                        {formContent.index < formMenu.length && (
                            <Button
                                type={'primary'}
                                size={'medium'}
                                buttonStyle={'filled'}
                                text={'Weiter'}
                                className="align-self-end"
                                onClick={onClickNext}
                            />
                        )}
                        {formContent.index === formMenu.length && (
                            <Button
                                type={'primary'}
                                size={'medium'}
                                buttonStyle={'filled'}
                                text={'Abschließen und Dokumente drucken'}
                                className="align-self-end"
                                onClick={onSubmit}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Form>
    );
};
