import { Course, CourseSubmit } from '../models/Course/Type';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Returns method to communicate with the courses api in the backend.
 */
export const useCoursesApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API call to get all existing courses
     *
     */
    const apiGetAllCourses = useCallback(async () => {
        return call<void, Course[]>('courses', 'GET');
    }, [call]);

    /**
     * API call to create a new course
     *
     * @param course
     */
    const apiCreateCourse = useCallback(
        async (course: CourseSubmit): Promise<Course> => {
            return call<CourseSubmit, Course>('courses', 'POST', course);
        },
        [call]
    );

    const apiRemoveUserFromCourse = useCallback(
        async (courseId: number, userId: number) => {
            return call<void, unknown>(`courses/${courseId}/remove_user/${userId}`, 'DELETE');
        },
        [call]
    );

    const apiAddUserToCourse = useCallback(
        async (courseId: number, userId: number) => {
            return call<void, unknown>(`courses/${courseId}/add_user/${userId}`, 'POST');
        },
        [call]
    );

    const apiAddParticipantToCourse = useCallback(
        async (courseId: number, participantId: number) => {
            return call<void, unknown>(`courses/${courseId}/add_participant/${participantId}`, 'POST');
        },
        [call]
    );

    const apiRemoveParticipantFromCourse = useCallback(
        async (courseId: number, participantId: number) => {
            return call<void, unknown>(`courses/${courseId}/remove_participant/${participantId}`, 'DELETE');
        },
        [call]
    );

    const apiArchiveCourse = useCallback(
        async (courseId: number) => {
            return call<void, unknown>(`courses/${courseId}/archive`, 'DELETE');
        },
        [call]
    );

    const apiActivateCourse = useCallback(
        async (courseId: number) => {
            return call<void, unknown>(`courses/${courseId}/activate`, 'POST');
        },
        [call]
    );

    const apiUpdateCourse = useCallback(
        async (courseId: number, course: CourseSubmit): Promise<Course> => {
            return call<CourseSubmit, Course>(`courses/${courseId}`, 'PATCH', course);
        },
        [call]
    );

    return {
        apiGetAllCourses: apiGetAllCourses,
        apiCreateCourse: apiCreateCourse,
        apiRemoveUserFromCourse: apiRemoveUserFromCourse,
        apiAddUserToCourse: apiAddUserToCourse,
        apiAddParticipantToCourse: apiAddParticipantToCourse,
        apiRemoveParticipantFromCourse: apiRemoveParticipantFromCourse,
        apiArchiveCourse: apiArchiveCourse,
        apiActivateCourse: apiActivateCourse,
        apiUpdateCourse: apiUpdateCourse
    };
};
