import React, { useState } from 'react';
import useSWR from 'swr';
import { KeyedMutator } from 'swr';
import { NeedForAction, NeedForActionSuggestion } from '../../models/NeedForAction/Type';
import { ObjectiveAgreement } from '../../models/ObjectiveAgreement/Type';
import { ParticipantWithOneMeasure } from '../../models/Participant/Types';
import { SupportPlan } from '../../models/SupportPlans/Type';
import { Button } from '../Button/Button';
import { FullScreenModal } from '../FullScreenModal/FullScreenModal';
import { NeedForActionCard } from '../NeedForAction/NeedForActionCard';
import { ObjectList } from '../ObjectList/ObjectList';
import { ObjectListBody } from '../ObjectListBody/ObjectListBody';
import { ObjectListItem } from '../ObjectListItem/ObjectListItem';
import { UpdateNeedForAction } from '../UpdateNeedForAction/UpdateNeedForAction';
import { useAppSelector } from '../../hooks';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface CreateObjectiveAgreementProps {
    onClose: () => void;
    supportPlan: SupportPlan | undefined;
    participant: ParticipantWithOneMeasure | undefined;
    reloadObjectiveAgreements?: KeyedMutator<ObjectiveAgreement[]>;
}

export const CreateObjectiveAgreement = (props: CreateObjectiveAgreementProps) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const measure = useAppSelector((x) => x.measure.selectedMeasure);
    const { data: suggestions } = useSWR<NeedForActionSuggestion[]>([
        backendUrl,
        'need_for_actions/suggestions',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const [filter, setFilter] = useState<string>('high');
    const [selectedNeedForAction, setSelectedNeedForAction] = useState<NeedForAction | undefined>(undefined);
    const needForActionFilter: { value: string; id: string }[] = [
        { value: 'hohe Priorität', id: 'high' },
        { value: 'mittlere Priorität', id: 'medium' },
        { value: 'niedrige Priorität', id: 'low' },
        { value: 'erledigte Handlungsbedarfe', id: 'finished' },
        { value: 'Chronologie der Handlungsbedarfe', id: 'date' },
        { value: 'sonstige', id: 'other' }
    ];

    const onClickMenu = (id: number | string) => {
        if (typeof id === 'string') {
            setFilter(id);
        }
    };

    const { data: objectiveAgreements } = useSWR<ObjectiveAgreement[]>([
        backendUrl,
        `participants/${props.participant?.id}/objective_agreements`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: needForActions } = useSWR<NeedForAction[]>([
        backendUrl,
        `participants/${props.participant?.id}/need_for_actions`,
        'GET',
        { measureId: measure?.id },
        authInfo,
        apiVersion
    ]);

    const onChangeNeedForAction = (needForAction?: NeedForAction) => {
        if (selectedNeedForAction) {
            setSelectedNeedForAction(undefined);
        } else {
            setSelectedNeedForAction(needForAction);
        }
    };

    const renderNeedForActions = () => {
        switch (filter) {
            case 'low':
            case 'medium':
            case 'high':
                return needForActions
                    ?.filter((action) => action.priority === filter && !action.closed)
                    .map((action) => (
                        <NeedForActionCard
                            key={action.id}
                            objectiveAgreement={objectiveAgreements?.filter(
                                (objectiveAgreement) => objectiveAgreement.needForActionId === action.id
                            )}
                            needForAction={action}
                            onClick={onChangeNeedForAction}
                        />
                    ));
            case 'date':
                return needForActions
                    ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                    .filter((action) => action.priority !== 'information' && !action.closed)
                    .map((action: NeedForAction) => (
                        <NeedForActionCard
                            key={action.id}
                            objectiveAgreement={objectiveAgreements?.filter(
                                (objectiveAgreement) => objectiveAgreement.needForActionId === action.id
                            )}
                            needForAction={action}
                            onClick={onChangeNeedForAction}
                        />
                    ));
            case 'other':
                return;
        }
    };

    const onClose = () => {
        if (props.reloadObjectiveAgreements) {
            props.reloadObjectiveAgreements().then();
        }

        props.onClose();
    };

    return !selectedNeedForAction ? (
        <FullScreenModal
            footer={
                <Button type={'primary'} size={'medium'} buttonStyle={'filled'} text={'Abbrechen'} onClick={onClose} />
            }
        >
            <div className="participant-need-for-action-modal-body">
                <div className="participant-need-for-action-modal-left-side">
                    <ObjectList>
                        <ObjectList.Head>
                            <div className="measure-list-head-container">
                                <div className="measure-list-head">
                                    <div>Handlungsbedarfe</div>
                                </div>
                            </div>
                        </ObjectList.Head>
                        <ObjectListBody>
                            {needForActionFilter.map((menuOption: { value: string; id: string }, index) => {
                                return (
                                    <ObjectListItem
                                        key={index}
                                        value={{ index: menuOption.id, item: menuOption.value }}
                                        selected={filter === menuOption.id}
                                        onClick={onClickMenu}
                                    >
                                        <div key={index}>{menuOption.value}</div>
                                    </ObjectListItem>
                                );
                            })}
                        </ObjectListBody>
                    </ObjectList>
                </div>
                <div className="participant-need-for-action-modal-right-side">{renderNeedForActions()}</div>
            </div>
        </FullScreenModal>
    ) : (
        <UpdateNeedForAction
            onClose={onClose}
            defineObjectiveAgreement
            supportPlan={props.supportPlan}
            multipleAnswers={selectedNeedForAction.needForActionType.includes('Mehrfachnennung möglich')}
            needForAction={selectedNeedForAction}
            onGoBack={onChangeNeedForAction}
            correspondingSuggestion={suggestions
                ?.find((suggestion) =>
                    suggestion.needForActionTypes.find(
                        (type) => type.needForActionType === selectedNeedForAction?.needForActionType
                    )
                )
                ?.needForActionTypes.find(
                    (type) => type.needForActionType === selectedNeedForAction?.needForActionType
                )}
        />
    );
};
