import { SupportPlan, SupportPlanSubmit } from '../models/SupportPlans/Type';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Returns methods to communicate with the backend related to SupportPlans.
 */
export const useSupportPlansApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API to create a support plan
     *
     * @param planInformation
     */
    const apiCreateSupportPlans = useCallback(
        async (planInformation: SupportPlanSubmit): Promise<SupportPlan> => {
            return call<SupportPlanSubmit, SupportPlan>('support_plans', 'POST', planInformation);
        },
        [call]
    );

    /**
     * API to get objective agreements to a corresponding support plan
     *
     * @param supportPlanId
     */
    const apiGetObjectiveAgreementBySupportPlan = useCallback(
        async (supportPlanId: number): Promise<SupportPlan[]> => {
            return call<void, SupportPlan[]>(`support_plans/${supportPlanId}/objective_agreements`, 'GET');
        },
        [call]
    );

    const apiDeleteSupportPlan = useCallback(
        async (supportPlanId: number) => {
            return call<void, unknown>(`support_plans/${supportPlanId}`, 'DELETE');
        },
        [call]
    );

    const apiUpdateSupportPlan = useCallback(
        async (supportPlanId: number, supportPlanUpdate: Partial<SupportPlan>): Promise<SupportPlan> => {
            return call<Partial<SupportPlan>, SupportPlan>(
                `support_plans/${supportPlanId}`,
                'PATCH',
                supportPlanUpdate
            );
        },
        [call]
    );

    return {
        apiCreateSupportPlans: apiCreateSupportPlans,
        apiGetObjectiveAgreementBySupportPlan: apiGetObjectiveAgreementBySupportPlan,
        apiDeleteSupportPlan: apiDeleteSupportPlan,
        apiUpdateSupportPlan: apiUpdateSupportPlan
    };
};
