
const digest = 'f5a9e19755398c2cf7d786537a79648da1105d0106266840ee043a97f46830a6';
const css = `._container_1c59p_1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

._fieldContainer_1c59p_8 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

._fieldLabel_1c59p_15 {
    font-size: large;
}

._formField_1c59p_19 {

}

._fieldError_1c59p_23 {

}

._fieldContainer_1c59p_8 .invalid-feedback {
    font-size: small;
    color: darkred;
}

._submitButton_1c59p_32 {

}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"container":"_container_1c59p_1","fieldContainer":"_fieldContainer_1c59p_8","fieldLabel":"_fieldLabel_1c59p_15","formField":"_formField_1c59p_19","fieldError":"_fieldError_1c59p_23","submitButton":"_submitButton_1c59p_32"};
export { css, digest };
  