import { ParticipantNote, ParticipantNoteSubmit } from '../models/Participant/Types';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Returns methods to communicate with the backend related to Notes.
 */
export const useNotesApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API to create a new note for a participant
     *
     * @param participantNotes
     */
    const apiCreateNewParticipantNote = useCallback(
        async (participantNotes: ParticipantNoteSubmit): Promise<ParticipantNote> => {
            return call<ParticipantNoteSubmit, ParticipantNote>('participant_notes', 'POST', participantNotes);
        },
        [call]
    );

    /**
     * API to update a participant note
     *
     * @param participantNotes
     */
    const apiUpdateParticipantNote = useCallback(
        async (participantNoteId: number, participantNotes: ParticipantNoteSubmit): Promise<ParticipantNote> => {
            return call<ParticipantNoteSubmit, ParticipantNote>(
                `participant_notes/${participantNoteId}`,
                'PATCH',
                participantNotes
            );
        },
        [call]
    );

    /**
     * API to delete a participant note
     *
     * @param id
     */
    const apiDeleteParticipantNote = useCallback(
        async (id: number | string) => {
            return call<void, unknown>(`participant_notes/${id}`, 'DELETE');
        },
        [call]
    );

    return {
        apiCreateNewParticipantNote: apiCreateNewParticipantNote,
        apiUpdateParticipantNote: apiUpdateParticipantNote,
        apiDeleteParticipantNote: apiDeleteParticipantNote
    };
};
