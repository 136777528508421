import avatar from '../../assets/img/user.png';
import { platformUrl } from '../../helpers';
import './Avatar.css';

interface AvatarProps {
    // url to load the avatar as an image
    url: string | number | undefined | null;
    // avatar's name
    name?: string;
    // small description under the name
    description?: string;
    // size of avatar
    size: 'small' | 'medium' | 'large';
}

export const Avatar = (props: AvatarProps) => {
    return (
        <div className="avatar">
            <div className={`avatar-container ${props.size}`}>
                <img
                    className="avatar-container-image"
                    src={props.url && typeof props.url === 'string' ? platformUrl(props.url) : avatar}
                    alt={'avatar'}
                />
            </div>
            <div className="p5-medium">
                <div> {props.name} </div>
                <div className={'avatar-description'}>{props.description}</div>
            </div>
        </div>
    );
};
