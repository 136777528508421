import { useCallback } from 'react';
import { removeUserFromSessionStorage } from '../models/SessionStorage/SessionStorage';
import { toast } from 'react-toastify';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../models/User/State';
import { GroupName } from '../models/User/Types';

/**
 * Hook that returns functions related to session management.
 */
export const useSession = () => {
    const { setAuthInfo } = useEzOnRails();
    const navigate = useNavigate();
    const currentUser = useUser((x) => x.currentUser);

    /**
     * Logs out the current user and navigates to the landing page.
     */
    const logOut = useCallback(() => {
        removeUserFromSessionStorage();
        setAuthInfo(null);
        toast(`🚀 Auf Wiedersehen`);
        navigate('/');
    }, [navigate, setAuthInfo]);

    /**
     * Returns whether the current user is in the specified group.
     */
    const isInGroup = useCallback(
        (group: GroupName) => {
            return currentUser?.groups?.some((userGroup) => group === userGroup);
        },
        [currentUser]
    );

    /**
     * Returns whether the current user is in the Administrator group.
     */
    const isAdministrator = useCallback(() => {
        return isInGroup('Administrator');
    }, [isInGroup]);

    /**
     * Returns whether the current user is in the QmManager group.
     */
    const isQmManager = useCallback(() => {
        return isInGroup('QmManager');
    }, [isInGroup]);

    /**
     * Returns whether the current user is in the Employee group.
     */
    const isEmployee = useCallback(() => {
        return isInGroup('Employee');
    }, [isInGroup]);

    return {
        logOut,
        isInGroup,
        isAdministrator,
        isEmployee,
        isQmManager
    };
};
