import { ChangeEvent, useEffect, useState } from 'react';
import { Measure } from '../../models/Measure/Types';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { MeasureListElements } from '../MeasureListElements/MeasureListElements';
import { ObjectList } from '../ObjectList/ObjectList';
import './MeasureList.css';

interface MeasureListProps {
    onChangeSelectedMeasure?: (measure: Measure) => void;
    measures: Measure[] | undefined;
    onCreateNewMeasure: () => void;
    selectedMeasure: Measure | undefined;
}

export interface MeasureListInterface {
    text: string;
    description?: string;
    year?: string;
    avatarUrl?: string;
    date?: Date | number;
    id: number;
}

export const MeasureList = (props: MeasureListProps) => {
    const [menuSelected, setMenuSelected] = useState<string>('active');
    const [showSearch, setShowSearch] = useState<boolean>(false);

    const [activeMeasures, setActiveMeasures] = useState<Measure[]>();
    const [inActiveMeasures, setInactiveMeasures] = useState<Measure[]>();
    const [initialActiveMeasures, setInitialActiveMeasures] = useState<Measure[]>();
    const [initialInActiveMeasures, setInitialInActiveMeasures] = useState<Measure[]>();

    useEffect(() => {
        const activeMeasuresArray: Measure[] = [];
        const inactiveMeasuresArray: Measure[] = [];
        if (props.measures) {
            props.measures.forEach((measure) => {
                if (measure.archived) {
                    inactiveMeasuresArray.push(measure);
                } else {
                    activeMeasuresArray.push(measure);
                }

                setActiveMeasures(activeMeasuresArray.sort((a, b) => a.name.localeCompare(b.name)));
                setInitialActiveMeasures(activeMeasuresArray);
                setInactiveMeasures(inactiveMeasuresArray.sort((a, b) => a.name.localeCompare(b.name)));
                setInitialInActiveMeasures(inactiveMeasuresArray);
            });
        }
    }, [props.measures]);

    const onClickOpenSearch = () => {
        setShowSearch(!showSearch);
    };

    /**
     * Called when another measure in the list was selected.
     * @param measure
     */
    const onChangeSelectedMeasure = (measure: Measure) => {
        if (props.onChangeSelectedMeasure) {
            props.onChangeSelectedMeasure(measure);
        }
    };

    const onChangeSearch = (event: ChangeEvent, value?: string) => {
        // create the filtered items based on the user's input
        if (menuSelected === 'active' && value) {
            const activeMeasuresBuffer = initialActiveMeasures?.filter((item: Measure) =>
                item.name.toLowerCase().includes(value?.toLowerCase())
            );
            setActiveMeasures(activeMeasuresBuffer);
        } else if (value) {
            const inActiveMeasuresBuffer = initialInActiveMeasures?.filter((item: Measure) =>
                item.name.toLowerCase().includes(value?.toLowerCase())
            );
            setInactiveMeasures(inActiveMeasuresBuffer);
        } else {
            if (menuSelected === 'active') {
                setActiveMeasures(initialActiveMeasures);
            } else {
                setInactiveMeasures(initialInActiveMeasures);
            }
        }
    };

    return (
        <ObjectList>
            <ObjectList.Head>
                <div className="measure-list-head-container">
                    <div className="measure-list-head">
                        <div className={'p2-medium'}>Maßnahmen</div>
                        <div className="measure-list-head-search">
                            <Button
                                type={'secondary'}
                                buttonStyle={'link'}
                                size={'small'}
                                firstIcon={<Icon type={'Search'} />}
                                onClick={onClickOpenSearch}
                            />
                            <Button
                                type={'primary'}
                                size={'small'}
                                buttonStyle={'filled'}
                                firstIcon={<Icon type={'Plus'} />}
                                onClick={props.onCreateNewMeasure}
                            />
                        </div>
                    </div>
                    {showSearch && (
                        <Input
                            icon={<Icon type={'Search'} />}
                            placeholder={'Maßnahme suchen'}
                            onChange={onChangeSearch}
                        />
                    )}
                </div>
            </ObjectList.Head>
            <div className="measure-list-navigation">
                <div
                    className={`measure-list-navigation-item label-2 ${menuSelected === 'active' && 'active'}`}
                    onClick={() => setMenuSelected('active')}
                >
                    AKTIV ({activeMeasures?.length})
                </div>
                <div
                    className={`measure-list-navigation-item label-2 ${menuSelected === 'inactive' && 'active'}`}
                    onClick={() => setMenuSelected('inactive')}
                >
                    ARCHIVIERT ({inActiveMeasures?.length})
                </div>
            </div>
            {menuSelected === 'active' && (
                <ObjectList.Body>
                    <MeasureListElements
                        selectedMeasure={props.selectedMeasure}
                        elements={activeMeasures}
                        onSelectMeasure={onChangeSelectedMeasure}
                    />
                </ObjectList.Body>
            )}
            {menuSelected === 'inactive' && (
                <ObjectList.Body>
                    <MeasureListElements
                        selectedMeasure={props.selectedMeasure}
                        elements={inActiveMeasures}
                        onSelectMeasure={onChangeSelectedMeasure}
                    />
                </ObjectList.Body>
            )}
        </ObjectList>
    );
};
