import { ParticipantDeactivationReason } from '../Participant/Types';
import * as Yup from 'yup';

export const mapLegalSphere = (legalSphere: string | undefined) => {
    switch (legalSphere) {
        case 'sgb_2':
            return 'SGB II';
        case 'sgb_3':
            return 'SGB III';
    }
};

export const mapMeasureParticipantDeactivationReason = (reason: ParticipantDeactivationReason | undefined) => {
    switch (reason) {
        case 'change_to_bvb':
            return 'Wechsel in eine BVB';
        case 'change_to_eq':
            return 'Wechsel in EQ';
        case 'measure_goal_achieved':
            return 'Maßnahmeziel erreicht';
        case 'change_to_secondary_school':
            return 'Wechsel zur weiterführenden Schule';
        case 'change_to_sgb_2_measure':
            return 'Wechsel in andere SGB II Maßnahme';
        case 'change_to_sgb_3_measure':
            return 'Wechsel in andere SGB III Maßnahme';
        case 'change_to_vocational_preparation_school':
            return 'Wechsel in schulische Berufsvorbereitung';
        case 'civil_or_military_duty':
            return 'Bundesfreiwilligendienst/freiwilliger Wehrdienst';
        case 'company_apprenticeship':
            return 'Betriebliche Ausbildung';
        case 'company_external_apprenticeship':
            return 'Außerbetriebliche Ausbildung';
        case 'entrance_exam_success':
            return 'Erwerb der Berufsreife';
        case 'job':
            return 'Arbeitsstelle';
        case 'job_center':
            return 'durch Jobcenter';
        case 'language_course':
            return 'Sprachkurs';
        case 'measure_conflicting_manner':
            return 'wg. maßnahmenwidrigem Verhalten';
        case 'mental_overload_or_underload':
            return 'wg. Über-/Unterforderung';
        case 'new_participation':
            return 'Erneute Teilnahme';
        case 'no_claim_for_ag_2':
            return 'Kein Anspruch von AGLII-Leistung';
        case 'own_abandonment':
            return 'Freiwilliger Verzicht';
        case 'penal_system':
            return 'wg. Strafvollzug';
        case 'pregnancy':
            return 'wg. Schwangerschaft';
        case 'relocation':
            return 'wg. Umzug';
        case 'school_apprenticeship':
            return 'schulische Ausbildung';
        case 'self_employed_job':
            return 'Selbstständige Tätigkeit';
        case 'sickness':
            return 'wg. Krankheit';
        case 'too_old':
            return 'ü25/noch nicht untergebracht';
        case 'unknown':
            return 'Verbleib unbekannt';
        case 'vocational_preparation':
            return 'Betriebliche Berufsvorbereitung';
        case 'work_or_minijob':
            return 'Arbeit/Minijob/400€';
        case 'youth_free_service':
            return 'Einmündung in Jugendfreiwilligendienst';
    }
};

const phoneRegx = /[0-9]/;
export const DetailsValidationScheme = Yup.object()
    .shape({
        name: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
        lotSize: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
        email: Yup.string()
            .required('Dieses Feld muss ausgefüllt sein.')
            .email('Bitten geben Sie eine gültige E-Mail Adresse ein'),
        rehabSlots: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
        fax: Yup.string()
            .required('Dieses Feld muss ausgefüllt sein.')
            .matches(phoneRegx, 'Dies scheint keine gültige Faxnummmer zu sein'),
        phone: Yup.string()
            .required('Dieses Feld muss ausgefüllt sein.')
            .matches(phoneRegx, 'Dies scheint keine gültige Telefonnummer zu sein'),
        state: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
        zipCode: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
        endAt: Yup.date().required('Dieses Feld muss ausgefüllt sein.'),
        number: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
        startAt: Yup.date().required('Dieses Feld muss ausgefüllt sein.'),
        legalSphere: Yup.string().required('Bitte geben Sie den Rechtskreis an.'),
        contact: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
        streetAndNumber: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
        city: Yup.string().required('Dieses Feld muss ausgefüllt sein.')
    })
    .defined();
