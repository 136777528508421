import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { Icon } from '../../components/Icon/Icon';
import { Input } from '../../components/Input/Input';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { ObjectList } from '../../components/ObjectList/ObjectList';
import { ObjectListItem } from '../../components/ObjectListItem/ObjectListItem';
import { ParticipantContactsCard } from '../../components/ParticipantContactsCard/ParticipantContactsCard';
import { StandardView } from '../../components/StandardView/StandardView';
import {
    ParticipantContact,
    ParticipantContactSubmit,
    ParticipantWithOneMeasure
} from '../../models/Participant/Types';
import { useParticipantsApi } from '../../api/useParticipantsApi';

interface ParticipantDetailInformationViewContactsProps {
    participant: ParticipantWithOneMeasure | undefined;
}

export const ParticipantDetailInformationViewContacts = (props: ParticipantDetailInformationViewContactsProps) => {
    const [participantContacts, setParticipantContacts] = useState<ParticipantContact[]>();
    const [selectedMenu, setSelectedMenu] = useState<number | string>();
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [filteredContacts, setFilteredContacts] = useState<ParticipantContact[]>();
    const [newContact, setNewContact] = useState<ParticipantContactSubmit>();
    const { apiSearchParticipantContacts } = useParticipantsApi();

    const onClickOpenSearch = () => {
        setShowSearch(!showSearch);
    };

    const onSelectMenu = (item: number | string) => {
        setSelectedMenu(item);
    };

    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setFilteredContacts(
            participantContacts?.filter(
                (participant) =>
                    participant.firstName.toLowerCase().includes(event.target.value.toLowerCase() as string) ||
                    participant.lastName.toLowerCase().includes(event.target.value.toLowerCase() as string) ||
                    participant.institution.toLowerCase().includes(event.target.value.toLowerCase() as string)
            )
        );
    };

    /**
     * Load participant's contacts
     */
    const loadParticipantContacts = useCallback(async () => {
        if (props.participant?.id) {
            const contacts = await apiSearchParticipantContacts(props.participant?.id);
            setParticipantContacts(contacts.results);
        }
    }, [apiSearchParticipantContacts, props.participant?.id]);

    /**
     * Reset the new contact to undefined. If a new contact was created before, select it
     * @param newContact
     */
    const onResetNewContact = (newContact?: ParticipantContact) => {
        if (newContact) {
            setSelectedMenu(participantContacts?.find((contact) => contact.id === newContact.id)?.id);
        }

        setNewContact(undefined);
    };

    const onCreateNewContact = () => {
        if (props.participant) {
            setNewContact({
                participantId: props.participant.id,
                firstName: '',
                lastName: '',
                role: '',
                title: '',
                fax: '',
                mobile: '',
                institution: '',
                phoneNumber: '',
                streetAndNumber: '',
                zipCode: '',
                city: '',
                country: '',
                addressAddition: '',
                email: '',
                confidentialityReleased: false
            });
            setSelectedMenu(-99);
        }
    };

    /**
     * Load the participant's contacts on initial page load
     */
    useEffect(() => {
        loadParticipantContacts();
    }, [loadParticipantContacts]);

    /**
     * if the id in the selected menu state does not exist anymore, grab the first item of the filtered contact array
     */
    useEffect(() => {
        if (filteredContacts && participantContacts) {
            if (
                filteredContacts &&
                filteredContacts.length > 0 &&
                selectedMenu &&
                participantContacts &&
                !newContact &&
                !participantContacts?.find((contact) => contact.id === selectedMenu)
            ) {
                setSelectedMenu(filteredContacts[0].id);
            }
        }
    }, [filteredContacts, newContact, participantContacts, selectedMenu]);

    /**
     * After fetching the contacts, write them to the filter state
     */
    useEffect(() => {
        if (participantContacts) {
            setFilteredContacts(participantContacts?.sort((a, b) => a.lastName.localeCompare(b.lastName)));
        }
    }, [participantContacts]);

    return (
        <StandardView>
            <StandardView.Left>
                <ObjectList>
                    <ObjectList.Head>
                        <div className={'user-list-head-container'} style={{ marginBottom: '24px' }}>
                            <div className={'user-list-head'}>
                                <div className="p2-medium">Ansprechpartner</div>
                                <div style={{ display: 'flex', gap: '16px' }}>
                                    <Button
                                        type={'secondary'}
                                        buttonStyle={'link'}
                                        size={'small'}
                                        firstIcon={<Icon type={'Search'} />}
                                        onClick={onClickOpenSearch}
                                    />
                                    <Button
                                        type={'primary'}
                                        size={'small'}
                                        buttonStyle={'filled'}
                                        firstIcon={<Icon type={'Plus'} />}
                                        onClick={onCreateNewContact}
                                    />
                                </div>
                            </div>
                            {showSearch && (
                                <>
                                    <Input
                                        icon={<Icon type={'Search'} />}
                                        placeholder={'Notizen suchen'}
                                        onChange={onChangeSearch}
                                    />
                                </>
                            )}
                        </div>
                    </ObjectList.Head>
                    <ObjectList.Body>
                        <div className="participant-detail-information-dates" style={{ flexDirection: 'column' }}>
                            {newContact && (
                                <ObjectListItem
                                    value={{ index: -99, item: newContact }}
                                    selected={selectedMenu === -99}
                                    onClick={onSelectMenu}
                                >
                                    <div>Neuer Ansprechpartner</div>
                                </ObjectListItem>
                            )}
                            {filteredContacts ? (
                                filteredContacts?.map((participantContact) => {
                                    return (
                                        <ObjectListItem
                                            key={participantContact.id}
                                            value={{ index: participantContact.id, item: participantContact }}
                                            selected={selectedMenu === participantContact.id}
                                            onClick={onSelectMenu}
                                        >
                                            <div>
                                                {participantContact.lastName}, {participantContact.firstName}
                                            </div>
                                            <div className="courses-selection-name">
                                                {participantContact.institution}
                                            </div>
                                        </ObjectListItem>
                                    );
                                })
                            ) : (
                                <LoadingSpinner />
                            )}
                        </div>
                    </ObjectList.Body>
                </ObjectList>
            </StandardView.Left>
            <StandardView.Right>
                <>
                    {' '}
                    {selectedMenu && (
                        <ParticipantContactsCard
                            onResetNewContact={onResetNewContact}
                            newContact={newContact}
                            reload={loadParticipantContacts}
                            participantContact={participantContacts?.find(
                                (participant) => participant.id === selectedMenu
                            )}
                        />
                    )}{' '}
                </>
            </StandardView.Right>
        </StandardView>
    );
};
