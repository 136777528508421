import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import './LoadingView.css';
import { ReactNode } from 'react';

interface LoadingViewProps {
    children?: ReactNode;
}

export const LoadingView = (props: LoadingViewProps) => {
    return (
        <div>
            <div className="loading-view" />
            <LoadingSpinner>{props.children}</LoadingSpinner>
        </div>
    );
};
