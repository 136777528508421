import { ReactNode } from 'react';
import styles from './Checkbox.module.css';

/**
 * Props for the Checkbox component.
 */
interface CheckboxProps {
    // The current checked state of the checkbox
    value: boolean;

    // Called if the box was checked or unchecked. Optional for disabled readonly checkboxes
    onChange?: (value: boolean) => void;

    // Shows the label of the checkbox, if given
    label?: ReactNode;

    // Optional class name of the container holding the label
    labelContainerClassName?: string;

    // Optional class name that is passed to the container holding the box and the label
    className?: string;

    // Indicates whether the box should be disabled.
    disabled?: boolean;

    // If true, the checkbox will be shown with some error indicator
    error?: boolean;
}

/**
 * Shows a stateless checkbox with an onChange listener given by the props.
 *
 * @param props
 * @constructor
 */
export const Checkbox = (props: CheckboxProps) => {
    /**
     * Called if the user clicks the checkbox.
     * Sends the change to the callback given by the props.
     */
    const onClick = () => {
        if (!props.onChange) return;

        props.onChange(!props.value);
    };

    return (
        <div className={`${styles.container} ${props.className}`}>
            <div onClick={onClick}>
                <div
                    className={`${styles.checkbox} ${props.value ? 'checked' : ''} ${
                        props.disabled ? 'disabled' : ''
                    } ${props.error && 'error'}`}
                >
                    {props.value && (
                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 6L4.5 9L11 1"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}
                </div>
                <div className={props.labelContainerClassName}>{props.label}</div>
            </div>
        </div>
    );
};
