
const digest = '66d73942273a9d2955911495cc45f79a1ba831ce9c8f2b953e5019ea50e62249';
const css = `/* font *//* background *//* borders *//* fonts *//* paddings *//* margins and gaps *//* borders */

._button_1m31x_6 {
    border-radius: 0.3rem;
    border: none;
    padding: 0.5rem;
}

._button_1m31x_6.primary {
    background-color: darkblue;
    color: white;
}

._button_1m31x_6.secondary {
    background-color: lightgray;
    color: black;
}

._button_1m31x_6.danger {
    background-color: darkred;
    color: white;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"colors":"\"../../styles/colors.module.css\"","distances":"\"../../styles/distances.module.css\"","colorBackgroundButtonPrimary":"darkblue","colorFontButtonPrimary":"white","colorBackgroundButtonSecondary":"lightgray","colorFontButtonSecondary":"black","colorBackgroundButtonDanger":"darkred","colorFontButtonDanger":"white","paddingMedium":"0.5rem","radiusBorderSmall":"0.3rem","button":"_button_1m31x_6"};
export { css, digest };
  