import { useEffect, useRef } from 'react';

/**
 * Custom hook to get a previous state as suggested by react team https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * @param value
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePrevious = (value: any) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};
