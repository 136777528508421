import { AppRoutes } from '../../configuration/AppRoutes';
import { CompanyLogo } from '../CompanyLogo/CompanyLogo';
import { Icon } from '../Icon/Icon';
import { SidebarItem } from '../SidebarItem/SidebarItem';
import './SideBarHead.css';

interface SideBarHeadProps {
    onClickClose: () => void;
    menuItemSelected: string;
    onChangeMenuItem: (arg: string) => void;
}

export const SideBarHead = (props: SideBarHeadProps) => {
    const onClickMenu = (value: string) => {
        props.onChangeMenuItem(value);
        props.onClickClose();
    };

    return (
        <div className="side-bar-head-container">
            <div className="side-bar-head-logo-and-close">
                <CompanyLogo />
                <Icon type={'Close'} className="side-bar-head-close" onClick={props.onClickClose} />
            </div>
            <SidebarItem
                link={AppRoutes.tasks}
                isSelected={props.menuItemSelected === 'tasks'}
                type={'primary'}
                iconFront={<Icon type={'CheckCircle'} />}
                text={'Meine Aufgaben'}
                onClick={() => onClickMenu('tasks')}
            />
        </div>
    );
};
