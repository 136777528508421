import { toast } from 'react-toastify';

/**
 * Display error toast with a custom message
 * @param message
 */
export const throwError = (message?: string) => {
    return toast.error(message ? message : 'Leider ist ein Fehler aufgetreten.', {
        position: 'top-center',
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
    });
};

/**
 * Display success toast with a custom message
 * @param message
 */
export const showSuccessMessage = (message?: string) => {
    return toast.success(message ? message : 'Die Aktion war erfolgreich.', {
        position: 'bottom-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
    });
};

/**
 * Display a warning toast with a custom message
 * @param message
 */
export const throwWarning = (message?: string) => {
    return toast.warning(message ? message : 'Leider ist ein Fehler aufgetreten.', {
        position: 'top-center',
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
    });
};

/**
 * Display a warning toast with a custom message
 * @param message
 */
export const throwInfo = (message?: string) => {
    return toast.info(message ? message : 'Leider ist ein Fehler aufgetreten.', {
        position: 'top-center',
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
    });
};
