import React, { ChangeEvent, useEffect, useState } from 'react';
import useSWR from 'swr';
import { useAppSelector } from '../../hooks';
import { InternshipCompany } from '../../models/InternshipCompanies/Type';
import { LetterDocx } from '../../models/Letters/Type';
import { LetterTemplate } from '../../models/LetterTemplates/Type';
import { throwError } from '../../models/Toasts/Toasts';
import { Button } from '../Button/Button';
import { FullScreenModal } from '../FullScreenModal/FullScreenModal';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { InternshipCompanyModal } from '../InternshipCompanyModal/InternshipCompanyModal';
import { LetterDetails } from '../LetterDetails/LetterDetails';
import { ObjectList } from '../ObjectList/ObjectList';
import { ObjectListItem } from '../ObjectListItem/ObjectListItem';
import { Download } from '../PrintQmDocumentModal/PrintQmDocumentModal';
import { StandardView } from '../StandardView/StandardView';
import { useLettersApi } from '../../api/useLettersApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface LetterModalProps {
    onClose: () => void;
    show: boolean;
}

export const LetterModal = (props: LetterModalProps) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const measureId = useAppSelector((state) => state.measure.selectedMeasure?.id);
    const participantId = useAppSelector((x) => x.participant.selectedParticipant?.id);
    const [selectedLetterTemplate, setSelectedLetterTemplate] = useState<LetterTemplate>();
    const [printableLetter, setPrintableLetter] = useState<LetterDocx>();
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [filteredLetterTemplates, setFilteredLetterTemplates] = useState<LetterTemplate[]>();
    const { data: letterTemplates } = useSWR<LetterTemplate[]>([
        backendUrl,
        'letter_templates',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: internshipCompanies } = useSWR<InternshipCompany[]>([
        backendUrl,
        'internship_companies',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const [selectedInternshipCompany, setSelectedInternshipCompany] = useState<number>();
    const [showInternshipCompanyModal, setShowInternshipCompanyModal] = useState<boolean>();
    const { apiSearchLetterForPrinting, apiCreateLetterDocx, apiCreateLetter, apiSearchLetterForPrintingWithCompany } =
        useLettersApi();

    const onShowInternshipCompanyModal = () => {
        setShowInternshipCompanyModal(!showInternshipCompanyModal);
        if (selectedInternshipCompany) {
            onPrintLetter();
        }
    };

    const onSetInternshipCompany = (companyId: number | undefined) => {
        setSelectedInternshipCompany(companyId);
    };

    /**
     * set the letter templates as the filtered ones
     */
    useEffect(() => {
        setFilteredLetterTemplates(letterTemplates);
    }, [letterTemplates]);

    /**
     * Call back to select a letter
     * @param index
     * @param letterTemplate
     */
    const onSelectLetterTemplate = (index: string | number, letterTemplate: LetterTemplate) => {
        setSelectedLetterTemplate(letterTemplate);
    };

    /**
     * call back and api call to get the corresponding docx in order to print it
     */
    const onPrintLetter = async () => {
        if (selectedLetterTemplate?.recipient !== 'company') {
            try {
                if (selectedLetterTemplate && measureId && participantId) {
                    const existingLetter = await apiSearchLetterForPrinting(
                        measureId,
                        participantId,
                        selectedLetterTemplate.id
                    );
                    if (existingLetter.results && existingLetter.results.length > 0) {
                        setPrintableLetter(await apiCreateLetterDocx(existingLetter.results[0].id));
                    } else {
                        const newLetter = await apiCreateLetter({
                            name: selectedLetterTemplate.name,
                            measureId: measureId,
                            participantId: participantId,
                            letterTemplateId: selectedLetterTemplate.id,
                            document: selectedLetterTemplate.document
                        });
                        if (newLetter) {
                            setPrintableLetter(await apiCreateLetterDocx(newLetter.id));
                        }
                    }
                }
            } catch (e) {
                throwError();
                console.log(e);
            }
        } else {
            if (selectedInternshipCompany) {
                try {
                    if (selectedLetterTemplate && measureId && participantId) {
                        const existingLetter = await apiSearchLetterForPrintingWithCompany(
                            measureId,
                            participantId,
                            selectedInternshipCompany,
                            selectedLetterTemplate.id
                        );
                        if (existingLetter.results && existingLetter.results.length > 0) {
                            setPrintableLetter(await apiCreateLetterDocx(existingLetter.results[0].id));
                        } else {
                            const newLetter = await apiCreateLetter({
                                name: selectedLetterTemplate.name,
                                measureId: measureId,
                                participantId: participantId,
                                letterTemplateId: selectedLetterTemplate.id,
                                internshipCompanyId: selectedInternshipCompany,
                                document: selectedLetterTemplate.document
                            });
                            if (newLetter) {
                                setPrintableLetter(await apiCreateLetterDocx(newLetter.id));
                            }
                        }
                    }
                } catch (e) {
                    throwError();
                    console.log(e);
                }
            }

            setSelectedInternshipCompany(undefined);
        }
    };

    /**
     * Close modal and delete everything
     */
    const onClose = () => {
        setSelectedLetterTemplate(undefined);
        setPrintableLetter(undefined);
        props.onClose();
    };

    const onClickOpenSearch = () => {
        setShowSearch(!showSearch);
    };

    const onChangeSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilteredLetterTemplates(
            letterTemplates?.filter((letterTemplate) =>
                letterTemplate.name.toLowerCase().includes(event.target.value.toLowerCase())
            )
        );
    };

    /**
     * set an letter template on modal start
     */
    useEffect(() => {
        if (!selectedLetterTemplate && letterTemplates) {
            setSelectedLetterTemplate(letterTemplates[0]);
        }
    }, [letterTemplates, selectedLetterTemplate]);

    return props.show ? (
        <FullScreenModal
            footer={
                <>
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'outline'}
                        text={'Abbrechen'}
                        onClick={onClose}
                    />
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'filled'}
                        text={'Brief drucken'}
                        onClick={
                            selectedLetterTemplate?.recipient === 'company'
                                ? onShowInternshipCompanyModal
                                : onPrintLetter
                        }
                    />
                </>
            }
            bodyClassName={'qm-document-modal'}
        >
            <StandardView>
                <StandardView.Left>
                    <ObjectList>
                        <ObjectList.Head>
                            <div>
                                <div className={'user-list-head'}>
                                    <div className="p2-medium">Briefvorlagen</div>
                                    <Button
                                        type={'secondary'}
                                        buttonStyle={'link'}
                                        size={'small'}
                                        firstIcon={<Icon type={'Search'} />}
                                        onClick={onClickOpenSearch}
                                    />
                                </div>
                                {showSearch && (
                                    <Input
                                        icon={<Icon type={'Search'} />}
                                        placeholder={'QM-Dokument suchen'}
                                        onChange={onChangeSearch}
                                    />
                                )}
                            </div>
                        </ObjectList.Head>
                        <ObjectList.Body>
                            {filteredLetterTemplates?.map((letterTemplate, index) => {
                                return (
                                    <ObjectListItem
                                        key={index}
                                        value={{ index: index, item: letterTemplate }}
                                        selected={selectedLetterTemplate === letterTemplate}
                                        onClick={onSelectLetterTemplate}
                                    >
                                        <div>{letterTemplate.name}</div>
                                    </ObjectListItem>
                                );
                            })}
                        </ObjectList.Body>
                    </ObjectList>
                </StandardView.Left>
                <StandardView.Right>
                    <>
                        <InternshipCompanyModal
                            onChange={onSetInternshipCompany}
                            internshipCompanies={internshipCompanies}
                            show={showInternshipCompanyModal}
                            onClose={onShowInternshipCompanyModal}
                        />
                        {selectedLetterTemplate && <LetterDetails letterTemplate={selectedLetterTemplate} />}
                        {printableLetter && <Download documents={[printableLetter]} />}
                    </>
                </StandardView.Right>
            </StandardView>
        </FullScreenModal>
    ) : null;
};
