import React, { ReactNode } from 'react';
import './Label.css';

interface LabelProps {
    size: 1 | 2 | 3 | 4;
    children: ReactNode;
    color?: string;
    className?: string;
    colorClassName?: string;
    onClick?: (arg: ReactNode) => void;
}
export const Label = (props: LabelProps) => {
    const onClickItem = () => {
        if (props.onClick && props.children) {
            props.onClick(props.children);
        }
    };

    return (
        <div
            onClick={onClickItem}
            className={`label-${props.size} ${props.className} ${props.colorClassName}`}
            style={{ color: props.color }}
        >
            {props.children}
        </div>
    );
};
