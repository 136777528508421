import React from 'react';
import { LetterTemplate, LetterTemplateSubmit } from '../../models/LetterTemplates/Type';
import { QmDocumentTemplate, QmDocumentTemplateFormValues } from '../../models/QmDocumentTemplate/Types';
import { Modal } from '../Modal/Modal';

interface DeleteQmDocumentTemplateModalProps {
    show: boolean;
    onClose: () => void;
    document: QmDocumentTemplate | QmDocumentTemplateFormValues | LetterTemplate | LetterTemplateSubmit | undefined;
    onClickDelete: () => void;
}

export const DeleteQmDocumentTemplateModal = (props: DeleteQmDocumentTemplateModalProps) => {
    return (
        <Modal
            show={props.show}
            header={'Dokument löschen'}
            buttons={{
                primary: {
                    text: 'Bestätigen',
                    onClick: props.onClickDelete
                },
                secondary: {
                    text: 'Abbrechen',
                    onClick: props.onClose
                }
            }}
        >
            <div>Wollen Sie das Dokument {props.document?.name} wirklich löschen?</div>
        </Modal>
    );
};
