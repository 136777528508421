
const digest = 'f81f926289b658c4622c98931d22c8d2e11353bdbd86dc1f41cada9539e27a39';
const css = `/* font *//* background *//* borders *//* fonts *//* paddings *//* margins and gaps *//* borders */

._button_1qxs1_6 {
    flex: 1;
    background-color: transparent;
    border: none;
    border-radius: 0.3rem;
    margin: 0.2rem;
    padding: 0.5rem;
}

._button_1qxs1_6.active,._button_1qxs1_6:active,._button_1qxs1_6:hover {
    background-color: #dddddd;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"colors":"\"../../../shared/styles/colors.module.css\"","colorBackgroundToolbarActive":"#dddddd","distances":"\"../../../shared/styles/distances.module.css\"","marginSmall":"0.2rem","paddingMedium":"0.5rem","radiusBorderSmall":"0.3rem","button":"_button_1qxs1_6"};
export { css, digest };
  