import './LoadingSpinner.css';
import { ReactNode } from 'react';

interface LoadingSpinnerProps {
    children?: ReactNode;
}
export const LoadingSpinner = (props: LoadingSpinnerProps) => {
    return (
        <div className="loading-spinner">
            <div className="lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
            </div>
            {props.children}
        </div>
    );
};
