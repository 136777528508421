import { Internship } from '../models/Internship/Types';
import { NeedForAction } from '../models/NeedForAction/Type';
import {
    Participant,
    ParticipantContact,
    ParticipantContactsSearch,
    ParticipantContactSubmit,
    ParticipantSubmit
} from '../models/Participant/Types';
import { SupportPlan } from '../models/SupportPlans/Type';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';
import { EzOnRailsSearchFilterRequest } from '../models/Search/Type';

/**
 * Returns methods to communicate with the backend related to Participants.
 */
export const useParticipantsApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API to create a participant
     *
     * @param participant
     */
    const apiCreateParticipant = useCallback(
        async (participant: ParticipantSubmit): Promise<Participant> => {
            return call<ParticipantSubmit, Participant>('participants', 'POST', participant);
        },
        [call]
    );

    /**
     * API to get a support plan by a participant id
     *
     * @param participantId
     * @param measureId
     */
    const apiGetSupportPlanByParticipantId = useCallback(
        async (participantId: number, measureId: number | undefined): Promise<SupportPlan[]> => {
            return call<{ measureId: number } | null, SupportPlan[]>(
                `participants/${participantId}/support_plans`,
                'GET',
                measureId ? { measureId: measureId } : null
            );
        },
        [call]
    );

    /**
     * API call to get need for actions by the participant id
     *
     * @param participantId
     */
    const apiGetNeedForActionsByParticipantId = useCallback(
        async (participantId: number): Promise<NeedForAction[]> => {
            return call<void, NeedForAction[]>(`participants/${participantId}/need_for_actions`, 'GET');
        },
        [call]
    );

    const apiGetObjectiveAgreementsByParticipantId = useCallback(
        async (participantId: number): Promise<NeedForAction[]> => {
            return call<void, NeedForAction[]>(`participants/${participantId}/objective_agreements`, 'GET');
        },
        [call]
    );

    const apiUpdateParticipant = useCallback(
        async (participantId: number, participant: Partial<ParticipantSubmit>): Promise<Participant> => {
            return call<{ participant: Partial<ParticipantSubmit> }, Participant>(
                `participants/${participantId}`,
                'PATCH',
                { participant }
            );
        },
        [call]
    );

    const apiGetInternshipByParticipantId = useCallback(
        async (participantId: number): Promise<Internship[]> => {
            return call<void, Internship[]>(`participants/${participantId}/internships`, 'GET');
        },
        [call]
    );

    const apiDeleteParticipant = useCallback(
        async (participantId: number) => {
            return call<void, unknown>(`participants/${participantId}`, 'DELETE');
        },
        [call]
    );

    const apiSearchParticipantContacts = useCallback(
        async (participantId: number): Promise<ParticipantContactsSearch> => {
            return call<EzOnRailsSearchFilterRequest, ParticipantContactsSearch>(
                'participant_contacts/search',
                'POST',
                {
                    filter: {
                        field: 'participant_id',
                        operator: 'eq',
                        value: participantId
                    }
                }
            );
        },
        [call]
    );

    /**
     * API to create a contact for a participant
     *
     * @param participantContact
     */
    const apiCreateParticipantContact = useCallback(
        async (participantContact: ParticipantContactSubmit): Promise<ParticipantContact> => {
            return call<ParticipantContactSubmit, ParticipantContact>(
                'participant_contacts',
                'POST',
                participantContact
            );
        },
        [call]
    );

    const apiDeleteParticipantContact = useCallback(
        async (participantContactId: number) => {
            return call<void, unknown>(`participant_contacts/${participantContactId}`, 'DELETE');
        },
        [call]
    );

    const apiUpdateParticipantContact = useCallback(
        async (participantContactId: number, participantContact: ParticipantContactSubmit) => {
            return call<ParticipantContactSubmit, ParticipantContact>(
                `participant_contacts/${participantContactId}`,
                'PATCH',
                participantContact
            );
        },
        [call]
    );

    return {
        apiCreateParticipant: apiCreateParticipant,
        apiGetSupportPlanByParticipantId: apiGetSupportPlanByParticipantId,
        apiGetNeedForActionsByParticipantId: apiGetNeedForActionsByParticipantId,
        apiGetObjectiveAgreementsByParticipantId: apiGetObjectiveAgreementsByParticipantId,
        apiUpdateParticipant: apiUpdateParticipant,
        apiGetInternshipByParticipantId: apiGetInternshipByParticipantId,
        apiDeleteParticipant: apiDeleteParticipant,
        apiSearchParticipantContacts: apiSearchParticipantContacts,
        apiCreateParticipantContact: apiCreateParticipantContact,
        apiDeleteParticipantContact: apiDeleteParticipantContact,
        apiUpdateParticipantContact: apiUpdateParticipantContact
    };
};
