import { Formik } from 'formik';
import { CourseSubmit } from '../../models/Course/Type';
import { UserObject } from '../../models/Employee/Types';
import { Participant } from '../../models/Participant/Types';
import { NewCourseDetailsForm } from '../NewCourseDetailsForm/NewCourseDetailsForm';

interface NewCourseDetailsProps {
    course: CourseSubmit | undefined;
    onSubmit: (arg: CourseSubmit) => void;
    allUsers: UserObject[] | undefined;
    allParticipants: Participant[] | undefined;
}

export const NewCourseDetails = (props: NewCourseDetailsProps) => {
    return (
        <>
            {props.course && (
                <Formik
                    initialValues={props.course}
                    validationSchema={null}
                    onSubmit={(values) => props.onSubmit(values)}
                    enableReinitialize
                >
                    {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
                        <NewCourseDetailsForm
                            course={props.course}
                            onSubmit={props.onSubmit}
                            errors={errors}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            setFieldValue={setFieldValue}
                            values={values}
                            allUsers={props.allUsers}
                            allParticipants={props.allParticipants}
                        />
                    )}
                </Formik>
            )}
        </>
    );
};
