import './Loading.css';

interface LoadingProps {
    wrapper: string;
    repeat?: number;
}

export const Loading = (props: LoadingProps) => {
    const showLoading = () => {
        const loading = [];

        if (!props.repeat) {
            loading.push(
                <div key={'repeat'} className={props.wrapper}>
                    <div className={'loading-container'}>
                        <div className={'loading'} />
                    </div>
                </div>
            );
        } else {
            for (let i = 0; i <= props.repeat; i++) {
                loading.push(
                    <div key={i} className={props.wrapper}>
                        <div className={'loading-container'}>
                            <div className={'loading'} />
                        </div>
                    </div>
                );
            }
        }

        return loading;
    };

    return <>{showLoading()}</>;
};
