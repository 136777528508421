import React from 'react';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { ParticipantContact, ParticipantContactSubmit } from '../../models/Participant/Types';
import { Label } from '../Label/Label';
import { List } from '../List/List';
import './NewParticipantContactsForm.css';
import { FormikErrors } from 'formik';
import { Checkbox } from '../Checkbox/Checkbox';

interface NewParticipantContactsFormProps {
    values: ParticipantContactSubmit;
    handleChange: FormikHandleChange;
    setFieldValue: FormikSetFieldValue;
    reload: () => Promise<void>;
    errors: FormikErrors<ParticipantContact>;
}

export const NewParticipantContactsForm = (props: NewParticipantContactsFormProps) => {
    /**
     * Manually change confidentiality released information
     */
    const onChangeConfidentialityReleased = async (checked: boolean) => {
        props.setFieldValue('confidentialityReleased', checked);
    };

    return (
        <div className="participant-details-container">
            <div className="new-participant-contacts-form-container">
                <div>
                    <Label className="user-details-general-title" size={2}>
                        Personalien
                    </Label>
                    <List
                        options={[
                            {
                                label: 'Nachname',
                                value: props.values.lastName,
                                onChange: props.handleChange,
                                name: 'lastName',
                                error: props.errors.lastName
                            },
                            {
                                label: 'Vorname',
                                value: props.values.firstName,
                                name: 'firstName',
                                onChange: props.handleChange,
                                error: props.errors.firstName
                            },
                            {
                                label: 'Titel',
                                value: props.values.title,
                                name: 'title',
                                onChange: props.handleChange
                            },
                            {
                                label: 'Einrichtung',
                                value: props.values.institution,
                                name: 'institution',
                                onChange: props.handleChange
                            },
                            {
                                label: 'Rolle für Teilnehmer',
                                value: props.values.role,
                                name: 'role',
                                onChange: props.handleChange
                            }
                        ]}
                    />
                </div>
                <div>
                    <Label className="user-details-general-title" size={2}>
                        Schweigepflichtsentbindung
                    </Label>
                    <List
                        options={[
                            {
                                label: 'liegt vor',
                                input: (
                                    <Checkbox
                                        value={props.values.confidentialityReleased}
                                        onChange={onChangeConfidentialityReleased}
                                    />
                                )
                            }
                        ]}
                    />
                </div>
                <div>
                    <Label className="user-details-general-title" size={2}>
                        Kontaktdaten
                    </Label>
                    <List
                        options={[
                            {
                                label: 'Straße und Hausnummer',
                                value: props.values.streetAndNumber,
                                onChange: props.handleChange,
                                name: 'streetAndNumber'
                            },
                            {
                                label: 'PLZ',
                                value: props.values.zipCode,
                                name: 'zipCode',
                                onChange: props.handleChange
                            },
                            {
                                label: 'Ort',
                                value: props.values.city,
                                name: 'city',
                                onChange: props.handleChange
                            },
                            {
                                label: 'Telefon',
                                value: props.values.phoneNumber,
                                name: 'phoneNumber',
                                onChange: props.handleChange
                            },
                            {
                                label: 'Fax',
                                value: props.values.fax,
                                name: 'fax',
                                onChange: props.handleChange
                            },
                            {
                                label: 'Mobil',
                                value: props.values.mobile,
                                name: 'mobile',
                                onChange: props.handleChange
                            },
                            {
                                label: 'E-Mail',
                                value: props.values.email,
                                name: 'email',
                                onChange: props.handleChange
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};
