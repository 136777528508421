import { FormikErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { formatDate } from '../../models/Date/Date';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { letterRecipients, mapLetterRecipients } from '../../models/LetterTemplates/LetterTemplate';
import { LetterRecipient, LetterTemplate } from '../../models/LetterTemplates/Type';
import { ActiveStorageDropzone } from '../ActiveStorageDropzone/ActiveStorageDropzone';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
import { DropDown } from '../DropDown/DropDown';
import { FormTextArea } from '../FormTextArea/FormTextArea';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';
import { List } from '../List/List';
import './LetterTemplateDetailsForm.css';

interface LetterTemplateDetailsFormProps {
    handleSubmit: () => void;
    values: LetterTemplate;
    errors: FormikErrors<LetterTemplate>;
    setFieldValue: FormikSetFieldValue;
    handleChange: FormikHandleChange;
    letterTemplate: LetterTemplate;
    onShowDeleteModal: () => void;
    resetForm: () => void;
}

export const LetterTemplateDetailsForm = (props: LetterTemplateDetailsFormProps) => {
    const [editLetterTemplate, setEditLetterTemplate] = useState<boolean>();

    /**
     * Show the edit button
     */
    const onChangeEditLetterTemplate = () => {
        setEditLetterTemplate(!editLetterTemplate);
    };

    const onSaveLetterTemplate = () => {
        onChangeEditLetterTemplate();
        props.handleSubmit();
    };

    const onChangeLetterRecipient = (value: LetterRecipient) => {
        props.setFieldValue('recipient', value);
    };

    const onCancel = () => {
        onChangeEditLetterTemplate();
        props.resetForm();
    };

    useEffect(() => {
        setEditLetterTemplate(false);
    }, [props.letterTemplate]);

    return (
        <>
            <div className="company-details-head">
                <h5>{props.values.name}</h5>
                {editLetterTemplate ? (
                    <div className={'letter-template-details-form-head'}>
                        <Button
                            type={'primary'}
                            size={'medium'}
                            buttonStyle={'outline'}
                            firstIcon={<Icon type="DocumentText" />}
                            text={'Briefvorlage speichern'}
                            onClick={onSaveLetterTemplate}
                        />
                        <Button
                            type={'secondary'}
                            size={'medium'}
                            buttonStyle={'outline'}
                            text={'Abbrechen'}
                            onClick={onCancel}
                        />
                        <Button
                            type={'primary'}
                            size={'small'}
                            buttonStyle={'outline'}
                            firstIcon={<Icon type={'Delete'} />}
                            onClick={props.onShowDeleteModal}
                        />
                    </div>
                ) : (
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'outline'}
                        firstIcon={<Icon type="DocumentText" />}
                        text={'Briefvorlage anpassen'}
                        onClick={onChangeEditLetterTemplate}
                    />
                )}
            </div>
            <Card>
                <div className="user-details-box-details">
                    <Label className="user-details-general-title" size={2}>
                        ALLGEMEINE INFORMATIONEN
                    </Label>
                    <div className="user-details-general-information">
                        <div className="user-details-general-information-container-text">
                            <List
                                readonly={!editLetterTemplate}
                                options={[
                                    {
                                        label: 'Name',
                                        value: props.values.name,
                                        onChange: props.handleChange,
                                        name: 'name'
                                    },
                                    {
                                        label: 'Empfänger',
                                        value: props.values.recipient,
                                        onChange: props.handleChange,
                                        name: 'recipient',
                                        input: (
                                            <DropDown
                                                title={<div>{mapLetterRecipients(props.values.recipient)}</div>}
                                                disabled={!editLetterTemplate}
                                            >
                                                {letterRecipients.map((recipient, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            onClick={() => onChangeLetterRecipient(recipient)}
                                                        >
                                                            {mapLetterRecipients(recipient)}
                                                        </div>
                                                    );
                                                })}
                                            </DropDown>
                                        )
                                    },
                                    {
                                        label: 'Zuletzt aktualisiert am',
                                        value: formatDate(new Date(props.letterTemplate.updatedAt)),
                                        valueClassName: 'list-item',
                                        readOnly: true
                                    },
                                    {
                                        label: 'Aktualisiert von',
                                        valueClassName: 'list-item',
                                        value: `${props.letterTemplate?.updatedBy.firstName} ${props.letterTemplate?.updatedBy.lastName}`,
                                        readOnly: true
                                    }
                                ]}
                            />
                            <FormTextArea
                                value={props.values.notes}
                                readonly={!editLetterTemplate}
                                placeholder={'Notizen'}
                                size={'medium'}
                                onChange={props.handleChange}
                                name={'notes'}
                            />
                            {editLetterTemplate && (
                                <ActiveStorageDropzone
                                    className={'qm-document-template-form-upload'}
                                    onChange={(files) =>
                                        props.setFieldValue('document', files.length > 0 ? files[0].signedId : '')
                                    }
                                    attributeName="document"
                                    textDropzone={
                                        editLetterTemplate
                                            ? 'Ändern Sie hier Ihre Datei'
                                            : 'Laden Sie hier eine neue Datei hoch'
                                    }
                                    files={props.values.document ? [{ signedId: props.values.document }] : []}
                                    multiple={false}
                                    maxFiles={1}
                                    onMaxFilesError={() => alert('Es ist nur eine Datei erlaubt')}
                                    maxSize={10485760}
                                    onMaxSizeError={() => alert('Die Datei ist zu groß. Sie darf max. 10MB groß sein.')}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
};
