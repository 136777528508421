import { FormikTouched } from 'formik';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Form } from '../Form/Form';
import { ValueCheckbox } from '../ValueCheckbox/ValueCheckbox';
import { Label } from '../Label/Label';
import './CreateProcessInputField.css';

interface CreateProcessInputFieldProps {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    value?: string | number;
    name?: string;
    error?: string;
    touched?: boolean | FormikTouched<unknown> | FormikTouched<unknown>[] | undefined;
    handleBlur?: (e: React.FocusEvent<unknown>) => void;
    title?: string;
    label?: string;
    options?: {
        label: string;
        value: string;
    }[];
    setFieldValue?: (arg: string, arg1: unknown) => void;
    autoFocus?: boolean;
    tabindex?: number;
    type?: string;
    className?: string;
}

export const CreateProcessInputField = (props: CreateProcessInputFieldProps) => {
    const [checkBoxChecked, setCheckBoxChecked] = useState<string>('');
    const [firstLetterOptions, setFirstLetterOptions] = useState<string[] | undefined>();

    const { setFieldValue, name } = props;

    /**
     * Get the keys that should be allowed for selection
     */
    useEffect(() => {
        const firstLetterOptions = props.options?.map((option) => {
            return option.label[0].toLowerCase();
        });
        setFirstLetterOptions(firstLetterOptions);
    }, [props.options]);

    /**
     * Manual change for Formiks setFieldValue
     * @param value
     */
    const onChangeManually = useCallback(
        (value: string) => {
            setCheckBoxChecked(value);
            if (setFieldValue && name) {
                setFieldValue(name, value);
            }
        },
        [name, setFieldValue]
    );

    /**
     * Select options with keypress
     */
    const checkOptionByKeypress = useCallback(
        (event: KeyboardEvent) => {
            if (firstLetterOptions?.includes(event.key) && props.options) {
                event.preventDefault();
                const index = props.options?.findIndex(
                    (option) => option.label[0].toLowerCase() === event.key.toLowerCase()
                );
                if (index !== -1) {
                    setCheckBoxChecked(props.options[index].value);
                    onChangeManually(props.options[index].value);
                }
            }
        },
        [firstLetterOptions, onChangeManually, props.options]
    );

    /**
     * Switch between checkbox options by using tab
     */
    const switchOptions = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Tab' && props.options) {
                event.preventDefault();
                const index = props.options.findIndex((option) => option.value === checkBoxChecked);
                if (index !== -1 && index < props.options.length - 1) {
                    setCheckBoxChecked(props.options[index + 1].value);
                    onChangeManually(props.options[index + 1].value);
                } else {
                    setCheckBoxChecked(props.options[0].value);
                    onChangeManually(props.options[0].value);
                }
            }
        },
        [checkBoxChecked, onChangeManually, props.options]
    );

    /**
     * Event listener so that users can navigate by using tab if checkboxes are used
     */
    useEffect(() => {
        if (props.options) {
            document.addEventListener('keydown', switchOptions);
        }

        return () => document.removeEventListener('keydown', switchOptions);
    }, [props.options, switchOptions]);

    useEffect(() => {
        if (props.options) {
            document.addEventListener('keydown', checkOptionByKeypress);
        }

        return () => document.removeEventListener('keydown', checkOptionByKeypress);
    }, [checkOptionByKeypress, props.options]);

    /**
     * Determine if a selection is already part of formik
     * @param input
     */
    const isInValue = (input: string) => {
        if (checkBoxChecked === input) {
            return true;
        } else {
            return props.value === input;
        }
    };

    return (
        <>
            {props.title && <h5 className="create-measure-input-title">{props.title}</h5>}
            {!props.options && (
                <Form.Input
                    className={props.className}
                    type={props.type}
                    tabindex={props.tabindex}
                    autoFocus={props.autoFocus}
                    size={'medium'}
                    onBlur={props.handleBlur}
                    value={props.value}
                    onChange={props.onChange}
                    error={props.touched ? props.error : ''}
                    name={props.name}
                >
                    <Label size={4}>{props.label}</Label>
                </Form.Input>
            )}
            {props.options && (
                <div>
                    <div className="create-process-input-field-checkbox">
                        {props.options.map((option, index) => {
                            return (
                                <ValueCheckbox
                                    key={index}
                                    className={'create-process-input-field-single-checkbox'}
                                    checked={isInValue(option.value)}
                                    onChange={onChangeManually}
                                    value={option.value}
                                    label={
                                        <div className="create-process-input-field-underscore-first-letter">
                                            {option.label}
                                        </div>
                                    }
                                />
                            );
                        })}
                    </div>
                    <div className="input-helper-error-container">
                        <div className="error">{props.touched && props.error ? props.error : ''}</div>
                    </div>
                </div>
            )}
        </>
    );
};
