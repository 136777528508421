import { InternshipCompany, InternshipCompanySubmit } from '../models/InternshipCompanies/Type';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Returns methods to communicate with the backend related to InternshipCompanies.
 */
export const useInternshipCompaniesApi = () => {
    const { call } = useEzApiHttpClient();

    const apiGetAllInternshipCompanies = useCallback(async (): Promise<InternshipCompany[]> => {
        return call<void, InternshipCompany[]>('internship_companies', 'GET');
    }, [call]);

    const apiCreateInternshipCompany = useCallback(
        async (internshipCompany: InternshipCompanySubmit): Promise<InternshipCompany> => {
            return call<InternshipCompanySubmit, InternshipCompany>('internship_companies', 'POST', internshipCompany);
        },
        [call]
    );

    const apiUpdateInternshipCompany = useCallback(
        async (
            internshipCompanyId: number,
            internshipCompany: Partial<InternshipCompanySubmit>
        ): Promise<InternshipCompany> => {
            return call<Partial<InternshipCompanySubmit>, InternshipCompany>(
                `internship_companies/${internshipCompanyId}`,
                'PATCH',
                internshipCompany
            );
        },
        [call]
    );

    return {
        apiGetAllInternshipCompanies: apiGetAllInternshipCompanies,
        apiCreateInternshipCompany: apiCreateInternshipCompany,
        apiUpdateInternshipCompany: apiUpdateInternshipCompany
    };
};
