import { ParticipantContact, ParticipantContactSubmit } from '../../models/Participant/Types';
import { Card } from '../Card/Card';
import { Button } from '../Button/Button';
import './ParticipantContactsCard.css';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Formik } from 'formik';
import { ParticipantContactsForm } from '../ParticipantContactsForm/ParticipantContactsForm';
import React, { useState } from 'react';
import { NewParticipantContactsForm } from '../NewParticipantContactsForm/NewParticipantContactsForm';
import * as Yup from 'yup';
import { Modal } from '../Modal/Modal';
import { useParticipantsApi } from '../../api/useParticipantsApi';

interface ParticipantContactsCardProps {
    participantContact: ParticipantContact | undefined;
    reload: () => Promise<void>;
    newContact?: ParticipantContactSubmit | undefined;
    onResetNewContact: (value: ParticipantContact) => void;
}

export const ParticipantContactsCard = (props: ParticipantContactsCardProps) => {
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const { apiDeleteParticipantContact, apiCreateParticipantContact } = useParticipantsApi();

    const onShowDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal);
    };

    const onDeleteParticipantContact = async () => {
        if (props.participantContact) {
            try {
                await apiDeleteParticipantContact(props.participantContact.id);
                props.reload();
                onShowDeleteModal();
                showSuccessMessage();
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    /**
     * Create new contact on button press
     */
    const onSaveNewContact = async (values: ParticipantContactSubmit) => {
        try {
            const newCreatedContact = await apiCreateParticipantContact(values);
            await props.reload();
            props.onResetNewContact(newCreatedContact);
            showSuccessMessage('Neuer Ansprechpartner erstellt');
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Validation scheme to make sure there is at least first and lastname
     */
    const ParticipantContactValidationScheme = Yup.object()
        .shape({
            firstName: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            lastName: Yup.string().required('Dieses Feld muss ausgefüllt sein.')
        })
        .defined();

    return (
        <div>
            {props.participantContact && (
                <Formik
                    initialValues={props.participantContact}
                    validateOnChange={true}
                    validationSchema={ParticipantContactValidationScheme}
                    validateOnBlur={true}
                    enableReinitialize
                    onSubmit={() => {
                        console.log();
                    }}
                >
                    {({ values, handleChange, setFieldValue, errors }) => (
                        <>
                            <div className="participant-contacts-card">
                                <h5>
                                    {values.firstName} {values.lastName}
                                </h5>
                                <Button
                                    type={'primary'}
                                    size={'small'}
                                    buttonStyle={'outline'}
                                    text={'Ansprechpartner löschen'}
                                    onClick={onShowDeleteModal}
                                />
                            </div>
                            <Card>
                                {props.participantContact && (
                                    <ParticipantContactsForm
                                        errors={errors}
                                        reload={props.reload}
                                        participantContact={props.participantContact}
                                        values={values}
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                                {showDeleteModal && (
                                    <Modal
                                        show={showDeleteModal}
                                        header={'Ansprechpartner löschen'}
                                        buttons={{
                                            primary: {
                                                text: 'Ja, Ansprechpartner löschen',
                                                onClick: onDeleteParticipantContact
                                            },
                                            secondary: { text: 'Abbrechen', onClick: onShowDeleteModal },
                                            swapped: true
                                        }}
                                    >
                                        <div>Möchten Sie den Ansprechpartner wirklich löschen?</div>
                                    </Modal>
                                )}
                            </Card>
                        </>
                    )}
                </Formik>
            )}
            {props.newContact && (
                <Formik
                    initialValues={props.newContact}
                    validationSchema={ParticipantContactValidationScheme}
                    validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize
                    onSubmit={(values) => {
                        onSaveNewContact(values);
                    }}
                >
                    {({ values, handleChange, setFieldValue, handleSubmit, errors }) => (
                        <>
                            <div className="participant-contacts-card">
                                <h5>
                                    {values.firstName} {values.lastName}
                                </h5>
                                {props.newContact ? (
                                    <Button
                                        type={'primary'}
                                        size={'small'}
                                        buttonStyle={'outline'}
                                        text={'Ansprechpartner anlegen'}
                                        onClick={handleSubmit}
                                    />
                                ) : (
                                    <Button
                                        type={'primary'}
                                        size={'small'}
                                        buttonStyle={'outline'}
                                        text={'Ansprechpartner löschen'}
                                        onClick={onDeleteParticipantContact}
                                    />
                                )}
                            </div>
                            <Card>
                                {props.newContact && (
                                    <NewParticipantContactsForm
                                        errors={errors}
                                        reload={props.reload}
                                        values={values}
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </Card>
                        </>
                    )}
                </Formik>
            )}
        </div>
    );
};
