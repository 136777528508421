/**
 * Parse backend enums to german words
 * @param priority
 */
import { NeedForActionEstimate, NeedForActionTendency } from './Type';

export const mapNeedForActionPriority = (priority: string | undefined) => {
    switch (priority) {
        case 'information':
            return 'Information';
        case 'high':
            return 'Hohe Priorität';
        case 'medium':
            return 'Mittlere Priorität';
        case 'low':
            return 'Niedrige Priorität';
    }
};

/**
 * Map backend enums
 * @param estimate
 */
export const mapNeedForActionEstimate = (estimate: NeedForActionEstimate | undefined) => {
    switch (estimate) {
        case 'much_support_needed':
            return 'benötigt viel Unterstützung';
        case 'no_support_needed':
            return 'benötigt keine Unterstützung';
        case 'sparsely_support_needed':
            return 'benötigt wenig Unterstützung';
        case 'support_needed':
            return 'benötigt Unterstützung';
    }
};

/**
 * Map backend enums to readable format
 * @param tendency
 */
export const mapNeedForActionTendency = (tendency: NeedForActionTendency) => {
    switch (tendency) {
        case 'inferior':
            return 'Handlungsbedarf hat sich verringert';
        case 'superior':
            return 'Handlungsbedarf hat sich erhöht';
        default:
            return 'keine Veränderung';
    }
};

export const estimates: NeedForActionEstimate[] = [
    'no_support_needed',
    'sparsely_support_needed',
    'support_needed',
    'much_support_needed'
];
export const needForActionTendency: NeedForActionTendency[] = ['superior', 'inferior'];
