import React, { createRef, useCallback, useEffect, useState } from 'react';
import { ActivityIndicator } from '../ActivityIndicator/ActivityIndicator';
import { Button, ButtonProps } from '../Button/Button';
import './DownloadButton.css';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

/**
 * Props for the download button component.
 */
interface DownloadButtonProps extends Omit<ButtonProps, 'onClick'> {
    // The relative target path (from the api url) to the target get request to download the file
    targetPath: string;

    // The filename of the resulting downloaded file
    filename: string;
}

/**
 * A Button component showing the default Button, but providing a download defined by the
 * targetPath prop. This component catches the onclick of the Button, requests a download
 * from the server and provides the download directly.
 * All the props the Button has are wrapped into this one.
 * @param props
 * @constructor
 */
export const DownloadButton = (props: DownloadButtonProps) => {
    const downloadLinkHack = createRef<HTMLAnchorElement>();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();

    /**
     * Copied from EzOnRails-React to create the Url to the api.
     * This should be removed if the current targeting EzOnRails-React version includes this.
     *
     * @param path
     */
    const toApiUrl = useCallback(
        (path: string): string => {
            if (path.startsWith('/')) {
                path = path.substr(1);
            }

            return `${backendUrl}/api/${path}`;
        },
        [backendUrl]
    );

    /**
     * Copied from EzOnRails-React to create a default http header having the auth info provided.
     * This should be removed if the current targeting EzOnRails-React version includes this.
     */
    const httpHeader = useCallback(() => {
        const header: HeadersInit = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'api-version': apiVersion || ''
        };

        if (!authInfo) {
            return header;
        }

        header['uid'] = authInfo.uid;
        header['client'] = authInfo.client;
        header['expiry'] = authInfo.expiry;
        header['token-type'] = authInfo.tokenType;
        header['access-token'] = authInfo.accessToken;

        return header;
    }, [authInfo, apiVersion]);

    /**
     * Called if the button was clicked. Sets the isProcessing state to trigger
     * the download in the following useEffect hook.
     */
    const onClick = useCallback(async () => {
        // show activity indicator
        setIsProcessing(true);
    }, []);

    /**
     * Starts the download defined by the targetPath in the props, by first fetching the
     * blob from the backend and attaching the result to the invisible ancor tag and automaticly
     * clicking those tag.
     * This is needed, because it is very important to understand that in the 21. century...
     * HUEHUEHUEHUEHUEHUEHUEHUEHUEHUEHUEHUEHUE...
     */
    const downloadHack = useCallback(async () => {
        if (!downloadLinkHack || !downloadLinkHack.current) {
            return;
        }

        const response = await fetch(toApiUrl(props.targetPath), {
            headers: httpHeader()
        });

        const blob = await response.blob();
        const blobHref = window.URL.createObjectURL(blob);

        downloadLinkHack.current.download = props.filename;
        downloadLinkHack.current.href = blobHref;
        downloadLinkHack.current.click();
    }, [downloadLinkHack, httpHeader, props.filename, props.targetPath, toApiUrl]);

    /**
     * Called if isProcessing changed.
     * If isProcessing is set to true, the download process starts and after it finishes the
     * isProcessing state is resetted to false.
     * This process is needed in the useEffect hook because if we only would handle all this steps in a callback,
     * the ref to the anchor tag would be changed. This would result in a crash if the asynchronous download
     * finished, because the callback tries to attach the download to the anchor tag that does not exist anymore.
     */
    useEffect(() => {
        if (!isProcessing) {
            return;
        }

        (async () => {
            // download using the hack
            await downloadHack();

            // hide activity indicator
            setIsProcessing(false);
        })();
    }, [downloadHack, isProcessing]);

    return (
        <div>
            {isProcessing ? <ActivityIndicator /> : <Button {...props} onClick={onClick} />}
            <a href={'/#'} className="download-button-link" ref={downloadLinkHack}>
                Download link
            </a>
        </div>
    );
};
