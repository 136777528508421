import { Course, CourseSubmit } from '../../models/Course/Type';
import { UserObject } from '../../models/Employee/Types';
import { Participant } from '../../models/Participant/Types';
import { AddToCourse } from '../AddToCourse/AddToCourse';
import { Avatar } from '../Avatar/Avatar';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';
import { ReactElement } from 'react';

interface CourseDetailsUsersProps {
    course: Course | CourseSubmit | undefined;
    label: string;
    allUsers?: UserObject[];
    allParticipants?: Participant[];
    assignedUsers?: UserObject[];
    assignedParticipants?: Participant[];
    onRemoveUser?: (id: number | undefined) => void;
    onRemoveParticipant?: (id: number | undefined) => void;
    onAddUserToCourse: (id: number | undefined) => void;
    showOnlyNames?: boolean;
    customButton?: (onShowCourse: () => void) => ReactElement;
}

export const CourseDetailsUsers = (props: CourseDetailsUsersProps) => {
    const onRemoveParticipant = (id: number | undefined) => {
        if (props.onRemoveParticipant) {
            props.onRemoveParticipant(id);
        }
    };

    const onRemoveUser = (id: number | undefined) => {
        if (props.onRemoveUser) {
            props.onRemoveUser(id);
        }
    };

    return (
        <div className="measure-user">
            <Label size={2} color={'#A1A1AA'}>
                {props.label.toUpperCase()}
            </Label>
            <div
                className={`${props.showOnlyNames ? 'measure-user-show-users-only-names' : 'measure-user-show-users'}`}
            >
                {props.assignedUsers?.map((user) => {
                    return (
                        <div key={user.id} className="measure-user-avatar">
                            {props.showOnlyNames ? (
                                <div>
                                    <div>
                                        {user.firstName} {user.lastName}
                                    </div>
                                    <Icon
                                        type={'CloseCircle'}
                                        className="measure-user-avatar-remove measure-user-name-remove"
                                        onClick={() => onRemoveUser(user.id)}
                                    />
                                </div>
                            ) : (
                                <>
                                    <Avatar size={'small'} url={user.avatarUrl} />
                                    <Icon
                                        type={'CloseCircle'}
                                        className="measure-user-avatar-remove"
                                        onClick={() => onRemoveUser(user.id)}
                                    />
                                    <div className="measure-user-avatar-hover-name">
                                        {user.firstName} {user.lastName}
                                    </div>
                                </>
                            )}
                        </div>
                    );
                })}
                {props.assignedParticipants?.map((user) => {
                    return (
                        <div key={user.id} className="measure-user-avatar">
                            {props.showOnlyNames ? (
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        {user.firstName} {user.lastName}
                                    </div>
                                    <Icon
                                        type={'CloseCircle'}
                                        className="measure-user-avatar-remove measure-user-name-remove"
                                        onClick={() => onRemoveParticipant(user.id)}
                                    />
                                </div>
                            ) : (
                                <>
                                    {' '}
                                    <Avatar size={'small'} url={user.avatarUrl} />
                                    <Icon
                                        type={'CloseCircle'}
                                        className="measure-user-avatar-remove"
                                        onClick={() => onRemoveParticipant(user.id)}
                                    />
                                    <div className="measure-user-avatar-hover-name">
                                        {user.firstName} {user.lastName}
                                    </div>
                                </>
                            )}
                        </div>
                    );
                })}
                {props.allUsers && props.allUsers.length === 0 && <div>Es existieren keine aktiven Benutzer</div>}
                {props.allParticipants && props.allParticipants.length === 0 && (
                    <div>Zu dieser Maßnahme existieren keine aktiven Teilnehmer</div>
                )}
                {props.allUsers && props.assignedUsers && (
                    <AddToCourse
                        allUsers={props.allUsers}
                        usersInCourse={props.assignedUsers}
                        course={props.course}
                        addTo={props.onAddUserToCourse}
                        customButton={props.customButton}
                    />
                )}
                {props.allParticipants && props.assignedParticipants && (
                    <AddToCourse
                        allUsers={props.allParticipants}
                        usersInCourse={props.assignedParticipants}
                        course={props.course}
                        addTo={props.onAddUserToCourse}
                        customButton={props.customButton}
                    />
                )}
            </div>
        </div>
    );
};
