import styles from './ParticipantReactivationModal.module.css';
import { Modal } from '../Modal/Modal';
import { useState } from 'react';
import { Participant } from '../../models/Participant/Types';
import { Datepicker } from '../Datepicker/Datepicker';

interface ParticipantReactivationModalProps {
    participant: Participant;
    show: boolean;
    onClose: () => void;
    onReactivate: (participant: Participant, plannedLeaveDate: Date) => void;
}

export const ParticipantReactivationModal = (props: ParticipantReactivationModalProps) => {
    const [plannedLeaveDate, setPlannedLeaveDate] = useState<Date>(props.participant.plannedLeaveDate);

    /**
     * Called if the user clicks the button to reactivate the participant.
     * Calls the callback given by the props using the correct params.
     */
    const onClickReactivate = () => {
        props.onReactivate(props.participant, plannedLeaveDate);
    };

    return (
        <Modal
            show={props.show}
            header={'Teilnehmer aktivieren'}
            buttons={{
                primary: { text: 'Teilnehmer aktivieren', onClick: onClickReactivate },
                secondary: { text: 'Abbrechen', onClick: props.onClose },
                swapped: true
            }}
            size={'large'}
        >
            <div className={styles.container}>
                <div>Wollen Sie diesen Teilnehmer wirklich wieder aktivieren?</div>

                <Datepicker
                    onChange={setPlannedLeaveDate}
                    label={'geplanter Austritt'}
                    value={plannedLeaveDate}
                    position={'absolute-left'}
                />
            </div>
        </Modal>
    );
};
