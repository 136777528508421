import { ReactElement, ReactNode } from 'react';
import { ObjectListBody } from '../ObjectListBody/ObjectListBody';
import { ObjectListHead } from '../ObjectListHead/ObjectListHead';
import { ObjectListItem } from '../ObjectListItem/ObjectListItem';
import './ObjectList.css';

interface ObjectListProps {
    children: ReactElement | ReactElement[] | ReactNode | ReactNode[];
    className?: string;
}

export const ObjectList = (props: ObjectListProps) => {
    return <div className={`object-list ${props.className}`}>{props.children}</div>;
};

ObjectList.Head = ObjectListHead;
ObjectList.Body = ObjectListBody;
ObjectList.Item = ObjectListItem;
