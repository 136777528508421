import { FieldArray, Formik, FormikErrors, FormikState, FormikTouched, FormikValues } from 'formik';
import { ChangeEvent, FocusEvent, useCallback, useState } from 'react';
import useSWR from 'swr';
import * as Yup from 'yup';
import { Button } from '../../components/Button/Button';
import { CreateProcessInputField } from '../../components/CreateProcessInputField/CreateProcessInputField';
import { CreateProcessKeyboardEnter } from '../../components/CreateProcessKeyboardEnter/CreateProcessKeyboardEnter';
import { Form } from '../../components/Form/Form';
import { FullscreenSidebar } from '../../components/FullscreenSidebar/FullscreenSidebar';
import { FullscreenSidebarItem } from '../../components/FullscreenSidebarItem/FullscreenSidebarItem';
import { Icon } from '../../components/Icon/Icon';
import { MeasureSubmit } from '../../models/Measure/Types';
import './CreateMeasure.css';
import { CreateMeasureEmployee } from './CreateMeasureEmployee/CreateMeasureEmployee';
import { CreateMeasureEnd } from './CreateMeasureEnd/CreateMeasureEnd';
import { CreateMeasureQMDocuments } from './CreateMeasureQMDocuments/CreateMeasureQMDocuments';
import { CreateMeasureStart } from './CreateMeasureStart/CreateMeasureStart';
import { CreateMeasureState } from './CreateMeasureState/CreateMeasureState';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface CreateMeasureProps {
    onSubmit: (values: MeasureSubmit) => void;
    onClose: () => void;
    className?: string;
    submitError: string;
}

type FormNavigationItemValueName =
    | 'name'
    | 'number'
    | 'startAt'
    | 'endAt'
    | 'streetAndNumber'
    | 'zipCode'
    | 'state'
    | 'phone'
    | 'fax'
    | 'email'
    | 'lotSize'
    | 'rehabSlots'
    | 'legalSphere'
    | 'contact'
    | 'user'
    | 'qmDocuments';

interface FormNavigationItem {
    name: string;
    selected: boolean;
    finished: boolean;
    formValue: FormNavigationItemValueName;
}

export const CreateMeasure = (props: CreateMeasureProps) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();

    const { data: allUsers } = useSWR([backendUrl, 'users', 'GET', null, authInfo, apiVersion]);

    // structure of the form menu
    const formNavigationStructure: FormNavigationItem[] = [
        {
            name: 'Name',
            selected: true,
            finished: false,
            formValue: 'name'
        },
        {
            name: 'Nr',
            selected: false,
            finished: false,
            formValue: 'number'
        },
        {
            name: 'Bewilligungsanfang',
            selected: false,
            finished: false,
            formValue: 'startAt'
        },
        {
            name: 'Bewilligungsende',
            selected: false,
            finished: false,
            formValue: 'endAt'
        },
        {
            name: 'Straße und Hausnummer',
            selected: false,
            finished: false,
            formValue: 'streetAndNumber'
        },
        {
            name: 'PLZ & Ort',
            selected: false,
            finished: false,
            formValue: 'zipCode'
        },
        {
            name: 'Bundesland',
            selected: false,
            finished: false,
            formValue: 'state'
        },
        {
            name: 'Telefon',
            selected: false,
            finished: false,
            formValue: 'phone'
        },
        {
            name: 'Fax',
            selected: false,
            finished: false,
            formValue: 'fax'
        },
        {
            name: 'E-Mail',
            selected: false,
            finished: false,
            formValue: 'email'
        },
        {
            name: 'Losgröße',
            selected: false,
            finished: false,
            formValue: 'lotSize'
        },
        {
            name: 'Rehaplätze',
            selected: false,
            finished: false,
            formValue: 'rehabSlots'
        },
        {
            name: 'Rechtskreis',
            selected: false,
            finished: false,
            formValue: 'legalSphere'
        },
        {
            name: 'Ansprechpartner',
            selected: false,
            finished: false,
            formValue: 'contact'
        },
        {
            name: 'Mitarbeiter',
            selected: false,
            finished: false,
            formValue: 'user'
        },
        {
            name: 'QM-Dokumente',
            selected: false,
            finished: false,
            formValue: 'qmDocuments'
        }
    ];

    // state that holds information about what form part should be shown to the user
    const [currentlyActiveForm, setCurrentlyActiveForm] = useState<{ name: string; index: number }>({
        name: formNavigationStructure[0].name,
        index: 1
    });
    // state that holds information about structure and states of the form menu
    const [formMenu, setFormMenu] = useState<FormNavigationItem[]>(formNavigationStructure);
    const initialFormValues: MeasureSubmit = {
        lotSize: '',
        email: '',
        client: '',
        rehabSlots: '',
        fax: '',
        phone: '',
        state: '',
        zipCode: '',
        streetAndNumber: '',
        city: '',
        endAt: new Date(),
        number: '',
        startAt: new Date(),
        name: '',
        qmDocuments: [],
        user: [],
        legalSphere: '',
        contact: '',
        archived: false
    };
    const phoneRegx = /[0-9]/;
    const CreateMeasureValidationScheme = Yup.object()
        .shape({
            name: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            lotSize: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            email: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .email('Bitten geben Sie eine gültige E-Mail Adresse ein'),
            /*client: Yup.string().required('Bitte geben Sie den Auftraggeber an.'),*/
            rehabSlots: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            fax: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .matches(phoneRegx, 'Dies scheint keine gültige Faxnummmer zu sein'),
            phone: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .matches(phoneRegx, 'Dies scheint keine gültige Telefonnummer zu sein'),
            state: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            zipCode: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            endAt: Yup.date().required('Dieses Feld muss ausgefüllt sein.'),
            number: Yup.string()
                .required('Dieses Feld muss ausgefüllt sein.')
                .matches(phoneRegx, 'Dies scheint keine gültige Nummer zu sein'),
            startAt: Yup.date().required('Dieses Feld muss ausgefüllt sein.'),
            qmDocuments: Yup.array().required('Dieses Feld muss ausgefüllt sein.'),
            user: Yup.array().required('Dieses Feld muss ausgefüllt sein.'),
            legalSphere: Yup.string().required('Bitte geben Sie den Rechtskreis an.'),
            contact: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            streetAndNumber: Yup.string().required('Dieses Feld muss ausgefüllt sein.'),
            city: Yup.string().required('Dieses Feld muss ausgefüllt sein.')
        })
        .defined();

    /**
     * Function to render the form for the user
     * @param values
     * @param handleChange
     * @param setFieldValue
     * @param handleBlur
     * @param errors
     * @param touched
     */
    const renderFormContent = (
        values: FormikValues,
        handleChange: (e: ChangeEvent<unknown>) => void,
        setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void,
        handleBlur: (e: FocusEvent<unknown>) => void,
        errors: FormikErrors<MeasureSubmit>,
        touched: FormikTouched<MeasureSubmit>
    ) => {
        switch (currentlyActiveForm.name) {
            case 'Name':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Wie ist die Bezeichnung der Maßnahme?'}
                        label={'Name der Maßnahme'}
                        touched={touched.name}
                        name={'name'}
                        value={values.name}
                        error={errors.name}
                    />
                );
            case 'Nr':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Wie ist die Nummer der Maßnahme?'}
                        label={'Nummer'}
                        touched={touched.number}
                        name={'number'}
                        value={values.number}
                        error={errors.number}
                    />
                );
            case 'Bewilligungsanfang':
                return <CreateMeasureStart setFieldValue={setFieldValue} values={values} />;
            case 'Bewilligungsende':
                return <CreateMeasureEnd setFieldValue={setFieldValue} values={values} />;
            case 'Straße und Hausnummer':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Wo findet die Maßnahme statt?'}
                        label={'Straße und Hausnummer'}
                        touched={touched.streetAndNumber}
                        name={'streetAndNumber'}
                        value={values.streetAndNumber}
                        error={errors.streetAndNumber}
                    />
                );
            case 'PLZ & Ort':
                return (
                    <div className="create-measure-street-zip">
                        <CreateProcessInputField
                            autoFocus
                            key={'zipCode'}
                            tabindex={1}
                            onChange={handleChange}
                            title={'Wo findet die Maßnahme statt?'}
                            label={'Postleitzahl'}
                            type={'number'}
                            touched={touched.zipCode}
                            name={'zipCode'}
                            value={values.zipCode}
                            error={errors.zipCode}
                        />
                        <CreateProcessInputField
                            tabindex={2}
                            onChange={handleChange}
                            label={'Ort'}
                            touched={touched.city}
                            name={'city'}
                            key={'city'}
                            value={values.city}
                            error={errors.city}
                        />
                    </div>
                );
            case 'Bundesland':
                return (
                    <CreateMeasureState
                        setFieldValue={setFieldValue}
                        name={'state'}
                        values={values.state}
                        error={errors.state}
                        touched={touched.state}
                    />
                );
            case 'Telefon':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Bitte geben Sie eine Telefonnummer zu der Maßnahme an'}
                        label={'Telefonnummer'}
                        touched={touched.phone}
                        name={'phone'}
                        value={values.phone}
                        error={errors.phone}
                        type={'tel'}
                    />
                );
            case 'Fax':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Bitte geben Sie eine Faxnummer zu der Maßnahme an'}
                        label={'Fax'}
                        touched={touched.fax}
                        name={'fax'}
                        value={values.fax}
                        error={errors.fax}
                    />
                );
            case 'E-Mail':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Bitte geben Sie eine E-Mail Adresse zu der Maßnahme an'}
                        label={'E-Mail Adresse'}
                        touched={touched.email}
                        name={'email'}
                        value={values.email}
                        error={errors.email}
                        type={'email'}
                    />
                );
            case 'Losgröße':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Bitte geben Sie die Losgröße der Maßnahme an'}
                        label={'Losgröße'}
                        touched={touched.lotSize}
                        name={'lotSize'}
                        value={values.lotSize}
                        error={errors.lotSize}
                    />
                );
            case 'Rehaplätze':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Bitte geben Sie die Anzahl der Rehaplätze in der Maßnahme an'}
                        label={'Anzahl Rehaplätze'}
                        touched={touched.rehabSlots}
                        name={'rehabSlots'}
                        value={values.rehabSlots}
                        error={errors.rehabSlots}
                    />
                );
            case 'Rechtskreis':
                return (
                    <CreateProcessInputField
                        options={[
                            { label: 'SGB II', value: 'sgb_2' },
                            { label: 'SGB III', value: 'sgb_3' }
                        ]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        title={'Bitte geben Sie den Rechtskreis zu der Maßnahme an?'}
                        label={'Rechtskreis'}
                        touched={touched.legalSphere}
                        name={'legalSphere'}
                        value={values.legalSphere}
                        error={errors.legalSphere}
                    />
                );
            case 'Ansprechpartner':
                return (
                    <CreateProcessInputField
                        autoFocus
                        onChange={handleChange}
                        title={'Bitte geben Sie eine Kontaktperson zu der Maßnahme an'}
                        label={'Kontaktperson'}
                        touched={touched.contact}
                        name={'contact'}
                        value={values.contact}
                        error={errors.contact}
                    />
                );
            case 'QM-Dokumente':
                return (
                    <FieldArray
                        name="qmDocuments"
                        render={({ remove, push, replace }) => (
                            <>
                                <CreateMeasureQMDocuments
                                    setFieldValue={setFieldValue}
                                    name={'qmDocuments'}
                                    values={values.qmDocuments}
                                    remove={remove}
                                    push={push}
                                    replace={replace}
                                    error={
                                        errors.qmDocuments instanceof Array
                                            ? errors.qmDocuments.join(' ')
                                            : errors.qmDocuments
                                    }
                                />
                            </>
                        )}
                    />
                );
            case 'Mitarbeiter':
                return (
                    <FieldArray
                        name="employee"
                        render={({ remove, push, replace }) => (
                            <>
                                <CreateMeasureEmployee
                                    onChange={handleChange}
                                    name={'employee'}
                                    values={values.user}
                                    user={allUsers}
                                    remove={remove}
                                    push={push}
                                    replace={replace}
                                    setFieldValue={setFieldValue}
                                    error={errors.user instanceof Array ? errors.user.join(' ') : errors.user}
                                />
                            </>
                        )}
                    />
                );
        }
    };

    /**
     * On click on the back button change the form
     */
    const onClickBackInForm = () => {
        changeSelectedStateOfMenu(formMenu[currentlyActiveForm.index - 2].name);
        setCurrentlyActiveForm({
            name: formMenu[currentlyActiveForm.index - 2].name,
            index: currentlyActiveForm.index - 1
        });
    };

    /**
     * On click function if the user clicks the sidebar menu
     * @param menuName
     * @param index
     */
    const onClickSidebarItem = (menuName: string, index: number) => {
        // change the form content
        setCurrentlyActiveForm({ name: menuName, index: index + 1 });
        changeSelectedStateOfMenu(menuName);
    };

    /**
     * Function to change the icon/state if a menu point is selected
     * @param menuName
     * @param index
     */
    const changeSelectedStateOfMenu = useCallback(
        (menuName: string) => {
            // check if there is any menu selected already -> unselect if needed
            const indexOfMenuToUnSelect = formMenu.findIndex((element) => {
                return element.selected;
            });
            formMenu[indexOfMenuToUnSelect].selected = false;

            // check the name of the form content and mark the corresponding menu point as selected
            const indexOfMenuToBeSelected = formMenu.findIndex((element) => {
                return element.name === menuName;
            });
            formMenu[indexOfMenuToBeSelected].selected = true;
            // set form menu state
            setFormMenu([...formMenu]);
        },
        [formMenu]
    );

    /**
     * Validate the formik form manually. Formik validates everything at once and it does not care if the user has visited
     * a field already. Therefore we take are of validation ourselves by telling formik that the current field is touched and
     * if there is an error it can be displayed
     *
     * @param touched
     * @param setTouched
     * @param validateForm
     */
    const validateFormManually = useCallback(
        (
            touched: FormikTouched<MeasureSubmit>,
            setTouched: (t: FormikTouched<MeasureSubmit>) => void,
            validateForm: () => void
        ) => {
            // set the field of the current view as touched in Formik so that we know, this field has been visited
            const formValueName = formMenu[currentlyActiveForm.index - 1].formValue;
            if (formValueName === 'zipCode') {
                // zipCode and city are basically in the same menu, so if the formValue is zipCode, both should be set as touched
                touched['zipCode'] = true;
                touched['city'] = true;
            } else {
                // @ts-ignore TODO fix this, i think this is becasue of array types in formik values
                touched[formValueName] = true;
            }

            // validate the form manually
            validateForm();
        },
        [currentlyActiveForm.index, formMenu]
    );

    /**
     * Call back when the user clicks the "next" button -> navigate through the form
     */
    const onClickNext = useCallback(
        (
            values: FormikValues,
            errors: FormikErrors<MeasureSubmit>,
            validateForm: () => void,
            touched: FormikTouched<MeasureSubmit>,
            setTouched: (t: FormikTouched<MeasureSubmit>) => void
        ) => {
            validateFormManually(touched, setTouched, validateForm);

            // check if there are any errors existing for the current view/field
            if (!errors || (errors && !errors[formMenu[currentlyActiveForm.index - 1].formValue])) {
                // check if values to the current text field is existing, if so mark the menu option as finished
                if (values[formMenu[currentlyActiveForm.index - 1].formValue]) {
                    if (
                        formMenu[currentlyActiveForm.index - 1].formValue === 'zipCode' &&
                        (!values.zipCode || !values.city)
                    ) {
                        return;
                    }

                    formMenu[currentlyActiveForm.index - 1].finished = true;
                    setFormMenu([...formMenu]);
                } else {
                    formMenu[currentlyActiveForm.index - 1].finished = false;
                    setFormMenu([...formMenu]);
                    return;
                }

                setCurrentlyActiveForm({
                    name: formMenu[currentlyActiveForm.index].name,
                    index: currentlyActiveForm.index + 1
                });
                changeSelectedStateOfMenu(formMenu[currentlyActiveForm.index].name);
            } else {
                return;
            }
        },
        [changeSelectedStateOfMenu, currentlyActiveForm.index, formMenu, validateFormManually]
    );

    /**
     * Call back in case user clicks the enter button
     * @param event
     */
    const clickEnter = useCallback(
        (
            event: KeyboardEvent,
            values: FormikValues,
            errors: FormikErrors<MeasureSubmit>,
            validateForm: () => void,
            setTouched: (t: FormikTouched<MeasureSubmit>) => void,
            touched: FormikTouched<MeasureSubmit>
        ) => {
            // check if enter key was pressed and if the user aka currentlyActiveForm has not reached the end of the formMenu yet
            if (event.key === 'Enter' && currentlyActiveForm.index < formMenu.length) {
                // prevent default click behaviour
                event.preventDefault();
                // trigger the next view
                onClickNext(values, errors, validateForm, touched, setTouched);
            }
        },
        [currentlyActiveForm.index, formMenu.length, onClickNext]
    );

    /**
     * Call back to close the sidebar and reset states
     * @param touched
     * @param resetForm
     */
    const onCloseSideBar = (
        touched: FormikTouched<MeasureSubmit>,
        resetForm: (nextState?: Partial<FormikState<MeasureSubmit>> | undefined) => void
    ) => {
        touched = {};
        resetForm();
        props.onClose();
    };

    return (
        <Formik
            initialValues={initialFormValues}
            onSubmit={(values, { resetForm }) => {
                props.onSubmit(values);
                resetForm();
            }}
            validateOnMount={false}
            enableReinitialize={false}
            validateOnChange={true}
            validationSchema={CreateMeasureValidationScheme}
        >
            {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                touched,
                validateForm,
                handleBlur,
                setTouched,
                resetForm
            }) => (
                <Form onSubmit={handleSubmit}>
                    <CreateProcessKeyboardEnter
                        values={values}
                        errors={errors}
                        onClickEnter={clickEnter}
                        validateForm={validateForm}
                        touched={touched}
                        setTouched={setTouched}
                    />
                    <div className={`create-measure ${props.className}`}>
                        <FullscreenSidebar
                            title={'Neue Maßnahme'}
                            onClose={() => onCloseSideBar(touched, resetForm)}
                            className="create-measure-menu"
                        >
                            {formMenu.map((item, index) => {
                                return (
                                    <FullscreenSidebarItem
                                        key={index}
                                        name={item.name}
                                        selected={item.selected}
                                        finished={item.finished}
                                        onClick={() => onClickSidebarItem(item.name, index)}
                                    />
                                );
                            })}
                        </FullscreenSidebar>
                        <div className="create-measure-detail">
                            <div className="label-3 create-measure-detail-sub-header">{`${
                                currentlyActiveForm.index
                            }. ${currentlyActiveForm.name.toUpperCase()}`}</div>
                            {renderFormContent(values, handleChange, setFieldValue, handleBlur, errors, touched)}
                            {currentlyActiveForm.index < formMenu.length && (
                                <div className="p5-medium create-measure-detail-enter-hint">
                                    Eingabetaste
                                    <Icon
                                        className="create-measure-detail-enter-hint-icon"
                                        iconFile={
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0 2.66667C0 1.19391 1.19391 0 2.66667 0H13.3333C14.8061 0 16 1.19391 16 2.66667V13.3333C16 14.8061 14.8061 16 13.3333 16H2.66667C1.19391 16 0 14.8061 0 13.3333V2.66667Z"
                                                    fill="#E4E4E7"
                                                />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12.8333 3.33301C13.1095 3.33301 13.3333 3.55687 13.3333 3.83301V7.33301C13.3333 8.34553 12.5125 9.16634 11.5 9.16634H4.42427L6.50997 11.1362C6.71073 11.3258 6.71977 11.6422 6.53016 11.843C6.34056 12.0437 6.0241 12.0528 5.82334 11.8632L2.82334 9.02985C2.72333 8.93539 2.66666 8.80391 2.66666 8.66634C2.66666 8.52878 2.72333 8.39729 2.82334 8.30283L5.82334 5.4695C6.0241 5.2799 6.34056 5.28894 6.53016 5.4897C6.71977 5.69045 6.71073 6.00691 6.50997 6.19651L4.42427 8.16634H11.5C11.9602 8.16634 12.3333 7.79325 12.3333 7.33301V3.83301C12.3333 3.55687 12.5572 3.33301 12.8333 3.33301Z"
                                                    fill="#71717A"
                                                    stroke="#71717A"
                                                    strokeWidth="0.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M2.66667 0.666667H13.3333V-0.666667H2.66667V0.666667ZM15.3333 2.66667V13.3333H16.6667V2.66667H15.3333ZM13.3333 15.3333H2.66667V16.6667H13.3333V15.3333ZM0.666667 13.3333V2.66667H-0.666667V13.3333H0.666667ZM2.66667 15.3333C1.5621 15.3333 0.666667 14.4379 0.666667 13.3333H-0.666667C-0.666667 15.1743 0.825718 16.6667 2.66667 16.6667V15.3333ZM15.3333 13.3333C15.3333 14.4379 14.4379 15.3333 13.3333 15.3333V16.6667C15.1743 16.6667 16.6667 15.1743 16.6667 13.3333H15.3333ZM13.3333 0.666667C14.4379 0.666667 15.3333 1.5621 15.3333 2.66667H16.6667C16.6667 0.825718 15.1743 -0.666667 13.3333 -0.666667V0.666667ZM2.66667 -0.666667C0.825718 -0.666667 -0.666667 0.825718 -0.666667 2.66667H0.666667C0.666667 1.5621 1.5621 0.666667 2.66667 0.666667V-0.666667Z"
                                                    fill="#E4E4E7"
                                                />
                                            </svg>
                                        }
                                    />
                                    zum fortfahren
                                </div>
                            )}
                            <div className="create-measure-detail-buttons">
                                {currentlyActiveForm.index > 1 && (
                                    <Button
                                        type={'primary'}
                                        size={'medium'}
                                        buttonStyle={'link'}
                                        firstIcon={<Icon type={'ArrowLeft'} />}
                                        text={'Zurück'}
                                        onClick={onClickBackInForm}
                                    />
                                )}
                                {currentlyActiveForm.index < formMenu.length && (
                                    <Button
                                        type={'primary'}
                                        size={'medium'}
                                        buttonStyle={'filled'}
                                        text={'Weiter'}
                                        className="align-self-end"
                                        onClick={() => onClickNext(values, errors, validateForm, touched, setTouched)}
                                    />
                                )}
                                {currentlyActiveForm.index === formMenu.length && (
                                    <Button
                                        type={'primary'}
                                        size={'medium'}
                                        buttonStyle={'filled'}
                                        text={'Maßnahme anlegen'}
                                        className="align-self-end"
                                        onClick={handleSubmit}
                                    />
                                )}
                            </div>
                            {props.submitError && (
                                <div className="create-measure-submit-error">{props.submitError}</div>
                            )}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
