import { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import { CreateParticipant } from '../../components/CreateParticipant/CreateParticipant';
import { ParticipantDetails } from '../../components/ParticipantDetails/ParticipantDetails';
import { ParticipantsList } from '../../components/ParticipantsList/ParticipantsList';
import { PrintQmDocumentModal } from '../../components/PrintQmDocumentModal/PrintQmDocumentModal';
import { StandardView } from '../../components/StandardView/StandardView';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Participant, ParticipantWithPresence } from '../../models/Participant/Types';
import { QmDocumentDocx } from '../../models/QmDocument/Types';
import './UserMeasureParticipantManagement.css';
import { setSelectedParticipant } from '../../models/Participant/Slice';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { store } from '../../store';

export const UserMeasureParticipantManagement = () => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const measureId = useAppSelector((x) => x.measure.selectedMeasure?.id);
    const selectedParticipant = useAppSelector((x) => x.participant.selectedParticipant);

    const [showCreateParticipant, setShowCreateParticipant] = useState<boolean>(false);
    const [showParticipantDetailInformation, setShowParticipantDetailInformation] = useState<boolean>(false);
    const [documentModal, setDocumentModal] = useState<boolean>(false);
    const [documents, setDocuments] = useState<QmDocumentDocx[]>();
    const { data: activeParticipants, mutate: reloadActiveParticipants } = useSWR<Participant[]>([
        backendUrl,
        `measures/${measureId}/active_participants`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: inactiveParticipants, mutate: reloadInactiveParticipants } = useSWR<Participant[]>([
        backendUrl,
        `measures/${measureId}/inactive_participants`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: participantsWithPresences, mutate: reloadParticipantsWithPresences } = useSWR<
        ParticipantWithPresence[]
    >([backendUrl, `measures/${measureId}/participants`, 'GET', null, authInfo, apiVersion]);

    const dispatch = useAppDispatch();

    const onOpenDocumentModal = () => {
        setDocumentModal(!documentModal);
    };

    const reloadParticipants = () => {
        reloadActiveParticipants().then();
        reloadInactiveParticipants().then();
        reloadParticipantsWithPresences().then();
    };

    const onSetDocuments = (documents: QmDocumentDocx[] | undefined) => {
        setDocuments(documents);
    };

    /**
     * Show create participant view or close it
     */
    const onCreateNewParticipant = () => {
        setShowCreateParticipant(!showCreateParticipant);
    };

    /**
     * Called if some participant gets selected from the list.
     * Dispatches the selected participant to the global state.
     *
     * @param participant
     */
    const onSelectParticipant = useCallback(
        (participant: Participant) => {
            const participantWithPresences = participantsWithPresences?.find(
                (participantWithPresence) => participantWithPresence.id === participant?.id
            );

            if (measureId && participantWithPresences) {
                const measuresParticipant = participantWithPresences.measuresParticipants.find(
                    (measureParticipant) => measureParticipant.measureId === measureId
                );

                if (measuresParticipant) {
                    dispatch(
                        setSelectedParticipant({
                            ...participantWithPresences,
                            ...{
                                measuresParticipants: measuresParticipant
                            }
                        })
                    );
                }
            }
        },
        [dispatch, measureId, participantsWithPresences]
    );

    /**
     * TODO: Remove if state handling is updated.
     *
     * This is a nasty hack to update the participant in the state if it was changed.
     * If some child component calls the mutator for swr, the list is reladed.
     * This usually happens if something in a participant changes.
     * If the mutator was called, the list will be reloaded, hence this useEffect here will be called.
     * This loads the current participant from the store and combines its data with the new one and set it to the state again.
     * This forces the child components to update the data.
     * It is important that we do not use the participant from the state and add it to the dependencies, because otherwise this will
     * cause a loop.
     */
    useEffect(() => {
        if (!participantsWithPresences) return;

        const participantInStore = store.getState().participant.selectedParticipant;
        if (!participantInStore) return;

        const participantInResponse = participantsWithPresences.find(
            (participantItem) => participantItem.id === participantInStore.id
        );
        if (!participantInResponse) return;

        dispatch(
            setSelectedParticipant({
                ...participantInResponse,
                measuresParticipants: participantInStore.measuresParticipants
            })
        );
    }, [dispatch, participantsWithPresences]);

    /**
     * Show the detail information of a participant
     */
    const onShowDetails = () => {
        setShowParticipantDetailInformation(!showParticipantDetailInformation);
    };

    return (
        <StandardView>
            <PrintQmDocumentModal
                show={!!documents}
                onSetDocuments={onSetDocuments}
                close={onOpenDocumentModal}
                documents={documents}
            />
            {showCreateParticipant ? (
                <CreateParticipant
                    onSetDocuments={onSetDocuments}
                    mutate={reloadParticipants}
                    onClose={onCreateNewParticipant}
                />
            ) : (
                <></>
            )}
            <>
                {' '}
                <StandardView.Left>
                    <ParticipantsList
                        onSelectParticipant={onSelectParticipant}
                        onCreateNewParticipant={onCreateNewParticipant}
                        selectedParticipant={selectedParticipant}
                        activeParticipants={activeParticipants?.sort((a: Participant, b: Participant) =>
                            a.lastName.localeCompare(b.lastName)
                        )}
                        inactiveParticipants={inactiveParticipants?.sort((a: Participant, b: Participant) =>
                            a.lastName.localeCompare(b.lastName)
                        )}
                    />{' '}
                </StandardView.Left>
                <StandardView.Right>
                    {selectedParticipant && activeParticipants && (
                        <ParticipantDetails
                            activeParticipants={activeParticipants}
                            onShowDetails={onShowDetails}
                            // @ts-ignore TODO fixme, multiple mutators at once...
                            mutateParticipantList={reloadParticipants}
                            participant={selectedParticipant}
                        />
                    )}
                </StandardView.Right>
            </>
        </StandardView>
    );
};
