import { FormikErrors } from 'formik';
import React from 'react';
import { isObjectEmpty } from '../../helpers';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { InternshipCompany } from '../../models/InternshipCompanies/Type';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { DropDown } from '../DropDown/DropDown';
import { List } from '../List/List';
import { useInternshipCompaniesApi } from '../../api/useInternshipCompaniesApi';

interface CompanyDetailsFormProps {
    errors: FormikErrors<InternshipCompany>;
    company: InternshipCompany | undefined;
    setFieldValue: FormikSetFieldValue;
    handleChange: FormikHandleChange;
    values: InternshipCompany;
    onBlur: (values: InternshipCompany, error: FormikErrors<InternshipCompany>) => void;
    reloadCompanies: () => void;
}

export const CompanyDetailsForm = (props: CompanyDetailsFormProps) => {
    const { apiUpdateInternshipCompany } = useInternshipCompaniesApi();

    /**
     * Send notes information to the backend if the onblur event is triggered
     * @param event
     */
    const onBlurUpdateNotes = async () => {
        try {
            if (props.values.notes && props.company?.id && props.company.notes !== props.values.notes) {
                await apiUpdateInternshipCompany(props.company?.id, { notes: props.values.notes });
                props.reloadCompanies();
                showSuccessMessage('Betrieb aktualisiert');
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * trigger onblur event in case user hits the enter key in an input field
     * @param event
     */
    const onKeyEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    };

    const states = [
        { value: 'Baden-Württemberg', id: 'Baden-Württemberg' },
        { value: 'Bayern', id: 'Bayern' },
        { value: 'Berlin', id: 'Berlin' },
        { value: 'Brandenburg', id: 'Brandenburg' },
        { value: 'Bremen', id: 'Bremen' },
        { value: 'Hamburg', id: 'Hamburg' },
        { value: 'Hessen', id: 'Hessen' },
        { value: 'Mecklenburg-Vorpommern', id: 'Mecklenburg-Vorpommern' },
        { value: 'Niedersachen', id: 'Niedersachen' },
        { value: 'Nordrhein-Westfalen', id: 'Nordrhein-Westfalen' },
        { value: 'Rheinland-Pfalz', id: 'Rheinland-Pfalz' },
        { value: 'Saarland', id: 'Saarland' },
        { value: 'Sachsen', id: 'Sachsen' },
        { value: 'Sachsen-Anhalt', id: 'Sachsen-Anhalt' },
        { value: 'Schleswig-Holstein', id: 'Schleswig-Holstein' },
        { value: 'Thüringen', id: 'Thüringen' }
    ];

    /**
     * Call back for onBlur event
     */
    const onChangeBlur = () => {
        props.onBlur(props.values, props.errors);
    };

    /**
     * Call back to change the state of a company
     * @param state
     */
    const onChangeState = async (state: string) => {
        if (isObjectEmpty(props.errors)) {
            try {
                if (props.company?.id) {
                    await apiUpdateInternshipCompany(props.company?.id, { state: state });
                    showSuccessMessage('Betrieb aktualisiert');
                    props.reloadCompanies();
                }

                props.setFieldValue('state', state);
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <div style={{ display: 'flex', gap: '1rem' }}>
            <List
                className={'flex-1'}
                options={[
                    {
                        label: 'Name',
                        value: props.values.name,
                        name: 'name',
                        onBlur: onChangeBlur,
                        onChange: props.handleChange,
                        error: props.errors.name,
                        onKeyDown: onKeyEnter
                    },
                    {
                        label: 'Branche',
                        value: props.values.sector,
                        onChange: props.handleChange,
                        name: 'sector',
                        onBlur: onChangeBlur,
                        error: props.errors.sector,
                        onKeyDown: onKeyEnter
                    },
                    {
                        label: 'Straße & Hausnummer',
                        value: props.values.streetAndNumber,
                        onChange: props.handleChange,
                        name: 'streetAndNumber',
                        onBlur: onChangeBlur,
                        error: props.errors.streetAndNumber,
                        onKeyDown: onKeyEnter
                    },
                    {
                        label: 'PLZ',
                        value: props.values.zipCode,
                        onChange: props.handleChange,
                        name: 'zipCode',
                        onBlur: onChangeBlur,
                        error: props.errors.zipCode,
                        onKeyDown: onKeyEnter
                    },
                    {
                        label: 'Ort',
                        value: props.values.city,
                        onChange: props.handleChange,
                        name: 'city',
                        onBlur: onChangeBlur,
                        error: props.errors.city,
                        onKeyDown: onKeyEnter
                    },
                    {
                        label: 'Bundesland',
                        input: (
                            <DropDown title={props.values.state}>
                                {states.map((state, index) => {
                                    return (
                                        <div key={index} onClick={() => onChangeState(state.id)}>
                                            {state.value}
                                        </div>
                                    );
                                })}
                            </DropDown>
                        )
                    },
                    {
                        label: 'Telefon',
                        value: props.values.phone,
                        onChange: props.handleChange,
                        name: 'phone',
                        onBlur: onChangeBlur,
                        error: props.errors.phone,
                        onKeyDown: onKeyEnter
                    },
                    {
                        label: 'Fax',
                        value: props.values.fax,
                        onChange: props.handleChange,
                        name: 'fax',
                        onBlur: onChangeBlur,
                        error: props.errors.fax,
                        onKeyDown: onKeyEnter
                    },
                    {
                        label: 'E-Mail',
                        value: props.values.email,
                        onChange: props.handleChange,
                        name: 'email',
                        onBlur: onChangeBlur,
                        error: props.errors.email,
                        onKeyDown: onKeyEnter
                    },
                    {
                        label: 'Ansprechparnter',
                        value: props.values.contact,
                        onChange: props.handleChange,
                        name: 'contact',
                        onBlur: onChangeBlur,
                        error: props.errors.contact,
                        onKeyDown: onKeyEnter
                    }
                ]}
            />
            <div className="ml-auto" style={{ flex: '1', display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                <div style={{ color: '#71717A' }} className={'p4-medium'}>
                    Notizen:
                </div>
                <textarea
                    rows={10}
                    onChange={props.handleChange}
                    className={'company-details-textarea'}
                    name={'notes'}
                    value={props.values.notes}
                    onBlur={onBlurUpdateNotes}
                />
            </div>
        </div>
    );
};
