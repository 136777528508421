import { useState } from 'react';
import './SubNavTab.css';

interface SubNavTabProps {
    navigations: string[];
    onGetSelected: (arg: string) => void;
}

export const SubNavTab = (props: SubNavTabProps) => {
    const [menuSelected, setMenuSelected] = useState<string>(props.navigations[0]);

    const onClickNavigation = (navigation: string) => {
        setMenuSelected(navigation);
        props.onGetSelected(navigation);
    };

    return (
        <div className={'sub-nav-tab'}>
            {props.navigations.map((navigation: string, index: number) => {
                return (
                    <div
                        key={index}
                        className={`sub-nav-tab-item label-2 ${menuSelected === navigation && 'active'}`}
                        onClick={() => onClickNavigation(navigation)}
                    >
                        {navigation.toUpperCase()}
                    </div>
                );
            })}
        </div>
    );
};
