import { Configuration } from '../configuration/Configuration';

/**
 * Returns a localized human readable date given by the specified date param.
 * The date can be given as iso string or date.
 *
 * @param date
 */
export const toHumanReadableDate = (date: Date | string | null): string => {
    if (!date) {
        return '';
    }

    if (typeof date === 'string') {
        return new Date(date).toLocaleDateString(undefined, Configuration.dateStyleOptions);
    }

    return date.toLocaleDateString(undefined, Configuration.dateStyleOptions);
};

/**
 * Returns true if the specified two dates are on the same day.
 *
 * @param date1
 * @param date2
 */
export const dayEquals = (date1: Date | string, date2: Date | string): boolean => {
    date1 = typeof date1 === 'string' ? new Date(date1) : date1;
    date2 = typeof date2 === 'string' ? new Date(date2) : date2;

    return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
    );
};

/**
 * Returns true if the specified date is today, without regarding the time.
 *
 * @param date
 */
export const isToday = (date: Date | string): boolean => {
    return dayEquals(date, new Date());
};

/**
 * Returns the number of days of the month of the specified day date.
 *
 * @param day
 */
export const daysOfMonthCount = (day: Date): number => {
    // Since the date constructors day index starts with 1, it creates a date object to the last previous months day
    // if we pass 0 here.
    return new Date(day.getFullYear(), day.getMonth() + 1, 0).getDate();
};

export const sortByDate = <T>(arr: T[], fieldName: string, order: 'asc' | 'desc' = 'asc') => {
    return arr.sort((a, b) => {
        // @ts-ignore we expect the programer to know that T has a field called fieldName, because it can not be checked at runtime
        const aDate: Date = a[fieldName];

        // @ts-ignore we expect the programer to know that T has a field called fieldName, because it can not be checked at runtime
        const bDate: Date = b[fieldName];

        if (aDate < bDate) return order == 'desc' ? 1 : -1;
        if (aDate > bDate) return order == 'desc' ? -1 : 1;
        return 0;
    });
};
