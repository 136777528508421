import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { Button } from '../../components/Button/Button';
import { Icon } from '../../components/Icon/Icon';
import { AppRoutes } from '../../configuration/AppRoutes';
import { useAppSelector } from '../../hooks';
import { ObjectiveAgreement } from '../../models/ObjectiveAgreement/Type';
import {
    Participant,
    ParticipantMeasure,
    ParticipantWithOneMeasure,
    ParticipantWithPresence
} from '../../models/Participant/Types';
import { AttendanceCard } from '../AttendanceCard/AttendanceCard';
import { ObjectiveAgreementCard } from '../ObjectiveAgreementCard/ObjectiveAgreementCard';
import { ParticipantDetailsCard } from '../ParticipantDetailsCard/ParticipantDetailsCard';
import './ParticipantDetails.css';
import { MeasuresParticipantHistoryCard } from '../MeasuresParticipantHistoryCard/MeasuresParticipantHistoryCard';
import { KeyedMutator } from 'swr';
import { Presence } from '../../models/Presence/Types';
import { MeasuresParticipantFamilyCard } from '../MeasuresParticipantFamilyCard/MeasuresParticipantFamilyCard';
import { useState } from 'react';
import { ParticipantReactivationModal } from '../ParticipantReactivationModal/ParticipantReactivationModal';
import { useMeasuresApi } from '../../api/useMeasuresApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { useParticipantsApi } from '../../api/useParticipantsApi';

interface ParticipantDetailsProps {
    participant: ParticipantWithOneMeasure;
    mutateParticipantList: KeyedMutator<Presence[]> | KeyedMutator<ParticipantWithPresence[]>;
}

export const ParticipantDetails = ({ participant, mutateParticipantList }: ParticipantDetailsProps) => {
    const measure = useAppSelector((state) => state.measure.selectedMeasure);
    const [showReactivateModal, setShowReactivateModal] = useState<boolean>(false);
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { apiReactivateArchivedParticipantInMeasure } = useMeasuresApi();
    const { apiUpdateParticipant } = useParticipantsApi();
    const { data: participantObjectiveAgreements, mutate: reloadObjectiveAgreements } = useSWR<ObjectiveAgreement[]>([
        backendUrl,
        `participants/${participant?.id}/objective_agreements`,
        'GET',
        { measureId: measure?.id },
        authInfo,
        apiVersion
    ]);

    // get detail information about the relation, like parents and children
    const { data: measuresParticipant } = useSWR<ParticipantMeasure>([
        backendUrl,
        `measures_participants/${participant?.measuresParticipants.id}/with_family`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    /**
     * Callback to show or close the reactivation modal
     */
    const onShowReactivateModal = () => {
        setShowReactivateModal(!showReactivateModal);
    };

    /**
     * Called if the button to reactivate a participant was pressed.
     */
    const onClickReactivateParticipant = async (participantToReactivate: Participant, plannedLeaveDate: Date) => {
        if (!measure?.id || !participant?.id) {
            return;
        }

        await apiReactivateArchivedParticipantInMeasure(measure.id, participantToReactivate.id);
        await apiUpdateParticipant(participantToReactivate.id, { plannedLeaveDate: plannedLeaveDate });

        setShowReactivateModal(false);
        mutateParticipantList();
    };

    return (
        <>
            <div className="user-information-container-head">
                <h5>
                    {participant?.firstName} {participant?.lastName}
                </h5>
                {participant?.measuresParticipants.inactive && (
                    <div style={{ display: 'flex', marginLeft: 'auto', marginRight: '1rem' }}>
                        <Button
                            type={'primary'}
                            size={'medium'}
                            buttonStyle={'outline'}
                            text={'Teilnehmer aktivieren'}
                            onClick={onShowReactivateModal}
                        />
                    </div>
                )}
                <div style={{ display: 'flex', gap: '2rem' }}>
                    <Link to={AppRoutes.participantDetailsSupportPlan(measure?.id)}>
                        <Button
                            type={'primary'}
                            size={'medium'}
                            buttonStyle={'outline'}
                            firstIcon={<Icon type={'ArrowRight'} />}
                            text={'Details öffnen'}
                        />
                    </Link>
                </div>
            </div>
            <ParticipantDetailsCard participant={participant} reloadParticipant={mutateParticipantList} />
            <AttendanceCard participant={participant} mutate={mutateParticipantList} />
            {participantObjectiveAgreements && participantObjectiveAgreements.length > 0 && (
                <div className="p2-medium">Aktuelle Ziele</div>
            )}
            {participantObjectiveAgreements?.map((objectiveAgreement) => {
                return (
                    !objectiveAgreement.supportPlan.closed && (
                        <ObjectiveAgreementCard
                            reloadObjectiveAgreements={reloadObjectiveAgreements}
                            objectiveAgreement={objectiveAgreement}
                        />
                    )
                );
            })}
            {measuresParticipant && <MeasuresParticipantHistoryCard measuresParticipant={measuresParticipant} />}
            {measuresParticipant && (measuresParticipant.parent || (measuresParticipant.children?.length || 0) > 0) && (
                <MeasuresParticipantFamilyCard measuresParticipant={measuresParticipant} />
            )}
            {showReactivateModal && (
                <ParticipantReactivationModal
                    participant={participant}
                    show={showReactivateModal}
                    onClose={onShowReactivateModal}
                    onReactivate={onClickReactivateParticipant}
                />
            )}
        </>
    );
};
