import { Letter, LetterDocx, LetterSubmit, SearchLetter } from '../models/Letters/Type';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';
import { EzOnRailsSearchFilterRequest } from '../models/Search/Type';

/**
 * Returns methods to communicate with the backend related to letters.
 */
export const useLettersApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API to create a letter
     *
     * @param letter
     */
    const apiCreateLetter = useCallback(
        async (letter: LetterSubmit): Promise<Letter> => {
            return call<LetterSubmit, Letter>('letters', 'POST', letter);
        },
        [call]
    );

    /**
     * API to crate docx in order to print/export a letter
     *
     * @param letterId
     */
    const apiCreateLetterDocx = useCallback(
        async (letterId: number | string): Promise<LetterDocx> => {
            return call<void, LetterDocx>(`letters/${letterId}/create_doc`, 'POST');
        },
        [call]
    );

    const apiSearchLetterForPrinting = useCallback(
        async (
            measureId: number | string,
            participantId: number | string,
            letterTemplateId: string | number
        ): Promise<SearchLetter> => {
            return call<EzOnRailsSearchFilterRequest, SearchLetter>('letters/search', 'POST', {
                filter: {
                    logic: 'and',
                    filters: [
                        { field: 'measure_id', operator: 'eq', value: measureId },
                        { field: 'participant_id', operator: 'eq', value: participantId },
                        { field: 'letter_template_id', operator: 'eq', value: letterTemplateId }
                    ]
                }
            });
        },
        [call]
    );

    const apiSearchLetterForPrintingWithCompany = useCallback(
        async (
            measureId: number | string,
            participantId: number | string,
            internshipCompanyId: number | string,
            letterTemplateId: string | number
        ): Promise<SearchLetter> => {
            return call<unknown, SearchLetter>('letters/search', 'POST', {
                filter: {
                    logic: 'and',
                    filters: [
                        { field: 'measure_id', operator: 'eq', value: measureId },
                        { field: 'participant_id', operator: 'eq', value: participantId },
                        { field: 'internship_company_id', operator: 'eq', value: internshipCompanyId },
                        { field: 'letter_template_id', operator: 'eq', value: letterTemplateId }
                    ]
                }
            });
        },
        [call]
    );

    return {
        apiCreateLetter: apiCreateLetter,
        apiCreateLetterDocx: apiCreateLetterDocx,
        apiSearchLetterForPrinting: apiSearchLetterForPrinting,
        apiSearchLetterForPrintingWithCompany: apiSearchLetterForPrintingWithCompany
    };
};
