import React, { useEffect, useState } from 'react';
import { ValueCheckbox } from '../../../components/ValueCheckbox/ValueCheckbox';
import { QmDocumentTemplate } from '../../../models/QmDocumentTemplate/Types';
import './CreateMeasureQmDocuments.css';
import { useQmDocumentTemplatesApi } from '../../../api/useQmDocumentTemplatesApi';

interface CreateMeasureQMDocumentsProps {
    onChange?: () => void;
    values: QmDocumentTemplate[];
    name?: string;
    setFieldValue: (arg1: string, arg2: number[]) => void;
    remove: (index: number) => void;
    push: (obj: unknown) => void;
    replace: (index: number, obj: unknown) => void;
    error?: string;
}

export const CreateMeasureQMDocuments = (props: CreateMeasureQMDocumentsProps) => {
    const [qmDocumentTemplates, setQmDocumentTemplates] = useState<QmDocumentTemplate[]>([]);
    const { apiGetQmDocumentTemplates } = useQmDocumentTemplatesApi();

    /**
     * Fetches the qmDocumentTemplates on first component render.
     */
    useEffect(() => {
        (async () => {
            const templates = await apiGetQmDocumentTemplates();
            setQmDocumentTemplates(templates);
        })();
    }, [apiGetQmDocumentTemplates]);

    /**
     * Gets the id of the selected element and either pushes or removes it from the formik state
     * @param qmDocumentTemplate
     */
    const onChangeValue = (qmDocumentTemplate: QmDocumentTemplate) => {
        const currentValues = props.values;
        const isAlreadyInState = currentValues.findIndex(
            (document) => document.document === qmDocumentTemplate.document
        );
        if (isAlreadyInState === -1) {
            props.push(qmDocumentTemplate);
        } else {
            props.remove(isAlreadyInState);
        }
    };

    /**
     * Determine if a qmtemplate is arlready part of formik, if so return true to mark the checkbox as checked
     * @param document
     */
    const isInValue = (document: QmDocumentTemplate) => {
        if (props.values.findIndex((value: QmDocumentTemplate) => value.name === document.name) !== -1) {
            return true;
        }
    };

    return (
        <>
            <h5 className="create-measure-number-title">Bitte wählen Sie die benötigen QM-Dokumente aus</h5>
            <div className="create-measure-qm-documents">
                {qmDocumentTemplates.map((document) => {
                    return (
                        <div key={document.id} className="create-measure-qm-documents-document">
                            <div>
                                <ValueCheckbox
                                    checked={isInValue(document)}
                                    label={document.name}
                                    onChange={onChangeValue}
                                    value={document}
                                />{' '}
                            </div>
                        </div>
                    );
                })}
                {props.error}
            </div>
        </>
    );
};
