import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import { UserObject } from '../../models/Employee/Types';
import { Participant } from '../../models/Participant/Types';
import { Task, TaskComment, TaskUpdate } from '../../models/Task/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Button } from '../Button/Button';
import { UpdateTaskForm } from '../UpdateTaskForm/UpdateTaskForm';
import './UserTaskDetails.css';
import { useMeasuresApi } from '../../api/useMeasuresApi';
import { useTasksApi } from '../../api/useTasksApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface UserTaskDetailsProps {
    onSubmit?: () => void;
    onClose: () => void;
    task: Task;
    mutateExistingTasks: () => void;
}

export const UserTaskDetails = (props: UserTaskDetailsProps) => {
    const [taskInformationHasChanged, setTaskInformationHasChanged] = useState<boolean>(false);
    const [participants, setParticipants] = useState<Participant[] | undefined>();
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { data: user } = useSWR<UserObject[]>([
        backendUrl,
        `measures/${props.task.measure.id}/active_users`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: taskComments, mutate: mutateComments } = useSWR<TaskComment[]>([
        backendUrl,
        `tasks/${props.task.id}/comments`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { apiGetActiveParticipantsInMeasure } = useMeasuresApi();
    const { apiUpdateTask, apiCreateTaskComment, apiAssignTask, apiDeleteTask } = useTasksApi();

    const initialFormValues: TaskUpdate = {
        ...props.task,
        ...{ text: '', assignedToId: props.task.assignments[0].assignedTo.id }
    };

    const onSubmitValues = async (values: TaskUpdate) => {
        if (values) {
            try {
                await apiUpdateTask(props.task.id, values);
                if (values.assignedToId && values.assignedToId !== props.task.assignments[0].assignedTo.id) {
                    await apiAssignTask(props.task.id, values.assignedToId);
                }

                if (values.text && values.text.length > 0) {
                    await apiCreateTaskComment(values.text, new Date(), props.task.id);
                }

                mutateComments().then();
                showSuccessMessage('Aufgabe aktualisiert.');
                props.mutateExistingTasks();
                props.onClose();
            } catch (e) {
                throwError('Das hat leider nicht funktioniert.');
            }
        }
    };

    const onDeleteTask = async () => {
        if (props.task.id) {
            try {
                await apiDeleteTask(props.task.id);
                showSuccessMessage('Aufgabe gelöscht.');
                props.mutateExistingTasks();
                props.onClose();
            } catch (e) {
                throwError('Das hat leider nicht geklappt.');
            }
        }
    };

    const onTaskInformationHasChanged = (arg: boolean) => {
        setTaskInformationHasChanged(arg);
    };

    /**
     * Backend call to get all participants that are part of the measures the user is also assigned to
     */
    const onGetParticipantOfMeasure = useCallback(async () => {
        try {
            if (props.task.measure.id) {
                setParticipants(
                    (await apiGetActiveParticipantsInMeasure(props.task.measure.id)).sort(
                        (a: { firstName: string }, b: { firstName: string }) => {
                            return a.firstName.localeCompare(b.firstName);
                        }
                    )
                );
            }
        } catch (e) {
            console.log(e);
        }
    }, [apiGetActiveParticipantsInMeasure, props.task.measure.id]);

    /**
     * Fetch participants of measure
     */
    useEffect(() => {
        onGetParticipantOfMeasure().then();
    }, [onGetParticipantOfMeasure]);

    return (
        <div className="create-tasks-foreground">
            <Formik
                initialValues={initialFormValues}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                    onSubmitValues(values).then();
                }}
            >
                {({ values, handleChange, handleSubmit, setFieldValue }) => (
                    <>
                        <div className="qm-document-template-form-background" />
                        <div className="create-task-container">
                            <div className="create-tasks">
                                {
                                    <UpdateTaskForm
                                        onDeleteTask={onDeleteTask}
                                        task={props.task}
                                        users={user}
                                        setFieldValue={setFieldValue}
                                        measures={props.task.measure}
                                        values={values}
                                        handleChange={handleChange}
                                        handleSubmit={handleSubmit}
                                        mutateComments={mutateComments}
                                        taskInformationHasChanged={onTaskInformationHasChanged}
                                        initialValues={initialFormValues}
                                        participants={participants}
                                        taskComments={taskComments}
                                    />
                                }
                                <div className="create-tasks-footer">
                                    <Button
                                        type={'primary'}
                                        size={'medium'}
                                        buttonStyle={'filled'}
                                        text={'Schließen'}
                                        onClick={props.onClose}
                                    />
                                    {taskInformationHasChanged && (
                                        <Button
                                            type={'primary'}
                                            size={'medium'}
                                            buttonStyle={'filled'}
                                            text={'Aufgabe speichern'}
                                            onClick={handleSubmit}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        </div>
    );
};
