import { Configuration } from './configuration/Configuration';

/**
 * Function to check if an object is empty
 * @param value
 */
export const isObjectEmpty = (value: unknown) => {
    return value && Object.keys(value).length === 0 && value.constructor === Object;
};

export const states = [
    { value: 'Baden-Württemberg', id: 'Baden-Württemberg' },
    { value: 'Bayern', id: 'Bayern' },
    { value: 'Berlin', id: 'Berlin' },
    { value: 'Brandenburg', id: 'Brandenburg' },
    { value: 'Bremen', id: 'Bremen' },
    { value: 'Hamburg', id: 'Hamburg' },
    { value: 'Hessen', id: 'Hessen' },
    { value: 'Mecklenburg-Vorpommern', id: 'Mecklenburg-Vorpommern' },
    { value: 'Niedersachen', id: 'Niedersachen' },
    { value: 'Nordrhein-Westfalen', id: 'Nordrhein-Westfalen' },
    { value: 'Rheinland-Pfalz', id: 'Rheinland-Pfalz' },
    { value: 'Saarland', id: 'Saarland' },
    { value: 'Sachsen', id: 'Sachsen' },
    { value: 'Sachsen-Anhalt', id: 'Sachsen-Anhalt' },
    { value: 'Schleswig-Holstein', id: 'Schleswig-Holstein' },
    { value: 'Thüringen', id: 'Thüringen' }
];

export const platformUrl = (url: string | null) => {
    return `${Configuration.backendUrl}${url}`;
};

export const phoneRegx = /[0-9]/;

export const mapBoolean = (input: boolean | undefined) => {
    switch (input) {
        case false:
            return 'Nein';
        case true:
            return 'Ja';
    }
};
