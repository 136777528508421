import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { AppRoutes } from '../../configuration/AppRoutes';
import { useAppSelector } from '../../hooks';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { Internship, InternshipSubmit } from '../../models/Internship/Types';
import { InternshipCompany } from '../../models/InternshipCompanies/Type';
import { Participant, ParticipantWithPresence } from '../../models/Participant/Types';
import { Datepicker } from '../Datepicker/Datepicker';
import { Input } from '../Input/Input';
import { InputSelect } from '../InputSelect/InputSelect';
import './CreateNewInternshipForm.css';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface CreateNewInternshipFormProps {
    setFieldValue: FormikSetFieldValue;
    handleChange: FormikHandleChange;
    values: InternshipSubmit | Internship;
    companies?: InternshipCompany[];
    participant?: Participant;
}

export const CreateNewInternshipForm = (props: CreateNewInternshipFormProps) => {
    const measureId = useAppSelector((state) => state.measure.selectedMeasure?.id);
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { data: participants } = useSWR<ParticipantWithPresence[]>([
        backendUrl,
        `measures/${measureId}/active_participants`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    /**
     * change participant id
     * @param id
     */
    const onChangeParticipantId = (id: number | undefined) => {
        props.setFieldValue('participantId', id);
    };

    /**
     * change internship start date
     * @param date
     */
    const onChangeStartDate = (date: Date) => {
        props.setFieldValue('startDate', date);
    };

    /**
     * change internship end date
     * @param date
     */
    const onChangeEndDate = (date: Date) => {
        props.setFieldValue('endDate', date);
    };

    /**
     * change internship company
     * @param id
     */
    const onChangeCompanyId = (id: number | undefined) => {
        props.setFieldValue('internshipCompanyId', id);
    };

    console.log(props.participant, props.companies);
    return (
        <div className="create-new-internship-form">
            {props.participant && props.companies ? (
                <div>
                    <div>In welchem Unternehmen möchten Sie das Praktikum buchen?</div>
                    <Link
                        to={AppRoutes.measureCompanies(measureId)}
                        className={'create-new-internship-form-company-link'}
                    >
                        Zur Unternehemensübersicht wechseln
                    </Link>
                    <InputSelect
                        dropdownOptions={props.companies?.map((company) => {
                            return {
                                value: company.name,
                                id: company.id
                            };
                        })}
                        onChange={onChangeCompanyId}
                        initialValue={
                            props.companies.some((company) => company.id === props.values.internshipCompanyId)
                                ? props.companies.find((company) => company.id === props.values.internshipCompanyId)
                                      ?.name
                                : ''
                        }
                    />
                </div>
            ) : (
                <div>
                    <div>Für welchen Teilnehmer möchten Sie ein Praktikum buchen?</div>
                    <InputSelect
                        dropdownOptions={participants?.map((participant) => {
                            return {
                                value: `${participant.firstName} ${participant.lastName}`,
                                id: participant.id
                            };
                        })}
                        onChange={onChangeParticipantId}
                        initialValue={
                            props.values.participantId
                                ? `${
                                      participants?.find(
                                          (participants) => participants.id === props.values.participantId
                                      )?.firstName
                                  } ${
                                      participants?.find(
                                          (participants) => participants.id === props.values.participantId
                                      )?.lastName
                                  }`
                                : ''
                        }
                    />
                </div>
            )}
            <div>
                <div>Welches Praktikum möchten Sie buchen?</div>
                <Input
                    placeholder={'z.B. Bürofachangestellte'}
                    value={props.values.name}
                    onChange={props.handleChange}
                    name={'name'}
                />
            </div>
            <div>
                <div>Praktikum Startdatum:</div>
                {props.values.startDate && (
                    <Datepicker value={new Date(props.values.startDate)} onChange={onChangeStartDate} />
                )}
            </div>
            <div>
                <div>Praktikum Enddatum</div>
                {props.values.endDate && (
                    <Datepicker value={new Date(props.values.endDate)} onChange={onChangeEndDate} />
                )}
            </div>
        </div>
    );
};
