import { ParticipantWithOneMeasure, ParticipantWithPresence } from '../../models/Participant/Types';
import { Card } from '../Card/Card';
import { Formik } from 'formik';
import React from 'react';
import { ParticipantCvCardForm } from '../ParticipantCvCardForm/ParticipantCvCardForm';
import { KeyedMutator } from 'swr';

interface ParticipantCvCardProps {
    participant: ParticipantWithOneMeasure;
    reloadParticipant: KeyedMutator<ParticipantWithPresence[]>;
}

export const ParticipantCvCard = (props: ParticipantCvCardProps) => {
    return (
        <Card key={props.participant.id}>
            <Formik
                initialValues={props.participant}
                // We need to pass this, because formik needs an onSubmit handler, but we do not use the submit mechanism of formik
                onSubmit={() => {
                    console.log('Submitting form');
                }}
            >
                {({ handleSubmit, values, errors, setFieldValue, handleChange }) => (
                    <ParticipantCvCardForm
                        reloadParticipant={props.reloadParticipant}
                        participant={props.participant}
                        errors={errors}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        values={values}
                    />
                )}
            </Formik>
        </Card>
    );
};
