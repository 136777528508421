import { useCallback, useEffect, useState } from 'react';
import { UserObject } from '../../models/Employee/Types';
import { Avatar } from '../Avatar/Avatar';
import { ObjectListItem } from '../ObjectListItem/ObjectListItem';
import { Loading } from '../Loading/Loading';

interface UserListElementsProps {
    users: UserObject[] | undefined;
    onClickGetData: (index: number, user: UserObject) => void;
    selectedUser: UserObject | undefined;
}

export const UserListElements = (props: UserListElementsProps) => {
    const [itemSelected, setItemSelected] = useState<UserObject | undefined>(undefined);
    const { onClickGetData } = props;

    const onClickData = useCallback(
        (index: number | string, item: UserObject) => {
            if (typeof index === 'number') {
                setItemSelected(item);
                onClickGetData(index, item);
            }
        },
        [onClickGetData]
    );

    /**
     * if no item is selected, select the first item
     */
    useEffect(() => {
        if (itemSelected === undefined && props.users) {
            onClickData(0, props.users[0]);
        }
    }, [itemSelected, onClickData, props.users]);

    return (
        <>
            {props.users ? (
                props.users
                    ?.sort((a, b) => String(a.lastName).localeCompare(String(b.lastName)))
                    .map((item, index) => {
                        return (
                            <ObjectListItem
                                key={index}
                                onClick={onClickData}
                                value={{ index, item }}
                                selected={itemSelected === item || item === props.selectedUser}
                            >
                                <div className="user-list-object-list-item-container">
                                    <div className="user-list-object-list-item">
                                        {item.id && <Avatar url={item.avatarUrl} size={'small'} />}
                                        <div className="user-list-object-list-item-text-description">
                                            <div>
                                                {item.lastName}, {item.firstName}{' '}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ObjectListItem>
                        );
                    })
            ) : (
                <Loading wrapper={'user-list-object-list-item-container'} repeat={8} />
            )}
        </>
    );
};
