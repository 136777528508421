import { useState } from 'react';
import { Link } from 'react-router-dom';
import './SidebarItemNav.css';

export interface SidebarItemNavProps {
    indicator?: number;
    title: string;
    spacer?: boolean;
    isSelected?: boolean;
    link?: string;
    onClick?: () => void;
}
export const SidebarItemNav = (props: SidebarItemNavProps) => {
    const [isClicked, setIsClicked] = useState<boolean>(false);

    const onClickItem = () => {
        if (props.isSelected === undefined) {
            setIsClicked(!isClicked);
        }

        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <Link to={props.link ? props.link : ''}>
            <div
                className={`side-bar-item-nav ${isClicked || props.isSelected ? 'active' : ''} `}
                onClick={onClickItem}
            >
                {props.spacer && <div className="side-bar-item-nav-spacer" />}
                <div className="p4-medium side-bar-item-nav-text">{props.title}</div>
                <div>{props.indicator}</div>
            </div>
        </Link>
    );
};
