import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeasureState } from './State';
import { Measure } from './Types';

/**
 * Slice state for redux work space
 */
export const MeasureSlice = createSlice({
    name: 'measure',
    initialState: {
        selectedMeasure: undefined
    } as MeasureState,
    reducers: {
        /**
         * Sets the given user to the state.
         *
         * @param state
         * @param action
         */
        setSelectedMeasure: (state, action: PayloadAction<Measure | undefined>) => {
            state.selectedMeasure = action.payload;
        }
    }
});

export const { setSelectedMeasure } = MeasureSlice.actions;
