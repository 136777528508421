import React, { ChangeEvent, ClipboardEvent, ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Label } from '../Label/Label';
import './Textarea.css';

interface TextareaProps {
    icon?: ReactElement;
    placeholder?: string;
    value?: string;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (arg?: string | number) => void;
    name?: string;
    className?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    onPaste?: (event: ClipboardEvent<HTMLTextAreaElement>) => void;
    label?: string;
    autoFocus?: boolean;
    rows?: number;
}

export const Textarea = (props: TextareaProps) => {
    /**
     * holds information about value in input field
     */
    const [inputValue, setInputValue] = useState<string>('');
    /**
     * State and refs needed to change the cursor position
     */
    const [cursor, setCursor] = useState<number | null>();
    const inputRef = useRef<HTMLInputElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    /**
     * Change event for the input field
     * @param event
     */
    const onChangeInputField = (event: ChangeEvent<HTMLTextAreaElement>) => {
        /**
         * Since this is a controlled input, save the cursor state for the next rendering
         */
        if (event.currentTarget) {
            setCursor(event.currentTarget.selectionStart);
        }

        if (!props.value) {
            setInputValue(event.currentTarget.value);
        }

        if (props.onChange) {
            props.onChange(event);
        }
    };

    /**
     * On blur event for the input field
     */
    const onBlurInputField = () => {
        if (props.onBlur) {
            props.onBlur(inputValue);
        }
    };

    /**
     * set an value if given by the parent component
     */
    useEffect(() => {
        if (props.value) {
            setInputValue(props.value);
        } else if (props.value?.toString().length === 0) {
            setInputValue('');
        }
    }, [props.value]);

    /**
     * Reset cursor position in this controlled component
     */
    useLayoutEffect(() => {
        if (inputRef.current && cursor) {
            inputRef.current.setSelectionRange(cursor, cursor);
        } else if (textareaRef.current && cursor) {
            textareaRef.current.setSelectionRange(cursor, cursor);
        }
    });

    return (
        <div className={`input-container ${props.className}`}>
            <div className="input-icon">{props.icon}</div>
            <div className="input-label-and-field">
                {props.label && <Label size={4}>{props.label}</Label>}
                <textarea
                    rows={props.rows}
                    ref={textareaRef}
                    autoFocus={props.autoFocus}
                    name={props.name}
                    onKeyDown={props.onKeyDown}
                    className={`input-textarea ${props.icon && 'input-icon-left'}`}
                    value={inputValue}
                    placeholder={props.placeholder}
                    onChange={onChangeInputField}
                    onBlur={onBlurInputField}
                />
            </div>
        </div>
    );
};
