import { KeyedMutator } from 'swr';
import { Course } from '../../models/Course/Type';
import { throwError } from '../../models/Toasts/Toasts';
import { Modal } from '../Modal/Modal';
import { useCoursesApi } from '../../api/useCourseApi';

interface CourseArchiveModalProps {
    show: boolean;
    onClose: () => void;
    course: Course | undefined;
    reloadCourse: KeyedMutator<Course>;
}

export const CourseArchiveModal = (props: CourseArchiveModalProps) => {
    const { apiArchiveCourse } = useCoursesApi();

    /**
     * Archive course
     */
    const onArchiveCourse = async () => {
        try {
            if (props.course) {
                await apiArchiveCourse(props.course.id);
                props.reloadCourse().then();
            }
        } catch (e) {
            throwError();
            console.log(e);
        }

        props.onClose();
    };

    return (
        <Modal
            show={props.show}
            header={'Qualifizierung archivieren'}
            buttons={{
                primary: {
                    text: 'Qualifizierung archivieren',
                    onClick: onArchiveCourse
                },
                secondary: {
                    text: 'Abbrechen',
                    onClick: props.onClose
                }
            }}
        >
            <div>
                Sind Sie sicher, dass Sie das Qualifizierungsangebot &quot;{props.course?.name}&quot; archivieren
                möchten?
            </div>
        </Modal>
    );
};
