import { ParticipantClient, ParticipantClientSubmit } from '../models/Participant/Types';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Returns methods to communicate with the backend related to ParticipantClients.
 */
export const useParticipantClientsApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API to create a client for a participant
     *
     * @param participantClient
     */
    const apiCreateParticipantClient = useCallback(
        async (participantClient: ParticipantClientSubmit): Promise<ParticipantClient> => {
            return call<ParticipantClientSubmit, ParticipantClient>('participant_clients', 'POST', participantClient);
        },
        [call]
    );

    const apiUpdateParticipantClient = useCallback(
        async (participantClientId: number, info: Partial<ParticipantClientSubmit>) => {
            return call<Partial<ParticipantClientSubmit>, ParticipantClient>(
                `participant_clients/${participantClientId}`,
                'PATCH',
                info
            );
        },
        [call]
    );

    return {
        apiCreateParticipantClient: apiCreateParticipantClient,
        apiUpdateParticipantClient: apiUpdateParticipantClient
    };
};
