import { Presence, PresenceSubmit, SearchPresenceOfParticipantInMeasure } from '../models/Presence/Types';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';
import { EzOnRailsSearchFilterRequest } from '../models/Search/Type';

/**
 * Returns methods to communicate with the backend related to Presences.
 */
export const usePresencesApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API call to create a new presence information
     *
     * @param presenceInformation
     */
    const apiCreatePresence = useCallback(
        async (presenceInformation: PresenceSubmit) => {
            return call<PresenceSubmit, Presence>('presences', 'POST', presenceInformation);
        },
        [call]
    );

    /**
     * API call to get all presence
     *
     */
    const apiGetPresence = useCallback(async (): Promise<Presence[]> => {
        return call<void, Presence[]>('presences', 'GET');
    }, [call]);

    /**
     * Return all presence of a participant
     *
     * @param participantId
     */
    const apiGetPresenceOfParticipant = useCallback(
        async (participantId: number): Promise<Presence[]> => {
            return call<void, Presence[]>(`participants/${participantId}/presences`, 'GET');
        },
        [call]
    );

    /**
     * API to update an existing presence
     *
     * @param presenceId
     * @param presence
     */
    const apiUpdatePresence = useCallback(
        async (presenceId: number, presence: PresenceSubmit): Promise<Presence> => {
            return call<PresenceSubmit, Presence>(`presences/${presenceId}`, 'PATCH', presence);
        },
        [call]
    );

    /**
     * API to remove a presence
     *
     * @param presenceId
     */
    const apiDeletePresence = useCallback(
        async (presenceId: number) => {
            return call<void, unknown>(`presences/${presenceId}`, 'DELETE');
        },
        [call]
    );

    const apiSearchPresenceOfParticipantInMeasure = useCallback(
        async (participantId: number, measureId: number): Promise<SearchPresenceOfParticipantInMeasure> => {
            return call<EzOnRailsSearchFilterRequest, SearchPresenceOfParticipantInMeasure>(
                `presences/search`,
                'POST',
                {
                    filter: {
                        logic: 'and',
                        filters: [
                            {
                                field: 'participant_id',
                                operator: 'eq',
                                value: participantId
                            },
                            {
                                field: 'measure_id',
                                operator: 'eq',
                                value: measureId
                            }
                        ]
                    }
                }
            );
        },
        [call]
    );

    return {
        apiCreatePresence: apiCreatePresence,
        apiGetPresence: apiGetPresence,
        apiGetPresenceOfParticipant: apiGetPresenceOfParticipant,
        apiUpdatePresence: apiUpdatePresence,
        apiDeletePresence: apiDeletePresence,
        apiSearchPresenceOfParticipantInMeasure: apiSearchPresenceOfParticipantInMeasure
    };
};
