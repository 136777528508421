import { ChangeEvent, useEffect, useState } from 'react';
import { UserObject } from '../../models/Employee/Types';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { ObjectList } from '../ObjectList/ObjectList';
import { UserListElements } from '../UserListElements/UserListElements';
import './UserList.css';

interface UserListProps {
    onGetUser?: (arg: UserObject) => void;
    selected?: boolean;
    users: UserObject[] | undefined;
    onCreateNewUser: () => void;
    selectedUser: UserObject | undefined;
}

export const UserList = (props: UserListProps) => {
    // states
    const [menuSelected, setMenuSelected] = useState<string>('active');
    const [activeUsers, setActiveUsers] = useState<UserObject[] | undefined>(props.users);
    const [inActiveUsers, setInactiveUsers] = useState<UserObject[] | undefined>();
    const [initialActiveUsers, setInitialActiveUsers] = useState<UserObject[] | undefined>(props.users);
    const [initialInActiveUsers, setInitialInActiveUsers] = useState<UserObject[] | undefined>();
    const [showSearch, setShowSearch] = useState<boolean>(false);

    // destructure
    const { onGetUser } = props;

    /**
     * Split the users
     */
    useEffect(() => {
        const activeUsersArray: UserObject[] = [];
        const inactiveUsersArray: UserObject[] = [];
        props.users?.forEach((user) => {
            if (user.archived) {
                inactiveUsersArray.push(user);
            } else {
                activeUsersArray.push(user);
            }

            setActiveUsers(activeUsersArray);
            setInitialActiveUsers(activeUsersArray);
            setInactiveUsers(inactiveUsersArray);
            setInitialInActiveUsers(inactiveUsersArray);
        });
    }, [props.users]);

    /**
     * Open/Close Searchbar
     */
    const onClickOpenSearch = () => {
        setShowSearch(!showSearch);
    };

    /**
     * Get user information by clicking on it
     */
    const onClickGetData = (index: number, item: UserObject) => {
        if (onGetUser) {
            onGetUser(item);
        }
    };

    const onChangeSearch = (event: ChangeEvent, value?: string) => {
        // create the filtered items based on the user's input
        if (menuSelected === 'active' && value) {
            const activeUsersBuffer = initialActiveUsers?.filter(
                (item: UserObject) =>
                    item.firstName.toLowerCase().includes(value?.toLowerCase()) ||
                    item.lastName.toLowerCase().includes(value?.toLowerCase())
            );
            setActiveUsers(activeUsersBuffer);
        } else if (value) {
            const inActiveUsersBuffer = initialInActiveUsers?.filter(
                (item: UserObject) =>
                    item.firstName.toLowerCase().includes(value?.toLowerCase()) ||
                    item.lastName.toLowerCase().includes(value?.toLowerCase())
            );
            setInactiveUsers(inActiveUsersBuffer);
        } else {
            if (menuSelected === 'active') {
                setActiveUsers(initialActiveUsers);
            } else {
                setInactiveUsers(initialInActiveUsers);
            }
        }
    };

    return (
        <ObjectList>
            <ObjectList.Head>
                <div className="user-list-head-container">
                    <div className="user-list-head">
                        <div className={'p2-medium'}>Mitarbeiter</div>
                        <div className="user-list-head-search">
                            <Button
                                type={'secondary'}
                                buttonStyle={'link'}
                                size={'small'}
                                firstIcon={<Icon type={'Search'} />}
                                onClick={onClickOpenSearch}
                            />
                            <Button
                                type={'primary'}
                                size={'small'}
                                buttonStyle={'filled'}
                                firstIcon={<Icon type={'Plus'} />}
                                onClick={props.onCreateNewUser}
                            />
                        </div>
                    </div>
                    {showSearch && (
                        <Input
                            icon={<Icon type={'Search'} />}
                            placeholder={'Mitarbeiter suchen'}
                            onChange={onChangeSearch}
                        />
                    )}
                </div>
            </ObjectList.Head>
            <div className="user-list-navigation">
                <div
                    className={`user-list-navigation-item label-2 ${menuSelected === 'active' && 'active'}`}
                    onClick={() => setMenuSelected('active')}
                >
                    AKTIV ({activeUsers?.length})
                </div>
                <div
                    className={`user-list-navigation-item label-2 ${menuSelected === 'inactive' && 'active'}`}
                    onClick={() => setMenuSelected('inactive')}
                >
                    ARCHIVIERT ({inActiveUsers?.length})
                </div>
            </div>
            {menuSelected === 'active' && (
                <ObjectList.Body>
                    <UserListElements
                        selectedUser={props.selectedUser}
                        users={activeUsers}
                        onClickGetData={onClickGetData}
                    />
                </ObjectList.Body>
            )}
            {menuSelected === 'inactive' && (
                <ObjectList.Body>
                    <UserListElements
                        selectedUser={props.selectedUser}
                        users={inActiveUsers}
                        onClickGetData={onClickGetData}
                    />
                </ObjectList.Body>
            )}
        </ObjectList>
    );
};
