import { Internship } from '../../models/Internship/Types';
import { Modal } from '../Modal/Modal';
import { InputSelect } from '../InputSelect/InputSelect';
import { formatDate } from '../../models/Date/Date';
import { QmDocument, QmDocumentDocx } from '../../models/QmDocument/Types';
import { useState } from 'react';
import { throwError, throwInfo } from '../../models/Toasts/Toasts';
import { useQmDocumentsApi } from '../../api/useQmDocumentsApi';

interface SelectInternshipForQmDocumentsProps {
    internships: Internship[];
    show: boolean;
    onClose: () => void;
    selectedQmDocument: QmDocument;
    participantId: number;
    setPrintableDocument: (document: QmDocumentDocx) => void;
}

export const SelectInternshipForQmDocuments = (props: SelectInternshipForQmDocumentsProps) => {
    const [internship, setInternship] = useState<Internship>();
    const { apiUpdateQmDocument, apiCreateQmDocumentDocx } = useQmDocumentsApi();

    /**
     * Print qm document that is connected to an internship
     */
    const onPrintWithInternship = async () => {
        if (internship) {
            try {
                if (props.selectedQmDocument && props.participantId && internship) {
                    await apiUpdateQmDocument(props.selectedQmDocument.id, {
                        ...props.selectedQmDocument,
                        ...{
                            participantId: props.participantId,
                            internshipId: internship.id,
                            internshipCompanyId: internship.internshipCompanyId
                        }
                    });
                    props.setPrintableDocument(await apiCreateQmDocumentDocx(props.selectedQmDocument.id));
                    props.onClose();
                }
            } catch (e) {
                console.log(e);
                throwError();
                props.onClose();
            }
        } else {
            throwInfo('Bitte wählen Sie zuerst ein Praktikum aus');
        }
    };

    return (
        <Modal
            show={props.show}
            header={'Praktikum auswählen'}
            buttons={{
                primary: { text: 'QM Dokument drucken', onClick: onPrintWithInternship },
                secondary: { text: 'Abbrechen', onClick: props.onClose }
            }}
        >
            <div>Dieser Teilnehmer hat eingetragene Praktika</div>
            <div>Bitte geben Sie an, welches Praktikum eingetragen werden soll.</div>
            <InputSelect
                dropdownOptions={props.internships.map((internship) => {
                    return {
                        value: `${internship.name} | ${internship.internshipCompanyId} | ${formatDate(
                            internship.startDate
                        )} - ${formatDate(internship.endDate)}`,
                        id: internship.id
                    };
                })}
                onChange={(id) => setInternship(props.internships.find((internship) => internship.id === id))}
            />
        </Modal>
    );
};
