import { FormikErrors } from 'formik';
import React from 'react';
import { KeyedMutator } from 'swr';
import { formatDate } from '../../models/Date/Date';
import { Participant, ParticipantWithOneMeasure, ParticipantWithPresence } from '../../models/Participant/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Datepicker } from '../Datepicker/Datepicker';
import { List } from '../List/List';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { Presence } from '../../models/Presence/Types';
import { useParticipantsApi } from '../../api/useParticipantsApi';
import { useParticipantClientsApi } from '../../api/useParticipantClientsApi';
import { useMeasuresApi } from '../../api/useMeasuresApi';

interface ParticipantDetailsListProps {
    participant: ParticipantWithOneMeasure;
    values: Participant;
    errors: FormikErrors<Participant>;
    handleChange: FormikHandleChange;
    setFieldValue: FormikSetFieldValue;
    reloadParticipant?: KeyedMutator<Presence[]> | KeyedMutator<ParticipantWithPresence[]>;
    readonly?: boolean;
}

export const ParticipantDetailsList = (props: ParticipantDetailsListProps) => {
    const { values, handleChange } = props;
    const { apiUpdateParticipant } = useParticipantsApi();
    const { apiUpdateParticipantClient } = useParticipantClientsApi();
    const { apiUpdateMeasureParticipant } = useMeasuresApi();

    /**
     * Submit function to change the participant's details
     */
    const onSubmit = async () => {
        try {
            if (props.participant) {
                await apiUpdateParticipant(props.participant?.id, props.values);
                showSuccessMessage('Teilnehmer aktualisiert');
                if (props.reloadParticipant) {
                    await props.reloadParticipant();
                }
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Call back for onBlur actions to submit the participant's details
     * @param arg
     */
    const onChangeBlur = async () => {
        try {
            if (props.participant && JSON.stringify(props.participant) !== JSON.stringify(props.values)) {
                await onSubmit();
                if (
                    JSON.stringify(props.participant.participantClient) !==
                    JSON.stringify(props.values.participantClient)
                ) {
                    await apiUpdateParticipantClient(
                        props.participant.participantClientId,
                        props.values.participantClient
                    );
                    if (props.reloadParticipant) {
                        await props.reloadParticipant();
                    }
                }

                if (props.reloadParticipant) {
                    await props.reloadParticipant();
                }
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Special submit action for birthday since it's a datepicker
     * @param value
     */
    const onChangeBirthday = async (value: Date) => {
        try {
            if (props.participant) {
                await apiUpdateParticipant(props.participant.id, {
                    ...props.values,
                    ...{ birthday: value }
                });
                showSuccessMessage('Teilnehmer aktualisiert');
                if (props.reloadParticipant) {
                    await props.reloadParticipant();
                }
            }

            props.setFieldValue('birthday', value);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Special submit action for the entrance date field since it's a datepicker
     * @param value
     */
    const onChangeEntranceDate = async (value: Date) => {
        try {
            if (props.participant) {
                // TODO: correct to use all parameters when updating entry?
                await apiUpdateParticipant(props.participant?.id, {
                    ...props.values,
                    ...{ entranceDate: value }
                });
                await apiUpdateMeasureParticipant(props.participant.measuresParticipants.id, {
                    ...props.participant.measuresParticipants,
                    ...{ entranceDate: value }
                });
                showSuccessMessage('Teilnehmer aktualisiert');
                if (props.reloadParticipant) {
                    await props.reloadParticipant();
                }
            }

            props.setFieldValue('entranceDate', value);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Special submit action for the planned leave date field since it's a datepicker
     * @param value
     */
    const onChangePlannedLeaveDate = async (value: Date) => {
        try {
            if (props.participant) {
                await apiUpdateParticipant(props.participant?.id, {
                    ...props.values,
                    ...{ plannedLeaveDate: value }
                });
                showSuccessMessage('Teilnehmer aktualisiert');
                if (props.reloadParticipant) {
                    await props.reloadParticipant();
                }
            }

            props.setFieldValue('plannedLeaveDate', value);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Call back to trigger the blur event if the user presses the enter key
     * @param event
     */
    const onKeyEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    };

    return (
        <List
            key={props.values.id}
            options={[
                {
                    label: 'Name',
                    value: values.lastName,
                    onChange: handleChange,
                    name: 'lastName',
                    onBlur: onChangeBlur,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Vorname',
                    value: values.firstName,
                    name: 'firstName',
                    onChange: handleChange,
                    onBlur: onChangeBlur,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Geburtsdatum',
                    value: formatDate(new Date(values.birthday)),
                    name: 'birthday',
                    onChange: handleChange,
                    input: (
                        <Datepicker
                            startYear={1950}
                            value={new Date(values.birthday)}
                            onChange={onChangeBirthday}
                            returnInputFieldDate={onChangeBirthday}
                        >
                            {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                <div className="details-form-list-calendar" onClick={openDatepicker}>
                                    <input
                                        className={'editable-item'}
                                        value={formatDate(new Date(values.birthday))}
                                        onChange={onChangeDateInForm}
                                        onPaste={onPasteDate}
                                        onKeyDown={onPressEnterSubmit}
                                    />
                                </div>
                            )}
                        </Datepicker>
                    )
                },
                {
                    label: 'Telefon',
                    value: values.phone,
                    name: 'phone',
                    onChange: handleChange,
                    onBlur: onChangeBlur,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'mobil',
                    value: values.mobilePhone,
                    name: 'mobilePhone',
                    onChange: handleChange,
                    onBlur: onChangeBlur,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'E-Mail',
                    value: values.email,
                    name: 'email',
                    onChange: handleChange,
                    onBlur: onChangeBlur,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Eintritt',
                    value: formatDate(new Date(props.participant.measuresParticipants.entranceDate)),
                    name: 'entranceDate',
                    onChange: handleChange,
                    onBlur: onChangeBlur,
                    input: (
                        <Datepicker
                            value={new Date(props.participant.measuresParticipants.entranceDate)}
                            onChange={onChangeEntranceDate}
                            onBlur={onChangeBlur}
                            returnInputFieldDate={onChangeEntranceDate}
                        >
                            {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                <div className="details-form-list-calendar" onClick={openDatepicker}>
                                    <input
                                        className={'editable-item'}
                                        value={formatDate(
                                            new Date(props.participant.measuresParticipants.entranceDate)
                                        )}
                                        onChange={onChangeDateInForm}
                                        onPaste={onPasteDate}
                                        onKeyDown={onPressEnterSubmit}
                                    />
                                </div>
                            )}
                        </Datepicker>
                    )
                },
                {
                    label: 'geplanter Austritt',
                    value: formatDate(new Date(values.plannedLeaveDate)),
                    name: 'plannedLeaveDate',
                    onChange: handleChange,
                    onBlur: onChangeBlur,
                    input: (
                        <Datepicker
                            value={new Date(values.plannedLeaveDate)}
                            onChange={onChangePlannedLeaveDate}
                            onBlur={onChangeBlur}
                            returnInputFieldDate={onChangePlannedLeaveDate}
                        >
                            {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                <div className="details-form-list-calendar" onClick={openDatepicker}>
                                    <input
                                        className={'editable-item'}
                                        value={formatDate(new Date(values.plannedLeaveDate))}
                                        onChange={onChangeDateInForm}
                                        onPaste={onPasteDate}
                                        onKeyDown={onPressEnterSubmit}
                                    />
                                </div>
                            )}
                        </Datepicker>
                    )
                },
                {
                    label: 'Kundennummer',
                    value: values.clientCustomerId,
                    name: 'clientCustomerId',
                    onChange: handleChange,
                    onBlur: onChangeBlur,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'BG-Nummer',
                    value: values.communityOfNeedsNo,
                    name: 'communityOfNeedsNo',
                    onChange: handleChange,
                    onBlur: onChangeBlur,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Teilnehmer ID Land',
                    value: values.idState,
                    name: 'idState',
                    onChange: handleChange,
                    onBlur: onChangeBlur,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Mitarbeiter/in Auftraggeber',
                    value: values.participantClient.contact,
                    name: 'participantClient.contact',
                    onChange: handleChange,
                    onBlur: onChangeBlur,
                    onKeyDown: onKeyEnter
                }
            ]}
        />
    );
};
