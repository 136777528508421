
const digest = '48ea307509f3a61e78826de5c891a87239295e1786a4328d73b9a16269544f45';
const css = `/* font *//* background *//* borders *//* fonts *//* paddings *//* margins and gaps *//* borders */
._input_kerls_6 {
    padding: 0.5rem;
    font-size: default;
    border-radius: 0.3rem;
    border: 1px solid lightgrey;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"colors":"\"../../styles/colors.module.css\"","colorBorderLight":"lightgrey","distances":"\"../../styles/distances.module.css\"","paddingMedium":"0.5rem","radiusBorderSmall":"0.3rem","sizeFontMedium":"default","sizeBorderSmall":"1px","input":"_input_kerls_6"};
export { css, digest };
  