
const digest = '6f394a277c167d830f66052578f6e65cada814bcd5536b091842c09d92584d52';
const css = `/* font *//* background *//* borders */
._error_1kpra_4 {
    color: darkred;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"colors":"\"../../styles/colors.module.css\"","colorFontDanger":"darkred","error":"_error_1kpra_4"};
export { css, digest };
  