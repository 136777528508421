import { UserGender, UserGroup } from './Types';

/**
 * Map backend types so that it's more understandable for the user
 * @param group
 */
export const mapRights = (group: UserGroup) => {
    switch (group) {
        case 'QmManager':
            return 'QM Beauftragter';
        case 'Administrator':
            return 'Administrator';
        case 'Employee':
            return 'Mitarbeiter';
        default:
            return 'Mitarbeiter';
    }
};

/**
 * Map gender backend type
 * @param gender
 */
export const mapGender = (gender: UserGender) => {
    switch (gender) {
        case 'male':
            return 'M';
        case 'female':
            return 'W';
        case 'diverse':
            return 'D';
    }
};
