import { ChangeEvent, useEffect, useState } from 'react';
import useSWR from 'swr';
import { Button } from '../../components/Button/Button';
import { CompanyInternships } from '../../components/CompanyInternships/CompanyInternships';
import { CreateNewCompany } from '../../components/CreateNewCompany/CreateNewCompany';
import { Icon } from '../../components/Icon/Icon';
import { Input } from '../../components/Input/Input';
import { ObjectList } from '../../components/ObjectList/ObjectList';
import { ObjectListItem } from '../../components/ObjectListItem/ObjectListItem';
import { StandardView } from '../../components/StandardView/StandardView';
import { Internship } from '../../models/Internship/Types';
import { InternshipCompany } from '../../models/InternshipCompanies/Type';
import { CompanyDetails } from '../CompanyDetails/CompanyDetails';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

export const CompaniesManagement = () => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { data: companies, mutate: reloadCompanies } = useSWR<InternshipCompany[]>([
        backendUrl,
        'internship_companies',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const [selectedCompany, setSelectedCompany] = useState<InternshipCompany>();
    const [createNewCompany, setCreateNewCompany] = useState<boolean>(false);
    const [search, setSearch] = useState<boolean>(false);
    const [filteredCompanies, setFilteredCompanies] = useState<InternshipCompany[] | undefined>([]);
    const { data: internships, mutate: reloadInternships } = useSWR<Internship[]>([
        backendUrl,
        selectedCompany ? `internship_companies/${selectedCompany.id}/own_internships` : null,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    /**
     * selected a company
     * @param index
     * @param company
     */
    const onSelectCompany = (index: number | string, company: InternshipCompany) => {
        setSelectedCompany(company);
    };

    /**
     * show create new company
     */
    const onCreateNewCompany = () => {
        setCreateNewCompany(!createNewCompany);
    };

    /**
     * if the companies are fetched and changed respectively update the filtered companies state
     */
    useEffect(() => {
        setFilteredCompanies(companies?.sort((a, b) => a.name.localeCompare(b.name)));
    }, [companies]);

    const onClickOpenSearch = () => {
        setSearch(!search);
    };

    /**
     * Change the filtered companies state based on input
     * @param event
     * @param value
     */
    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setFilteredCompanies(
            companies?.filter(
                (company) =>
                    company.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    company.sector.toLowerCase().includes(event.target.value.toLowerCase())
            )
        );
    };

    /**
     * if the companies or the selected company changes update the selected company
     */
    useEffect(() => {
        if (companies && selectedCompany) {
            setSelectedCompany(companies.find((company) => company.id === selectedCompany.id));
        }
    }, [companies, selectedCompany]);

    return (
        <>
            {createNewCompany && <CreateNewCompany reloadCompanies={reloadCompanies} onClose={onCreateNewCompany} />}
            <StandardView>
                <StandardView.Left>
                    <ObjectList>
                        <ObjectList.Head>
                            <div className="measure-list-head-container" style={{ marginBottom: '24px' }}>
                                <div className="measure-list-head">
                                    <div className="p2-medium">Betriebe</div>
                                    <div className="measure-list-head-search">
                                        <Button
                                            type={'secondary'}
                                            buttonStyle={'link'}
                                            size={'small'}
                                            firstIcon={<Icon type={'Search'} />}
                                            onClick={onClickOpenSearch}
                                        />
                                        <Button
                                            type={'primary'}
                                            size={'small'}
                                            buttonStyle={'filled'}
                                            firstIcon={<Icon type={'Plus'} />}
                                            onClick={onCreateNewCompany}
                                        />
                                    </div>
                                </div>
                                {search && (
                                    <Input
                                        icon={<Icon type={'Search'} />}
                                        placeholder={'Betrieb suchen'}
                                        onChange={onChangeSearch}
                                    />
                                )}
                            </div>
                        </ObjectList.Head>
                        <ObjectList.Body>
                            <div className="participant-detail-information-dates" style={{ flexDirection: 'column' }}>
                                {filteredCompanies?.map((company, index) => {
                                    return (
                                        <ObjectListItem
                                            key={company.id}
                                            value={{ index: index, item: company }}
                                            selected={selectedCompany === company}
                                            onClick={onSelectCompany}
                                        >
                                            <div>{company.name}</div>
                                            <div className="courses-selection-name">{company.sector}</div>
                                        </ObjectListItem>
                                    );
                                })}
                            </div>
                        </ObjectList.Body>
                    </ObjectList>
                </StandardView.Left>
                <StandardView.Right>
                    <>
                        {selectedCompany && (
                            <CompanyDetails
                                reloadInternships={reloadInternships}
                                reloadCompanies={reloadCompanies}
                                company={selectedCompany}
                            />
                        )}
                        {internships && (
                            <CompanyInternships reloadInternships={reloadInternships} internships={internships} />
                        )}
                    </>
                </StandardView.Right>
            </StandardView>
        </>
    );
};
