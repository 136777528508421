import { Formik } from 'formik';
import React from 'react';
import {
    NeedForAction,
    NeedForActionNewObjectiveAgreement,
    NeedForActionSuggestionTypes
} from '../../models/NeedForAction/Type';
import { ObjectiveAgreement } from '../../models/ObjectiveAgreement/Type';
import { SupportPlan } from '../../models/SupportPlans/Type';
import { UpdateNeedForActionForm } from '../UpdateNeedForActionForm/UpdateNeedForActionForm';

interface UpdateNeedForActionProps {
    needForAction: NeedForAction;
    defineObjectiveAgreement?: boolean;
    onGoBack: () => void;
    supportPlan?: SupportPlan | undefined;
    correspondingSuggestion?: NeedForActionSuggestionTypes;
    objectiveAgreements?: ObjectiveAgreement[];
    multipleAnswers?: boolean;
    onClose?: () => void;
    dontShowGoBack?: boolean;
}

export const UpdateNeedForAction = (props: UpdateNeedForActionProps) => {
    const initialFormValues: NeedForAction | NeedForActionNewObjectiveAgreement = props.defineObjectiveAgreement
        ? {
              ...props.needForAction,
              ...{
                  objectiveAgreement: {
                      name: props.needForAction.category,
                      text: '',
                      deadline: undefined,
                      achieved: 'open',
                      needForActionId: props.needForAction.id,
                      supportPlanId: props.supportPlan?.id,
                      date: new Date()
                  }
              },
              ...{
                  newProgressDate:
                      props.needForAction.progress && props.needForAction.progress.length > 0
                          ? new Date(props.needForAction.progress[props.needForAction.progress.length - 1].date)
                          : new Date()
              }
          }
        : {
              ...props.needForAction,
              ...{
                  newProgressDate:
                      props.needForAction.progress && props.needForAction.progress.length > 0
                          ? new Date(props.needForAction.progress[props.needForAction.progress.length - 1].date)
                          : new Date()
              }
          };

    return (
        <Formik
            initialValues={initialFormValues}
            validateOnChange={false}
            validateOnBlur={false}
            // We need to pass a handler here because formik expects one, but we do not want to use the formik submit mechanism
            onSubmit={() => console.log('Form submit.')}
        >
            {({ values, handleChange, setFieldValue }) => (
                <UpdateNeedForActionForm
                    dontShowGoBack={props.dontShowGoBack}
                    onClose={props.onClose}
                    multipleAnswers={props.multipleAnswers}
                    objectiveAgreements={props.objectiveAgreements}
                    correspondingSuggestion={props.correspondingSuggestion}
                    onGoBack={props.onGoBack}
                    defineObjectiveAgreement={props.defineObjectiveAgreement}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    values={values}
                    needForAction={props.needForAction}
                />
            )}
        </Formik>
    );
};
