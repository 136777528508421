import React from 'react';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { ParticipantContact } from '../../models/Participant/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Label } from '../Label/Label';
import { List } from '../List/List';
import './ParticipantContactsForm.css';
import { FormikErrors } from 'formik';
import { isObjectEmpty } from '../../helpers';
import { Checkbox } from '../Checkbox/Checkbox';
import { useParticipantsApi } from '../../api/useParticipantsApi';

interface ParticipantContactsFormProps {
    values: ParticipantContact;
    handleChange: FormikHandleChange;
    setFieldValue: FormikSetFieldValue;
    participantContact: ParticipantContact;
    reload: () => Promise<void>;
    errors: FormikErrors<ParticipantContact>;
}

export const ParticipantContactsForm = (props: ParticipantContactsFormProps) => {
    const { apiUpdateParticipantContact } = useParticipantsApi();

    /**
     * Update information at onBlur event
     */
    const onChangeBlur = async () => {
        if (JSON.stringify(props.participantContact) !== JSON.stringify(props.values) && isObjectEmpty(props.errors)) {
            try {
                await apiUpdateParticipantContact(props.participantContact.id, props.values);
                showSuccessMessage();
                props.reload();
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    /**
     * Check for enter key press
     * @param event
     */
    const onKeyEnter = (event: React.KeyboardEvent<HTMLDivElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            onChangeBlur();
        }
    };

    /**
     * Manually change confidentiality released information
     */
    const onChangeConfidentialityReleased = async (checked: boolean) => {
        if (isObjectEmpty(props.errors)) {
            try {
                await apiUpdateParticipantContact(props.participantContact.id, {
                    ...props.values,
                    ...{
                        confidentialityReleased: checked
                    }
                });
                showSuccessMessage();
                props.reload();
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    return (
        <div className="participant-details-container">
            <div className="participant-contacts-form-container">
                <div>
                    <Label className="user-details-general-title" size={2}>
                        Personalien
                    </Label>
                    <List
                        options={[
                            {
                                label: 'Nachname',
                                value: props.values.lastName,
                                onChange: props.handleChange,
                                name: 'lastName',
                                error: props.errors.lastName,
                                onBlur: onChangeBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Vorname',
                                value: props.values.firstName,
                                name: 'firstName',
                                onChange: props.handleChange,
                                error: props.errors.firstName,
                                onBlur: onChangeBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Titel',
                                value: props.values.title,
                                name: 'title',
                                onChange: props.handleChange,
                                onBlur: onChangeBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Einrichtung',
                                value: props.values.institution,
                                name: 'institution',
                                onChange: props.handleChange,
                                onBlur: onChangeBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Rolle für Teilnehmer',
                                value: props.values.role,
                                name: 'role',
                                onChange: props.handleChange,
                                onBlur: onChangeBlur,
                                onKeyDown: onKeyEnter
                            }
                        ]}
                    />
                </div>
                <div>
                    <Label className="user-details-general-title" size={2}>
                        Schweigepflichtsentbindung
                    </Label>
                    <List
                        options={[
                            {
                                label: 'liegt vor',
                                input: (
                                    <Checkbox
                                        value={props.values.confidentialityReleased}
                                        onChange={onChangeConfidentialityReleased}
                                    />
                                )
                            }
                        ]}
                    />
                </div>
                <div>
                    <Label className="user-details-general-title" size={2}>
                        Kontaktdaten
                    </Label>
                    <List
                        options={[
                            {
                                label: 'Straße und Hausnummer',
                                value: props.values.streetAndNumber,
                                onChange: props.handleChange,
                                name: 'streetAndNumber',
                                onBlur: onChangeBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'PLZ',
                                value: props.values.zipCode,
                                name: 'zipCode',
                                onChange: props.handleChange,
                                onBlur: onChangeBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Ort',
                                value: props.values.city,
                                name: 'city',
                                onChange: props.handleChange,
                                onBlur: onChangeBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Telefon',
                                value: props.values.phoneNumber,
                                name: 'phoneNumber',
                                onChange: props.handleChange,
                                onBlur: onChangeBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Fax',
                                value: props.values.fax,
                                name: 'fax',
                                onChange: props.handleChange,
                                onBlur: onChangeBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Mobil',
                                value: props.values.mobile,
                                name: 'mobile',
                                onChange: props.handleChange,
                                onBlur: onChangeBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'E-Mail',
                                value: props.values.email,
                                name: 'email',
                                onChange: props.handleChange,
                                onBlur: onChangeBlur,
                                onKeyDown: onKeyEnter
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};
