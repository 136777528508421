import React from 'react';
import { formatDate } from '../../models/Date/Date';
import { QmDocument } from '../../models/QmDocument/Types';
import { mapQmDocumentNeededAt } from '../../models/QmDocumentTemplate/QmDocumentTemplate';
import { QmDocumentTemplate } from '../../models/QmDocumentTemplate/Types';
import { Card } from '../Card/Card';
import { Label } from '../Label/Label';
import { List } from '../List/List';
import { Checkbox } from '../Checkbox/Checkbox';

interface QmDocumentDetailsProps {
    qmDocument: QmDocument | undefined;
    qmDocumentTemplate: QmDocumentTemplate | undefined;
}

export const QmDocumentDetails = (props: QmDocumentDetailsProps) => {
    return (
        <>
            <h5>{props.qmDocument?.name}</h5>
            <Card>
                <>
                    <Label className="user-details-general-title" size={2}>
                        ALLGEMEINE INFORMATIONEN
                    </Label>
                    {props.qmDocument && (
                        <List
                            readonly
                            options={[
                                {
                                    label: 'Titel',
                                    value: props.qmDocument.name,
                                    name: 'name'
                                },
                                {
                                    label: 'Ausdruck',
                                    value: mapQmDocumentNeededAt(props.qmDocumentTemplate?.neededAt),
                                    name: 'neededAt'
                                },
                                {
                                    label: 'Zuletzt aktualisiert am',
                                    value: formatDate(new Date(props.qmDocument.updatedAt)),
                                    name: 'neededAt'
                                },
                                {
                                    label: 'Zuletzt aktualisiert von',
                                    value: `${props.qmDocumentTemplate?.updatedBy.firstName} ${props.qmDocumentTemplate?.updatedBy.lastName}`,
                                    name: `name`
                                },
                                {
                                    label: 'beinhaltet Informationen zu Praktikum',
                                    input: (
                                        <Checkbox
                                            disabled
                                            className={'editable-readonly-item'}
                                            value={props.qmDocumentTemplate?.hasInternshipCompanyFields || false}
                                        />
                                    )
                                }
                            ]}
                        />
                    )}
                </>
            </Card>
        </>
    );
};
