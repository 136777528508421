import { FormikErrors } from 'formik';
import { KeyedMutator } from 'swr';
import { courseCategories, mapCourseCategories } from '../../models/Course/Course';
import { Course, CourseCategory, CourseSubmit } from '../../models/Course/Type';
import { formatDate } from '../../models/Date/Date';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { showSuccessMessage } from '../../models/Toasts/Toasts';
import { Datepicker } from '../Datepicker/Datepicker';
import { DropDown } from '../DropDown/DropDown';
import { List } from '../List/List';
import { useCoursesApi } from '../../api/useCourseApi';

interface CourseDetailsFormProps {
    course?: Course | undefined;
    values: Course | CourseSubmit;
    onChangeBlur?: () => void;
    handleChange: FormikHandleChange;
    errors: FormikErrors<Course | CourseSubmit>;
    setFieldValue: FormikSetFieldValue;
    newCourse?: CourseSubmit | undefined;
    reloadCourse?: KeyedMutator<Course>;
    reloadAllCourses?: KeyedMutator<Course[]>;
}

export const CourseDetailsForm = (props: CourseDetailsFormProps) => {
    const { apiUpdateCourse } = useCoursesApi();

    const onBlurUpdate = async () => {
        try {
            if (props.course && 'id' in props.values && JSON.stringify(props.course) !== JSON.stringify(props.values)) {
                await apiUpdateCourse(props.course?.id, props.values);
                showSuccessMessage();
                if (props.reloadAllCourses) {
                    await props.reloadAllCourses();
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onChangeCategory = async (course: CourseCategory) => {
        try {
            if (props.course && 'id' in props.values) {
                await apiUpdateCourse(props.course?.id, { ...props.values, category: course });
                showSuccessMessage();
                if (props.reloadAllCourses) {
                    await props.reloadAllCourses();
                }
            }
        } catch (e) {
            console.log(e);
        }

        props.setFieldValue('category', course);
    };

    const onChangeDate = async (date: Date) => {
        try {
            if (props.course && 'id' in props.values) {
                await apiUpdateCourse(props.course?.id, { ...props.values, date: date });
                showSuccessMessage();
                if (props.reloadAllCourses) {
                    await props.reloadAllCourses();
                }
            }
        } catch (e) {
            console.log(e);
        }

        props.setFieldValue('date', date);
    };

    const onChangeDateManually = (date: string | Date) => {
        props.setFieldValue('date', date);
    };

    return (
        <List
            key={props.course?.id}
            readonly={props.course?.archived}
            options={[
                {
                    label: 'Kategorie',
                    input: (
                        <DropDown
                            title={<div>{mapCourseCategories(props.values.category)}</div>}
                            disabled={props.course?.archived}
                        >
                            {courseCategories.map((category, index) => {
                                return (
                                    <div key={index} onClick={() => onChangeCategory(category)}>
                                        {mapCourseCategories(category)}
                                    </div>
                                );
                            })}
                        </DropDown>
                    )
                },
                {
                    label: 'Titel',
                    value: props.values.name,
                    onChange: props.handleChange,
                    name: 'name',
                    onBlur: onBlurUpdate,
                    error: props.errors.name
                },
                {
                    label: 'Datum',
                    value: props.values.date,
                    onChange: props.handleChange,
                    name: 'date',
                    onBlur: props.onChangeBlur,
                    input: (
                        <Datepicker
                            value={new Date(props.values.date)}
                            onChange={onChangeDate}
                            returnInputFieldDate={onChangeDateManually}
                        >
                            {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                <div
                                    className="details-form-list-calendar"
                                    onClick={props.course?.archived ? undefined : openDatepicker}
                                >
                                    <input
                                        className={'editable-item'}
                                        readOnly={props.course?.archived}
                                        value={formatDate(new Date(props.values.date))}
                                        onChange={onChangeDateInForm}
                                        onPaste={onPasteDate}
                                        onKeyDown={onPressEnterSubmit}
                                    />
                                </div>
                            )}
                        </Datepicker>
                    )
                },
                {
                    label: 'Inhalte',
                    value: props.values.description,
                    onChange: props.handleChange,
                    name: 'description',
                    onBlur: onBlurUpdate,
                    error: props.errors.description,
                    input: (
                        <textarea
                            name={'description'}
                            value={props.values.description}
                            onChange={props.handleChange}
                            onBlur={onBlurUpdate}
                        />
                    )
                }
            ]}
        />
    );
};
