import { useStore } from '../../store';
import { User } from './Types';

/**
 * Type for the user state.
 */
export interface UserState {
    // the current user
    currentUser: User | null;
}

/**
 * Hook to use the account state saved in the redux state.
 * The passed mapping function takes the account part of the state.
 * The return value of the function will be returned by the hook.
 */
export const useUser = <T>(mapping: (state: UserState) => T) => {
    return useStore<T>((x) => mapping(x.user));
};
