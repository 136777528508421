import { Modal } from '../Modal/Modal';
import { InputSelect } from '../InputSelect/InputSelect';
import { InternshipCompany } from '../../models/InternshipCompanies/Type';

interface InternshipCompanyModalProps {
    show: boolean | undefined;
    internshipCompanies: InternshipCompany[] | undefined;
    onClose: () => void;
    onChange: (arg: number | undefined) => void;
}

export const InternshipCompanyModal = (props: InternshipCompanyModalProps) => {
    return (
        <Modal
            show={props.show}
            header={'Betrieb auswählen'}
            buttons={{
                secondary: {
                    text: 'Abbrechen',
                    onClick: props.onClose
                },
                primary: {
                    text: 'Bestätigen',
                    onClick: props.onClose
                }
            }}
        >
            <div>Bitte wählen Sie einen Betrieb aus</div>
            <InputSelect
                dropdownOptions={props.internshipCompanies?.map((company) => {
                    return {
                        value: company.name,
                        id: company.id
                    };
                })}
                onChange={props.onChange}
            />
        </Modal>
    );
};
