import * as ActiveStorage from '@rails/activestorage';
import { ClipboardEvent, MouseEvent, PropsWithChildren, ReactElement, ReactNode, useCallback, useState } from 'react';
import Dropzone, { Accept, FileRejection } from 'react-dropzone';
import './ActiveStorageDropzone.css';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { EzOnRailsHttp } from '@d4us1/ez-on-rails-react';

/**
 * Describes a rails file blob.
 */
export interface RailsFileBlob {
    signedId?: string;
    url?: string;
    filename?: string;
}

/**
 * Props for the ActiveStorageDropzone component.
 */
interface ActiveStorageDropzoneProps {
    // Called if the files value changed.
    onChange: (files: RailsFileBlob[]) => void;

    // The name of the attribute in the formik values
    attributeName: string;

    // Used for edit action to initialy load the existing files to the dropzone
    files: RailsFileBlob[];

    // The text shown in the dropzone component. Use empty string to show no text
    textDropzone?: string;

    // The text shown in the pastezone component
    textPastezone?: string;

    // Indicates whether multiple files are allowed.
    multiple: boolean;

    // If multiple is true, this is the maximum number of allowed files
    maxFiles: number;

    // Called if the user tried to insert more files than the limit to maxFiles
    onMaxFilesError: (maxFiles: number) => void;

    // The maximum size of a file allowed in bytes
    maxSize: number;

    // Called if the user tried to insert files having more than the limited maxSize
    onMaxSizeError: (maxSize: number) => void;

    // One media type to filter allowed files. This can be used to allow only images for instance
    accept?: Accept;

    // Indicates if the paste zone should be available
    pasteZone?: boolean;

    // set custom icon for dropzone
    customIcon?: string;

    // set custom styling
    className?: string;

    // state that is needed for a check if a portrait picture was uploaded
    onHasPortraitPicture?: (value: boolean) => void;

    // indicate if the new pasted file should overwrite the old one
    overwriteOldFile?: boolean;

    // react children for this component
    children?: ReactElement | ReactElement[];
}

/**
 * Image Uploader component for active storage content having a react dropzone
 * and some input field for pasting content.
 */
export const ActiveStorageDropzone = (props: ActiveStorageDropzoneProps) => {
    const [uploadsInProgress, setUploadsInProgress] = useState<number>(0);
    const { backendUrl, apiVersion, authInfo } = useEzOnRails();

    /**
     * Converts teh specified relative path to a full url including the base url of the backend server.
     * @param path
     */
    const toFullBackendUrl = useCallback(
        (path: string): string => {
            if (!path.startsWith('/')) {
                path = `/${path}`;
            }

            return `${backendUrl}${path}`;
        },
        [backendUrl]
    );

    /**
     * Returns the relative url from the backend url to show the blob having the specified signedId and filename from the backend.
     *
     * @param signedId
     * @param filename
     */
    const blobShowUrl = useCallback((signedId: string, filename: string): string => {
        return `rails/active_storage/blobs/${signedId}/${filename}`;
    }, []);

    // standard upload icon for dropzone (from boostrap icons)
    const standardUploadIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-cloud-arrow-up"
            viewBox="0 0 16 16"
        >
            <path
                fillRule="evenodd"
                d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
            />
            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
        </svg>
    );

    /**
     * Removes the file having the given signedId from the server.
     *
     * @param signedId
     */
    const removeFileFromServer = (signedId: string) => {
        EzOnRailsHttp.client.delete(backendUrl, `active_storage/blobs/${signedId}`, null, authInfo).then();
    };

    /**
     * Removes the file having the specified signedId from the server and the dropzone,
     *
     * @param event The javascript event to stop other onclick callbacks.
     * @param signedId The signed id of the image.
     */
    const removeFile = (event: MouseEvent, signedId: string) => {
        // remove file from server to prevent garbage
        removeFileFromServer(signedId);

        // Remove from this component
        let newFiles = [...props.files];
        newFiles = newFiles.filter((file) => signedId !== file.signedId);
        props.onChange(newFiles);

        // Supress dropzones onclick callback
        event.stopPropagation();
    };

    /**
     * Called if some direct upload updates its progress.
     * Updates the state to rerender the view.
     *
     * @param event
     */
    const onDirectUploadProgress = (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
        if (event.loaded / event.total >= 0.9999999) {
            // console.log('Upload finished');
        }
    };

    /**
     * Called by the dropzone if some file is dropped into it.
     * Uploads the file to the active storage.
     *
     * @param acceptedFiles
     */
    const onDropAccepted = (acceptedFiles: File[]) => {
        // if we have no acceptedFiles, just leave
        if (!acceptedFiles.length) {
            return;
        }

        // Only make all the checks if overwriting files is not allowed
        if (!props.overwriteOldFile) {
            // Only allow as much as possible acceptedFiles allowed
            if (props.maxFiles) {
                const maxNewFiles = props.maxFiles - (props.files.length + uploadsInProgress);

                if (maxNewFiles < acceptedFiles.length) {
                    props.onMaxFilesError(props.maxFiles);
                }

                if (maxNewFiles <= 0) {
                    return;
                }

                acceptedFiles = acceptedFiles.slice(0, maxNewFiles);
            }
        }

        // Only allow files with limited size
        if (props.maxSize) {
            const sizeFilteredFiles = acceptedFiles.filter((file) => file.size <= props.maxSize);
            if (sizeFilteredFiles.length < acceptedFiles.length) {
                props.onMaxSizeError(props.maxSize);
            }

            acceptedFiles = sizeFilteredFiles;
        }

        // Update the number of uploads for feedback
        setUploadsInProgress((uploadsInProgress) => uploadsInProgress + acceptedFiles.length);
        // try to upload every file
        acceptedFiles.forEach((acceptedFile) => {
            // upload the file to the active storage
            // let uploader = new ActiveStorageUploader(onDirectUploadProgress, props.authInfo)
            const upload = new ActiveStorage.DirectUpload(
                acceptedFile,
                toFullBackendUrl('api/active_storage/blobs/create_direct_upload'),
                {
                    directUploadWillCreateBlobWithXHR: (request: XMLHttpRequest) => {
                        const httpHeader: Record<string, string> = EzOnRailsHttp.client.defaultHttpHeader(
                            authInfo,
                            apiVersion
                        );

                        Object.keys(httpHeader).forEach((key) => {
                            request.setRequestHeader(key, httpHeader[key]);
                        });

                        request.upload.addEventListener('progress', onDirectUploadProgress);
                    }
                }
            );
            upload.create((error: Error, blob: ActiveStorage.Blob) => {
                setUploadsInProgress((uploadsInProgress) => uploadsInProgress - 1);
                // if some error occurs, just print it to the console and do nothing else
                if (error) {
                    console.log('Image Error:', error);
                } else {
                    const file: RailsFileBlob = { signedId: blob.signed_id };
                    // create preview image, if this is an image, otherwise, just create a preview Text
                    if (acceptedFile.type.includes('image')) {
                        file.url = blobShowUrl(blob.signed_id, blob.filename);
                    } else {
                        file.filename = blob.filename;
                    }

                    // Set current files to render them
                    const newFiles = props.overwriteOldFile ? [] : props.files;
                    newFiles.push(file);
                    props.onChange([...newFiles]);
                }
            });
        });
    };

    /**
     * Called if some value is pasted into the input paste field.
     * Tries to catch the value. If it is a file, it will be pasted into
     * the dropzone.
     *
     * @param event The javascript event.
     */
    const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
        // if no clipboard was detected
        if (!event.clipboardData) {
            return;
        }

        // catch all clipboard items, if exists
        const items = event.clipboardData.items;
        if (items === undefined) {
            return;
        }

        const pastedFiles = [];
        for (const item of items) {
            // this item is no image
            if (props.accept && !item.type.match(props.accept.toString())) {
                continue;
            }

            const file = item.getAsFile();

            if (file) {
                pastedFiles.push(file);
            }
        }

        onDropAccepted(pastedFiles);
    };

    // preview of the current stated files
    const previews = props.files.map((file) => (
        <div
            key={file.signedId}
            className="card mb-4 w-25 animate__animated animate__fadeIn"
            style={{ flex: '0 0 auto' }}
        >
            <div className={'card-header p-1'}>
                <button
                    onClick={(event) => removeFile(event, file.signedId || '')}
                    type="button"
                    className="close"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div
                style={{ marginTop: '1rem' }}
                className={'d-flex justify-content-center align-items-center w-100 h-100'}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-file-earmark-word"
                    viewBox="0 0 16 16"
                >
                    <path d="M5.485 6.879a.5.5 0 1 0-.97.242l1.5 6a.5.5 0 0 0 .967.01L8 9.402l1.018 3.73a.5.5 0 0 0 .967-.01l1.5-6a.5.5 0 0 0-.97-.242l-1.036 4.144-.997-3.655a.5.5 0 0 0-.964 0l-.997 3.655L5.485 6.88z" />
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                </svg>
                {file.filename}
            </div>
        </div>
    ));

    // shows some indicator for some incoming files
    const progressSpinners: ReactNode[] = [];

    for (let i = 0; i < uploadsInProgress; i++) {
        progressSpinners.push(
            <div key={i} className="card mb-4 w-25" style={{ flex: '0 0 auto' }}>
                <div className={'d-flex justify-content-center align-items-center w-100 h-100'}>
                    <div className="text-center p-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Called if one or more files were rejected by the dropzone.
     * Checks why the files were rejected and calls the related callbacks in the props.
     *
     * @param fileRejections
     */
    const onDropzoneRejection = (fileRejections: FileRejection[]) => {
        const maxSizeRejection = fileRejections.find((fileRejection) => fileRejection.file.size > props.maxSize);

        if (maxSizeRejection) {
            props.onMaxSizeError(props.maxSize);
        } else {
            props.onMaxFilesError(props.maxFiles);
        }
    };

    // dropzone having a drop area
    return (
        <div>
            {props.pasteZone && (
                <input
                    type="text"
                    className="w-100 p-2 active-storage-dropzone-pastezone-container"
                    value={props.textPastezone || 'Copy and paste some files here'}
                    onPaste={onPaste}
                    readOnly
                />
            )}
            <Dropzone
                onDropAccepted={onDropAccepted}
                multiple={props.multiple}
                maxFiles={props.maxFiles}
                maxSize={props.maxSize}
                onDropRejected={onDropzoneRejection}
                accept={props.accept}
            >
                {({
                    getRootProps,
                    getInputProps
                }: {
                    getRootProps: () => PropsWithChildren<unknown>;
                    getInputProps: () => PropsWithChildren<unknown>;
                }) =>
                    props.children ? (
                        <section>
                            <div {...getRootProps()}>
                                {/* the file input field, but invisible */}
                                <input {...getInputProps()} />
                                {props.children}
                            </div>
                        </section>
                    ) : (
                        <section>
                            <div
                                {...getRootProps()}
                                className={`p-4 active-storage-dropzone-dropzone-container ${props.className}`}
                            >
                                {/* the file input field, but invisible */}
                                <input {...getInputProps()} />

                                <p className={'m-0'}>
                                    {props.textDropzone || "Drag 'n' drop some files here, or click to select files"}
                                </p>

                                {/* The preview of the current uploaded files */}
                                {previews.length > 0 ? (
                                    <aside className={'card-deck justify-content-center w-100 m-4'}>{previews}</aside>
                                ) : (
                                    <div className={'m-0'}>{props.customIcon || standardUploadIcon}</div>
                                )}
                                {progressSpinners.length > 0 && (
                                    <aside className={'card-deck justify-content-center w-100 m-4'}>
                                        {progressSpinners}
                                    </aside>
                                )}
                            </div>
                        </section>
                    )
                }
            </Dropzone>
        </div>
    );
};
