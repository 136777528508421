import { ReactNode } from 'react';
import './StandardView.css';
import { StandardViewLeft } from './StandardViewLeft/StandardViewLeft';
import { StandardViewRight } from './StandardViewRight/StandardViewRight';

interface StandardViewProps {
    children: ReactNode;
    className?: string;
}
export const StandardView = (props: StandardViewProps) => {
    return <div className={`standard-view ${props.className}`}>{props.children}</div>;
};

StandardView.Left = StandardViewLeft;
StandardView.Right = StandardViewRight;
