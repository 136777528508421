import React from 'react';
import './ActivityIndicator.css';

/**
 * A small indicator showing that some work is in progress.
 *
 * @constructor
 */
export const ActivityIndicator = () => {
    return <div className="activity-indicator" />;
};
