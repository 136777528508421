import { Formik } from 'formik';
import { useAppSelector } from '../../hooks';
import { Measure } from '../../models/Measure/Types';
import { ParticipantDeactivationSubmit, ParticipantWithOneMeasure } from '../../models/Participant/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Modal } from '../Modal/Modal';
import { ParticipantArchiveForm } from '../ParticipantArchiveForm/ParticipantArchiveForm';
import { useMeasuresApi } from '../../api/useMeasuresApi';

interface ParticipantArchiveModalProps {
    show: boolean;
    onClose: () => void;
}

export const ParticipantArchiveModal = (props: ParticipantArchiveModalProps) => {
    const participant: ParticipantWithOneMeasure | undefined = useAppSelector(
        (state) => state.participant.selectedParticipant
    );
    const measure: Measure | undefined = useAppSelector((state) => state.measure.selectedMeasure);
    const { apiArchiveParticipantForMeasure } = useMeasuresApi();

    /**
     * Archive user in measure
     */
    const onArchiveUser = async (values: ParticipantDeactivationSubmit) => {
        if (participant && participant.id && measure?.id && values) {
            try {
                await apiArchiveParticipantForMeasure(measure.id, participant.id, values);
                showSuccessMessage('Teilnehmer wurde in Maßnahme archiviert');
                props.onClose();
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    const initialArchiveFormValues: ParticipantDeactivationSubmit = {
        date: new Date(),
        explanation: '',
        jobCompany: '',
        jobStartedAt: undefined,
        jobType: '',
        measureGoalAchieved: false,
        notes: '',
        reason: undefined,
        summary: ''
    };

    return (
        <Formik
            initialValues={initialArchiveFormValues}
            onSubmit={(values: ParticipantDeactivationSubmit) => onArchiveUser(values)}
            enableReinitialize
        >
            {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
                <Modal
                    show={props.show}
                    header={'Teilnehmer in Maßnahme archivieren'}
                    buttons={{
                        primary: {
                            text: 'Teilnehmer archivieren',
                            onClick: handleSubmit
                        },
                        secondary: {
                            text: 'Abbrechen',
                            onClick: props.onClose
                        }
                    }}
                >
                    <ParticipantArchiveForm
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                    />
                </Modal>
            )}
        </Formik>
    );
};
