import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Participant } from '../../models/Participant/Types';
import { Avatar } from '../Avatar/Avatar';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { ObjectList } from '../ObjectList/ObjectList';
import './ParticipantsList.css';
import { useAppSelector } from '../../hooks';

interface ParticipantsProps {
    onSelectParticipant: (arg: Participant) => void;
    selectedParticipant?: Participant;
    activeParticipants: Participant[] | undefined;
    inactiveParticipants: Participant[] | undefined;
    onCreateNewParticipant: () => void;
}

export const ParticipantsList = (props: ParticipantsProps) => {
    const [menuSelected, setMenuSelected] = useState<string>('active');
    const [activeParticipants, setActiveParticipants] = useState<Participant[]>();
    const [inactiveParticipants, setInactiveParticipants] = useState<Participant[]>();
    const participant = useAppSelector((x) => x.participant.selectedParticipant);

    useEffect(() => {
        setActiveParticipants(props.activeParticipants);
    }, [props.activeParticipants]);

    useEffect(() => {
        setInactiveParticipants(props.inactiveParticipants);
    }, [props.inactiveParticipants]);

    const [showSearch, setShowSearch] = useState<boolean>(false);

    const onClickOpenSearch = () => {
        setShowSearch(!showSearch);
    };

    /**
     * Called if the selected participant changes.
     * Calls the callback given by the props.
     */
    const onClickGetData = useCallback(
        (_index: number | string, item: Participant) => {
            props.onSelectParticipant(item);
        },
        // This is because formally the props object is referenced as "this" in the callback, hence formally old data
        // could be used. We do not use "this", hence this is ok here.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.onSelectParticipant]
    );

    const onChangeSearch = (event: ChangeEvent, value?: string) => {
        // create the filtered items based on the participant's input
        if (menuSelected === 'active' && value) {
            const activeParticipantsBuffer = props.activeParticipants?.filter(
                (item: Participant) =>
                    item.firstName.toLowerCase().includes(value?.toLowerCase()) ||
                    item.lastName.toLowerCase().includes(value?.toLowerCase())
            );
            setActiveParticipants(activeParticipantsBuffer);
        } else if (value) {
            const inActiveParticipantsBuffer = props.inactiveParticipants?.filter(
                (item: Participant) =>
                    item.firstName.toLowerCase().includes(value?.toLowerCase()) ||
                    item.lastName.toLowerCase().includes(value?.toLowerCase())
            );
            setInactiveParticipants(inActiveParticipantsBuffer);
        } else {
            if (menuSelected === 'active') {
                setActiveParticipants(props.activeParticipants);
            } else {
                setInactiveParticipants(props.inactiveParticipants);
            }
        }
    };

    /**
     * if no item is selected, select the first item.
     * If some participant is selected, but the correct menu (inactive or active) is not selected, select it.
     */
    useEffect(() => {
        if (!props.selectedParticipant && activeParticipants && activeParticipants.length > 0) {
            onClickGetData(0, activeParticipants[0]);
        }

        if (props.selectedParticipant) {
            if (activeParticipants?.some((active) => active.id === props.selectedParticipant?.id)) {
                setMenuSelected('active');
            } else if (inactiveParticipants?.some((active) => active.id === props.selectedParticipant?.id)) {
                setMenuSelected('inactive');
            }
        }
    }, [activeParticipants, inactiveParticipants, props.selectedParticipant, onClickGetData, participant]);

    return (
        <ObjectList>
            <ObjectList.Head>
                <div className="participants-list-head-container">
                    <div className="participants-list-head">
                        <div className="p2-medium">Teilnehmer</div>
                        <div className="participants-list-head-search">
                            <Button
                                type={'secondary'}
                                buttonStyle={'link'}
                                size={'small'}
                                firstIcon={<Icon type={'Search'} />}
                                onClick={onClickOpenSearch}
                            />
                            <Button
                                type={'primary'}
                                size={'small'}
                                buttonStyle={'filled'}
                                firstIcon={<Icon type={'Plus'} />}
                                onClick={props.onCreateNewParticipant}
                            />
                        </div>
                    </div>
                    {showSearch && (
                        <Input
                            icon={<Icon type={'Search'} />}
                            placeholder={'Mitarbeiter suchen'}
                            onChange={onChangeSearch}
                        />
                    )}
                </div>
                <div className="participants-list-navigation">
                    <div
                        className={`participants-list-navigation-item label-2 ${menuSelected === 'active' && 'active'}`}
                        onClick={() => setMenuSelected('active')}
                    >
                        AKTIV ({activeParticipants?.length})
                    </div>
                    <div
                        className={`participants-list-navigation-item label-2 ${
                            menuSelected === 'inactive' && 'active'
                        }`}
                        onClick={() => setMenuSelected('inactive')}
                    >
                        ARCHIVIERT ({inactiveParticipants?.length})
                    </div>
                </div>
            </ObjectList.Head>
            {menuSelected === 'active' && (
                <ObjectList.Body>
                    <>
                        <div className={'seperator'} />
                        {activeParticipants?.map((item, index) => {
                            return (
                                <ObjectList.Item
                                    key={index}
                                    onClick={onClickGetData}
                                    value={{ index, item }}
                                    selected={props.selectedParticipant?.id === item.id}
                                >
                                    <div className="participants-list-object-list-item-container">
                                        <div className="participants-list-object-list-item">
                                            {item.id && (
                                                <Avatar url={item.avatarUrl ? item.avatarUrl : null} size={'small'} />
                                            )}
                                            <div className="participants-list-object-list-item-text-description">
                                                <div>
                                                    {item.lastName}, {item.firstName}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ObjectList.Item>
                            );
                        })}
                    </>
                </ObjectList.Body>
            )}
            {menuSelected === 'inactive' && (
                <ObjectList.Body>
                    <div className="participants-list">
                        {inactiveParticipants?.map((item, index) => {
                            return (
                                <ObjectList.Item
                                    key={index}
                                    onClick={onClickGetData}
                                    value={{ index, item }}
                                    selected={props.selectedParticipant?.id === item.id}
                                >
                                    <div className="participants-list-object-list-item-container">
                                        <div className="participants-list-object-list-item">
                                            {item.id && (
                                                <Avatar url={item.avatarUrl ? item.avatarUrl : null} size={'medium'} />
                                            )}
                                            <div className="participants-list-object-list-item-text-description">
                                                <div>
                                                    {item.lastName}, {item.firstName}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ObjectList.Item>
                            );
                        })}
                    </div>
                </ObjectList.Body>
            )}
        </ObjectList>
    );
};
