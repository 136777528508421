import {
    ObjectiveAgreement,
    ObjectiveAgreementSearch,
    ObjectiveAgreementSubmit
} from '../models/ObjectiveAgreement/Type';
import { EzOnRailsSearchFilterRequest } from '../models/Search/Type';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Returns methods to communicate with the backend related to ObjectiveAgreements.
 */
export const useObjectiveAgreementsApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API to create an objective agreement
     *
     * @param objectiveAgreement
     */
    const apiCreateObjectiveAgreement = useCallback(
        async (objectiveAgreement: ObjectiveAgreementSubmit): Promise<ObjectiveAgreement> => {
            return call<ObjectiveAgreementSubmit, ObjectiveAgreement>(
                'objective_agreements',
                'POST',
                objectiveAgreement
            );
        },
        [call]
    );

    const apiObjectiveAgreementSearch = useCallback(
        async (filter: EzOnRailsSearchFilterRequest): Promise<ObjectiveAgreementSearch> => {
            return call<EzOnRailsSearchFilterRequest, ObjectiveAgreementSearch>(
                'objective_agreements/search',
                'POST',
                filter
            );
        },
        [call]
    );

    const apiUpdateObjectiveAgreement = useCallback(
        async (
            objectiveAgreementId: number,
            objectiveAgreement: Partial<ObjectiveAgreement>
        ): Promise<ObjectiveAgreement> => {
            return call<Partial<ObjectiveAgreement>, ObjectiveAgreement>(
                `objective_agreements/${objectiveAgreementId}`,
                'PATCH',
                objectiveAgreement
            );
        },
        [call]
    );

    const apiDeleteObjectiveAgreement = useCallback(
        async (objectiveAgreementId: number) => {
            return call<void, unknown>(`objective_agreements/${objectiveAgreementId}`, 'DELETE');
        },
        [call]
    );

    return {
        apiCreateObjectiveAgreement: apiCreateObjectiveAgreement,
        apiObjectiveAgreementSearch: apiObjectiveAgreementSearch,
        apiUpdateObjectiveAgreement: apiUpdateObjectiveAgreement,
        apiDeleteObjectiveAgreement: apiDeleteObjectiveAgreement
    };
};
