import { FormikErrors } from 'formik';
import React, { useState } from 'react';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { letterRecipients, mapLetterRecipients } from '../../models/LetterTemplates/LetterTemplate';
import { LetterRecipient, LetterTemplateSubmit } from '../../models/LetterTemplates/Type';
import { ActiveStorageDropzone } from '../ActiveStorageDropzone/ActiveStorageDropzone';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
import { DropDown } from '../DropDown/DropDown';
import { FormTextArea } from '../FormTextArea/FormTextArea';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';
import { List } from '../List/List';

interface NewLetterTemplateDetailsFormProps {
    handleSubmit: () => void;
    values: LetterTemplateSubmit;
    errors: FormikErrors<LetterTemplateSubmit>;
    setFieldValue: FormikSetFieldValue;
    handleChange: FormikHandleChange;
    newLetterTemplate: LetterTemplateSubmit | undefined;
}

export const NewLetterTemplateDetailsForm = (props: NewLetterTemplateDetailsFormProps) => {
    const [editLetterTemplate, setEditLetterTemplate] = useState<boolean>();

    const onChangeEditLetterTemplate = () => {
        setEditLetterTemplate(!editLetterTemplate);
    };

    const onSaveLetterTemplate = () => {
        onChangeEditLetterTemplate();
        props.handleSubmit();
    };

    const onChangeLetterRecipient = (recipient: LetterRecipient) => {
        props.setFieldValue('recipient', recipient);
    };

    return (
        <>
            <div className="company-details-head">
                <h5>{props.values?.name}</h5>
                <Button
                    type={'primary'}
                    size={'medium'}
                    buttonStyle={'outline'}
                    firstIcon={<Icon type="DocumentText" />}
                    text={'Briefvorlage speichern'}
                    onClick={onSaveLetterTemplate}
                />
            </div>
            <Card>
                <div className="user-details-box-details">
                    <Label className="user-details-general-title" size={2}>
                        ALLGEMEINE INFORMATIONEN
                    </Label>
                    <div className="user-details-general-information">
                        <div className="user-details-general-information-container-text">
                            <List
                                readonly={editLetterTemplate}
                                options={[
                                    {
                                        label: 'Name',
                                        value: props.values.name,
                                        onChange: props.handleChange,
                                        name: 'name'
                                    },
                                    {
                                        label: 'Empfänger',
                                        value: props.values.recipient,
                                        onChange: props.handleChange,
                                        name: 'recipient',
                                        input: (
                                            <DropDown title={<div>{mapLetterRecipients(props.values.recipient)}</div>}>
                                                {letterRecipients.map((letterRecipient, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            onClick={() => onChangeLetterRecipient(letterRecipient)}
                                                        >
                                                            {mapLetterRecipients(letterRecipient)}
                                                        </div>
                                                    );
                                                })}
                                            </DropDown>
                                        )
                                    }
                                ]}
                            />
                            <FormTextArea
                                value={props.values.notes}
                                placeholder={'Notizen'}
                                size={'medium'}
                                onChange={props.handleChange}
                                name={'notes'}
                            />
                            <ActiveStorageDropzone
                                className={'qm-document-template-form-upload'}
                                onChange={(files) =>
                                    props.setFieldValue('document', files.length > 0 ? files[0].signedId : '')
                                }
                                attributeName="document"
                                textDropzone={
                                    editLetterTemplate
                                        ? 'Ändern Sie hier Ihre Datei'
                                        : 'Laden Sie hier eine neue Datei hoch'
                                }
                                files={props.values.document ? [{ signedId: props.values.document }] : []}
                                multiple={false}
                                maxFiles={1}
                                onMaxFilesError={() => alert('Es ist nur eine Datei erlaubt')}
                                maxSize={10485760}
                                onMaxSizeError={() => alert('Die Datei ist zu groß. Sie darf max. 10MB groß sein.')}
                            />
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
};
