import { FormikErrors } from 'formik';
import React from 'react';
import { KeyedMutator } from 'swr';
import { mapBoolean } from '../../helpers';
import { formatDate } from '../../models/Date/Date';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { mapMeasureParticipantDeactivationReason } from '../../models/Measure/Measure';
import {
    ParticipantDeactivationReason,
    ParticipantWithOneMeasure,
    ParticipantWithPresence
} from '../../models/Participant/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Datepicker } from '../Datepicker/Datepicker';
import { DropDown } from '../DropDown/DropDown';
import { Input } from '../Input/Input';
import { List } from '../List/List';
import './ParticipantArchivedInformationForm.css';
import { useMeasuresApi } from '../../api/useMeasuresApi';
import { participantDeactivationReasons } from '../../models/Participant/Participant';

interface ParticipantArchivedInformationFormProps {
    values: ParticipantWithOneMeasure | undefined;
    errors: FormikErrors<ParticipantWithOneMeasure>;
    handleChange: FormikHandleChange;
    setFieldValue: FormikSetFieldValue;
    participant: ParticipantWithOneMeasure | undefined;
    reloadParticipant: KeyedMutator<ParticipantWithPresence[]>;
}

export const ParticipantArchivedInformationForm = (props: ParticipantArchivedInformationFormProps) => {
    const { apiUpdateMeasureParticipant } = useMeasuresApi();

    /**
     * Show success message and reload participant
     */
    const success = () => {
        showSuccessMessage();
        props.reloadParticipant();
    };

    /**
     * Trigger participant change if in one of the fields an onBlur event is triggered
     */
    const onChangeBlur = async () => {
        if (
            props.participant &&
            props.values?.measuresParticipants &&
            JSON.stringify(props.values.measuresParticipants) !== JSON.stringify(props.participant.measuresParticipants)
        ) {
            try {
                await apiUpdateMeasureParticipant(
                    props.participant?.measuresParticipants.id,
                    props.values?.measuresParticipants
                );
                success();
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    /**
     * trigger onblur event in case user hits the enter key in an input field
     * @param event
     */
    const onKeyEnter = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    };

    /**
     * Change jobStartedAt manually if info is changed in the datepicker
     * @param value
     */
    const onChangeJobStartedAt = async (value: Date | string | undefined) => {
        if (props.participant && props.values?.measuresParticipants) {
            try {
                props.setFieldValue('measuresParticipants[0].jobStartedAt', value == null ? '' : value);
                await apiUpdateMeasureParticipant(props.participant?.measuresParticipants.id, {
                    ...props.values?.measuresParticipants,
                    ...{ jobStartedAt: value }
                });
                success();
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    /**
     * Change archive reason manually when the dropdown is selected
     * @param value
     */
    const onChangeArchiveReason = async (value: ParticipantDeactivationReason) => {
        if (props.participant && props.values?.measuresParticipants) {
            try {
                props.setFieldValue('measuresParticipants[0].inactiveReason', value);
                await apiUpdateMeasureParticipant(props.participant?.measuresParticipants.id, {
                    ...props.values?.measuresParticipants,
                    ...{ inactiveReason: value }
                });
                success();
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    /**
     * Change inactiveDate manually if the date in the datepicker changes
     * @param value
     */
    const onChangeInactiveDate = async (value: Date | string) => {
        if (props.participant && props.values?.measuresParticipants) {
            try {
                props.setFieldValue('measuresParticipants[0].inactiveDate', value);
                await apiUpdateMeasureParticipant(props.participant?.measuresParticipants.id, {
                    ...props.values?.measuresParticipants,
                    ...{ inactiveDate: value }
                });
                success();
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    /**
     * Change manually if the measure goal is achived
     * @param value
     */
    const onChangeMeasureGoalAchieved = async (value: boolean) => {
        if (props.participant && props.values?.measuresParticipants) {
            try {
                props.setFieldValue('measuresParticipants[0].measureGoalAchieved', value);
                await apiUpdateMeasureParticipant(props.participant?.measuresParticipants.id, {
                    ...props.values?.measuresParticipants,
                    ...{ measureGoalAchieved: value }
                });
                success();
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    return (
        <div className={'participant-archived-information-form'} key={props.values?.id}>
            {props.values && props.values.measuresParticipants.inactive && (
                <List
                    options={[
                        {
                            label: 'Austrittsdatum',
                            input: (
                                <Datepicker
                                    value={new Date(props.values.measuresParticipants.inactiveDate)}
                                    onChange={onChangeInactiveDate}
                                    returnInputFieldDate={onChangeInactiveDate}
                                >
                                    {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                        <div className="details-form-list-calendar" onClick={openDatepicker}>
                                            {props.values && (
                                                <input
                                                    className={'editable-item'}
                                                    value={formatDate(
                                                        new Date(props.values.measuresParticipants.inactiveDate)
                                                    )}
                                                    onChange={onChangeDateInForm}
                                                    onPaste={onPasteDate}
                                                    onKeyDown={onPressEnterSubmit}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Datepicker>
                            )
                        },
                        {
                            label: 'Austrittsgrund',
                            value: mapMeasureParticipantDeactivationReason(
                                props.values.measuresParticipants.inactiveReason
                            ),
                            input: (
                                <DropDown
                                    title={
                                        <div>
                                            {mapMeasureParticipantDeactivationReason(
                                                props.values.measuresParticipants.inactiveReason
                                            )}
                                        </div>
                                    }
                                >
                                    {participantDeactivationReasons.map((value, index) => {
                                        return (
                                            <div key={index} onClick={() => onChangeArchiveReason(value)}>
                                                {mapMeasureParticipantDeactivationReason(value)}
                                            </div>
                                        );
                                    })}
                                </DropDown>
                            )
                        },
                        {
                            label: 'Beschäftigungsbeginn',
                            name: 'jobStartedAt',
                            input: (
                                <Datepicker
                                    value={
                                        props.values.measuresParticipants.jobStartedAt
                                            ? new Date(props.values.measuresParticipants.jobStartedAt)
                                            : undefined
                                    }
                                    onChange={onChangeJobStartedAt}
                                    returnInputFieldDate={onChangeJobStartedAt}
                                >
                                    {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                        <div className="details-form-list-calendar" onClick={openDatepicker}>
                                            {props.values && (
                                                <input
                                                    className={'editable-item'}
                                                    value={formatDate(
                                                        props.values.measuresParticipants.jobStartedAt
                                                            ? new Date(props.values.measuresParticipants.jobStartedAt)
                                                            : undefined
                                                    )}
                                                    onChange={onChangeDateInForm}
                                                    onPaste={onPasteDate}
                                                    onKeyDown={onPressEnterSubmit}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Datepicker>
                            )
                        },
                        {
                            label: 'Berufsbezeichnung',
                            value: props.values.measuresParticipants.jobType,
                            onChange: props.handleChange,
                            name: 'measuresParticipants[0].jobType',
                            onBlur: onChangeBlur,
                            onKeyDown: onKeyEnter
                        },
                        {
                            label: 'Betrieb',
                            value: props.values.measuresParticipants.jobCompany,
                            onChange: props.handleChange,
                            name: 'measuresParticipants[0].jobCompany',
                            onBlur: onChangeBlur,
                            onKeyDown: onKeyEnter
                        }
                    ]}
                />
            )}
            <hr className={'hr-line'} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                {props.values && (
                    <List
                        options={[
                            {
                                label: 'Maßnahmenziel',
                                onChange: props.handleChange,
                                name: 'measuresParticipants[0].measureGoalAchieved',
                                input: (
                                    <DropDown
                                        title={
                                            <div>
                                                {mapBoolean(props.values.measuresParticipants.measureGoalAchieved)}
                                            </div>
                                        }
                                    >
                                        {[true, false].map((value, index) => {
                                            return (
                                                <div key={index} onClick={() => onChangeMeasureGoalAchieved(value)}>
                                                    {mapBoolean(value)}
                                                </div>
                                            );
                                        })}
                                    </DropDown>
                                )
                            }
                        ]}
                    />
                )}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div className={'list-element-label p4-medium'}>Begründung</div>
                    <Input
                        name={'measuresParticipants[0].explanation'}
                        onChange={props.handleChange}
                        onBlur={onChangeBlur}
                        onKeyDown={onKeyEnter}
                        value={props.values?.measuresParticipants.explanation}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div className={'list-element-label p4-medium'}>Vermerk zum Austritt</div>
                    <Input
                        name={'measuresParticipants[0].notes'}
                        onChange={props.handleChange}
                        onBlur={onChangeBlur}
                        onKeyDown={onKeyEnter}
                        value={props.values?.measuresParticipants.notes}
                    />
                </div>
            </div>
        </div>
    );
};
