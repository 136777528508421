import { FormikValues } from 'formik';
import { Datepicker } from '../../../components/Datepicker/Datepicker';
import './CreateMeasureStart.css';

interface CreateMeasureStartProps {
    setFieldValue: (arg: string, arg1: unknown) => void;
    values: FormikValues;
    name?: string;
}

export const CreateMeasureStart = (props: CreateMeasureStartProps) => {
    const changeDateValueInForm = (date: Date) => {
        props.setFieldValue('startAt', date);
    };

    return (
        <>
            <h5 className="create-measure-start-title">Wann beginnt die Maßnahme?</h5>
            <Datepicker
                label={'Beginn der Maßnahme'}
                onChange={changeDateValueInForm}
                initialDate={props.values.startAt}
                value={props.values.startAt}
            />
        </>
    );
};
