import { List } from '../../List/List';
import { Datepicker } from '../../Datepicker/Datepicker';
import { formatDate } from '../../../models/Date/Date';
import { Card } from '../../Card/Card';
import React from 'react';
import { ParticipantNote } from '../../../models/Participant/Types';
import { FormikHandleChange, FormikSetFieldValue } from '../../../models/Formik/Type';
import { showSuccessMessage } from '../../../models/Toasts/Toasts';
import { KeyedMutator } from 'swr';
import './ParticipantDetailsForm.css';
import { Textarea } from '../../Textarea/Textarea';
import { Checkbox } from '../../Checkbox/Checkbox';
import { useNotesApi } from '../../../api/useNotesApi';

interface ParticipantNotesDetailsFormProps {
    values: ParticipantNote;
    handleChange: FormikHandleChange;
    setFieldValue: FormikSetFieldValue;
    reloadNotes: KeyedMutator<ParticipantNote[]>;
    participantNote: ParticipantNote;
}
export const ParticipantNotesDetailsForm = (props: ParticipantNotesDetailsFormProps) => {
    const { apiUpdateParticipantNote } = useNotesApi();

    /**
     * Reload notes and show a success message
     */
    const success = () => {
        props.reloadNotes();
        showSuccessMessage('Änderung erfolgreich');
    };

    /**
     * Change note date
     * @param date
     */
    const onChangeDate = async (date: Date) => {
        await apiUpdateParticipantNote(props.values.id, { ...props.values, date: date });
        props.setFieldValue('date', date);
        success();
    };

    /**
     * update participant note in general if it has changed
     */
    const updateParticipantNote = async (value: 'heading' | 'text') => {
        if (props.values[value] !== props.participantNote[value]) {
            await apiUpdateParticipantNote(props.values.id, { ...props.values });
            success();
        }
    };

    /**
     * change participant highlight
     */
    const onChangeHighlight = async (checked: boolean) => {
        await apiUpdateParticipantNote(props.values.id, { ...props.values, highlight: checked });
        props.setFieldValue('highlight', checked);
        success();
    };

    return (
        <Card key={props.values.id}>
            <List
                options={[
                    {
                        label: 'Titel',
                        value: props.values.heading,
                        name: 'heading',
                        onChange: props.handleChange,
                        onBlur: () => updateParticipantNote('heading')
                    },
                    {
                        label: 'Erfassungsdatum',
                        value: props.values.date,
                        input: (
                            <Datepicker value={new Date(props.values.date)} onChange={onChangeDate}>
                                {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                    <div className="details-form-list-calendar" onClick={openDatepicker}>
                                        {
                                            <input
                                                className={'editable-item'}
                                                value={formatDate(new Date(props.values.date))}
                                                onChange={onChangeDateInForm}
                                                onPaste={onPasteDate}
                                                onKeyDown={onPressEnterSubmit}
                                            />
                                        }
                                    </div>
                                )}
                            </Datepicker>
                        )
                    },
                    {
                        label: 'Wichtig',
                        input: (
                            <Checkbox
                                onChange={onChangeHighlight}
                                value={props.values.highlight}
                                className={'editable-readonly-item'}
                            />
                        )
                    }
                ]}
            />
            <Textarea
                rows={10}
                onBlur={() => updateParticipantNote('text')}
                placeholder={'Notizen'}
                value={props.values.text}
                name={'text'}
                onChange={props.handleChange}
            />
        </Card>
    );
};
