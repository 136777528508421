import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { formatDate } from '../../models/Date/Date';
import { NeedForAction } from '../../models/NeedForAction/Type';
import { ObjectiveAgreement } from '../../models/ObjectiveAgreement/Type';
import { ParticipantWithOneMeasure } from '../../models/Participant/Types';
import { SupportPlan } from '../../models/SupportPlans/Type';
import { throwError } from '../../models/Toasts/Toasts';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { ObjectList } from '../ObjectList/ObjectList';
import { ObjectListItem } from '../ObjectListItem/ObjectListItem';
import { StandardView } from '../StandardView/StandardView';
import { SupportPlanDetails } from '../SupportPlanDetails/SupportPlanDetails';
import { UpdateNeedForAction } from '../UpdateNeedForAction/UpdateNeedForAction';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { useSupportPlansApi } from '../../api/useSupportPlansApi';
import { useParticipantsApi } from '../../api/useParticipantsApi';
import { useNeedForActionsApi } from '../../api/useNeedForActionsApi';

interface ParticipantDetailInformationViewSupportPlansProps {
    participant: ParticipantWithOneMeasure | undefined;
}

export const ParticipantDetailInformationViewSupportPlans = (
    props: ParticipantDetailInformationViewSupportPlansProps
) => {
    const participant = useAppSelector((x) => x.participant.selectedParticipant);
    const measure = useAppSelector((x) => x.measure.selectedMeasure);

    const [selectedSupportPlan, setSelectedSupportPlan] = useState<SupportPlan>();
    const [supportPlans, setSupportPlans] = useState<SupportPlan[]>([]);
    const [reassignObjectiveAgreement, setReassignObjectiveAgreement] = useState<NeedForAction>();
    const { apiGetSupportPlanByParticipantId } = useParticipantsApi();
    const { apiCreateSupportPlans } = useSupportPlansApi();
    const { apiGetNeedForActionById } = useNeedForActionsApi();

    /**
     * Fetch all support plans
     */
    const fetchSupportPlans = useCallback(async () => {
        try {
            if (props.participant) {
                setSupportPlans(await apiGetSupportPlanByParticipantId(props.participant?.id, measure?.id));
            }
        } catch (e) {
            console.log(e);
        }
    }, [apiGetSupportPlanByParticipantId, measure?.id, props.participant]);

    useEffect(() => {
        fetchSupportPlans().then();
    }, [fetchSupportPlans]);

    /**
     * Create new support plan
     */
    const onCreateNewSupportPlan = async () => {
        try {
            if (props.participant && measure) {
                const newSupportPlan = await apiCreateSupportPlans({
                    name: new Date(),
                    participantId: props.participant.id,
                    measureId: measure.id
                });
                if (newSupportPlan && supportPlans) {
                    supportPlans.push(newSupportPlan);
                    setSupportPlans([...supportPlans]);
                    setSelectedSupportPlan(newSupportPlan);
                }
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * If the selected support plan does not exist anymore, remove it from the state
     */
    useEffect(() => {
        if (!supportPlans.some((plan) => plan.id === selectedSupportPlan?.id)) {
            setSelectedSupportPlan(undefined);
        }
    }, [selectedSupportPlan, supportPlans]);

    /**
     * if the support plans are fetched, check if there is an selected support plan and update it if it still exists
     */
    useEffect(() => {
        if (supportPlans && selectedSupportPlan) {
            setSelectedSupportPlan(supportPlans.find((plan) => plan.id === selectedSupportPlan.id));
        }
    }, [selectedSupportPlan, supportPlans]);

    /**
     * Callback to write the selected support plan into the state
     * @param index
     * @param plan
     */
    const onSelectedSupportPlan = (index: number | string, plan: SupportPlan) => {
        setSelectedSupportPlan(plan);
    };

    /**
     * Reassign objective agreement. If there is an open support plan, select this new plan and reassign the objective agreement.
     * If not create a new support plan and reassign the objective agreement.
     * @param arg
     */
    const onAddObjectiveAgreementToNewSupportPlan = async (arg: ObjectiveAgreement) => {
        if (supportPlans.some((supportPlan) => !supportPlan.closed)) {
            setSelectedSupportPlan(supportPlans.find((supportPlan) => !supportPlan.closed));
        } else {
            await onCreateNewSupportPlan();
        }

        setReassignObjectiveAgreement(await apiGetNeedForActionById(arg.needForActionId));
    };

    /**
     * Needed for old support plan structure. In the past the support plan's name were stored in the format dd.mm.yyyy
     * @param value
     */
    const checkSupportPlanName = (value: string | number) => {
        const regex = new RegExp('\\d\\d\\.\\d\\d\\.\\d\\d\\d\\d');
        if (typeof value === 'string' && regex.test(value)) {
            return `${value[3]}${value[4]}.${value[0]}${value[1]}.${value[6]}${value[7]}${value[8]}${value[8]}`;
        } else {
            return value;
        }
    };

    return (
        <StandardView>
            <StandardView.Left>
                <ObjectList>
                    <ObjectList.Head>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '24px'
                            }}
                        >
                            <div className="p2-medium">Bisherige Förderpläne</div>
                            {!supportPlans?.some((supportPlan) => !supportPlan.closed) &&
                                !participant?.measuresParticipants?.inactive && (
                                    <Button
                                        type={'primary'}
                                        size={'small'}
                                        buttonStyle={'filled'}
                                        firstIcon={<Icon type={'Plus'} />}
                                        onClick={onCreateNewSupportPlan}
                                    />
                                )}
                        </div>
                    </ObjectList.Head>
                    <ObjectList.Body>
                        <div className="participant-detail-information-dates" style={{ flexDirection: 'column' }}>
                            {supportPlans ? (
                                supportPlans.map((plan, index) => {
                                    return (
                                        <ObjectListItem
                                            key={plan.id}
                                            value={{ index: index, item: plan }}
                                            selected={selectedSupportPlan?.id === plan.id}
                                            onClick={onSelectedSupportPlan}
                                        >
                                            <div>{formatDate(new Date(checkSupportPlanName(plan.name)))}</div>
                                        </ObjectListItem>
                                    );
                                })
                            ) : (
                                <LoadingSpinner />
                            )}
                        </div>
                    </ObjectList.Body>
                </ObjectList>
            </StandardView.Left>
            <StandardView.Right>
                <>
                    {selectedSupportPlan && (
                        <SupportPlanDetails
                            onAddObjectiveAgreementToNewSupportPlan={onAddObjectiveAgreementToNewSupportPlan}
                            reload={fetchSupportPlans}
                            participant={props.participant}
                            supportPlan={selectedSupportPlan}
                        />
                    )}
                    {reassignObjectiveAgreement && (
                        <UpdateNeedForAction
                            supportPlan={selectedSupportPlan}
                            defineObjectiveAgreement
                            needForAction={reassignObjectiveAgreement}
                            onGoBack={() => setReassignObjectiveAgreement(undefined)}
                        />
                    )}
                </>
            </StandardView.Right>
        </StandardView>
    );
};
