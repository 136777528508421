import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styles from './DropDown.module.css';

/**
 * Props for the DropDown component.
 */
interface DropDownProps {
    // The title that is always shown
    title: ReactNode;

    // The children (selectable items)
    children?: ReactNode;

    // Optional css classes name for the container
    className?: string;

    // Optional css classes for the title container
    titleClassName?: string;

    // Optional css classes for the body ontainer
    bodyClassName?: string;

    // Indicates that the dropdown should not be able to be opened
    disabled?: boolean;

    // If true, the items are aligned right and not left. Useful if the dropdown is on the right view corner.
    alignItemsRight?: boolean;
}

/**
 * Shows a Dropdown.
 * The title specified in the props is always shown.
 * The children are expected to be the dropdown elements.
 * The elements itself should handle its click listeners. This is only meant to be a dropdown with
 * a selectable list the user can choose one item from. It is not meant to be a form element.
 *
 * @param props
 * @constructor
 */
export const DropDown = (props: DropDownProps) => {
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    const dropDownRef = useRef<HTMLDivElement>(null);

    /**
     * Handles a click outside the dropdown.
     * Closes the dropdown if it is open.
     *
     * @param event
     */
    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (dropDownRef.current && dropDownRef.current.contains(event.target as Node)) {
                return;
            }

            setMenuIsOpen(false);
        },
        [dropDownRef]
    );

    /**
     * Register event listener for the case that the user clicks outside of the dropdown menu in order to close it
     */
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);

    /**
     * Function to open/close the menu
     */
    const onClickMenu = () => {
        if (!props.disabled) {
            setMenuIsOpen(!menuIsOpen);
        }
    };

    return (
        <div
            onClick={onClickMenu}
            className={`${styles.container} ${props.disabled ? 'disabled' : ''} ${props.className}`}
            ref={dropDownRef}
        >
            <div className={`${styles.titleContainer} ${props.titleClassName}`}>{props.title}</div>

            {menuIsOpen && (
                <div
                    className={`${styles.itemsContainer} ${props.alignItemsRight ? 'align-right' : ''} ${
                        props.bodyClassName || ''
                    }`}
                >
                    {props.children ? (
                        props.children
                    ) : (
                        <div className={styles.noItemsText}>Es sind keine Optionen vorhanden.</div>
                    )}
                </div>
            )}
        </div>
    );
};
