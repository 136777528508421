import { Measure } from '../../models/Measure/Types';
import { List } from '../List/List';

/**
 * Interface for the MeasureFamily component.
 */
interface MeasureFamilyProps {
    measure: Measure;
}

/**
 * Shows parent and children of measure.
 * A measure has a parent if she was copied from another measure.
 * A measure has children if other measures was copied from it.
 *
 * @param props
 * @constructor
 */
export const MeasureFamily = (props: MeasureFamilyProps) => {
    return (
        <div>
            {props.measure.parent ? (
                <List readonly={true} options={[{ label: 'Maßnahme kopiert von', value: props.measure.parent.name }]} />
            ) : null}

            {props.measure.children && props.measure.children.length > 0 ? (
                <div>
                    <List
                        readonly={true}
                        options={[
                            {
                                label: 'Kopierte Maßnahmen',
                                value: props.measure.children
                                    .map((child) => {
                                        return child.name;
                                    })
                                    .join(', ')
                            }
                        ]}
                    />
                </div>
            ) : null}
        </div>
    );
};
