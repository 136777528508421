import { Formik } from 'formik';
import { ParticipantNote } from '../../../models/Participant/Types';
import { Button } from '../../Button/Button';
import './ParticipantNotesDetails.css';
import React, { useRef, useState } from 'react';
import { ParticipantNotesDetailsForm } from '../ParticipantNotesDetailsForm/ParticipantNotesDetailsForm';
import { KeyedMutator } from 'swr';
import { showSuccessMessage } from '../../../models/Toasts/Toasts';
import { DeleteButtonModal } from '../../DeleteButtonModal/DeleteButtonModal';
import { useOnClickOutside } from 'usehooks-ts';
import { useNotesApi } from '../../../api/useNotesApi';

interface ParticipantNotesDetailsProps {
    participantNote: ParticipantNote;
    reloadNotes: KeyedMutator<ParticipantNote[]>;
}

export const ParticipantNotesDetails = (props: ParticipantNotesDetailsProps) => {
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const buttonRef = useRef<HTMLDivElement>(null);
    const { apiDeleteParticipantNote } = useNotesApi();

    /**
     * Register onClick outside
     */
    useOnClickOutside(buttonRef, () => {
        setShowDeleteModal(false);
    });

    /**
     * onClick callback to delete a note
     */
    const onDeleteNote = async () => {
        await apiDeleteParticipantNote(props.participantNote.id);
        onShowDelete();
        showSuccessMessage('Notiz gelöscht');
        props.reloadNotes();
    };

    /**
     * Callback to show the delete modal
     */
    const onShowDelete = () => {
        setShowDeleteModal(!showDeleteModal);
    };

    return (
        <div className={'participant-notes-details'}>
            <Formik
                initialValues={props.participantNote}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize
                onSubmit={() => {
                    console.log();
                }}
            >
                {({ values, handleChange, setFieldValue }) => (
                    <>
                        <div className={'participant-notes-details-head'}>
                            <h5>{values.heading}</h5>
                            <div style={{ position: 'relative' }} ref={buttonRef}>
                                <Button
                                    onClick={onShowDelete}
                                    type={'primary'}
                                    size={'medium'}
                                    buttonStyle={'outline'}
                                    text={'Notiz löschen'}
                                />
                                {showDeleteModal && <DeleteButtonModal onClose={onShowDelete} onClick={onDeleteNote} />}
                            </div>
                        </div>
                        <div>
                            <ParticipantNotesDetailsForm
                                reloadNotes={props.reloadNotes}
                                values={values}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                participantNote={props.participantNote}
                            />
                        </div>
                    </>
                )}
            </Formik>
        </div>
    );
};
