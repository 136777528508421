import Select, { MultiValue } from 'react-select';
import { Label } from '../../../components/Label/Label';
import { UserObject } from '../../../models/Employee/Types';
import { User } from '../../../models/User/Types';
import './CreateMeasureEmployee.css';
import { ChangeEvent } from 'react';

interface CreateMeasureEmployeeProps {
    onChange: (e: ChangeEvent<unknown>) => void;
    values?: User[];
    user?: UserObject[];
    name?: string;
    remove: (index: number) => void;
    push: (obj: unknown) => void;
    replace: (index: number, obj: unknown) => void;
    setFieldValue?: (arg: string, arg2: unknown) => void;
    error?: string;
}

export const CreateMeasureEmployee = (props: CreateMeasureEmployeeProps) => {
    /**
     * Add employee ID to Formik
     * @param employee
     */
    const onChange = (employee: MultiValue<{ value: number | undefined; label: string }>) => {
        if (props.setFieldValue) {
            props.setFieldValue(
                'user',
                employee.map((item) => item.value)
            );
        }
    };

    return (
        <>
            <h5 className="create-measure-input-title">Bitte fügen Sie Mitarbeiter der Maßnahme hinzu</h5>
            <Label className={'create-measure-employee-label'} size={4}>
                Mitarbeiter
            </Label>
            <Select
                options={props.user?.map((user) => {
                    return {
                        value: user.id,
                        label: `${user.firstName} ${user.lastName}`
                    };
                })}
                isMulti
                onChange={onChange}
                placeholder={'Mitarbeiter auswählen'}
            />
        </>
    );
};
