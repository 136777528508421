import React, { useRef, useState } from 'react';
import { formatDate } from '../../models/Date/Date';
import { Task } from '../../models/Task/Types';
import { Avatar } from '../Avatar/Avatar';
import { Icon } from '../Icon/Icon';
import { Loading } from '../Loading/Loading';
import { UserTaskDetails } from '../UserTaskDetails/UserTaskDetails';
import './UserTasksCard.css';

/**
 * Props for the SingleTask component.
 */
interface SingleTaskProps {
    task: Task;
    onFinishTask: (task: Task) => void;
    onShowTaskDetails: (task: Task) => void;
}

/**
 * Single task component for the UserTasksCard. Shows the single task given by the props.
 *
 * @param props
 * @constructor
 */
const SingleTask = (props: SingleTaskProps) => {
    const clickRef = useRef<HTMLDivElement>(null);

    /**
     * Call back to call the parent onShowTaskDetails function and to open the task details. Prevent this if the clicked element was actually the finish task button
     * @param event
     */
    const onClickSingleTask = (event: React.MouseEvent<HTMLInputElement>) => {
        if (event.currentTarget === clickRef.current) {
            event.preventDefault();
            return;
        }

        props.onShowTaskDetails(props.task);
    };

    /**
     * Call back to prevent click on finish button to open the details information and calls the parent onFinishTask function
     * @param event
     */
    const onFinishTask = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        props.onFinishTask(props.task);
    };

    return (
        <div
            className={`user-tasks-single-task ${
                !props.task.finishedAt && new Date(props.task.deadline) <= new Date() ? 'task-overdue' : ''
            }`}
            onClick={onClickSingleTask}
        >
            <div className="user-tasks-single-task-deadline">
                <div ref={clickRef} onClick={onFinishTask}>
                    {!props.task.finishedAt && <Icon className="user-tasks-open" type={'Circle'} />}
                    {props.task.finishedAt && <Icon className="user-tasks-done" type={'CheckCircle'} />}
                </div>
                {props.task.deadline && <div>{formatDate(new Date(props.task.deadline))}</div>}
                {props.task.participant && (
                    <div>
                        {props.task.participant.firstName} {props.task.participant.lastName}
                    </div>
                )}
            </div>
            <div className="user-tasks-single-task-text">{props.task.name}</div>
            <div className="user-tasks-single-task-names">
                <div>{props.task.measure?.name}</div>
                <div>{<Avatar url={props.task.assignments[0].assignedTo.id} size={'small'} />}</div>
            </div>
        </div>
    );
};

/**
 * Describes a filter for user tasks.
 */
export interface UserTasksCardFilter {
    measureId?: number | string | undefined;
    status?: null | boolean;
    assignee?: number | undefined;
}

/**
 * Props for the UserTasksCard component.
 */
interface UserTasksCardProps {
    tasks: Task[] | undefined;
    filter: UserTasksCardFilter;
    onFinishTask: (task: Task) => void;
    mutateExistingTasks: () => void;
}

export const UserTasksCard = (props: UserTasksCardProps) => {
    const [showTaskDetails, setShowTaskDetails] = useState<Task | undefined>(undefined);

    /**
     * Call back to show the task details
     * @param task
     */
    const onShowTaskDetails = (task: Task) => {
        setShowTaskDetails(task);
    };

    /**
     * Call back to close the task details
     */
    const onCloseTaskDetails = () => {
        setShowTaskDetails(undefined);
    };

    /**
     * call back to call parent function onFinishTask
     * @param task
     */
    const onFinishTask = (task: Task) => {
        props.onFinishTask(task);
    };

    return (
        <div className="user-tasks-card-container">
            {showTaskDetails && (
                <UserTaskDetails
                    task={showTaskDetails}
                    mutateExistingTasks={props.mutateExistingTasks}
                    onClose={onCloseTaskDetails}
                />
            )}
            {props.tasks && props.tasks.length > 0 ? (
                props.tasks.map((task, index) => {
                    return (
                        <SingleTask
                            task={task}
                            key={index}
                            onShowTaskDetails={onShowTaskDetails}
                            onFinishTask={onFinishTask}
                        />
                    );
                })
            ) : props.tasks ? (
                <div>Dieser Nutzer hat keine Aufgaben</div>
            ) : (
                <Loading repeat={10} wrapper={'user-tasks-single-task-loading'} />
            )}
        </div>
    );
};
