import {
    NeedForAction,
    NeedForActionComment,
    NeedForActionCommentSearch,
    NeedForActionCommentSubmit,
    NeedForActionSearch,
    NeedForActionSubmit,
    NeedForActionSuggestion,
    SearchNeedForActions
} from '../models/NeedForAction/Type';
import { EzOnRailsSearchFilterRequest } from '../models/Search/Type';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Returns methods to communicate with the backend related to NeedForActions.
 */
export const useNeedForActionsApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API to create a new need for action
     *
     * @param needForAction
     */
    const apiCreateNeedForAction = useCallback(
        async (needForAction: NeedForActionSubmit): Promise<NeedForAction> => {
            return call<NeedForActionSubmit, NeedForAction>('need_for_actions', 'POST', needForAction);
        },
        [call]
    );

    /**
     * API to create a new comment for a need for action
     *
     * @param comment
     */
    const apiCreateNeedForActionComment = useCallback(
        async (comment: NeedForActionCommentSubmit): Promise<NeedForActionComment> => {
            return call<NeedForActionCommentSubmit, NeedForActionComment>('need_for_action_comments', 'POST', comment);
        },
        [call]
    );

    /**
     * API to get all need for actions
     *
     */
    const apiGetAllNeedForActions = useCallback(async (): Promise<NeedForAction[]> => {
        return call<void, NeedForAction[]>('need_for_actions', 'GET');
    }, [call]);

    const apiNeedForActionsSearch = useCallback(
        async (filter: EzOnRailsSearchFilterRequest): Promise<NeedForActionSearch> => {
            return call<EzOnRailsSearchFilterRequest, NeedForActionSearch>('need_for_actions/search', 'POST', filter);
        },
        [call]
    );

    const apiNeedForActionsCommentsSearch = useCallback(
        async (filter: EzOnRailsSearchFilterRequest): Promise<NeedForActionCommentSearch> => {
            return call<EzOnRailsSearchFilterRequest, NeedForActionCommentSearch>(
                'need_for_action_comments/search',
                'POST',
                filter
            );
        },
        [call]
    );

    const apiGetNeedForActionsSuggestions = useCallback(async (): Promise<NeedForActionSuggestion> => {
        return call<void, NeedForActionSuggestion>('need_for_actions/suggestions', 'GET');
    }, [call]);

    const apiSearchOpenNeedForActionsByParticipantId = useCallback(
        async (participantId: number): Promise<SearchNeedForActions> => {
            return call<EzOnRailsSearchFilterRequest, SearchNeedForActions>('need_for_actions/search', 'POST', {
                filter: {
                    logic: 'and',
                    filters: [
                        {
                            field: 'participant_id',
                            operator: 'eq',
                            value: participantId
                        },
                        {
                            field: 'closed',
                            operator: 'eq',
                            value: false
                        }
                    ]
                }
            });
        },
        [call]
    );

    /**
     * API to get need for action by id
     *
     * @param needForActionId
     */
    const apiGetNeedForActionById = useCallback(
        async (needForActionId: number): Promise<NeedForAction> => {
            return call<void, NeedForAction>(`need_for_actions/${needForActionId}`, 'GET');
        },
        [call]
    );

    /**
     * API to update need for action
     *
     * @param needForActionId
     * @param needForAction
     */
    const apiUpdateNeedForAction = useCallback(
        async (needForActionId: number, needForAction: Partial<NeedForActionSubmit>): Promise<NeedForAction> => {
            return call<Partial<NeedForActionSubmit>, NeedForAction>(
                `need_for_actions/${needForActionId}`,
                'PATCH',
                needForAction
            );
        },
        [call]
    );

    /**
     * API to update an need for action comment
     *
     * @param needForActionCommentId
     * @param needForActionComment
     */
    const apiUpdateNeedForActionComment = useCallback(
        async (
            needForActionCommentId: number,
            needForActionComment: NeedForActionCommentSubmit
        ): Promise<NeedForActionComment> => {
            return call<NeedForActionCommentSubmit, NeedForActionComment>(
                `need_for_action_comments/${needForActionCommentId}`,
                'PATCH',
                needForActionComment
            );
        },
        [call]
    );

    /**
     * API to delete an need for action comment
     *
     * @param needForActionCommentId
     */
    const apiDeleteNeedForActionComment = useCallback(
        async (needForActionCommentId: number): Promise<NeedForActionComment> => {
            return call<void, NeedForActionComment>(`need_for_action_comments/${needForActionCommentId}`, 'DELETE');
        },
        [call]
    );

    return {
        apiCreateNeedForAction: apiCreateNeedForAction,
        apiCreateNeedForActionComment: apiCreateNeedForActionComment,
        apiGetAllNeedForActions: apiGetAllNeedForActions,
        apiNeedForActionsSearch: apiNeedForActionsSearch,
        apiNeedForActionsCommentsSearch: apiNeedForActionsCommentsSearch,
        apiGetNeedForActionsSuggestions: apiGetNeedForActionsSuggestions,
        apiSearchOpenNeedForActionsByParticipantId: apiSearchOpenNeedForActionsByParticipantId,
        apiGetNeedForActionById: apiGetNeedForActionById,
        apiUpdateNeedForAction: apiUpdateNeedForAction,
        apiUpdateNeedForActionComment: apiUpdateNeedForActionComment,
        apiDeleteNeedForActionComment: apiDeleteNeedForActionComment
    };
};
