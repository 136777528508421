
const digest = '6ef17add9cd35361ec4cfcbedda8614c5326d1a6fe7b684eb90ce0737149ce54';
const css = `/* fonts *//* paddings *//* margins and gaps *//* borders */

._container_1d8t7_4 {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
}

._spacer_1d8t7_12 {
    width: 1px;
    background-color: black;
    margin: 0.2rem 0.5rem;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"distances":"\"../../../shared/styles/distances.module.css\"","marginSmall":"0.2rem","marginMedium":"0.5rem","sizeBorderSmall":"1px","container":"_container_1d8t7_4","spacer":"_spacer_1d8t7_12"};
export { css, digest };
  