
const digest = 'b5e63a31f778c86eb405a861d024ee907010ce936002ede914d3c512a468b771';
const css = `/* fonts *//* paddings *//* margins and gaps *//* borders */
._container_192j0_4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.2rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"distances":"\"../../styles/distances.module.css\"","marginSmall":"0.2rem","container":"_container_192j0_4"};
export { css, digest };
  