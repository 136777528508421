import useSWR from 'swr';
import { Measure } from '../../models/Measure/Types';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { Label } from '../Label/Label';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { SingleParticipantInformation } from '../SingleParticipantInformation/SingleParticipantInformation';
import './ParticipantsManagement.css';
import { Field } from 'formik';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface ParticipantsManagementProps {
    measure: Measure;

    // Are checkboxes for selecting participants supposed to be shown
    areCheckboxesEnabled?: boolean;
}

export const ParticipantsManagement = (props: ParticipantsManagementProps) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { data: participants, mutate: reload } = useSWR<ParticipantWithPresence[]>([
        backendUrl,
        `measures/${props.measure.id}/participants`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    return (
        <div>
            <div style={{ marginBottom: '20px' }} className="participants-management-table">
                <Label size={2}>Name</Label>
                <Label size={2}>Geburtsdatum</Label>
                <Label size={2}>Kundennummer</Label>
                <Label size={2}>Status</Label>
                <div />
            </div>
            {participants ? (
                participants
                    .sort((a, b) => a.lastName.localeCompare(b.lastName))
                    .map((participant) => {
                        return props.areCheckboxesEnabled ? (
                            <label key={participant.id} className={'participant-checkbox-label'}>
                                {/* 'value' must be converted to string because numbers aren't handled correctly due to dom limitations */}
                                <Field type={'checkbox'} name={'participantsIds'} value={participant.id.toString()} />
                                <SingleParticipantInformation
                                    key={participant.id}
                                    reload={reload}
                                    participant={participant}
                                />
                            </label>
                        ) : (
                            <SingleParticipantInformation
                                key={participant.id}
                                reload={reload}
                                participant={participant}
                                measure={props.measure}
                                isRemoveIconEnabled={true}
                            />
                        );
                    })
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};
