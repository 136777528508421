import { EzOnRailsHttp } from '@d4us1/ez-on-rails-react';
import { SWRConfiguration } from 'swr';

interface ConfigurationType {
    backendUrl: string;
    swrConfig: SWRConfiguration;
    dateStyleOptions: { year: 'numeric'; month: '2-digit'; day: '2-digit' };
}

export const Configuration: ConfigurationType = {
    // The url of the backend service
    backendUrl:
        process.env.REACT_APP_BACKEND_URL === 'staging'
            ? 'https://sorocket-portal-backend-staging.dausenau.dev'
            : process.env.REACT_APP_BACKEND_URL === 'production'
              ? 'https://sorocket-portal-backend.herokuapp.com'
              : process.env.REACT_APP_BACKEND_URL === 'local'
                ? 'http://localhost:3000'
                : process.env.REACT_APP_BACKEND_URL
                  ? process.env.REACT_APP_BACKEND_URL
                  : 'http://localhost:3000',
    swrConfig: {
        refreshInterval: 0,
        errorRetryCount: 0,
        shouldRetryOnError: false,
        fetcher: EzOnRailsHttp.swr.fetcher,
        revalidateOnFocus: false
    },
    dateStyleOptions: { year: 'numeric', month: '2-digit', day: '2-digit' }
};
