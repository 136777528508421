import { ReactElement, ReactNode, MouseEvent } from 'react';
import './Card.css';

interface CardProps {
    children: ReactElement | ReactElement[] | undefined | null | ReactNode | ReactNode[];
    className?: string;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

export const Card = (props: CardProps) => {
    return (
        <div className={`card ${props.className}`} onClick={props.onClick}>
            {props.children}
        </div>
    );
};
