import { useState } from 'react';
import { platformUrl } from '../../helpers';
import { QmDocumentDocx } from '../../models/QmDocument/Types';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Modal } from '../Modal/Modal';
import './PrintQmDocumentModal.css';
import { LetterDocx } from '../../models/Letters/Type';

interface PrintQmDocumentModalProps<T extends QmDocumentDocx | LetterDocx> {
    show: boolean;
    documents: T[] | undefined;
    close: () => void;
    onSetDocuments: (arg: T[] | undefined) => void;
}

interface DownloadProps<T extends QmDocumentDocx | LetterDocx> {
    documents?: T[];
}

/**
 * Component to download several files at once
 * @param props
 * @constructor
 */
export const Download = <T extends QmDocumentDocx | LetterDocx>(props: DownloadProps<T>) => {
    return (
        <div style={{ display: 'none' }}>
            {props.documents?.map((document) => {
                return <iframe key={document.id} title={document.name} src={platformUrl(document.documentUrl)} />;
            })}
        </div>
    );
};

/**
 * Modal to render the qm downloadable files after an user has been created
 * @param props
 * @constructor
 */
export const PrintQmDocumentModal = <T extends QmDocumentDocx | LetterDocx>(props: PrintQmDocumentModalProps<T>) => {
    const [onAllDownload, setOnAllDownload] = useState<boolean>(false);

    /**
     * "Show" the download iframe
     */
    const onClickDownloadAllFiles = () => {
        setOnAllDownload(true);
        setTimeout(() => {
            setOnAllDownload(false);
        }, 1000);
    };

    /**
     * Call back to close the modal
     */
    const onClose = () => {
        props.onSetDocuments(undefined);
    };

    return (
        <Modal
            show={props.show}
            header={'QM-Dokumente drucken'}
            buttons={{
                primary: { text: 'Schließen', onClick: onClose }
            }}
        >
            <div>Sie können folgende QM-Dokumente zum Teilnehmer drucken</div>
            <div className="print-qm-document-modal-body">
                {props.documents?.map((document) => {
                    return (
                        <a key={document.id} href={platformUrl(document.documentUrl)}>
                            <div className="print-qm-document-modal-documents">
                                <Icon type={'DocumentDownload'} />
                                <div>{document.name}</div>
                            </div>
                        </a>
                    );
                })}
                {onAllDownload && <Download documents={props.documents} />}
            </div>
            <Button
                type={'secondary'}
                size={'small'}
                buttonStyle={'outline'}
                firstIcon={<Icon type={'DocumentDownload'} />}
                text={'Alle Dokumente herunterladen'}
                onClick={onClickDownloadAllFiles}
            />
        </Modal>
    );
};
