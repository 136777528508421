import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { UserObject } from '../../models/Employee/Types';
import { Measure } from '../../models/Measure/Types';
import { Participant } from '../../models/Participant/Types';
import { TaskSubmit } from '../../models/Task/Types';
import { Datepicker } from '../Datepicker/Datepicker';
import { Input } from '../Input/Input';
import { InputSelect } from '../InputSelect/InputSelect';
import { Label } from '../Label/Label';
import './CreateTasksForm.css';
import { FormikSetFieldValue } from '../../models/Formik/Type';
import { Textarea } from '../Textarea/Textarea';
import { useMeasuresApi } from '../../api/useMeasuresApi';

interface CreateTasksFormProps {
    values: TaskSubmit;
    handleSubmit: () => void;
    measures: Measure[] | undefined;
    setFieldValue: FormikSetFieldValue;
}

export const CreateTasksForm = (props: CreateTasksFormProps) => {
    const [users, setUsers] = useState<UserObject[]>();
    const [participants, setParticipants] = useState<Participant[]>();
    const { apiGetActiveUsersOfMeasure, apiGetActiveParticipantsInMeasure } = useMeasuresApi();

    /**
     * Change measure id in formik values
     * @param value
     */
    const onChangeMeasure = (value: number | undefined) => {
        props.setFieldValue('measureId', value);
    };

    /**
     * Change user in formik values
     * @param value
     */
    const onChangeUser = useCallback(
        (value: number | undefined) => {
            props.setFieldValue('assignedToId', value);
        },

        // This complains because "this" is available in setFieldValue and possibly targeting stale props,
        // but we do not access this. Hence it is ok to exclude it here
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.setFieldValue]
    );

    /**
     * Change text in formik values
     * @param event
     * @param value
     */
    const onChangeText = (event: ChangeEvent<HTMLTextAreaElement>) => {
        props.setFieldValue('text', event.target.value);
    };

    /**
     * Change deadline in formik values
     * @param value
     */
    const onChangeDeadline = (value: Date) => {
        props.setFieldValue('deadline', value);
    };

    /**
     * Change name / description of task
     * @param event
     * @param value
     */
    const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        props.setFieldValue('name', event.target.value);
    };

    /**
     * Change participant that this task also belongs to
     * @param value
     */
    const onChangeParticipant = (value: number | undefined) => {
        props.setFieldValue('participantId', value);
    };

    /**
     * Backend call to get all participants that are part of the measures the user is also assigned to
     */
    const onGetParticipantOfMeasure = useCallback(async () => {
        try {
            if (props.values.measureId) {
                setParticipants(
                    (await apiGetActiveParticipantsInMeasure(props.values.measureId)).sort(
                        (a: { firstName: string }, b: { firstName: string }) => {
                            return a.firstName.localeCompare(b.firstName);
                        }
                    )
                );
            }
        } catch (e) {
            console.log(e);
        }
    }, [apiGetActiveParticipantsInMeasure, props.values.measureId]);

    /**
     * Fetch participants of measure
     */
    useEffect(() => {
        onGetParticipantOfMeasure().then();
    }, [onGetParticipantOfMeasure]);

    /**
     * Fetch users of measure
     */
    useEffect(() => {
        (async () => {
            try {
                if (props.values.measureId) {
                    setUsers(
                        (await apiGetActiveUsersOfMeasure(props.values.measureId)).sort(
                            (a: { firstName: string }, b: { firstName: string }) => {
                                return a.firstName?.localeCompare(b.firstName);
                            }
                        )
                    );
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, [apiGetActiveUsersOfMeasure, props.values]);

    /**
     * reset user in values if the current selected assignee is not an user in the selected measure
     */
    useEffect(() => {
        if (users?.some((user) => user.id === props.values.assignedToId)) {
            return;
        } else {
            onChangeUser(undefined);
        }
    }, [onChangeUser, props.values.assignedToId, users]);

    return (
        <>
            <div className="create-tasks-border">
                <div>
                    <h5>neue Aufgabe</h5>
                    <div className="create-tasks-form">
                        <div className="create-tasks-form-filter">
                            <div className="create-task-form-input-elements">
                                <InputSelect
                                    label={'Maßnahme'}
                                    dropdownOptions={props.measures?.map((measure: Measure) => {
                                        return {
                                            value: `${measure.name} ${
                                                measure.children && measure.children.length > 0 ? '(alt)' : ''
                                            }`,
                                            id: measure.id
                                        };
                                    })}
                                    onChange={onChangeMeasure}
                                />
                            </div>
                            <div className="create-task-form-input-elements">
                                <InputSelect
                                    label={'Mitarbeiter'}
                                    initialValue={
                                        users?.find((user) => user.id === props.values.assignedToId)
                                            ? `${
                                                  users?.find((user) => user.id === props.values.assignedToId)
                                                      ?.firstName
                                              } ${
                                                  users?.find((user) => user.id === props.values.assignedToId)?.lastName
                                              }`
                                            : ' '
                                    }
                                    dropdownOptions={users?.map((user) => {
                                        return {
                                            value: `${user.firstName} ${user.lastName}`,
                                            id: user.id
                                        };
                                    })}
                                    onChange={onChangeUser}
                                />
                            </div>
                            <div className="create-task-form-input-elements">
                                <div className="create-tasks-form-date">
                                    <Label size={4}>Fälligkeitsdatum</Label>
                                    <Datepicker
                                        value={
                                            typeof props.values.deadline === 'string'
                                                ? new Date(props.values.deadline)
                                                : props.values.deadline
                                        }
                                        onChange={onChangeDeadline}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="create-tasks-form-filter">
                            <div className="create-task-form-input-elements">
                                <InputSelect
                                    label={'Teilnehmer (Optional)'}
                                    initialValue={
                                        participants?.find(
                                            (participant) => participant.id === props.values.participantId
                                        )
                                            ? `${
                                                  participants?.find(
                                                      (participant) => participant.id === props.values.participantId
                                                  )?.firstName
                                              } ${
                                                  participants?.find(
                                                      (participant) => participant.id === props.values.participantId
                                                  )?.lastName
                                              }`
                                            : ' '
                                    }
                                    dropdownOptions={participants?.map((participant) => {
                                        return {
                                            value: `${participant.firstName} ${participant.lastName}`,
                                            id: participant.id
                                        };
                                    })}
                                    onChange={onChangeParticipant}
                                />
                            </div>
                            <div className="create-task-form-input-elements-text">
                                <Label className={'input-select-label'} size={4}>
                                    Aufgabenbeschreibung
                                </Label>
                                <Input onChange={onChangeName} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-tasks-form-text">
                <Label className={'input-select-label'} size={4}>
                    Notizen
                </Label>
                <Textarea name="text" onChange={onChangeText} />
            </div>
        </>
    );
};
