import { LetterRecipient } from './Type';

export const letterRecipients: LetterRecipient[] = ['client', 'company', 'other'];

export const mapLetterRecipients = (value: LetterRecipient) => {
    switch (value) {
        case 'client':
            return 'Teilnehmer';
        case 'company':
            return 'Unternehmen';
        case 'other':
            return 'Andere';
    }
};
