import { Formik } from 'formik';
import { KeyedMutator } from 'swr';
import { Internship } from '../../models/Internship/Types';
import { InternshipCompany } from '../../models/InternshipCompanies/Type';
import { Participant } from '../../models/Participant/Types';
import { showSuccessMessage, throwError, throwWarning } from '../../models/Toasts/Toasts';
import { CreateNewInternshipForm } from '../CreateNewInternshipForm/CreateNewInternshipForm';
import { Modal } from '../Modal/Modal';
import { useInternshipsApi } from '../../api/useInternshipsApi';

interface ChangeInternshipModalProps {
    company?: InternshipCompany;
    companies?: InternshipCompany[];
    show: boolean;
    onClose: () => void;
    participant?: Participant;
    reloadInternship: KeyedMutator<Internship[]>;
    internship: Internship;
}

export const ChangeInternshipModal = (props: ChangeInternshipModalProps) => {
    const { apiUpdateInternship } = useInternshipsApi();

    /**
     * create new internship
     * @param values
     */
    const onSubmitData = async (values: Internship) => {
        try {
            if (values.participantId) {
                await apiUpdateInternship(values, props.internship.id);
                showSuccessMessage('Praktikum aktualisiert');
                if (props.reloadInternship) {
                    props.reloadInternship().then();
                }

                props.onClose();
            } else {
                throwWarning('Bitte geben Sie einen Teilnehmer an');
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Initial values for formik
     */
    const initialValues: Internship = props.internship;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={null}
            onSubmit={(values) => onSubmitData(values)}
            enableReinitialize
        >
            {({ values, handleChange, setFieldValue, handleSubmit }) => (
                <Modal
                    show={props.show}
                    header={props.internship && <div>Praktikum ändern</div>}
                    buttons={{
                        secondary: {
                            text: 'Abbrechen',
                            onClick: props.onClose
                        },
                        primary: {
                            text: 'Praktikum speichern',
                            onClick: handleSubmit
                        }
                    }}
                >
                    <CreateNewInternshipForm
                        companies={props.companies}
                        participant={props.participant}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        values={values}
                    />
                </Modal>
            )}
        </Formik>
    );
};
