import useSWR from 'swr';
import { useAppSelector } from '../../hooks';
import { Measure } from '../../models/Measure/Types';
import { parseStatisticsKey } from '../../models/Statistics/Statistics';
import { MeasureStatistics } from '../../models/Statistics/Type';
import { Accordion } from '../Accordion/Accordion';
import { Label } from '../Label/Label';
import { List } from '../List/List';
import { ListElementProps } from '../ListElement/ListElement';
import './Statistics.css';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface MeasureStatisticsProps {
    statistics?: MeasureStatisticsInterface;
    measure?: Measure;
}

interface MeasureStatisticsInterface {
    cancel: {
        jobCenter: number;
        illness: number;
        adverseBehaviour: number;
        pregnancy: number;
        penalInstitution: number;
        burnBoreOut: number;
        relocation: number;
        noRights: number;
    };
    successful: {
        miniJob: number;
        work: number;
        noneCompanyTraining: number;
        secondarySchoolDiploma: number;
        companyTraining: number;
        inCompanyVocationalPreparation: number;
        schoolTraining: number;
        selfEmployed: number;
        nextSchool: number;
    };
    otherReasons: {
        federalVoluntaryService: number;
        youthVoluntaryService: number;
        renewedParticipation: number;
        renounce: number;
        languageCourse: number;
        notYetAccommodated: number;
        unknown: number;
        changeToSgb3Measure: number;
        changeToSgb2Measure: number;
        changeToBVB: number;
        changeToSchoolCareerPreparation: number;
        changeToEQ: number;
    };
    averageAmountOfParticipants: number;
    amountOfInternships: number;
    averageDurationOfInternships: number;
    amountOfActionRequired: number;
    actionRequiredDone: number;
    actionRequiredPartlyDone: number;
}

export const Statistics = (props: MeasureStatisticsProps) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const measureId = useAppSelector((x) => x.measure.selectedMeasure?.id);
    const { data: measureStatistics } = useSWR<MeasureStatistics>([
        backendUrl,
        `statistics/measure/${props.measure ? props.measure.id : measureId ? measureId : ''}`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const cancel = [
        'jobCenter',
        'sickness',
        'measureConflictingManner',
        'pregnancy',
        'penalSystem',
        'mentalOverloadOrUnderload',
        'relocation',
        'noClaimForAg2'
    ];
    const success = [
        'workOrMinijob',
        'job',
        'companyExternalApprenticeship',
        'companyApprenticeship',
        'entranceExamSuccess',
        'vocationalPreparation',
        'schoolApprenticeship',
        'selfEmployedJob',
        'changeToSecondarySchool',
        'measureGoalAchieved'
    ];
    const other = [
        'civilOrMilitaryDuty',
        'youthFreeService',
        'newParticipation',
        'ownAbandonment',
        'languageCourse',
        'tooOld',
        'unknown',
        'changeToSgb2Measure',
        'changeToSgb3Measure',
        'changeToBvb',
        'changeToVocationalPreparationSchool',
        'changeToEq'
    ];

    const statistics = (title: string, category: string[], information: Record<string, number> | undefined) => {
        const statisticsList: ListElementProps[] = [];
        let count = 0;
        if (information) {
            for (const [key, value] of Object.entries(information)) {
                if (category.some((success) => success === key)) {
                    statisticsList.push({
                        label: parseStatisticsKey(key),
                        value: value,
                        readOnly: true,
                        valueClassName: 'statistics-item'
                    });
                    count = count + value;
                }
            }

            return (
                <Accordion title={title} titleNumber={count}>
                    <List options={statisticsList} />
                </Accordion>
            );
        }
    };

    return (
        <div className="statistics-container">
            <div className="statistics">
                <div className="statistics-accordions">
                    <Label size={2} color={'#A1A1AA'}>
                        AUSSTIEGSSGRUND
                    </Label>
                    {statistics('Abbruch', cancel, measureStatistics?.participants.inactiveReasonsCount)}
                    {statistics(
                        'Erfolgreicher Abschluss',
                        success,
                        measureStatistics?.participants.inactiveReasonsCount
                    )}
                    {statistics('Sonstiger Grund', other, measureStatistics?.participants.inactiveReasonsCount)}
                </div>
                <div className="statistics-details-container">
                    <div className="statistics-details bottom">
                        <Label size={2} color={'#A1A1AA'}>
                            ANWESENHEITEN
                        </Label>
                        <div className="statistics-details-list">
                            <List
                                options={[
                                    {
                                        label: 'Durchschnittliche Anwesenheit der Teilnehmer',
                                        value: measureStatistics
                                            ? `${(measureStatistics?.presences.averagePresencePercentage * 100).toFixed(
                                                  2
                                              )} % `
                                            : undefined,
                                        valueClassName: 'statistics-item',
                                        readOnly: true
                                    }
                                ]}
                            />
                            <List
                                options={[
                                    {
                                        label: 'Anzahl eingetretener Teilnehmer',
                                        value: measureStatistics?.participants.count,
                                        valueClassName: 'statistics-item',
                                        readOnly: true
                                    }
                                ]}
                            />
                            <List
                                options={[
                                    {
                                        label: 'Anzahl archivierter Teilnehmer',
                                        value: measureStatistics?.participants.inactiveCount,
                                        valueClassName: 'statistics-item',
                                        readOnly: true
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <div className="statistics-details bottom">
                        <Label size={2} color={'#A1A1AA'}>
                            PRAKTIKA
                        </Label>
                        <div className="statistics-details-list">
                            <List
                                options={[
                                    {
                                        label: 'Anzahl der geleisteten Praktika',
                                        value: measureStatistics?.internships.finishedCount,
                                        valueClassName: 'statistics-item',
                                        readOnly: true
                                    }
                                ]}
                            />
                            <List
                                options={[
                                    {
                                        label: 'Durchschnittliche Dauer der Praktika',
                                        value: `${measureStatistics?.internships.averageDurationInDays} Tage`,
                                        valueClassName: 'statistics-item',
                                        readOnly: true
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <div className="statistics-details">
                        <Label size={2} color={'#A1A1AA'}>
                            ABGEBAUTE HANDLUNGSBEDARFE
                        </Label>
                        <div className="statistics-details-list">
                            <List
                                options={[
                                    {
                                        label: 'Anzahl der festgestellten Handlungsbedarfe',
                                        value: measureStatistics?.needForActions.count,
                                        valueClassName: 'statistics-item',
                                        readOnly: true
                                    }
                                ]}
                            />
                            <List
                                options={[
                                    {
                                        label: 'davon abgebaut',
                                        value: measureStatistics?.needForActions.closedCount,
                                        valueClassName: 'statistics-item',
                                        readOnly: true
                                    }
                                ]}
                            />
                            <List
                                options={[
                                    {
                                        label: 'erreichte Teilziele',
                                        value: measureStatistics?.needForActions.achievedObjectivesAgreementsCount,
                                        valueClassName: 'statistics-item',
                                        readOnly: true
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
