import React, { useLayoutEffect, useRef, useState } from 'react';
import { Icon } from '../Icon/Icon';
import './Accordion.css';

interface AccordionProps {
    // title that is shown in the accordion header
    title: string;
    // children to render in the accordion
    children: React.ReactElement | React.ReactElement[];
    // number in case a number should be shown in the accordions header
    titleNumber?: number | string;
    // if accordion should be open
    isOpen?: boolean;
}

export const Accordion = (props: AccordionProps) => {
    const [openAccordionHeight, setOpenAccordionHeight] = useState<number>(0);
    const [accordionIsOpen, setAccordionIsOpen] = useState<boolean>(false);
    /**
     * Ref to get the height of all accordion children
     */
    const accordionElementsRef = useRef<HTMLDivElement>(null);

    /**
     * On click on title open the accordion with its elements height
     */
    const onClickTitle = () => {
        if (accordionElementsRef.current?.clientHeight && !accordionIsOpen) {
            setOpenAccordionHeight(accordionElementsRef.current.clientHeight);
        } else if (accordionIsOpen) {
            setOpenAccordionHeight(0);
        }

        setAccordionIsOpen(!accordionIsOpen);
    };

    useLayoutEffect(() => {
        if (props.isOpen && accordionElementsRef.current?.clientHeight) {
            setOpenAccordionHeight(accordionElementsRef.current.clientHeight);
        } else {
            setOpenAccordionHeight(0);
        }
    }, [props.isOpen]);

    return (
        <div className="accordion-container">
            <div onClick={onClickTitle} className={`accordion-title ${(accordionIsOpen || props.isOpen) && 'active'}`}>
                <div className="p4-medium">{props.title}</div>
                <div className="accordion-title-number-chevron p4-medium">
                    {props.titleNumber}
                    {accordionIsOpen ? <Icon type={'ChevronUp'} /> : <Icon type={'ChevronDown'} />}
                </div>
            </div>
            <div className="accordion-elements-container" style={{ height: openAccordionHeight }}>
                <div ref={accordionElementsRef}>
                    {Array.isArray(props.children) ? (
                        props.children.map((child, index) => {
                            return (
                                <div className="accordion-elements" key={index}>
                                    {child}
                                </div>
                            );
                        })
                    ) : (
                        <div className="accordion-elements">{props.children}</div>
                    )}
                </div>
            </div>
        </div>
    );
};
