import { Task, TaskComment, TaskSubmit } from '../models/Task/Types';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Returns methods to communicate with the backend related to Tasks.
 */
export const useTasksApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API call to create a task
     *
     * @param taskInfo
     */
    const apiCreateTask = useCallback(
        async (taskInfo: TaskSubmit): Promise<Task> => {
            return call<TaskSubmit, Task>('tasks', 'POST', taskInfo);
        },
        [call]
    );

    /**
     * API call to retrieve all tasks for a specific measure
     *
     * @param measureId
     */
    const apiGetAllTasksByMeasure = useCallback(
        async (measureId: number): Promise<Task[]> => {
            return call<void, Task[]>(`measures/${measureId}/tasks`, 'GET');
        },
        [call]
    );

    /**
     * API call to retrieve all tasks
     *
     */
    const apiGetAllTasks = useCallback(async () => {
        return call<void, Task[]>('tasks', 'GET');
    }, [call]);

    /**
     * API to create a task comment
     *
     * @param text
     * @param date
     * @param taskId
     */
    const apiCreateTaskComment = useCallback(
        async (text: string, date: Date, taskId: number) => {
            return call<{ text: string; date: Date; taskId: number }, TaskComment>('task_comments', 'POST', {
                text: text,
                date: date,
                taskId: taskId
            });
        },
        [call]
    );

    /**
     * API to get the comments of a task
     *
     * @param taskId
     */
    const apiGetTaskComments = useCallback(
        async (taskId: number): Promise<TaskComment[]> => {
            return call<void, TaskComment[]>(`tasks/${taskId}/comments`, 'GET');
        },
        [call]
    );

    /**
     * Update task based on id
     *
     * @param taskId
     * @param task
     */
    const apiUpdateTask = useCallback(
        async (taskId: number, task: Partial<TaskSubmit>): Promise<Task> => {
            return call<Partial<TaskSubmit>, Task>(`tasks/${taskId}`, 'PATCH', task);
        },
        [call]
    );

    /**
     * Delete task
     *
     * @param taskId
     */
    const apiDeleteTask = useCallback(
        async (taskId: number) => {
            return call<void, unknown>(`tasks/${taskId}`, 'DELETE');
        },
        [call]
    );

    /**
     * Assign a task to an user
     *
     * @param taskId
     * @param assignedToId
     */
    const apiAssignTask = useCallback(
        async (taskId: number, assignedToId: number) => {
            return call<{ taskId: number; assignedToId: number }, unknown>('task_assignments', 'POST', {
                taskId,
                assignedToId
            });
        },
        [call]
    );

    return {
        apiCreateTask: apiCreateTask,
        apiGetAllTasksByMeasure: apiGetAllTasksByMeasure,
        apiGetAllTasks: apiGetAllTasks,
        apiCreateTaskComment: apiCreateTaskComment,
        apiGetTaskComments: apiGetTaskComments,
        apiUpdateTask: apiUpdateTask,
        apiDeleteTask: apiDeleteTask,
        apiAssignTask: apiAssignTask
    };
};
