
const digest = '0d530e367273eaea02159029483adb1621c58242b1d40af0727bce47a5307a7b';
const css = `/* fonts *//* paddings *//* margins and gaps *//* borders */
._container_nct2d_4 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"distances":"\"../../styles/distances.module.css\"","marginLarge":"1rem","container":"_container_nct2d_4"};
export { css, digest };
  