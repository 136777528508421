import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { MeasureSlice } from './models/Measure/Slice';
import { ParticipantSlice } from './models/Participant/Slice';
import { UserSlice } from './models/User/Slice';

export const store = configureStore({
    reducer: {
        user: UserSlice.reducer,
        participant: ParticipantSlice.reducer,
        measure: MeasureSlice.reducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

/**
 * Hook to use some part of the redux store.
 * Returns the part of the state that is returned by the function that is passed as parameter.
 * The function takes the state as parameter and expects to return some part of the state.
 * Those part will be returned by the hook.
 *
 * @param mapping
 */
export const useStore = <T>(mapping: (state: RootState) => T) => {
    return useSelector<RootState, T>(mapping);
};
