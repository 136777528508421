import { useState } from 'react';
import { UserObject } from '../../models/Employee/Types';
import { Measure } from '../../models/Measure/Types';
import { Button } from '../Button/Button';
import { InputSelect } from '../InputSelect/InputSelect';
import './AddUserToMeasure.css';
import { useMeasuresApi } from '../../api/useMeasuresApi';

interface AddUserToMeasureProps {
    // user object
    user: UserObject | undefined;
    mutateUserMeasure: () => void;
    userMeasures: Measure[] | undefined;
    onClose: () => void;
    // Measures the user is archived in
    archivedMeasures: Measure[] | undefined;
    measuresUserCanBeAddedTo: Measure[];
}

export const AddUserToMeasure = (props: AddUserToMeasureProps) => {
    // list a measures an user should be added to
    const [selectedMeasure, setSelectedMeasure] = useState<number>();
    const { apiReactivateArchivedUserInMeasure, apiAddUserToMeasure } = useMeasuresApi();

    /**
     * add measure to state or remove it
     * @param id
     */
    const onSelectMeasure = (id: number | undefined) => {
        if (!id) return;

        setSelectedMeasure(id);
    };

    /**
     * Backend call to add user to measure
     */
    const addUserToMeasure = async () => {
        try {
            if (props.user?.id && selectedMeasure) {
                if (props.archivedMeasures?.some((archivedMeasure) => archivedMeasure.id === selectedMeasure)) {
                    await apiReactivateArchivedUserInMeasure(selectedMeasure, props.user?.id);
                    props.mutateUserMeasure();
                    props.onClose();
                } else {
                    const response = await apiAddUserToMeasure(props.user.id, selectedMeasure);
                    if (response) {
                        props.mutateUserMeasure();
                        props.onClose();
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="add-user-to-measure">
            <InputSelect
                size={'small'}
                dropdownOptions={props.measuresUserCanBeAddedTo.map((measure) => {
                    return {
                        value: measure.name,
                        id: measure.id
                    };
                })}
                onChange={onSelectMeasure}
            />
            <Button
                text={'Hinzufügen'}
                type={'primary'}
                size={'small'}
                buttonStyle={'filled'}
                onClick={addUserToMeasure}
            />
        </div>
    );
};
