import React, { useCallback, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { formatDate } from '../../models/Date/Date';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { ObjectiveAgreement } from '../../models/ObjectiveAgreement/Type';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Datepicker } from '../Datepicker/Datepicker';
import { DropDown } from '../DropDown/DropDown';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { Modal } from '../Modal/Modal';
import './SingleObjective.css';
import { SingleObjectiveAgreementStatus } from '../ObjectiveAgreementStatus/ObjectiveAgreementStatus';
import { EmptyDatepicker } from '../EmptyDatepicker/EmptyDatepicker';
import { useObjectiveAgreementsApi } from '../../api/useObjectiveAgreementsApi';

interface SingleObjectiveProps {
    objective: ObjectiveAgreement;
    reload: () => Promise<void>;
    handleChange: FormikHandleChange;
    setFieldValue: FormikSetFieldValue;
}

export const SingleObjective = (props: SingleObjectiveProps) => {
    const [isSelected, setIsSelected] = useState<boolean>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    const { apiUpdateObjectiveAgreement, apiDeleteObjectiveAgreement } = useObjectiveAgreementsApi();

    useOnClickOutside(
        componentRef,
        useCallback(() => {
            setIsSelected(false);
        }, [])
    );

    const onChangeIsSelected = () => {
        setIsSelected(!isSelected);
    };

    const onChangeDate = async (date: Date) => {
        try {
            await apiUpdateObjectiveAgreement(props.objective.id, {
                ...props.objective,
                ...{ date: date }
            });
            props.setFieldValue('date', date);
            showSuccessMessage();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    const onChangeDeadline = async (date: Date) => {
        try {
            await apiUpdateObjectiveAgreement(props.objective.id, {
                ...props.objective,
                ...{ deadline: date.toString() }
            });
            props.setFieldValue('deadline', date);
            showSuccessMessage();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    const onChangeObjectiveAgreementWithEnter = async (
        event: React.KeyboardEvent<HTMLDivElement | HTMLTextAreaElement>
    ) => {
        if (event.key === 'Enter') {
            await onChangeText();
            setIsSelected(false);
        }
    };

    const onChangeText = async () => {
        try {
            await apiUpdateObjectiveAgreement(props.objective.id, props.objective);
            showSuccessMessage();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Show the delete modal
     */
    const onShowDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal);
    };

    /**
     * Delete objective agreement
     */
    const onDelete = async () => {
        try {
            await apiDeleteObjectiveAgreement(props.objective.id);
            props.reload();
            onShowDeleteModal();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    return (
        <div className={'update-need-for-action-comments-wrapper'} ref={componentRef} key={props.objective.id}>
            <div className={'update-need-for-action-comments'}>
                <div className="update-need-for-action-comments-icon-created-at">
                    <SingleObjectiveAgreementStatus objective={props.objective} reload={props.reload} />
                    {isSelected ? (
                        <Datepicker
                            onChange={onChangeDate}
                            initialDate={new Date(props.objective.date)}
                            value={new Date(props.objective.date)}
                        >
                            {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                <>
                                    <Icon
                                        className="update-need-for-action-deadline-icon"
                                        type={'Calendar'}
                                        onClick={openDatepicker}
                                    />
                                    <input
                                        className="input"
                                        value={formatDate(new Date(props.objective.date))}
                                        onChange={onChangeDateInForm}
                                        onPaste={onPasteDate}
                                        onKeyDown={onPressEnterSubmit}
                                    />
                                </>
                            )}
                        </Datepicker>
                    ) : (
                        <div>{formatDate(new Date(props.objective.date))}</div>
                    )}
                </div>
                {isSelected ? (
                    <>
                        {' '}
                        {props.objective.deadline ? (
                            <Datepicker
                                onChange={onChangeDeadline}
                                initialDate={new Date(props.objective.deadline)}
                                value={new Date(props.objective.deadline)}
                                position={'fixed'}
                            >
                                {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                    <>
                                        <Icon className="update-need-for-action-deadline-icon" type={'Calendar'} />
                                        <input
                                            className="input"
                                            onFocus={openDatepicker}
                                            value={formatDate(new Date(props.objective.deadline))}
                                            onChange={onChangeDateInForm}
                                            onPaste={onPasteDate}
                                            onKeyDown={onPressEnterSubmit}
                                        />
                                    </>
                                )}
                            </Datepicker>
                        ) : (
                            <EmptyDatepicker
                                onChange={onChangeDeadline}
                                initialDate={new Date()}
                                value={props.objective.deadline ? new Date(props.objective.deadline) : undefined}
                            />
                        )}
                        <Input
                            value={props.objective.text}
                            name={'text'}
                            className={'width-100'}
                            onKeyDown={onChangeObjectiveAgreementWithEnter}
                            onChange={props.handleChange}
                            onBlur={onChangeText}
                        />
                    </>
                ) : (
                    <>
                        <div className="single-objective-deadline-and-text-deadline">
                            {props.objective.deadline ? formatDate(new Date(props.objective.deadline)) : '-'}
                        </div>{' '}
                        : <div>{props.objective.text}</div>
                    </>
                )}
                {!isSelected && (
                    <DropDown
                        title={<Icon type={'DotsHorizontal'} />}
                        className={'single-objective-dropdown'}
                        bodyClassName={'single-objective-dropdown-body'}
                    >
                        <div onClick={onChangeIsSelected}>Bearbeiten</div>
                        <div onClick={onShowDeleteModal}>Löschen</div>
                    </DropDown>
                )}
            </div>
            {isSelected && (
                <div className="p5-medium create-measure-detail-enter-hint">
                    Eingabetaste
                    <Icon
                        className="create-measure-detail-enter-hint-icon"
                        iconFile={
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 2.66667C0 1.19391 1.19391 0 2.66667 0H13.3333C14.8061 0 16 1.19391 16 2.66667V13.3333C16 14.8061 14.8061 16 13.3333 16H2.66667C1.19391 16 0 14.8061 0 13.3333V2.66667Z"
                                    fill="#E4E4E7"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.8333 3.33301C13.1095 3.33301 13.3333 3.55687 13.3333 3.83301V7.33301C13.3333 8.34553 12.5125 9.16634 11.5 9.16634H4.42427L6.50997 11.1362C6.71073 11.3258 6.71977 11.6422 6.53016 11.843C6.34056 12.0437 6.0241 12.0528 5.82334 11.8632L2.82334 9.02985C2.72333 8.93539 2.66666 8.80391 2.66666 8.66634C2.66666 8.52878 2.72333 8.39729 2.82334 8.30283L5.82334 5.4695C6.0241 5.2799 6.34056 5.28894 6.53016 5.4897C6.71977 5.69045 6.71073 6.00691 6.50997 6.19651L4.42427 8.16634H11.5C11.9602 8.16634 12.3333 7.79325 12.3333 7.33301V3.83301C12.3333 3.55687 12.5572 3.33301 12.8333 3.33301Z"
                                    fill="#71717A"
                                    stroke="#71717A"
                                    strokeWidth="0.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M2.66667 0.666667H13.3333V-0.666667H2.66667V0.666667ZM15.3333 2.66667V13.3333H16.6667V2.66667H15.3333ZM13.3333 15.3333H2.66667V16.6667H13.3333V15.3333ZM0.666667 13.3333V2.66667H-0.666667V13.3333H0.666667ZM2.66667 15.3333C1.5621 15.3333 0.666667 14.4379 0.666667 13.3333H-0.666667C-0.666667 15.1743 0.825718 16.6667 2.66667 16.6667V15.3333ZM15.3333 13.3333C15.3333 14.4379 14.4379 15.3333 13.3333 15.3333V16.6667C15.1743 16.6667 16.6667 15.1743 16.6667 13.3333H15.3333ZM13.3333 0.666667C14.4379 0.666667 15.3333 1.5621 15.3333 2.66667H16.6667C16.6667 0.825718 15.1743 -0.666667 13.3333 -0.666667V0.666667ZM2.66667 -0.666667C0.825718 -0.666667 -0.666667 0.825718 -0.666667 2.66667H0.666667C0.666667 1.5621 1.5621 0.666667 2.66667 0.666667V-0.666667Z"
                                    fill="#E4E4E7"
                                />
                            </svg>
                        }
                    />
                    zum fortfahren
                </div>
            )}
            {showDeleteModal && (
                <Modal
                    show={showDeleteModal}
                    header={'Ziel löschen'}
                    buttons={{
                        primary: { text: 'Ja, Ziel löschen', onClick: onDelete },
                        secondary: { text: 'Abbrechen', onClick: onShowDeleteModal },
                        swapped: true
                    }}
                >
                    <div>
                        Sie sind dabei, ein Ziel zu löschen. Einmal gelöschte Ziele können nicht wieder hergestellt
                        werden.
                    </div>
                    <div>Sind Sie sicher, dass Sie das Ziel löschen möchten?</div>
                </Modal>
            )}
        </div>
    );
};
