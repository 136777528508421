import { useEffect, useState } from 'react';
import { Icon } from '../Icon/Icon';
import './Select.css';

interface SelectProps<T = number | string> {
    dropdownOptions: T[];
    onChange?: (arg: T) => void;
    initialValue?: T;
}

export const Select = <T = number | string,>(props: SelectProps<T>) => {
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    const [value, setValue] = useState<T>(props.initialValue ? props.initialValue : props.dropdownOptions[0]);

    const openCloseDropdown = () => {
        setOpenDropdown(!openDropdown);
    };

    const onClickOnItem = (option: T) => {
        setValue(option);
        if (props.onChange) {
            props.onChange(option);
        }

        openCloseDropdown();
    };

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue);
        }
    }, [props.initialValue]);

    return (
        <div className="select-wrapper">
            <div className="select-container" onClick={openCloseDropdown}>
                <div>{`${value}`}</div>
                <Icon type="ChevronDown" />
            </div>
            {openDropdown && (
                <div className="select-dropdown">
                    {props.dropdownOptions.map((option: T, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => onClickOnItem(option)}
                                className={`select-dropdown-option ${value === option && 'selected'}`}
                            >
                                {`${option} `}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
