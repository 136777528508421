import { FormikErrors } from 'formik';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { mapMeasureParticipantDeactivationReason } from '../../models/Measure/Measure';
import { ParticipantDeactivationSubmit } from '../../models/Participant/Types';
import { Form } from '../Form/Form';
import { Input } from '../Input/Input';
import { InputSelect } from '../InputSelect/InputSelect';
import { Label } from '../Label/Label';
import './ParticipantArchiveForm.css';
import { Datepicker } from '../Datepicker/Datepicker';
import { participantDeactivationReasons } from '../../models/Participant/Participant';

interface ParticipantArchiveFormProps {
    values: ParticipantDeactivationSubmit;
    errors: FormikErrors<ParticipantDeactivationSubmit>;
    handleChange: FormikHandleChange;
    setFieldValue: FormikSetFieldValue;
}

export const ParticipantArchiveForm = (props: ParticipantArchiveFormProps) => {
    /**
     * Change measure goal achieved field in form manually
     */
    const onChangeMeasureGoalAchieved = (checked: boolean) => {
        props.setFieldValue('measureGoalAchieved', checked);
    };

    /**
     * Change start date manually
     */
    const onChangeJobStartedAt = (date: Date) => {
        props.setFieldValue('jobStartedAt', date);
    };

    /**
     * Change reason manually
     * @param reason
     */
    const onChangeReason = (reason: string | undefined) => {
        props.setFieldValue('reason', reason);
    };

    /**
     * Change leave date manually
     * @param date
     */
    const onChangeInactiveDate = (date: Date) => {
        props.setFieldValue('date', date);
    };

    /**
     * Check if participant will be employed
     */
    const isEmployed = () => {
        switch (props.values.reason) {
            case 'work_or_minijob':
            case 'job':
            case 'company_external_apprenticeship':
            case 'company_apprenticeship':
                return true;
            default:
                return false;
        }
    };

    return (
        <div>
            <Form>
                <Datepicker
                    value={new Date(props.values.date)}
                    label={'Austrittsdatum'}
                    onChange={onChangeInactiveDate}
                    openDatepickerOnClick
                />
                <div className={'participant-archive-form-element'}>
                    <InputSelect
                        dropdownOptions={participantDeactivationReasons.map((reason) => {
                            return {
                                value: mapMeasureParticipantDeactivationReason(reason),
                                id: reason
                            };
                        })}
                        label={'Austrittsgrund'}
                        onChange={onChangeReason}
                        name={'reason'}
                        initialValue={
                            props.values.reason && mapMeasureParticipantDeactivationReason(props.values.reason)
                        }
                        error={props.errors.reason}
                    />
                    {isEmployed() && (
                        <Datepicker
                            label={'Beschäftigungsbeginn (Optional)'}
                            onChange={onChangeJobStartedAt}
                            value={props.values.jobStartedAt ? new Date(props.values.jobStartedAt) : new Date()}
                            openDatepickerOnClick
                        />
                    )}
                </div>
                {isEmployed() && (
                    <div className={'participant-archive-form-element'}>
                        <Form.Input
                            className={'width-100'}
                            onChange={props.handleChange}
                            size={'large'}
                            name={'jobType'}
                            error={props.errors.jobType}
                        >
                            <Label size={4}>Berufsbezeichnung </Label>
                        </Form.Input>
                        <Form.Input
                            onChange={props.handleChange}
                            size={'large'}
                            name={'jobCompany'}
                            error={props.errors.jobCompany}
                        >
                            <Label size={4}>Betrieb </Label>
                        </Form.Input>
                    </div>
                )}
                <div className="participant-archive-form-element-line">
                    <Form.TextArea
                        size={'large'}
                        onChange={props.handleChange}
                        name={'notes'}
                        error={props.errors.notes}
                    >
                        <Label size={4}>Vermerk zum Austritt</Label>
                    </Form.TextArea>
                </div>

                <Form.Checkbox
                    label={'Maßnahmenziel erreicht'}
                    onChange={onChangeMeasureGoalAchieved}
                    value={props.values.measureGoalAchieved || false}
                />
                <Input
                    label={'Begründung'}
                    placeholder={'optional'}
                    onChange={props.handleChange}
                    name={'explanation'}
                />

                <Form.TextArea
                    size={'large'}
                    onChange={props.handleChange}
                    name={'summary'}
                    error={props.errors.summary}
                >
                    <Label size={4}>Zusammenfassung/Empfehlung</Label>
                </Form.TextArea>
            </Form>
        </div>
    );
};
