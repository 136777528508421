/**
 * Get user ID that was stored in local storage
 */
import { EzOnRailsAuthInfo } from '@d4us1/ez-on-rails-react';

export const getUserAuthInfoFromSessionStorage = () => {
    return JSON.parse(sessionStorage.getItem('__sorocket_portal_user_auth_info') as string);
};

/**
 *  Update information on local storage with the username that was entered before
 * @param user
 */
export const updateSessionStorageWithUser = (user: EzOnRailsAuthInfo | undefined) => {
    sessionStorage.setItem('__sorocket_portal_user_auth_info', JSON.stringify(user));
};

/**
 * Remove user from session storage
 */
export const removeUserFromSessionStorage = () => {
    sessionStorage.removeItem('__sorocket_portal_user_auth_info');
};
