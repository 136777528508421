import React, { ChangeEvent, useEffect } from 'react';
import { formatDate } from '../../models/Date/Date';
import { UserObject } from '../../models/Employee/Types';
import { Measure } from '../../models/Measure/Types';
import { Participant } from '../../models/Participant/Types';
import { Task, TaskAssignments, TaskComment } from '../../models/Task/Types';
import { throwError } from '../../models/Toasts/Toasts';
import { Datepicker } from '../Datepicker/Datepicker';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { InputSelect } from '../InputSelect/InputSelect';
import { Label } from '../Label/Label';
import { Loading } from '../Loading/Loading';
import './UpdateTaskForm.css';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { useTasksApi } from '../../api/useTasksApi';

interface UpdateTaskFormProps {
    values: Task;
    handleSubmit: () => void;
    handleChange: FormikHandleChange;
    measures: Measure | undefined;
    setFieldValue: FormikSetFieldValue;
    users: UserObject[] | undefined;
    taskComments: TaskComment[] | undefined;
    task: Task;
    onDeleteTask: () => void;
    mutateComments: () => void;
    taskInformationHasChanged: (arg: boolean) => void;
    initialValues: Task;
    participants: Participant[] | undefined;
}

export const UpdateTaskForm = (props: UpdateTaskFormProps) => {
    const { taskInformationHasChanged } = props;
    const { apiCreateTaskComment } = useTasksApi();

    /**
     * Change user in formik values
     * @param value
     */
    const onChangeUser = (value: number | undefined) => {
        props.setFieldValue('assignedToId', value);
    };

    /**
     * Change text in formik values
     * @param event
     * @param value
     */
    const onChangeText = (event: ChangeEvent<HTMLInputElement>) => {
        props.setFieldValue('text', event.target.value);
    };

    /**
     * Change deadline in formik values
     * @param value
     */
    const onChangeDeadline = (value: Date) => {
        props.setFieldValue('deadline', value);
    };

    /**
     * Change name / description of task
     * @param event
     * @param value
     */
    const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        props.setFieldValue('name', event.target.value);
    };

    /**
     * Change participant that this task also belongs to
     * @param value
     */
    const onChangeParticipant = (value: string | undefined) => {
        props.setFieldValue('participantId', value);
    };

    /**
     * Render the task information of a task
     */
    const renderTaskInformation = () => {
        if (props.taskComments) {
            let taskInfos: (TaskComment | TaskAssignments)[] = [];
            // merge task comments and task assignments into one array for displaying in the comment section
            taskInfos = taskInfos.concat(props.task.assignments, props.taskComments);
            // sort the array by oldest first
            taskInfos.sort((a: { createdAt: string | Date }, b: { createdAt: string | Date }) => {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            });

            return taskInfos.map((info: TaskAssignments | TaskComment) => {
                return (
                    <div key={info.id} className="update-task-details-comments">
                        <div className="update-task-details-date">
                            <Icon type={'DocumentText'} />
                            <span>{formatDate(new Date(info.createdAt))}</span>
                        </div>
                        <div className="update-task-details-single-comment">
                            {'assignedTo' in info ? (
                                <div>
                                    Aufgabe zugewiesen an {info.assignedTo.firstName} {info.assignedTo.lastName}{' '}
                                    <span className="update-task-details-single-comment-from">
                                        von {info.assignedBy.firstName} {info.assignedBy.lastName}{' '}
                                    </span>
                                </div>
                            ) : (
                                <div>
                                    {info.text}{' '}
                                    <span className="update-task-details-single-comment-from">
                                        von {info.user.firstName} {info.user.lastName}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                );
            });
        }
    };

    const onEnterSubmit = async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            if (props.values.text && props.values.text.length > 0) {
                try {
                    await apiCreateTaskComment(props.values.text, new Date(), props.task.id);
                    props.setFieldValue('text', '');
                    props.mutateComments();
                } catch (e) {
                    throwError();
                    console.log(e);
                }
            }
        }
    };

    useEffect(() => {
        if (JSON.stringify(props.initialValues) !== JSON.stringify(props.values)) {
            taskInformationHasChanged(true);
        } else {
            taskInformationHasChanged(false);
        }
    }, [props.values, props.initialValues, taskInformationHasChanged]);

    return (
        <>
            <div className="create-tasks-border" key={props.task.id}>
                <div>
                    <div className="create-tasks-title">
                        <h5>Aufgabe bearbeiten</h5>
                        <Icon onClick={props.onDeleteTask} className="create-tasks-delete" type={'Delete'} />
                    </div>
                    <div className="create-tasks-form">
                        <div className="create-tasks-form-filter">
                            <div className="create-task-form-input-elements">
                                <Label className={'input-select-label'} size={4}>
                                    Maßnahme
                                </Label>
                                <div className="task-form-measure-fixed">
                                    <div className={'p2-bold'}> {props.measures?.name} </div>
                                </div>
                            </div>
                            <div className="create-task-form-input-elements">
                                <InputSelect
                                    label={'Mitarbeiter'}
                                    initialValue={`${props.task.assignments[0].assignedTo.firstName} ${props.task.assignments[0].assignedTo.lastName}`}
                                    dropdownOptions={props.users?.map((user) => {
                                        return {
                                            value: `${user.firstName} ${user.lastName}`,
                                            id: user.id
                                        };
                                    })}
                                    onChange={onChangeUser}
                                />
                            </div>
                            <div className="create-task-form-input-elements">
                                <div className="create-tasks-form-date">
                                    <Label size={4}>Fälligkeitsdatum</Label>
                                    <Datepicker
                                        value={
                                            typeof props.values.deadline === 'string'
                                                ? new Date(props.values.deadline)
                                                : props.values.deadline
                                        }
                                        onChange={onChangeDeadline}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="create-tasks-form-filter">
                            <div className="create-task-form-input-elements">
                                <InputSelect
                                    label={'Teilnehmer (Optional)'}
                                    initialValue={
                                        props.task.participant &&
                                        props.participants?.some(
                                            (participant) => participant.id === props.task.participant.id
                                        )
                                            ? `${
                                                  props.participants?.find(
                                                      (participant) => participant.id === props.task.participant.id
                                                  )?.firstName
                                              } ${
                                                  props.participants?.find(
                                                      (participant) => participant.id === props.task.participant.id
                                                  )?.lastName
                                              }`
                                            : ' '
                                    }
                                    dropdownOptions={props.participants?.map((participant) => {
                                        return {
                                            value: `${participant.firstName} ${participant.lastName}`,
                                            id: participant.id.toString()
                                        };
                                    })}
                                    onChange={onChangeParticipant}
                                />
                            </div>
                            <div className="create-task-form-input-elements-text">
                                <Label className={'input-select-label'} size={4}>
                                    Aufgabenbeschreibung
                                </Label>
                                <Input onChange={onChangeName} name={'name'} value={props.values.name} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="update-task-details-render-comments">
                <div className="update-task-details-render-comments-border">
                    <div className="update-task-details-comments-input">
                        <div className="update-task-details-date-input">
                            <Icon type={'DocumentAdd'} />
                            <span className={'p4-medium'}>{formatDate(new Date())}</span>
                        </div>
                        <Input
                            className="update-task-form-text"
                            name="text"
                            value={props.values.text}
                            onChange={onChangeText}
                            onKeyDown={onEnterSubmit}
                            icon={<Icon type={'Enter'} />}
                        />
                    </div>
                    <div className={'update-task-details-render-comments-scroll'}>
                        {renderTaskInformation() || (
                            <Loading repeat={3} wrapper={'update-task-details-comments-loading'} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
