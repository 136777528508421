import React from 'react';
import { Card } from '../../components/Card/Card';
import { Statistics } from '../../components/Statistics/Statistics';
import './MeasureStatistics.css';
import { DownloadButton } from '../../components/DownloadButton/DownloadButton';
import { Icon } from '../../components/Icon/Icon';
import { useAppSelector } from '../../hooks';

export const MeasureStatistics = () => {
    const measure = useAppSelector((x) => x.measure.selectedMeasure);

    return (
        <div className="measure-statistics">
            <Card>
                <div className="measure-statistics-export">
                    {measure?.id && (
                        <DownloadButton
                            targetPath={`pdf_creation/statistics?measure_id=${measure.id}`}
                            filename={`Statistiken_${measure?.name || ''}.pdf`}
                            type={'primary'}
                            size={'medium'}
                            buttonStyle={'outline'}
                            text={'Statistik drucken'}
                            className="statistics-print-button"
                            firstIcon={<Icon type={'Download'} />}
                        />
                    )}
                </div>
                <Statistics />
            </Card>
        </div>
    );
};
