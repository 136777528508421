import de from 'date-fns/locale/de';
import React, {
    ChangeEvent,
    ReactElement,
    FocusEvent,
    ClipboardEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
    KeyboardEvent as ReactKeyboardEvent
} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '../../models/Date/Date';
import { Button } from '../Button/Button';
import { FormInput } from '../FormInput/FormInput';
import { Label } from '../Label/Label';
import { Select } from '../Select/Select';
import './Datepicker.css';

registerLocale('de', de);

interface DatepickerProps {
    onChange: (arg: Date) => void;
    initialDate?: Date;
    value: Date | undefined;
    label?: string;
    onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
    children?: (
        onChangeDateInForm: (event: ChangeEvent<HTMLInputElement>) => void,
        onPasteDate: (event: ClipboardEvent) => void,
        onPressEnterSubmit: (event: ReactKeyboardEvent<HTMLDivElement>) => void,
        openDatepicker: () => void,
        // TODO: make onBlur mandatory
        onBlur: (e: FocusEvent<HTMLInputElement>) => void,
        inputFieldDate: string | undefined
    ) => ReactElement | ReactElement[];
    // TODO: deprecated?
    returnInputFieldDate?: (date: Date) => void;
    // first entry of the selection for the year in the calendar; default value -> see end of file
    startYear?: number;
    minDate?: Date;
    maxDate?: Date;
    openDatepickerOnClick?: boolean;
    autoFocus?: boolean;
    position?: 'fixed' | 'absolute-left' | 'absolute-middle';
}

export const Datepicker = (props: DatepickerProps) => {
    // date to store and pass to the parent component;
    // only updated (through a useEffect hook) when there is a valid date given by the input field to ensure consistency
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    // date set before changing it in the calendar; used to restore the previous value if the cancel button was pressed
    const [previousDate, setPreviousDate] = useState<Date | undefined>(undefined);
    const [currentSelectedMonth, setCurrentSelectedMonth] = useState<number | undefined>();
    const [currentSelectedYear, setCurrentSelectedYear] = useState<number | undefined>();
    // date value in the text input field given by the props and changed by the user
    const [inputFieldDate, setInputFieldDate] = useState<string>(
        props.initialDate ? formatDate(props.initialDate) : ''
    );
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    /**
     * Call back for datepicker to handle its change event
     * @param date
     */
    const onChangeDate = (date: Date | [Date | null, Date | null] | null | undefined) => {
        if (!Array.isArray(date) && date) {
            setInputFieldDate(formatDate(date));
        }
    };

    /**
     * Callback to determine the design of the datepicker days
     * @param date
     */
    const dateDesign = (date: Date) => {
        if (date.getMonth() === new Date().getMonth() || date.getMonth() === currentSelectedMonth) {
            return 'datepicker-all-days datepicker-day-current-month';
        } else {
            return 'datepicker-all-days datepicker-day';
        }
    };

    /**
     * Determines the years to be shown in the datepicker
     */
    const years = (): number[] => {
        const currentYear = new Date().getFullYear(),
            years = [];
        let startYear = Math.min(props.startYear || currentYear, currentSelectedYear || currentYear);
        while (startYear <= currentYear + 4) {
            years.push(startYear++);
        }

        return years;
    };

    /**
     * Months to be shown in the datepicker
     */
    const months = useMemo(
        () => [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember'
        ],
        []
    );

    /**
     * Callback to open calendar and store the current date to reset it on pressing cancel button
     */
    const openCalendar = () => {
        // prevent datepicker calendar from opening, when there is an invalid date
        if (startDate && isNaN(startDate.getTime())) {
            return;
        }

        setPreviousDate(startDate);
        setIsCalendarOpen(true);
    };

    /**
     * Called if the cancel button in the calendar menu was pressed; resets the date to the previous value
     */
    const onCancelCalendar = () => {
        if (previousDate) {
            setInputFieldDate(formatDate(previousDate));
        }

        setPreviousDate(undefined);
        setIsCalendarOpen(false);
    };

    /**
     * OnChange callback to set input value
     * @param event
     */
    const onChangeDateInForm = (event: ChangeEvent<HTMLInputElement>) => {
        setInputFieldDate(event.currentTarget.value);
    };

    /**
     * Parse the given date string to get the corresponding date object
     */
    const parseDateString = (date: string) => {
        const year = Number(date.slice(6));
        const month = Number(date.slice(3, 5)) - 1;
        const day = Number(date.slice(0, 2));
        return new Date(year, month, day);
    };

    /**
     * When input field changes check for user readable format and adapt it if needed
     */
    useEffect(() => {
        if (inputFieldDate && inputFieldDate !== '') {
            let correctedInputDate = inputFieldDate;

            // TODO: check for shorter date strings deprecated?
            // check if 3 chars were entered and if the user added a dot between the numbers
            if (correctedInputDate.length === 3 && correctedInputDate[2] !== '.') {
                correctedInputDate = correctedInputDate.slice(0, 2) + '.' + correctedInputDate.slice(2);
            }

            // check if 5 chars were entered and if the user added a dot between the numbers
            if (correctedInputDate.length === 6 && correctedInputDate[5] !== '.') {
                correctedInputDate = correctedInputDate.slice(0, 5) + '.' + correctedInputDate.slice(5);
            }

            // check if some chars were added and therefore the length is > 10. reset the field to a readable style
            if (correctedInputDate.length > 10 && (correctedInputDate[2] !== '.' || correctedInputDate[5] !== '.')) {
                const string = correctedInputDate.replaceAll('.', '');
                correctedInputDate = string.slice(0, 2) + '.' + string.slice(2, 4) + '.' + string.slice(4);
            }

            // cut everything that is too much
            if (correctedInputDate.length > 10) {
                correctedInputDate = correctedInputDate.slice(0, 10);
            }

            // if everything matches, create a date object out of it and set the corresponding information for the datepicker.
            // to not cause infinite renders we compare if the corrected date string is the same as what is in the input field
            if (correctedInputDate.length === 10) {
                let correctedDate = parseDateString(correctedInputDate);
                if (isNaN(correctedDate.getTime())) {
                    // set the date to today, when it is not valid
                    correctedDate = new Date();
                    correctedInputDate = formatDate(correctedDate);
                }

                if (correctedInputDate !== inputFieldDate) {
                    setInputFieldDate(correctedInputDate);
                }

                setStartDate(correctedDate);
            }
        }
    }, [inputFieldDate]);

    /**
     * Sets the selected month and year for the calendar
     */
    useEffect(() => {
        if (!startDate || isNaN(startDate.getTime())) {
            return;
        }

        setCurrentSelectedMonth(startDate.getMonth());
        setCurrentSelectedYear(startDate.getFullYear());
    }, [startDate]);

    /**
     * Pass the new 'startDate' to the parent component
     */
    const storeDate = useCallback(() => {
        if (!startDate || isNaN(startDate.getTime())) {
            return;
        }

        const date = startDate;
        // TODO: why is 'hours' changed?
        date.setHours(10);
        if (date.getTime() !== props.value?.getTime()) {
            props.onChange(date);
        }
    }, [props, startDate]);

    /**
     * If data was passed into the input field, slice it to somehow create a date like text
     * @param event
     */
    const onPasteDate = (event: ClipboardEvent) => {
        if (event.clipboardData && event.clipboardData.getData('Text').length > 4) {
            event.preventDefault();
            const string = event.clipboardData.getData('Text');
            setInputFieldDate(string.slice(0, 2) + '.' + string.slice(2, 4) + '.' + string.slice(4));
        }
    };

    /**
     * Call back if user clicks submit button
     */
    const onSubmitDate = () => {
        storeDate();
        setIsCalendarOpen(false);
    };

    // TODO: intended to save on enter in this component?
    const onPressEnterSubmit = (event: ReactKeyboardEvent) => {
        if (event.key === 'Enter' && startDate) {
            storeDate();
        }
    };

    /**
     * If the outer value changes, update the state
     */
    useEffect(() => {
        if (!props.value) {
            return;
        }

        setInputFieldDate(formatDate(props.value));
    }, [props.value]);

    /**
     * Listener for escape key to close calendar
     */
    const escapeKeyListener = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsCalendarOpen(false);
        }
    };

    /**
     * Event listener to close the datepicker calendar if the escape key was pressed
     */
    useEffect(() => {
        document.addEventListener('keydown', escapeKeyListener);
        document.removeEventListener('keydown', escapeKeyListener);
    }, []);

    /**
     * Callback for the onBlur event of the input field
     */
    const onBlur = (e: FocusEvent<HTMLInputElement>) => {
        if (props.onBlur && e) {
            props.onBlur(e);
        }

        storeDate();
    };

    return (
        <div className="datepicker-container">
            {!props.children && (
                <FormInput
                    onBlur={onBlur}
                    onKeyDown={onPressEnterSubmit}
                    onClickIconLeft={openCalendar}
                    iconLeft={'Calendar'}
                    onPaste={onPasteDate}
                    autoFocus={props.autoFocus}
                    onChange={onChangeDateInForm}
                    size={'medium'}
                    value={inputFieldDate}
                    onClick={props.openDatepickerOnClick ? openCalendar : undefined}
                >
                    {props.label ? <Label size={4}>{props.label}</Label> : undefined}
                </FormInput>
            )}
            {props.children
                ? props.children(
                      onChangeDateInForm,
                      onPasteDate,
                      onPressEnterSubmit,
                      openCalendar,
                      onBlur,
                      inputFieldDate
                  )
                : null}
            {isCalendarOpen && (
                <div
                    className="datepicker-position"
                    style={
                        props.position === 'fixed'
                            ? { position: 'fixed', left: 'auto', top: 'auto' }
                            : props.position === 'absolute-left'
                              ? { position: 'absolute', top: '0', left: '0' }
                              : { position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }
                    }
                >
                    <DatePicker
                        renderCustomHeader={({ changeYear, changeMonth }) => (
                            <div className="datepicker-custom-header">
                                <Select
                                    initialValue={
                                        currentSelectedMonth
                                            ? months[currentSelectedMonth]
                                            : startDate
                                              ? months[startDate.getMonth()]
                                              : months[0]
                                    }
                                    dropdownOptions={months}
                                    onChange={(value) => {
                                        changeMonth(months.indexOf(value));
                                        setCurrentSelectedMonth(months.indexOf(value));
                                    }}
                                />
                                <Select
                                    initialValue={currentSelectedYear}
                                    dropdownOptions={years()}
                                    onChange={changeYear}
                                />
                            </div>
                        )}
                        selected={startDate}
                        onChange={(date) => onChangeDate(date)}
                        locale={'de'}
                        className="datepicker-test"
                        dayClassName={dateDesign}
                        calendarClassName={'datepicker-calender'}
                        shouldCloseOnSelect={false}
                        inline={true}
                        tabIndex={1}
                        autoFocus={true}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                    >
                        <div className="datepicker-buttons">
                            <Button
                                type={'secondary'}
                                size={'medium'}
                                buttonStyle={'filled'}
                                text={'Abbrechen'}
                                onClick={onCancelCalendar}
                            />
                            <Button
                                type={'primary'}
                                size={'medium'}
                                buttonStyle={'filled'}
                                text={'Bestätigen'}
                                onClick={onSubmitDate}
                            />
                        </div>
                    </DatePicker>
                </div>
            )}
        </div>
    );
};

Datepicker.defaultProps = {
    startYear: new Date().getFullYear()
};
