import { LetterTemplate, LetterTemplateSubmit } from '../models/LetterTemplates/Type';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Returns methods to communicate with the backend related to LetterTemplates.
 */
export const useLetterTemplatesApi = () => {
    const { call } = useEzApiHttpClient();

    const apiCreateLetterTemplate = useCallback(
        async (letterTemplate: LetterTemplateSubmit): Promise<LetterTemplate> => {
            return call<{ letterTemplate: LetterTemplateSubmit }, LetterTemplate>('letter_templates', 'POST', {
                letterTemplate: letterTemplate
            });
        },
        [call]
    );

    const apiUpdateLetterTemplate = useCallback(
        async (letterTemplateId: number, letterTemplate: LetterTemplateSubmit): Promise<LetterTemplate> => {
            return call<LetterTemplateSubmit, LetterTemplate>(
                `letter_templates/${letterTemplateId}`,
                'PATCH',
                letterTemplate
            );
        },
        [call]
    );

    const apiDeleteLetterTemplate = useCallback(
        async (letterTemplateId: number) => {
            return call<void, unknown>(`letter_templates/${letterTemplateId}`, 'DELETE');
        },
        [call]
    );

    const apiGetAllLetterTemplates = useCallback(async (): Promise<LetterTemplate[]> => {
        return call<void, LetterTemplate[]>('letter_templates', 'GET');
    }, [call]);

    return {
        apiCreateLetterTemplate: apiCreateLetterTemplate,
        apiUpdateLetterTemplate: apiUpdateLetterTemplate,
        apiDeleteLetterTemplate: apiDeleteLetterTemplate,
        apiGetAllLetterTemplates: apiGetAllLetterTemplates
    };
};
