import React, { ReactElement, ReactNode } from 'react';
import './Tag.css';

interface TagProps {
    children: ReactElement | ReactElement[] | ReactNode;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const Tag = (props: TagProps) => {
    return (
        <div className={`tag ${props.className}`} onClick={props.onClick}>
            {props.children}
        </div>
    );
};
