import React from 'react';
import { formatDate } from '../../models/Date/Date';
import { mapNeedForActionPriority } from '../../models/NeedForAction/NeedForAction';
import { NeedForAction, NeedForActionSuggestion, NeedForActionSuggestionTypes } from '../../models/NeedForAction/Type';
import { ObjectiveAgreement } from '../../models/ObjectiveAgreement/Type';
import { Card } from '../Card/Card';
import { Label } from '../Label/Label';
import { Tag } from '../Tag/Tag';
import './NeedForActionCard.css';

interface NeedForActionProps {
    needForAction?: NeedForAction;
    type?: NeedForActionSuggestionTypes;
    onClick: (
        nfa: NeedForAction | undefined,
        nfaSuggestionType: NeedForActionSuggestionTypes | undefined,
        suggestion: NeedForActionSuggestion[] | undefined
    ) => void;
    suggestion?: NeedForActionSuggestion[];
    objectiveAgreement?: ObjectiveAgreement[] | undefined;
}

export const NeedForActionCard = (props: NeedForActionProps) => {
    /**
     * On click call back
     */
    const onClick = () => {
        props.onClick(props.needForAction, props.type, props.suggestion);
    };

    return (
        <Card key={props.needForAction?.id} className="need-for-action-card" onClick={onClick}>
            <div
                className={`need-for-action-card-upper-part ${
                    props.needForAction?.comments && props.needForAction?.comments.length > 0 && 'border-bottom'
                }`}
            >
                <div className="need-for-action-card-head">
                    <div className="p3-medium">
                        {props.type ? props.type.needForActionType : props.needForAction?.needForActionType}
                    </div>
                    {props.needForAction?.closed ? (
                        <Tag className={'need-for-action-card-closed '}>Abgeschlossen</Tag>
                    ) : (
                        <Tag className={`need-for-action-card-${props.needForAction?.priority}`}>
                            {mapNeedForActionPriority(props.needForAction?.priority)}
                        </Tag>
                    )}
                </div>
                <div className="need-for-action-card-options">
                    {' '}
                    {props.needForAction?.options.map((option, index) => {
                        return (
                            <div key={index}>
                                {option.value && option.type === 'date'
                                    ? formatDate(new Date(option.value))
                                    : option.value}
                                {props.needForAction?.options &&
                                    index < props.needForAction?.options.length - 1 &&
                                    ' | '}
                                &nbsp;
                            </div>
                        );
                    })}
                </div>
                <div className="need-for-action-card-tags">
                    <Tag
                        className={`tag-need-for-action tag-${
                            props.suggestion
                                ? props.suggestion[0].category.toLowerCase().replace(/[^A-Za-z]+/g, '')
                                : props.needForAction && 'category' in props.needForAction
                                  ? props.needForAction.category?.toLowerCase().replace(/[^A-Za-z]+/g, '')
                                  : ''
                        }`}
                    >
                        <Label size={3}>
                            {props.suggestion
                                ? props.suggestion[0].category
                                : props.needForAction && 'category' in props.needForAction
                                  ? props.needForAction.category
                                  : ''}
                        </Label>
                    </Tag>
                    <>
                        {props.objectiveAgreement &&
                            props.objectiveAgreement.length > 0 &&
                            props.objectiveAgreement.map((objectiveAgreement) => {
                                return (
                                    <Tag key={objectiveAgreement.id} className="tag-need-for-action tag-support-plan">
                                        <Label size={3}>
                                            {' '}
                                            {formatDate(new Date(objectiveAgreement.supportPlan.name))}{' '}
                                        </Label>
                                    </Tag>
                                );
                            })}
                    </>
                </div>
            </div>
            {props.needForAction?.comments && (
                <div className="need-for-action-card-comments">
                    {props.needForAction.comments
                        .slice(-2)
                        .reverse()
                        .map((comment) => {
                            return (
                                <div key={comment.id} className="need-for-action-card-comments-single-comment">
                                    <div>{formatDate(new Date(comment.date))}</div>
                                    <div>{comment.text}</div>
                                </div>
                            );
                        })}
                </div>
            )}
        </Card>
    );
};
