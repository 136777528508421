import { FormikErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { QmDocumentTemplate, QmDocumentTemplateFormValues } from '../../models/QmDocumentTemplate/Types';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
import { DeleteQmDocumentTemplateModal } from '../DeleteQmDocumentTemplateModal/DeleteQmDocumentTemplateModal';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';
import { QmDocumentTemplatesForm } from '../QmDocumentTemplatesForm/QmDocumentTemplatesForm';
import './QmDocumentTemplateDetails.css';

interface QmDocumentTemplateDetailsProps {
    qmDocumentTemplate: QmDocumentTemplate | QmDocumentTemplateFormValues;
    values: QmDocumentTemplate | QmDocumentTemplateFormValues;
    handleChange: FormikHandleChange;
    errors: FormikErrors<QmDocumentTemplateFormValues | QmDocumentTemplate>;
    setFieldValue: FormikSetFieldValue;
    isEdit?: boolean;
    handleSubmit: () => void;
    onClickDelete: () => Promise<void>;
    resetForm: () => void;
}

export const QmDocumentTemplateDetails = (props: QmDocumentTemplateDetailsProps) => {
    const [editDocument, setEditDocument] = useState<boolean>(false);
    const [deleteDocument, setDeleteDocument] = useState<boolean>(false);

    const onShowDeleteModal = () => {
        setDeleteDocument(!deleteDocument);
    };

    const onSaveDocument = () => {
        setEditDocument(false);
        props.handleSubmit();
    };

    const onEditDocument = () => {
        setEditDocument(true);
    };

    const onClickDelete = async () => {
        await props.onClickDelete();
        onShowDeleteModal();
    };

    const onCancel = () => {
        setEditDocument(false);
        props.resetForm();
    };

    useEffect(() => {
        setEditDocument(false);
    }, [props.qmDocumentTemplate]);

    return (
        <>
            <DeleteQmDocumentTemplateModal
                show={deleteDocument}
                onClose={onShowDeleteModal}
                onClickDelete={onClickDelete}
                document={props.qmDocumentTemplate}
            />
            <div className="qm-document-template-details-head">
                <h5>{props.values.name}</h5>
                {editDocument || props.isEdit ? (
                    <div className="qm-document-template-details-head-edit-buttons">
                        {' '}
                        <Button
                            type={'primary'}
                            size={'medium'}
                            buttonStyle={'outline'}
                            firstIcon={<Icon type={'DocumentText'} />}
                            text={'Dokument speichern'}
                            onClick={onSaveDocument}
                        />
                        {editDocument && (
                            <Button
                                type={'secondary'}
                                size={'medium'}
                                buttonStyle={'outline'}
                                text={'Abbrechen'}
                                onClick={onCancel}
                            />
                        )}
                        <Button
                            className="color-red"
                            type={'secondary'}
                            size={'small'}
                            buttonStyle={'outline'}
                            firstIcon={<Icon type={'Delete'} />}
                            onClick={onShowDeleteModal}
                        />
                    </div>
                ) : (
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'outline'}
                        firstIcon={<Icon type={'DocumentText'} />}
                        text={'Dokument aktualisieren'}
                        onClick={onEditDocument}
                    />
                )}
            </div>
            <Card>
                <>
                    <Label className="user-details-general-title" size={2}>
                        ALLGEMEINE INFORMATIONEN
                    </Label>
                    <QmDocumentTemplatesForm
                        isEdit={props.isEdit || editDocument}
                        values={props.values}
                        errors={props.errors}
                        handleChange={props.handleChange}
                        setFieldValue={props.setFieldValue}
                    />
                </>
            </Card>
        </>
    );
};
