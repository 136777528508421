import { Internship, InternshipSubmit } from '../models/Internship/Types';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Returns methods to communicate with the backend related to internships.
 */
export const useInternshipsApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API call to get all internship
     *
     */
    const apiGetInternships = useCallback(async () => {
        return call<void, Internship[]>('internships', 'GET');
    }, [call]);

    /**
     * API call to create a new internship
     *
     * @param internshipData
     */
    const apiCreateInternship = useCallback(
        async (internshipData: InternshipSubmit): Promise<Internship> => {
            return call<InternshipSubmit, Internship>('internships', 'POST', internshipData);
        },
        [call]
    );

    /**
     * API call to delete internship
     *
     * @param internshipId
     */
    const apiDeleteInternship = useCallback(
        async (internshipId: number) => {
            return call<void, unknown>(`internships/${internshipId}`, 'DELETE');
        },
        [call]
    );

    /**
     * API to update the internship data
     *
     * @param internshipId
     * @param internship
     */
    const apiUpdateInternship = useCallback(
        async (internship: InternshipSubmit, internshipId: number) => {
            return call<InternshipSubmit, Internship>(`internships/${internshipId}`, 'PATCH', internship);
        },
        [call]
    );

    return {
        apiGetInternships: apiGetInternships,
        apiCreateInternship: apiCreateInternship,
        apiDeleteInternship: apiDeleteInternship,
        apiUpdateInternship: apiUpdateInternship
    };
};
