import { ParticipantMeasure, ParticipantMeasureHistory } from '../../models/Participant/Types';
import React, { useEffect, useState } from 'react';
import styles from './MeasuresParticipantHistoryCard.module.css';
import { toHumanReadableDate } from '../../utils/DateUtils';
import { mapMeasureParticipantDeactivationReason } from '../../models/Measure/Measure';
import { List } from '../List/List';
import { Card } from '../Card/Card';
import { useMeasuresApi } from '../../api/useMeasuresApi';

/**
 * Props for the MeasuresParticipantHistoryCard component.
 */
interface MeasuresParticipantHistoryCardProps {
    measuresParticipant: ParticipantMeasure;
}

/**
 * Component that shows a card with measueres participant history entries that are first fectehd
 * from the server.
 *
 * @param props
 * @constructor
 */
export const MeasuresParticipantHistoryCard = (props: MeasuresParticipantHistoryCardProps) => {
    const [historyEntries, setHistoryEntries] = useState<ParticipantMeasureHistory[]>([]);
    const { apiGetMeasuresParticipantHistory } = useMeasuresApi();

    /**
     * Called if the measuresParticipant object given by the props changes.
     * Fetches the history entries from the server and saves them to the state.
     */
    useEffect(() => {
        (async () => {
            if (!props.measuresParticipant) {
                return;
            }

            setHistoryEntries(await apiGetMeasuresParticipantHistory(props.measuresParticipant.id));
        })();
    }, [apiGetMeasuresParticipantHistory, props.measuresParticipant]);

    return historyEntries.length > 0 ? (
        <Card className={styles.container}>
            <div className={`${styles.title} p2-medium`}>Eintritts-/Austritts Historie</div>
            <div className={styles.historiesContainer}>
                {historyEntries.map((historyEntry, index) => {
                    const listOptions = [
                        {
                            label: 'Eingetreten am',
                            value: toHumanReadableDate(historyEntry.entranceDate)
                        }
                    ];

                    if (historyEntry.inactiveDate) {
                        listOptions.push({
                            label: 'Ausgetreten am',
                            value: toHumanReadableDate(historyEntry.inactiveDate)
                        });
                    }

                    if (historyEntry.inactiveReason) {
                        listOptions.push({
                            label: 'Grund des Austritts:',
                            value: mapMeasureParticipantDeactivationReason(historyEntry.inactiveReason) || ''
                        });
                    }

                    if (historyEntry.jobStartedAt) {
                        listOptions.push({
                            label: 'Start der Beschäftigung am:',
                            value: toHumanReadableDate(historyEntry.jobStartedAt)
                        });
                    }

                    if (historyEntry.jobType) {
                        listOptions.push({
                            label: 'Beschäftigung:',
                            value: historyEntry.jobType
                        });
                    }

                    if (historyEntry.jobCompany) {
                        listOptions.push({
                            label: 'Beschäftigt bei:',
                            value: historyEntry.jobCompany
                        });
                    }

                    if (historyEntry.measureGoalAchieved !== undefined) {
                        listOptions.push({
                            label: 'Maßnahmenziel erreicht:',
                            value: historyEntry.measureGoalAchieved ? 'Ja' : 'Nein'
                        });
                    }

                    return <List key={index} options={listOptions} />;
                })}
            </div>
        </Card>
    ) : null;
};
