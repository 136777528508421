import React from 'react';
import { ParticipantWithOneMeasure } from '../../models/Participant/Types';
import { Card } from '../Card/Card';
import './ParticipantMuchDetailsCard.css';
import { ParticipantMuchDetailsCardForm } from '../ParticipantMuchDetailsCardForm/ParticipantMuchDetailsCardForm';
import { Formik } from 'formik';

interface ParticipantMuchDetailsCardProps {
    participant: ParticipantWithOneMeasure;
    reloadParticipant: () => void;
}

export const ParticipantMuchDetailsCard = (props: ParticipantMuchDetailsCardProps) => {
    return (
        <Card key={props.participant.id} className={'participant-much-details-card'}>
            <Formik
                initialValues={props.participant}
                // We need to pass a submit handler because formik expects one, but we do not use the formik submit mechanism
                onSubmit={() => console.log('Form Submit')}
                enableReinitialize
            >
                {({ values, errors, handleChange, setFieldValue }) => (
                    <ParticipantMuchDetailsCardForm
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        participant={props.participant}
                        reloadParticipant={props.reloadParticipant}
                    />
                )}
            </Formik>
        </Card>
    );
};
