
const digest = '0f2abb9a88b427c6e6d5fb9fa10d2a26503859fecb21ba85ddd9e7225a16dbb7';
const css = `/* fonts *//* paddings *//* margins and gaps *//* borders */
._container_1kqth_4 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"distances":"\"../../styles/distances.module.css\"","marginSmall":"0.2rem","container":"_container_1kqth_4"};
export { css, digest };
  