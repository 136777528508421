import { UserObject } from '../models/Employee/Types';
import { Measure, MeasureSubmit } from '../models/Measure/Types';
import {
    ParticipantDeactivationSubmit,
    ParticipantMeasure,
    ParticipantMeasureHistory,
    ParticipantWithPresence
} from '../models/Participant/Types';
import { QmDocument } from '../models/QmDocument/Types';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';

/**
 * Hook that returns methods to communicate with the backends measures api.
 */
export const useMeasuresApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API to get all measures
     *
     */
    const apiGetMeasures = useCallback(async (): Promise<Measure[]> => {
        return call<void, Measure[]>('measures', 'GET');
    }, [call]);

    /**
     * API to get measure by an id
     *
     * @param measureId
     */
    const apiGetMeasureById = useCallback(
        async (measureId: number): Promise<Measure> => {
            return call<void, Measure>(`measures/${measureId}`, 'GET');
        },
        [call]
    );

    /**
     * API to create measure
     *
     * @param measureInfo
     */
    const apiCreateMeasure = useCallback(
        async (measureInfo: MeasureSubmit): Promise<Measure> => {
            return call<MeasureSubmit, Measure>('measures', 'POST', measureInfo);
        },
        [call]
    );

    /**
     * API to add an user to a measure
     *
     * @param userId
     * @param measureId
     */
    const apiAddUserToMeasure = useCallback(
        async (userId: number, measureId: number) => {
            return call<{ measureId: number; userId: number }, unknown>('measures_users', 'POST', {
                measureId: measureId,
                userId: userId
            });
        },
        [call]
    );

    /**
     * API call to get all measure participants
     *
     * @param measureId
     */
    const apiGetActiveParticipantsInMeasure = useCallback(
        async (measureId: number): Promise<ParticipantWithPresence[]> => {
            return call<void, ParticipantWithPresence[]>(`measures/${measureId}/active_participants`, 'GET');
        },
        [call]
    );

    /**
     * API call to add a participant to a measure
     *
     * @param participantID
     * @param measureID
     * @param presenceType
     * @param entranceDate
     */
    const apiAddParticipantToMeasure = useCallback(
        async (participantID: number, measureID: number, presenceType: string, entranceDate: Date) => {
            return call<
                {
                    measureId: number;
                    participantId: number;
                    presenceType: string;
                    entranceDate: Date;
                },
                unknown
            >('measures_participants', 'POST', {
                measureId: measureID,
                participantId: participantID,
                presenceType: presenceType,
                entranceDate: entranceDate
            });
        },
        [call]
    );

    /**
     * Returns all History entries of the specified measuresParticipant record.
     *
     *
     * @param measuresParticipantId
     */
    const apiGetMeasuresParticipantHistory = useCallback(
        async (measuresParticipantId: number): Promise<ParticipantMeasureHistory[]> => {
            return call<void, ParticipantMeasureHistory[]>(
                `measures_participants/${measuresParticipantId}/history`,
                'GET'
            );
        },
        [call]
    );

    /**
     * API call to delete a measure
     *
     * @param measureId
     */
    const apiDeleteMeasure = useCallback(
        async (measureId: number) => {
            return call(`measures/${measureId}`, 'DELETE');
        },
        [call]
    );

    /**
     * Api to update a measure
     *
     * @param measure
     * @param measureId
     */
    const apiUpdateMeasure = useCallback(
        async (measure: Partial<MeasureSubmit>, measureId: number) => {
            return call<Partial<MeasureSubmit>, Measure>(`measures/${measureId}`, 'PATCH', measure);
        },
        [call]
    );

    /**
     * API to get QMDocuments corresponding to a measure
     *
     * @param measureId
     */
    const apiGetQMDocumentsOfMeasure = useCallback(
        async (measureId: number): Promise<QmDocument[]> => {
            return call<void, QmDocument[]>(`measures/${measureId}/qm_documents`, 'GET');
        },
        [call]
    );

    /**
     * API to receive all active users that are connected to a measure
     *
     * @param measureId
     */
    const apiGetActiveUsersOfMeasure = useCallback(
        async (measureId: number): Promise<UserObject[]> => {
            return call(`measures/${measureId}/active_users`, 'GET');
        },
        [call]
    );

    /**
     * API to archive a participant for a specific measure
     *
     * @param measureId
     * @param participantId
     * @param reason
     */
    const apiArchiveParticipantForMeasure = useCallback(
        async (measureId: number, participantId: number, reason: ParticipantDeactivationSubmit) => {
            return call<ParticipantDeactivationSubmit, unknown>(
                `measures/${measureId}/participants/${participantId}/deactivate`,
                'DELETE',
                reason
            );
        },
        [call]
    );

    /**
     * API request to remove a participant from the measure.
     *
     *
     * @param measureId
     * @param participantId
     */
    const apiRemoveParticipantFromMeasure = useCallback(
        async (measureId: number, participantId: number) => {
            return call<void, unknown>(`measures/${measureId}/participants/${participantId}/remove`, 'DELETE');
        },
        [call]
    );

    /**
     * API to reactive an archived user for a measure
     *
     * @param measureId
     * @param userId
     */
    const apiReactivateArchivedUserInMeasure = useCallback(
        async (measureId: number, userId: number) => {
            return call<void, unknown>(`measures/${measureId}/users/${userId}/activate`, 'POST');
        },
        [call]
    );

    /**
     * API to reactivate an archived participant in a measure
     *
     * @param measureId
     * @param participantId
     */
    const apiReactivateArchivedParticipantInMeasure = useCallback(
        async (measureId: number, participantId: number) => {
            return call<void, unknown>(`measures/${measureId}/participants/${participantId}/activate`, 'POST');
        },
        [call]
    );

    const apiUpdateMeasureParticipant = useCallback(
        async (
            measureParticipantId: number,
            measureParticipant: Partial<ParticipantMeasure>
        ): Promise<ParticipantMeasure> => {
            return call<Partial<ParticipantMeasure>, ParticipantMeasure>(
                `measures_participants/${measureParticipantId}`,
                'PATCH',
                measureParticipant
            );
        },
        [call]
    );

    /**
     * API to copy a measure with the participants given by their ids
     *
     * @param oldMeasureId
     * @param measure
     * @param participantsIds
     */
    const apiCopyMeasure = useCallback(
        async (
            oldMeasureId: number,
            measure: Partial<Measure>, // TODO: & Pick<Measure, 'id'>,
            participantsIds: number[]
        ) => {
            return call<{ measure: Partial<Measure>; participantsIds: number[] }, unknown>(
                `measures/${oldMeasureId}/copy`,
                'POST',
                { measure: measure, participantsIds: participantsIds }
            );
        },
        [call]
    );

    return {
        apiGetMeasures: apiGetMeasures,
        apiGetMeasureById: apiGetMeasureById,
        apiCreateMeasure: apiCreateMeasure,
        apiAddUserToMeasure: apiAddUserToMeasure,
        apiGetActiveParticipantsInMeasure: apiGetActiveParticipantsInMeasure,
        apiAddParticipantToMeasure: apiAddParticipantToMeasure,
        apiGetMeasuresParticipantHistory: apiGetMeasuresParticipantHistory,
        apiDeleteMeasure: apiDeleteMeasure,
        apiUpdateMeasure: apiUpdateMeasure,
        apiGetQMDocumentsOfMeasure: apiGetQMDocumentsOfMeasure,
        apiGetActiveUsersOfMeasure: apiGetActiveUsersOfMeasure,
        apiArchiveParticipantForMeasure: apiArchiveParticipantForMeasure,
        apiRemoveParticipantFromMeasure: apiRemoveParticipantFromMeasure,
        apiReactivateArchivedUserInMeasure: apiReactivateArchivedUserInMeasure,
        apiReactivateArchivedParticipantInMeasure: apiReactivateArchivedParticipantInMeasure,
        apiUpdateMeasureParticipant: apiUpdateMeasureParticipant,
        apiCopyMeasure: apiCopyMeasure
    };
};
