import { Formik } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { KeyedMutator } from 'swr';
import { AppRoutes } from '../../configuration/AppRoutes';
import { Measure } from '../../models/Measure/Types';
import { Button } from '../Button/Button';
import { DetailsFormList } from '../DetailsFormList/DetailsFormList';
import { Icon } from '../Icon/Icon';
import { MeasureDetailsQmDocuments } from '../MeasureDetailsQMDocuments/MeasureDetailsQmDocuments';
import { MeasureUser } from '../MeasureUser/MeasureUser';
import { Statistics } from '../Statistics/Statistics';
import { SubNavTab } from '../SubNavTab/SubNavTab';
import './MeasureDetails.css';
import { DownloadButton } from '../DownloadButton/DownloadButton';
import { ParticipantsManagement } from '../ParticipantsManagement/ParticipantsManagement';
import { DetailsValidationScheme } from '../../models/Measure/Measure';
import { MeasureFamily } from '../MeasureFamily/MeasureFamily';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface MeasureDetailsProps {
    measure: Measure | undefined;
    mutate: KeyedMutator<Measure[]>;
}

export const MeasureDetails = (props: MeasureDetailsProps) => {
    const [menuSelected, setMenuSelected] = useState<string>('Informationen');
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();

    const { data: measure, mutate: mutateMeasure } = useSWR([
        backendUrl,
        props.measure ? `measures/${props.measure.id}/with_family` : null,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    /**
     * Call back which navigation element was selected by the user
     * @param menu
     */
    const onGetSelection = (menu: string) => {
        setMenuSelected(menu);
    };

    /**
     * Update the measure state in this and other components.
     */
    const onMeasureUpdated = () => {
        props.mutate().then();
        mutateMeasure().then();
    };

    return (
        <div className={'measure-details'}>
            <div className="measure-details-nav">
                <SubNavTab navigations={['Informationen', 'Statistik', 'Teilnehmer']} onGetSelected={onGetSelection} />
            </div>
            <div className="measure-details-form">
                <div className="measure-details-form-flex">
                    <div className="p2-medium">{menuSelected}</div>
                    {menuSelected === 'Statistik' && authInfo && props.measure?.id && (
                        <DownloadButton
                            targetPath={`pdf_creation/statistics?measure_id=${props.measure.id}`}
                            filename={`Statistiken_${props.measure?.name || ''}.pdf`}
                            type={'primary'}
                            size={'medium'}
                            buttonStyle={'outline'}
                            text={'Statistik drucken'}
                            className="statistics-print-button"
                            firstIcon={<Icon type={'Download'} />}
                        />
                    )}
                </div>
                {menuSelected === 'Informationen' && (
                    <div className="measure-details-form-body">
                        <div className="measure-details-form-details-user">
                            <div className="measure-details-form-information">
                                {measure && (
                                    <Formik
                                        initialValues={measure}
                                        validationSchema={DetailsValidationScheme}
                                        // We need to pass a handler here because formik expects one, but we do not want to use the formik submit mechanism
                                        onSubmit={() => console.log('Form submit.')}
                                        enableReinitialize
                                    >
                                        {({ values, errors, handleChange, setFieldValue }) => (
                                            <form>
                                                {' '}
                                                <DetailsFormList
                                                    measure={measure}
                                                    setFieldValue={setFieldValue}
                                                    onMeasureUpdated={onMeasureUpdated}
                                                    values={values}
                                                    handleChange={handleChange}
                                                    errors={errors}
                                                    isUpdateOnChangeEnabled={true}
                                                />{' '}
                                            </form>
                                        )}
                                    </Formik>
                                )}
                            </div>
                            {measure ? (
                                <div className="measure-details-form-information">
                                    <MeasureUser measure={measure} />
                                </div>
                            ) : null}
                            {measure && (measure.parent || measure.children?.length > 0) && (
                                <MeasureFamily measure={measure} />
                            )}
                        </div>
                        <div className="measure-details-qm-documents">
                            <div className="measure-details-qm-documents-title">
                                <div className="p3-medium">QM-Dokumente</div>
                                <Link to={AppRoutes.admin.qmDocuments}>
                                    <Button
                                        type={'primary'}
                                        size={'small'}
                                        buttonStyle={'link'}
                                        secondIcon={<Icon type={'ArrowRight'} />}
                                        text={'Details ansehen'}
                                    />
                                </Link>
                            </div>
                            <MeasureDetailsQmDocuments measure={props.measure} />
                        </div>
                    </div>
                )}
                {menuSelected === 'Statistik' && (
                    <div className="measure-details-form-body">
                        <div className="measure-details-form-details-user">
                            {measure && <Statistics measure={measure} />}
                        </div>
                    </div>
                )}
                {menuSelected === 'Teilnehmer' && (
                    <div className="measure-details-form-body">
                        <div className="measure-details-form-details-user">
                            {measure && <ParticipantsManagement measure={measure} />}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
