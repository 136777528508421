import { InputSelect } from '../../../components/InputSelect/InputSelect';
import { Label } from '../../../components/Label/Label';
import './CreateMeasureState.css';
import { FormikTouched } from 'formik';

interface CreateMeasureStateProps {
    setFieldValue: (arg1: string, arg2: string) => void;
    values?: string;
    name?: string;
    error?: string;
    touched?: FormikTouched<unknown>;
}

export const CreateMeasureState = (props: CreateMeasureStateProps) => {
    const onChangeValue = (value: string | undefined) => {
        if (!value) return;

        props.setFieldValue('state', value);
    };

    const states = [
        { value: 'Baden-Württemberg', id: 'Baden-Württemberg' },
        { value: 'Bayern', id: 'Bayern' },
        { value: 'Berlin', id: 'Berlin' },
        { value: 'Brandenburg', id: 'Brandenburg' },
        { value: 'Bremen', id: 'Bremen' },
        { value: 'Hamburg', id: 'Hamburg' },
        { value: 'Hessen', id: 'Hessen' },
        { value: 'Mecklenburg-Vorpommern', id: 'Mecklenburg-Vorpommern' },
        { value: 'Niedersachen', id: 'Niedersachen' },
        { value: 'Nordrhein-Westfalen', id: 'Nordrhein-Westfalen' },
        { value: 'Rheinland-Pfalz', id: 'Rheinland-Pfalz' },
        { value: 'Saarland', id: 'Saarland' },
        { value: 'Sachsen', id: 'Sachsen' },
        { value: 'Sachsen-Anhalt', id: 'Sachsen-Anhalt' },
        { value: 'Schleswig-Holstein', id: 'Schleswig-Holstein' },
        { value: 'Thüringen', id: 'Thüringen' }
    ];

    return (
        <>
            <h5 className="create-measure-input-title">In welchem Bundesland findet die Maßnahme statt?</h5>
            <Label className="create-measure-state-label" size={1}>
                Bundesland wählen
            </Label>
            <InputSelect
                dropdownOptions={states}
                onChange={onChangeValue}
                initialValue={props.values}
                name={props.name}
                error={props.touched && props.error ? props.error : ''}
            />
        </>
    );
};
