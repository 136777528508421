import { Link } from 'react-router-dom';

import styles from './UserPageLink.module.css';

/**
 * Props for the UserPageLink component.
 */
interface UserPageLinkProps {
    // The text shown in the link
    linkText: string;

    // The link target
    href: string;
}

/**
 * Shows a text followed by the link styled for the user management area.
 *
 * @param props
 * @constructor
 */
export const UserPageLink = (props: UserPageLinkProps) => {
    return (
        <div className={styles.container}>
            <Link to={props.href}>{props.linkText}</Link>
        </div>
    );
};
