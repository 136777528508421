import React, { ReactElement } from 'react';
import Select, { MultiValue } from 'react-select';
import { formatDate } from '../../models/Date/Date';
import {
    estimates,
    mapNeedForActionEstimate,
    mapNeedForActionPriority
} from '../../models/NeedForAction/NeedForAction';
import {
    NeedForActionEstimate,
    NeedForActionSubmit,
    NeedForActionSuggestion,
    NeedForActionSuggestionTypes
} from '../../models/NeedForAction/Type';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Button } from '../Button/Button';
import { FullScreenModal } from '../FullScreenModal/FullScreenModal';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { InputSelect } from '../InputSelect/InputSelect';
import { Label } from '../Label/Label';
import { Tag } from '../Tag/Tag';
import './CreateNeedForActionForm.css';
import { Datepicker } from '../Datepicker/Datepicker';
import { FormikSetFieldValue } from '../../models/Formik/Type';
import { useNeedForActionsApi } from '../../api/useNeedForActionsApi';

interface CreateNeedForActionFormProps {
    needForAction: {
        action: NeedForActionSuggestionTypes | undefined;
        suggestion: NeedForActionSuggestion[] | undefined;
    };
    values: NeedForActionSubmit;
    handleChange: {
        (e: React.ChangeEvent<unknown>): void;
        <T = string | React.ChangeEvent<unknown>>(
            field: T
        ): T extends React.ChangeEvent<unknown> ? void : (e: string | React.ChangeEvent<unknown>) => void;
    };
    setFieldValue: FormikSetFieldValue;
    onGoBack: () => void;
    initialValues: NeedForActionSubmit;
    multipleAnswers?: boolean;
    onClose: () => void;
}

export const CreateNeedForActionForm = (props: CreateNeedForActionFormProps) => {
    const priorities = [
        { value: 'Hoch', id: 'high' },
        { value: 'Mittel', id: 'medium' },
        {
            value: 'Niedrig',
            id: 'low'
        },
        { value: 'Information', id: 'information' }
    ];
    const { apiCreateNeedForAction, apiCreateNeedForActionComment } = useNeedForActionsApi();

    const onChangeNeedForActionPriority = (value: string | undefined) => {
        props.setFieldValue('priority', value);
    };

    /**
     * Callback to create new need for action and corresponding comment
     */
    const onCreateNeedForAction = async () => {
        try {
            const needForAction = await apiCreateNeedForAction(props.values);
            if (needForAction && props.values.newComment) {
                props.values.comment.text = props.values.newComment;
                props.values.comment.needForActionId = needForAction.id;
                await apiCreateNeedForActionComment(props.values.comment);
            }

            showSuccessMessage('Handlungsbedarf erstellt');
            props.onGoBack();
        } catch (e) {
            throwError('Fehler beim Erstellen des Handlungsbedarfs');
            console.log(e);
        }
    };

    const onChangeNeedForActionEstimate = (estimate: NeedForActionEstimate | undefined) => {
        props.setFieldValue('estimate', estimate);
    };

    const onEnterInput = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && props.values.comment.text) {
            onCreateNeedForAction().then();
        }
    };

    const onChangeAnswer = (
        answer: MultiValue<{
            label: string | number | boolean | null | undefined;
            value: string | number | boolean | null | undefined;
        }>
    ) => {
        props.setFieldValue(
            'options',
            answer.map((item) => {
                return {
                    name: props.needForAction.action?.options[0].name,
                    type: props.needForAction.action?.options[0].type,
                    value: item.value
                };
            })
        );
    };

    /**
     * Function that renders the options an user can enter based on the need for action
     */
    const renderNeedForActionsForm = () => {
        const elements: ReactElement[] = [];
        props.needForAction.action?.options.forEach((option, index) => {
            if (option.type === 'enum' && option.enumValues) {
                elements.push(
                    <InputSelect
                        label={'Antwort'}
                        dropdownOptions={option.enumValues.map((option) => {
                            return { value: option, id: option };
                        })}
                        initialValue={
                            props.values.options[index]?.value && typeof props.values.options[index].value === 'string'
                                ? props.values.options[index].value
                                : ''
                        }
                        onChange={(arg) =>
                            props.setFieldValue(`options[${index}]`, {
                                name: props.needForAction?.action?.options[index].name,
                                type: props.needForAction?.action?.options[index].type,
                                value: arg
                            })
                        }
                    />
                );
            } else if (option.type === 'boolean') {
                elements.push(
                    <InputSelect
                        label={'Antwort'}
                        dropdownOptions={[
                            { value: 'Ja', id: 'Ja' },
                            { value: 'Nein', id: 'Nein' }
                        ]}
                        initialValue={
                            props.values.options[index]?.value && typeof props.values.options[index].value === 'string'
                                ? props.values.options[index].value
                                : ''
                        }
                        onChange={(arg) =>
                            props.setFieldValue(`options[${index}]`, {
                                name: props.needForAction?.action?.options[index].name,
                                type: props.needForAction?.action?.options[index].type,
                                value: arg
                            })
                        }
                    />
                );
            } else if (option.type === 'string') {
                elements.push(
                    <Input
                        label={option.name}
                        onChange={(event) =>
                            props.setFieldValue(`options[${index}]`, {
                                name: props.needForAction?.action?.options[index].name,
                                type: props.needForAction?.action?.options[index].type,
                                value: event.target.value
                            })
                        }
                    />
                );
            } else if (option.type === 'date') {
                elements.push(
                    <Datepicker
                        label={'Antwort'}
                        value={
                            props.values.options[index] && props.values.options[index].value
                                ? // the line above tests this, hence the ! is ok here
                                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  new Date(props.values.options[index].value!)
                                : new Date()
                        }
                        onChange={(date) =>
                            props.setFieldValue(`options[${index}]`, {
                                name: props.needForAction?.action?.options[index].name,
                                type: props.needForAction?.action?.options[index].type,
                                value: date
                            })
                        }
                    />
                );
            }
        });
        return elements;
    };

    return (
        <FullScreenModal
            footer={
                <>
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'outline'}
                        text={'Abbrechen'}
                        onClick={props.onClose}
                    />
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'filled'}
                        text={'Handlungsfeld speichern'}
                        onClick={onCreateNeedForAction}
                    />
                </>
            }
        >
            <div className="create-need-for-action">
                <div style={{ display: 'flex', flexDirection: 'column', gap: '42px' }}>
                    <div className="create-need-for-action-head">
                        <Icon type={'ArrowLeft'} onClick={props.onGoBack} />
                        <h5>{props.needForAction?.action?.needForActionType}</h5>
                    </div>
                    <div className="create-need-for-action-form">
                        <div className="create-need-for-action-form-body">
                            {!props.multipleAnswers && props.values.options && renderNeedForActionsForm()}

                            {props.multipleAnswers && (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Label className={'input-select-label'} size={3}>
                                        Antwort
                                    </Label>
                                    <Select
                                        options={props.needForAction.action?.options[0].enumValues?.map((option) => {
                                            return {
                                                value: option,
                                                label: option
                                            };
                                        })}
                                        isMulti
                                        onChange={onChangeAnswer}
                                        placeholder={'Antwort'}
                                        value={props.values.options.map((option) => {
                                            return {
                                                label: option.value,
                                                value: option.value
                                            };
                                        })}
                                    />
                                </div>
                            )}
                            <div>
                                {props.values.priority !== 'information' && (
                                    <InputSelect
                                        label={'Einschätzung'}
                                        initialValue={mapNeedForActionEstimate(props.values.estimate)}
                                        dropdownOptions={estimates.map((estimate) => {
                                            return {
                                                value: mapNeedForActionEstimate(estimate),
                                                id: estimate
                                            };
                                        })}
                                        onChange={onChangeNeedForActionEstimate}
                                    />
                                )}
                            </div>
                            <InputSelect
                                label={'Priorität'}
                                initialValue={mapNeedForActionPriority(props.values.priority)}
                                dropdownOptions={priorities}
                                onChange={onChangeNeedForActionPriority}
                            />
                        </div>
                        {props.needForAction.suggestion && (
                            <Tag
                                className={`tag-need-for-action tag-${props.needForAction.suggestion[0].category
                                    .toLowerCase()
                                    .replace(/[^A-Za-z]+/g, '')}`}
                            >
                                <Label size={3}>{props.needForAction.suggestion[0].category}</Label>
                            </Tag>
                        )}
                    </div>
                </div>
                <div className="update-task-details-render-comments">
                    <div className="create-need-for-action-comments-border">
                        <div className="create-need-for-action-comment">
                            <div className="create-need-for-action-comment-icon">
                                <Icon type={'DocumentText'} />
                                <div className={'p4-medium'}>{formatDate(new Date())}</div>
                            </div>
                            <Input
                                className="update-task-form-text"
                                name="newComment"
                                placeholder={'Notizen hinzufügen'}
                                value={props.values.newComment}
                                onChange={props.handleChange}
                                onKeyDown={onEnterInput}
                                icon={<Icon type={'Enter'} />}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </FullScreenModal>
    );
};
