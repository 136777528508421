import {
    QmDocumentTemplate,
    QmDocumentTemplateFormValues,
    QmDocumentTemplatesSearch
} from '../models/QmDocumentTemplate/Types';
import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';
import { EzOnRailsSearchFilterRequest } from '../models/Search/Type';

/**
 * Returns methods to communicate with the backend related to QmDocumentTemplates.
 */
export const useQmDocumentTemplatesApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API to get all qmDocumentTemplates.
     *
     *
     */
    const apiGetQmDocumentTemplates = useCallback(async (): Promise<QmDocumentTemplate[]> => {
        return call<void, QmDocumentTemplate[]>('qm_document_templates', 'GET');
    }, [call]);

    /**
     * API to create a qm document template
     *
     * @param qmDocumentTemplate
     */
    const apiCreateQmDocumentTemplate = useCallback(
        async (qmDocumentTemplate: QmDocumentTemplateFormValues): Promise<QmDocumentTemplate> => {
            return call<{ qmDocumentTemplate: QmDocumentTemplateFormValues }, QmDocumentTemplate>(
                `qm_document_templates`,
                'POST',
                { qmDocumentTemplate: qmDocumentTemplate }
            );
        },
        [call]
    );

    /**
     * API to delete a qm document template
     *
     * @param qmDocumentTemplateId
     */
    const apiDeleteQmDocumentTemplate = useCallback(
        async (qmDocumentTemplateId: number) => {
            return call<void, unknown>(`/qm_document_templates/${qmDocumentTemplateId}`, 'DELETE');
        },
        [call]
    );

    /**
     * API to update a qm document template
     *
     * @param qmDocumentTemplate
     */
    const apiUpdateQmDocumentTemplate = useCallback(
        async (
            qmDocumentTemplateId: number,
            qmDocumentTemplate: Partial<QmDocumentTemplate>
        ): Promise<QmDocumentTemplate> => {
            return call<{ qmDocumentTemplate: Partial<QmDocumentTemplate> }, QmDocumentTemplate>(
                `qm_document_templates/${qmDocumentTemplateId}`,
                'PATCH',
                { qmDocumentTemplate: qmDocumentTemplate }
            );
        },
        [call]
    );

    const apiSearchQmDocumentTemplatesForPrintingNeededAtParticipantCreation =
        useCallback(async (): Promise<QmDocumentTemplatesSearch> => {
            return call<EzOnRailsSearchFilterRequest, QmDocumentTemplatesSearch>(
                'qm_document_templates/search',
                'POST',
                {
                    filter: {
                        field: 'needed_at',
                        operator: 'eq',
                        value: 0
                    }
                }
            );
        }, [call]);

    const apiSearchQmDocumentTemplatesForPrintingNeededAtRequest =
        useCallback(async (): Promise<QmDocumentTemplatesSearch> => {
            return call<EzOnRailsSearchFilterRequest, QmDocumentTemplatesSearch>(
                'qm_document_templates/search',
                'POST',
                {
                    filter: {
                        field: 'needed_at',
                        operator: 'eq',
                        value: 1
                    }
                }
            );
        }, [call]);

    return {
        apiGetQmDocumentTemplates: apiGetQmDocumentTemplates,
        apiCreateQmDocumentTemplate: apiCreateQmDocumentTemplate,
        apiDeleteQmDocumentTemplate: apiDeleteQmDocumentTemplate,
        apiUpdateQmDocumentTemplate: apiUpdateQmDocumentTemplate,
        apiSearchQmDocumentTemplatesForPrintingNeededAtParticipantCreation:
            apiSearchQmDocumentTemplatesForPrintingNeededAtParticipantCreation,
        apiSearchQmDocumentTemplatesForPrintingNeededAtRequest: apiSearchQmDocumentTemplatesForPrintingNeededAtRequest
    };
};
