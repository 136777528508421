
const digest = '9e446ce2706bf177e17a53a7d0b98c052265eb10f4862ab6fd03a6c3a21e3e8c';
const css = `/* font *//* background *//* borders *//* fonts *//* paddings *//* margins and gaps *//* borders */

._container_13d6j_6 {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    background-color: #f7f7f7;
    border-bottom: grey 1px solid;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
}

._innerContainer_13d6j_17 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"colors":"\"../../../shared/styles/colors.module.css\"","colorBorderDark":"grey","colorBackgroundToolbar":"#f7f7f7","distances":"\"../../../shared/styles/distances.module.css\"","sizeBorderSmall":"1px","radiusBorderMedium":"0.6rem","container":"_container_13d6j_6","innerContainer":"_innerContainer_13d6j_17"};
export { css, digest };
  