import React, { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { formatDate } from '../../models/Date/Date';
import { FormikHandleChange } from '../../models/Formik/Type';
import { mapNeedForActionTendency } from '../../models/NeedForAction/NeedForAction';
import { NeedForActionComment, NeedForActionProgress } from '../../models/NeedForAction/Type';
import { ObjectiveAgreement } from '../../models/ObjectiveAgreement/Type';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { DropDown } from '../DropDown/DropDown';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { Modal } from '../Modal/Modal';
import './SingleComment.css';
import { useNeedForActionsApi } from '../../api/useNeedForActionsApi';
import { Datepicker } from '../Datepicker/Datepicker';

interface SingleCommentProps {
    comment: NeedForActionComment | NeedForActionProgress | ObjectiveAgreement;
    handleChange: FormikHandleChange;
    reload: () => Promise<void>;
}

export const SingleComment = (props: SingleCommentProps) => {
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    const { apiUpdateNeedForActionComment, apiDeleteNeedForActionComment } = useNeedForActionsApi();

    useOnClickOutside(componentRef, () => {
        setIsSelected(false);
    });
    /**
     * Callback to show the delete modal or close it
     */
    const onShowDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal);
    };

    /**
     * Show input field to change the comment
     */
    const onChangeIsSelected = () => {
        setIsSelected(!isSelected);
    };

    /**
     * changed need for action comment
     */
    const onChangeText = async () => {
        try {
            if (!props.comment.id) return;

            await apiUpdateNeedForActionComment(props.comment.id, props.comment);
            onChangeIsSelected();
            props.reload();
            showSuccessMessage();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Called if the date of the comment was changed.
     * Updates the date in the backend and removes the selection.
     *
     * @param date
     */
    const onChangeDate = async (date: Date) => {
        try {
            if (!props.comment.id) return;

            await apiUpdateNeedForActionComment(props.comment.id, { date: date });

            setIsSelected(false);
            await props.reload();
            showSuccessMessage();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Change need for action after the enter key was pressed
     * @param event
     */
    const onChangeObjectiveAgreementWithEnter = async (
        event: React.KeyboardEvent<HTMLDivElement | HTMLTextAreaElement>
    ) => {
        if (event.key === 'Enter') {
            await onChangeText();
        }
    };

    /**
     * Delete need for action comment
     */
    const onDelete = async () => {
        try {
            if (!props.comment.id) return;

            await apiDeleteNeedForActionComment(props.comment.id);
            props.reload();
            onShowDeleteModal();
            showSuccessMessage();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    return (
        <div className="single-comment-wrapper" ref={componentRef}>
            <div className="single-comment">
                <div className="update-need-for-action-comments-icon-created-at">
                    <Icon type={'DocumentText'} />
                    {isSelected ? (
                        <>
                            <Datepicker onChange={onChangeDate} value={props.comment.date} position={'absolute-left'} />
                        </>
                    ) : (
                        <>
                            {'date' in props.comment && <div>{formatDate(new Date(props.comment.date))}</div>}
                            {'tendency' in props.comment && <div>{formatDate(new Date(props.comment.date))}</div>}
                        </>
                    )}
                </div>
                {'text' in props.comment ? (
                    isSelected ? (
                        <Input
                            value={props.comment.text}
                            name={'text'}
                            className={'width-100'}
                            onKeyDown={onChangeObjectiveAgreementWithEnter}
                            onChange={props.handleChange}
                            onBlur={onChangeText}
                        />
                    ) : (
                        <>
                            <div>{props.comment.text}</div>
                            {'text' in props.comment && (
                                <DropDown
                                    className={'single-objective-dropdown'}
                                    bodyClassName={'single-objective-dropdown-body'}
                                    title={<Icon type={'DotsHorizontal'} />}
                                >
                                    <>
                                        <div onClick={onChangeIsSelected}>Bearbeiten</div>
                                        <div onClick={onShowDeleteModal}>Löschen</div>
                                    </>
                                </DropDown>
                            )}
                        </>
                    )
                ) : null}
                {'tendency' in props.comment && <div>{mapNeedForActionTendency(props.comment.tendency)} </div>}
            </div>
            {isSelected && 'text' in props.comment && (
                <div className="p5-medium create-measure-detail-enter-hint">
                    Eingabetaste
                    <Icon
                        className="create-measure-detail-enter-hint-icon"
                        iconFile={
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 2.66667C0 1.19391 1.19391 0 2.66667 0H13.3333C14.8061 0 16 1.19391 16 2.66667V13.3333C16 14.8061 14.8061 16 13.3333 16H2.66667C1.19391 16 0 14.8061 0 13.3333V2.66667Z"
                                    fill="#E4E4E7"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.8333 3.33301C13.1095 3.33301 13.3333 3.55687 13.3333 3.83301V7.33301C13.3333 8.34553 12.5125 9.16634 11.5 9.16634H4.42427L6.50997 11.1362C6.71073 11.3258 6.71977 11.6422 6.53016 11.843C6.34056 12.0437 6.0241 12.0528 5.82334 11.8632L2.82334 9.02985C2.72333 8.93539 2.66666 8.80391 2.66666 8.66634C2.66666 8.52878 2.72333 8.39729 2.82334 8.30283L5.82334 5.4695C6.0241 5.2799 6.34056 5.28894 6.53016 5.4897C6.71977 5.69045 6.71073 6.00691 6.50997 6.19651L4.42427 8.16634H11.5C11.9602 8.16634 12.3333 7.79325 12.3333 7.33301V3.83301C12.3333 3.55687 12.5572 3.33301 12.8333 3.33301Z"
                                    fill="#71717A"
                                    stroke="#71717A"
                                    strokeWidth="0.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M2.66667 0.666667H13.3333V-0.666667H2.66667V0.666667ZM15.3333 2.66667V13.3333H16.6667V2.66667H15.3333ZM13.3333 15.3333H2.66667V16.6667H13.3333V15.3333ZM0.666667 13.3333V2.66667H-0.666667V13.3333H0.666667ZM2.66667 15.3333C1.5621 15.3333 0.666667 14.4379 0.666667 13.3333H-0.666667C-0.666667 15.1743 0.825718 16.6667 2.66667 16.6667V15.3333ZM15.3333 13.3333C15.3333 14.4379 14.4379 15.3333 13.3333 15.3333V16.6667C15.1743 16.6667 16.6667 15.1743 16.6667 13.3333H15.3333ZM13.3333 0.666667C14.4379 0.666667 15.3333 1.5621 15.3333 2.66667H16.6667C16.6667 0.825718 15.1743 -0.666667 13.3333 -0.666667V0.666667ZM2.66667 -0.666667C0.825718 -0.666667 -0.666667 0.825718 -0.666667 2.66667H0.666667C0.666667 1.5621 1.5621 0.666667 2.66667 0.666667V-0.666667Z"
                                    fill="#E4E4E7"
                                />
                            </svg>
                        }
                    />
                    zum fortfahren
                </div>
            )}
            {showDeleteModal && (
                <Modal
                    show={showDeleteModal}
                    header={'Kommentar löschen'}
                    buttons={{
                        primary: { text: 'Ja, Kommentar löschen', onClick: onDelete },
                        secondary: { text: 'Abbrechen', onClick: onShowDeleteModal },
                        swapped: true
                    }}
                >
                    <div>
                        Sie sind dabei, einen Kommentar zu löschen. Einmal gelöschte Kommentare können nicht wieder
                        hergestellt werden.
                    </div>
                    <div>Sind Sie sicher, dass Sie den Kommentar löschen möchten?</div>
                </Modal>
            )}
        </div>
    );
};
