import React, { ReactNode } from 'react';
import styles from './Modal.module.css';
import { Button } from '../Button/Button';

/**
 * Describes one button in the footer of the modal.
 */
interface ModalButton {
    // The text of the button
    text: string;

    // Called if the user clicks the button
    onClick: () => void;
}

/**
 * Props for the Modal component.
 */
interface ModalProps {
    // Shown at the top of the modal as header, if givem
    header?: ReactNode;

    // the content of the modal
    children: ReactNode;

    // Optional footer that is shown below the content
    footer?: ReactNode;

    // If the buttons are passed instead of the footer, they will be shown
    buttons?: {
        primary?: ModalButton;
        secondary?: ModalButton;

        // Swapps the primary and secondary style of the buttons
        swapped?: boolean;
    };

    // Indicates whether the modal should be shown
    show?: boolean;

    // Optional class name that is passed to the content container
    className?: string;

    // Defines the size of the inner container
    size?: 'default' | 'large';
}

/**
 * Shows an modal holding its children in a white box as content.
 *
 * @param props
 * @constructor
 */
export const Modal = (props: ModalProps) => {
    return props.show ? (
        <div className={styles.outerContainer}>
            <div className={`${styles.container} ${props.size}`}>
                <div>
                    {props.header && <div className={`${styles.headerContainer} p2-semi-bold`}>{props.header}</div>}
                    <div className={`${styles.bodyContainer} p4-regular`}>{props.children}</div>
                </div>

                {props.buttons ? (
                    <div className={styles.buttonsContainer}>
                        {props.buttons.secondary && (
                            <Button
                                buttonStyle={props.buttons.swapped ? 'filled' : 'outline'}
                                size={'medium'}
                                type={props.buttons.swapped ? 'primary' : 'secondary'}
                                text={props.buttons.secondary.text}
                                onClick={props.buttons.secondary.onClick}
                            />
                        )}

                        {props.buttons.primary && (
                            <Button
                                buttonStyle={'filled'}
                                size={'medium'}
                                type={props.buttons.swapped ? 'secondary' : 'primary'}
                                text={props.buttons.primary.text}
                                onClick={props.buttons.primary.onClick}
                            />
                        )}
                    </div>
                ) : (
                    <div className={styles.footerContainer}>{props.footer}</div>
                )}
            </div>
        </div>
    ) : null;
};
