
const digest = '1c1dbcd2c42d02299f831d49628ce9d3f5ec491ec0bcded7747b8749611ea0c7';
const css = `/* font *//* background *//* borders *//* fonts *//* paddings *//* margins and gaps *//* borders */
._label_1escg_6 {
    font-size: small;
    color: dimgray
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"colors":"\"../../styles/colors.module.css\"","colorFontSecondary":"dimgray","distances":"\"../../styles/distances.module.css\"","sizeFontSmall":"small","label":"_label_1escg_6"};
export { css, digest };
  