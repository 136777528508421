import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { NeedForAction, NeedForActionSuggestion } from '../../models/NeedForAction/Type';
import { Participant, ParticipantWithOneMeasure, ParticipantWithPresence } from '../../models/Participant/Types';
import { AttendanceCard } from '../AttendanceCard/AttendanceCard';
import { NeedForActionCard } from '../NeedForAction/NeedForActionCard';
import { ObjectList } from '../ObjectList/ObjectList';
import { ObjectListItem } from '../ObjectListItem/ObjectListItem';
import { ParticipantCourses } from '../ParticipantCourses/ParticipantCourses';
import { ParticipantDetailsCard } from '../ParticipantDetailsCard/ParticipantDetailsCard';
import { ParticipantInternships } from '../ParticipantInternships/ParticipantInternships';
import { ParticipantMuchDetailsCard } from '../ParticipantMuchDetailsCard/ParticipantMuchDetailsCard';
import { StandardView } from '../StandardView/StandardView';
import { Presence } from '../../models/Presence/Types';
import { setSelectedParticipant } from '../../models/Participant/Slice';
import { UpdateNeedForAction } from '../UpdateNeedForAction/UpdateNeedForAction';
import { ParticipantArchivedInformationCard } from '../ParticipantArchivedInformationCard/ParticipantArchivedInformationCard';
import { ParticipantLifeInformationCard } from '../ParticipantLifeInformationCard/ParticipantLifeInformationCard';
import { ParticipantMobilityCard } from '../ParticipantMobilityCard/ParticipantMobilityCard';
import { ParticipantCvCard } from '../ParticipantCvCard/ParticipantCvCard';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface ParticipantDetailInformationViewInformationProps {
    participant: ParticipantWithOneMeasure;
}

export const ParticipantDetailInformationViewInformation = (
    props: ParticipantDetailInformationViewInformationProps
) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const measureId = useAppSelector((x) => x.measure.selectedMeasure?.id);
    const [selectedMenu, setSelectedMenu] = useState<string | NeedForActionSuggestion | number>('Teilnehmerprofil');
    const menu = ['Teilnehmerprofil', 'Daten zur Person', 'Lebenslauf'];
    const { data: suggestions } = useSWR<NeedForActionSuggestion[]>([
        backendUrl,
        'need_for_actions/suggestions',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: needForActions } = useSWR<NeedForAction[]>(
        [
            backendUrl,
            `participants/${props.participant?.id}/need_for_actions`,
            'GET',
            { measureId: measureId },
            authInfo,
            apiVersion
        ],
        { refreshInterval: 10000 }
    );
    const { data: participantPresences, mutate: reloadPresences } = useSWR<Presence[]>([
        backendUrl,
        `participants/${props.participant?.id}/presences`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: activeParticipants } = useSWR<Participant[]>([
        backendUrl,
        `measures/${measureId}/active_participants`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: allParticipantsWithPresences, mutate: reloadParticipants } = useSWR<ParticipantWithPresence[]>([
        backendUrl,
        `measures/${measureId}/participants`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const dispatch = useAppDispatch();
    const [selectedNeedForAction, setSelectedNeedForAction] = useState<NeedForAction>();

    const onSelectMenu = (menuItem: string | number | NeedForActionSuggestion) => {
        setSelectedMenu(menuItem);
    };

    /**
     * Dispatch the current participant with its presences into the Redux state
     */
    useEffect(() => {
        const participant = allParticipantsWithPresences?.find(
            (participant) => participant.id === props.participant?.id
        );
        if (!participant) return;

        const measueresParticipant = allParticipantsWithPresences
            ?.find((participant) => participant.id === props.participant?.id)
            ?.measuresParticipants.find((measureParticipant) => measureParticipant.measureId === measureId);
        if (!measueresParticipant) return;

        const currentParticipant = {
            ...participant,
            ...{
                measuresParticipants: measueresParticipant
            }
        };

        dispatch(setSelectedParticipant(currentParticipant));
    }, [allParticipantsWithPresences, dispatch, measureId, props.participant?.id]);

    /**
     * Set need for action in order to update it
     * @param needForAction
     */
    const onChangeNeedForAction = (needForAction?: NeedForAction) => {
        if (selectedNeedForAction) {
            setSelectedNeedForAction(undefined);
        } else {
            setSelectedNeedForAction(needForAction);
        }
    };

    /**
     * Function to render what should be shown to the user
     */
    const renderView = () => {
        switch (selectedMenu) {
            case 'Teilnehmerprofil':
                return (
                    <>
                        <ParticipantDetailsCard
                            reloadParticipant={reloadParticipants}
                            participant={props.participant}
                        />
                        {props.participant?.measuresParticipants?.inactive && (
                            <ParticipantArchivedInformationCard
                                reloadParticipant={reloadParticipants}
                                participant={props.participant}
                            />
                        )}
                        <ParticipantLifeInformationCard
                            reloadParticipant={reloadParticipants}
                            participant={props.participant}
                        />
                        <ParticipantMobilityCard
                            reloadParticipant={reloadParticipants}
                            participant={props.participant}
                        />
                        {activeParticipants && (
                            <AttendanceCard
                                mutate={reloadPresences}
                                participant={
                                    participantPresences
                                        ? { ...props.participant, ...{ presences: participantPresences } }
                                        : props.participant
                                }
                            />
                        )}
                        {props.participant && <ParticipantInternships participant={props.participant} />}
                        {props.participant && <ParticipantCourses participant={props.participant} />}
                    </>
                );
            case 'Daten zur Person':
                return (
                    props.participant && (
                        <ParticipantMuchDetailsCard
                            reloadParticipant={reloadParticipants}
                            participant={props.participant}
                        />
                    )
                );
            case 'Lebenslauf':
                return (
                    props.participant && (
                        <ParticipantCvCard reloadParticipant={reloadParticipants} participant={props.participant} />
                    )
                );
            default:
                return needForActions
                    ?.filter((action) => action.category === selectedMenu)
                    .map((needForAction) => {
                        return (
                            <NeedForActionCard
                                key={needForAction.id}
                                onClick={onChangeNeedForAction}
                                needForAction={needForAction}
                            />
                        );
                    });
        }
    };

    return (
        <StandardView>
            {selectedNeedForAction ? (
                <UpdateNeedForAction
                    multipleAnswers={selectedNeedForAction.needForActionType.includes('Mehrfachnennung möglich')}
                    correspondingSuggestion={suggestions
                        ?.find((suggestion) =>
                            suggestion.needForActionTypes.find(
                                (type) => type.needForActionType === selectedNeedForAction?.needForActionType
                            )
                        )
                        ?.needForActionTypes.find(
                            (type) => type.needForActionType === selectedNeedForAction?.needForActionType
                        )}
                    dontShowGoBack
                    onClose={onChangeNeedForAction}
                    needForAction={selectedNeedForAction}
                    onGoBack={onChangeNeedForAction}
                />
            ) : null}
            <StandardView.Left>
                <ObjectList>
                    <ObjectList.Head>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '24px'
                            }}
                        >
                            <div className="p2-medium">Informationen zum Teilnehmer</div>
                        </div>
                    </ObjectList.Head>
                    <ObjectList.Body>
                        <div className="participant-detail-information-dates" style={{ flexDirection: 'column' }}>
                            {menu.map((item, index) => {
                                return (
                                    <ObjectListItem
                                        key={index}
                                        value={{ index: item, item: item }}
                                        selected={selectedMenu === item}
                                        onClick={onSelectMenu}
                                    >
                                        <div>{item}</div>
                                    </ObjectListItem>
                                );
                            })}
                            {suggestions?.map((suggestion, index) => {
                                return (
                                    <ObjectListItem
                                        key={index}
                                        value={{ index: suggestion.category, item: suggestion }}
                                        selected={selectedMenu === suggestion.category}
                                        onClick={onSelectMenu}
                                    >
                                        <div>{suggestion.category}</div>
                                    </ObjectListItem>
                                );
                            })}
                        </div>
                    </ObjectList.Body>
                </ObjectList>
            </StandardView.Left>
            <StandardView.Right>
                <> {renderView()} </>
            </StandardView.Right>
        </StandardView>
    );
};
