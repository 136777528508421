import { ReactElement } from 'react';
import { Button } from '../Button/Button';
import { FullscreenSidebarItem } from '../FullscreenSidebarItem/FullscreenSidebarItem';
import { Icon } from '../Icon/Icon';
import './FullscreenSidebar.css';

interface FullscreenSidebarProps {
    title: string;
    children: ReactElement | ReactElement[];
    onClose: () => void;
    className?: string;
}

export const FullscreenSidebar = (props: FullscreenSidebarProps) => {
    return (
        <div className={`full-screen-side-bar ${props.className}`}>
            <Button
                className="full-screen-side-bar-back-button label-4"
                type={'secondary'}
                size={'small'}
                buttonStyle={'link'}
                text={'Abbrechen'}
                firstIcon={<Icon type={'ArrowLeft'} />}
                onClick={props.onClose}
            />
            <div className="p1-semi-bold full-screen-side-bar-title">{props.title}</div>
            <div className="full-screen-side-bar-items">{props.children}</div>
        </div>
    );
};

FullscreenSidebar.Item = FullscreenSidebarItem;
