import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { formatDate } from '../../models/Date/Date';
import { UserObject } from '../../models/Employee/Types';
import { Measure, MeasureUser } from '../../models/Measure/Types';
import { throwError } from '../../models/Toasts/Toasts';
import { AddUserToMeasure } from '../AddUserToMeasure/AddUserToMeasure';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Modal } from '../Modal/Modal';
import './UserDetailsMeasureTable.css';
import { useMeasuresApi } from '../../api/useMeasuresApi';
import { useUsersApi } from '../../api/useUsersApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface UserDetailsMeasureTableProps {
    userMeasures?: Measure[];
    user?: UserObject;
    mutateUserMeasure: () => void;
    isArchived: boolean;
    // measures the user is archived in
    archivedMeasure: Measure[] | undefined;
}

export const UserDetailsMeasureTable = (props: UserDetailsMeasureTableProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showAddUserToMeasure, setShowAddUserToMeasure] = useState<boolean>(false);
    const [measure, setMeasure] = useState<Measure>();
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { data: activeMeasures } = useSWR<Measure[]>([
        backendUrl,
        'measures/active',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const [measuresUserCanBeAddedTo, setMeasuresUserCanBeAddedTo] = useState<Measure[]>();
    const { apiReactivateArchivedUserInMeasure } = useMeasuresApi();
    const { apiArchiveUserInMeasure } = useUsersApi();

    useEffect(() => {
        setMeasuresUserCanBeAddedTo(
            activeMeasures?.filter(
                (activeMeasure) => !props.userMeasures?.some((userMeasure) => userMeasure.id === activeMeasure.id)
            )
        );
    }, [activeMeasures, props.userMeasures]);

    const { data: measureUsers } = useSWR<MeasureUser[]>([
        backendUrl,
        `measures_users/of_user/${props.user?.id}`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    const onShowAddToMeasure = () => {
        setShowAddUserToMeasure(!showAddUserToMeasure);
    };

    /**
     * Reset if measure selection or measures changes
     */
    useEffect(() => {
        setShowAddUserToMeasure(false);
    }, [props.isArchived, props.userMeasures]);

    /**
     * Open a confirmation modal and save the measure id to a state
     * @param measure
     */
    const openModal = (measure: Measure | undefined) => {
        if (measure) {
            setMeasure(measure);
            setShowModal(true);
        }
    };

    /**
     * Remove a user from a measure, close the modal and fetch the measure_user data again
     */
    const onRemoveFromMeasure = async () => {
        if (props.user?.id && measure) {
            try {
                await apiArchiveUserInMeasure(measure.id, props.user.id);
                setShowModal(false);
                props.mutateUserMeasure();
            } catch (e) {
                console.log(e);
            }
        }
    };

    /**
     * return the date when an user was added to a measure and/or archived
     * @param measure
     */
    const measureAddedDate = (measure: Measure) => {
        const measureUser = measureUsers?.find((measureUser: MeasureUser) => measureUser.measureId === measure.id);
        if (measureUser) {
            return measureUser.archived
                ? formatDate(new Date(measureUser.archivedAt))
                : formatDate(new Date(measureUser.updatedAt));
        }
    };

    /**
     * Reactive user in a measure
     */
    const onAddToMeasure = async () => {
        try {
            if (measure && props.user?.id) {
                await apiReactivateArchivedUserInMeasure(measure.id, props.user?.id);
                setShowModal(false);
                props.mutateUserMeasure();
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    return (
        <div className={'user-details-measure-table'}>
            <Modal
                show={showModal}
                header={
                    <div>
                        {props.isArchived
                            ? 'Mitarbeiter erneut zu Maßnahme hinfügen'
                            : 'Mitarbeiter aus Maßnahme entfernen'}
                    </div>
                }
                buttons={
                    props.isArchived
                        ? {
                              primary: { text: 'Mitarbeiter hinzufügen', onClick: onAddToMeasure },
                              secondary: { text: 'Abbrechen', onClick: () => setShowModal(false) }
                          }
                        : {
                              primary: { text: 'Mitarbeiter entfernen', onClick: onRemoveFromMeasure },
                              secondary: { text: 'Abbrechen', onClick: () => setShowModal(false) }
                          }
                }
            >
                {props.isArchived ? (
                    <div>
                        Sind Sie sicher, dass Sie den Mitarbeiter {props.user?.firstName} {props.user?.lastName} erneut
                        zu dieser Maßnahme hinzufügen wollen
                    </div>
                ) : (
                    <div>
                        Sind Sie sicher, dass Sie den Mitarbeiter {props.user?.firstName} {props.user?.lastName} aus der
                        Maßnahme entfernen wollen?{' '}
                    </div>
                )}
            </Modal>
            <div>
                {props.userMeasures &&
                    props.userMeasures.map((measure: Measure) => {
                        return (
                            <div key={measure.id} className="user-details-measure-table-items">
                                <div className="p4-medium user-details-measure-table-single-item">{measure.name}</div>
                                <div className="p4-medium user-details-measure-table-date user-details-measure-table-single-item">
                                    {props.isArchived ? 'inaktiv seit' : 'aktiv seit'} {measureAddedDate(measure)}
                                </div>
                                {props.user?.archived ? (
                                    <div>Dieser Benutzer ist archiviert.</div>
                                ) : !measure.archived ? (
                                    <Button
                                        className="user-details-measure-table-single-item-button"
                                        type={'primary'}
                                        buttonStyle={'link'}
                                        size={'small'}
                                        text={
                                            props.isArchived
                                                ? 'Erneut zu Maßnahme hinzufügen'
                                                : 'Aus Maßnahme entfernen'
                                        }
                                        onClick={() => openModal(measure)}
                                    />
                                ) : (
                                    <div>Diese Maßnahme ist bereits abgeschlossen</div>
                                )}
                            </div>
                        );
                    })}
            </div>
            {!props.user?.archived &&
                !showAddUserToMeasure &&
                measuresUserCanBeAddedTo &&
                measuresUserCanBeAddedTo?.length > 0 && (
                    <Button
                        type={'primary'}
                        size={'small'}
                        buttonStyle={'link'}
                        firstIcon={<Icon type={'Plus'} />}
                        text={'Zu Maßnahme hinzufügen'}
                        onClick={onShowAddToMeasure}
                    />
                )}
            {!props.user?.archived && showAddUserToMeasure && measuresUserCanBeAddedTo && (
                <AddUserToMeasure
                    archivedMeasures={props.archivedMeasure}
                    userMeasures={props.userMeasures}
                    mutateUserMeasure={props.mutateUserMeasure}
                    measuresUserCanBeAddedTo={measuresUserCanBeAddedTo}
                    user={props.user}
                    onClose={onShowAddToMeasure}
                />
            )}
        </div>
    );
};
