import { ChangeEvent, KeyboardEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { FormInput } from '../FormInput/FormInput';
import { Label } from '../Label/Label';
import './InputSelect.css';

interface InputSelectProps<TId> {
    dropdownOptions:
        | {
              icon?: ReactElement;
              value: string | undefined;
              // undefined is used for "no selection" here
              id: TId | undefined;
          }[]
        | undefined;
    value?: string | undefined;
    onChange?: (id: TId | undefined) => void;
    initialValue?: string;
    name?: string;
    error?: string;
    size?: 'small' | 'medium' | 'large';
    label?: string;
    readonly?: boolean;
}

interface InputSelectOption<TId> {
    icon?: ReactElement;
    value: string | undefined;
    id: TId | undefined;
}

export const InputSelect = <TId,>(props: InputSelectProps<TId>) => {
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    // value is the value of the search textfield
    const [value, setValue] = useState<string | undefined>(props.initialValue ? props.initialValue : props.value);
    const [options, setOptions] = useState<InputSelectOption<TId>[]>(props.dropdownOptions || []);
    const [keyboardSelectedItemPosition, setKeyboardSelectedItemPosition] = useState<number | undefined>(undefined);
    const dropDownRef = useRef<HTMLDivElement>(null);

    /**
     * Close the select dropdown
     */
    const openCloseDropdown = useCallback(() => {
        setOpenDropdown(!openDropdown);
    }, [openDropdown]);

    /**
     * If user clicks on item in dropdown, set it as value, close the dropdown, call outside onchange function
     */
    const onClickOnItem = useCallback(
        (optionId: TId | undefined, value?: string | undefined) => {
            setValue(value);

            if (props.onChange) {
                props.onChange(optionId);
            }

            openCloseDropdown();
        },
        [openCloseDropdown, props]
    );

    /**
     * If user enters something into the input field, filter the dropdown and set the value
     * @param event
     */
    const onChangeInputSelectInput = (event: ChangeEvent<HTMLInputElement>) => {
        setOpenDropdown(true);
        setValue(event.currentTarget.value);
        const filter = props.dropdownOptions?.filter((option) => {
            if (typeof option.value === 'string') {
                return option.value.toLowerCase().includes(event.currentTarget.value.toLowerCase());
            } else {
                return '';
            }
        });
        if (filter?.length === 0) {
            filter.push({ value: 'Nicht vorhanden', id: undefined });
        }

        if (filter) {
            setOptions(filter);
        }
    };

    /**
     * Navigate through the dropdown with ArrowUp, ArrowDown and Enter
     */
    const onKeyDownPress = (event: KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                if (!openDropdown) {
                    setOpenDropdown(true);
                }

                if (
                    keyboardSelectedItemPosition !== undefined &&
                    options &&
                    keyboardSelectedItemPosition < options.length - 1
                ) {
                    setKeyboardSelectedItemPosition(keyboardSelectedItemPosition + 1);
                } else {
                    if (!keyboardSelectedItemPosition) {
                        setKeyboardSelectedItemPosition(0);
                    }
                }

                break;
            case 'ArrowUp':
                event.preventDefault();
                if (!openDropdown) {
                    setOpenDropdown(true);
                }

                if (keyboardSelectedItemPosition && keyboardSelectedItemPosition > 0) {
                    setKeyboardSelectedItemPosition(keyboardSelectedItemPosition - 1);
                }

                // if there are items in the dropdown, dont allow the cursor jumping
                if (options && options.length > 0) {
                    event.preventDefault();
                }

                break;
            case 'Enter':
                if (openDropdown && keyboardSelectedItemPosition !== undefined && keyboardSelectedItemPosition >= 0) {
                    event.preventDefault();
                    if (options) {
                        onClickOnItem(
                            options[keyboardSelectedItemPosition].id,
                            options[keyboardSelectedItemPosition].value
                        );
                    }

                    setKeyboardSelectedItemPosition(undefined);
                    setOpenDropdown(false);
                    break;
                }
        }
    };

    useEffect(() => {
        if (props.dropdownOptions) {
            setOptions(props.dropdownOptions);
        }
    }, [props.dropdownOptions]);

    /**
     * Handles a click outside the dropdown.
     * Closes the dropdown if it is open.
     *
     * @param event
     */
    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (dropDownRef.current && dropDownRef.current.contains(event.target as Node)) {
                return;
            }

            setOpenDropdown(false);
        },
        [dropDownRef]
    );

    /**
     * Register event listener for the case that the user clicks outside of the dropdown menu in order to close it
     */
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);

    /**
     * Set the new 'value' if the corresponding prop has changed.
     */
    useEffect(() => {
        if (props.value && value) {
            // TODO: refactor; this workaround is used to ensure that the 'value' state and 'props.value' are equal.
            setValue(props.value);
        }
    }, [props.value, value]);

    /**
     * Set given initial value to value state
     */
    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue);
        }
    }, [props.initialValue]);

    return (
        <div className="input-select-wrapper" ref={dropDownRef}>
            {props.label && (
                <Label className={'input-select-label'} size={4}>
                    {props.label}
                </Label>
            )}
            <FormInput
                readonly={props.readonly}
                autoFocus
                name={props.name}
                size={props.size || 'medium'}
                error={props.error}
                value={value}
                onChange={onChangeInputSelectInput}
                onClick={openCloseDropdown}
                iconRight={'ChevronDown'}
                onKeyDown={onKeyDownPress}
            />
            {openDropdown && options && options.length > 0 && (
                <div className="input-select-dropdown">
                    {options?.map((option, index) => {
                        return (
                            <div
                                key={index}
                                onMouseOver={() => setKeyboardSelectedItemPosition(index)}
                                onClick={() => onClickOnItem(option.id, option.value)}
                                className={`input-select-dropdown-option ${
                                    keyboardSelectedItemPosition === index && 'input-select-dropdown-option-keyboard'
                                } ${value === option.value && 'input-selected'}`}
                            >
                                {option.icon}
                                {option.value}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
