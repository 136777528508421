/**
 * Format the date object or string to get a string in the form day.month.year
 * @param date
 */
export const formatDate = (date: Date | string | undefined): string => {
    if (date) {
        if (typeof date === 'string') {
            return new Date(date).toLocaleDateString('de-DE', { month: '2-digit', day: '2-digit', year: 'numeric' });
        } else {
            return date.toLocaleDateString('de-DE', { month: '2-digit', day: '2-digit', year: 'numeric' });
        }
    } else {
        return '';
    }
};

export const getLocaleMonth = (month: number) => {
    return new Date(new Date().getFullYear(), month + 1, 0).toLocaleString('default', { month: 'long' });
};
