import styles from './CheckList.module.css';
import { ReactNode, useState } from 'react';
import { Checkbox } from '../Checkbox/Checkbox';

/**
 * Describes one item in the list.
 */
interface CheckListItem {
    // Used to identify the item. Passed in the selection callback eg.
    identifier: number;

    // The selectable content. Can be anything, a Text or Compoonent
    content: ReactNode;
}

/**
 * Props for the CheckList component.
 */
interface CheckListProps {
    // The items to select from
    items: CheckListItem[];

    // Called if the selection changes. The selectedItems is the array of identifiers given in the items.
    onChange: (selectedItems: number[]) => void;

    // If this is set to true, the checkbox to select all items will not be shown
    hideSelectAll?: boolean;

    // Currently selected item identifiers
    selectedItems: number[];

    // Optional class name that is added to the items container
    itemsContainerClassName?: string;
}

/**
 * Shows a list of selectable items given by the props.
 *
 * @param props
 * @constructor
 */
export const CheckList = (props: CheckListProps) => {
    const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

    /**
     * Called if the value for some checkbox of an item changes.
     * Updates the list of selected items and calls the callback given by the props.
     *
     * @param identifier
     */
    const onChangeCheckbox = (identifier: number, value: boolean) => {
        if (value) {
            props.onChange([...props.selectedItems, identifier]);
        } else {
            props.onChange(props.selectedItems.filter((itemIdentifier) => itemIdentifier !== identifier));
        }
    };

    /**
     * Called if the checkbox to select all items changes.
     * If it is set to true, all items will be selected.
     * Otherwise all items will be unselected.
     *
     * @param value
     */
    const onChangeSelectAll = (value: boolean) => {
        if (!value) {
            props.onChange([]);
        } else {
            props.onChange(props.items.map((item) => item.identifier));
        }

        setSelectAllChecked(value);
    };

    return (
        <div className={styles.container}>
            {!props.hideSelectAll && (
                <div className={styles.selectAllContainer}>
                    <Checkbox value={selectAllChecked} onChange={onChangeSelectAll} label={'Alle auswählen'} />
                </div>
            )}
            <div
                className={`${styles.itemsContainer} ${
                    props.itemsContainerClassName ? props.itemsContainerClassName : ''
                }`}
            >
                {props.items.map((item) => (
                    <Checkbox
                        key={item.identifier}
                        value={props.selectedItems.includes(item.identifier)}
                        onChange={(value: boolean) => onChangeCheckbox(item.identifier, value)}
                        label={item.content}
                    />
                ))}
            </div>
        </div>
    );
};
