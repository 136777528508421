import { ObjectiveAgreementStatus } from '../../models/ObjectiveAgreement/Type';
import './ObjectiveAgreementStatusChange.css';

interface ObjectiveAgreementStatusChangeProps {
    onClick: (arg: ObjectiveAgreementStatus) => void;
}

export const ObjectiveAgreementStatusChange = (props: ObjectiveAgreementStatusChangeProps) => {
    return (
        <div className="objective-agreement-status-change">
            <div onClick={() => props.onClick('open')}>Offen</div>
            <div onClick={() => props.onClick('not_achieved')}>Nicht abgeschlossen</div>
            <div onClick={() => props.onClick('partially_achieved')}>Teilweise erfolgreich abgeschlossen</div>
            <div onClick={() => props.onClick('full_achieved')}>Erfolgreich abgeschlossen</div>
        </div>
    );
};
