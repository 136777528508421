import { Formik } from 'formik';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { formatDate } from '../../models/Date/Date';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import {
    estimates,
    mapNeedForActionEstimate,
    mapNeedForActionPriority,
    mapNeedForActionTendency,
    needForActionTendency
} from '../../models/NeedForAction/NeedForAction';
import {
    NeedForAction,
    NeedForActionComment,
    NeedForActionEstimate,
    NeedForActionNewObjectiveAgreement,
    NeedForActionProgress,
    NeedForActionSuggestionTypes,
    NeedForActionTendency
} from '../../models/NeedForAction/Type';
import { ObjectiveAgreement } from '../../models/ObjectiveAgreement/Type';
import { showSuccessMessage, throwError, throwInfo } from '../../models/Toasts/Toasts';
import { useUser } from '../../models/User/State';
import { Button } from '../Button/Button';
import { Datepicker } from '../Datepicker/Datepicker';
import { EmptyDatepicker } from '../EmptyDatepicker/EmptyDatepicker';
import { FullScreenModal } from '../FullScreenModal/FullScreenModal';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { InputSelect } from '../InputSelect/InputSelect';
import { Label } from '../Label/Label';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { SingleComment } from '../SingleComment/SingleComment';
import { SingleObjective } from '../SingleObjective/SingleObjective';
import { Tag } from '../Tag/Tag';
import './UpdateNeedForActionForm.css';
import { Modal } from '../Modal/Modal';
import { useObjectiveAgreementsApi } from '../../api/useObjectiveAgreementsApi';
import { useNeedForActionsApi } from '../../api/useNeedForActionsApi';

interface UpdateNeedForActionFormProps {
    setFieldValue: FormikSetFieldValue;
    handleChange: FormikHandleChange;
    values: NeedForAction | NeedForActionNewObjectiveAgreement;
    needForAction: NeedForAction;
    defineObjectiveAgreement?: boolean;
    onGoBack: () => void;
    correspondingSuggestion?: NeedForActionSuggestionTypes;
    objectiveAgreements?: ObjectiveAgreement[];
    multipleAnswers?: boolean;
    onClose?: () => void;
    dontShowGoBack?: boolean;
}

export const UpdateNeedForActionForm = (props: UpdateNeedForActionFormProps) => {
    const [needForActionComments, setNeedForActionComments] = useState<NeedForActionComment[]>([]);
    const [objectiveAgreements, setObjectiveAgreements] = useState<ObjectiveAgreement[]>([]);
    const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
    const priorities = [
        { value: 'Hoch', id: 'high' },
        { value: 'Mittel', id: 'medium' },
        {
            value: 'Niedrig',
            id: 'low'
        },
        { value: 'Information', id: 'information' }
    ];
    const { apiObjectiveAgreementSearch, apiCreateObjectiveAgreement } = useObjectiveAgreementsApi();
    const { apiNeedForActionsCommentsSearch, apiCreateNeedForActionComment, apiUpdateNeedForAction } =
        useNeedForActionsApi();
    const onShowArchiveModal = () => {
        setShowArchiveModal(!showArchiveModal);
    };

    const currentUser = useUser((x) => x.currentUser);

    /**
     * Change estimate field
     * @param estimate
     */
    const onChangeEstimates = (estimate: NeedForActionEstimate | undefined) => {
        props.setFieldValue('estimate', estimate);
    };

    /**
     * change priority field
     * @param priority
     */
    const onChangeNeedForActionPriority = (priority: string | undefined) => {
        props.setFieldValue('priority', priority);
    };

    /**
     * Change deadline of an objective agreement
     * @param date
     */
    const onChangeDeadline = (date: Date) => {
        props.setFieldValue('objectiveAgreement.deadline', date);
    };

    /**
     * Create objective agreement
     */
    const onCreateObjectiveAgreement = async () => {
        try {
            if ('objectiveAgreement' in props.values && props.values.objectiveAgreement.text) {
                await apiCreateObjectiveAgreement(props.values.objectiveAgreement);
                props.setFieldValue('objectiveAgreement.text', '');
                requestComments().then();
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Load and fetch comments as well as objective agreements
     */
    const requestComments = useCallback(async () => {
        const comments = await apiNeedForActionsCommentsSearch({
            filter: {
                field: 'need_for_action_id',
                operator: 'eq',
                value: props.needForAction.id
            }
        });
        const goals = await apiObjectiveAgreementSearch({
            filter: {
                field: 'need_for_action_id',
                operator: 'eq',
                value: props.needForAction.id
            }
        });
        setObjectiveAgreements(goals.results);
        setNeedForActionComments(comments.results);
    }, [apiNeedForActionsCommentsSearch, apiObjectiveAgreementSearch, props.needForAction.id]);

    /**
     * load comments and objective agreements on initial load
     */
    useEffect(() => {
        requestComments().then();
    }, [requestComments]);

    /**
     * Render the comments and objective agreements
     */
    const renderCommentsAndObjectiveAgreement = () => {
        if (needForActionComments && objectiveAgreements) {
            let commentsAndObjectives: (NeedForActionComment | ObjectiveAgreement | NeedForActionProgress)[] = [];
            // merge task comments and task assignments into one array for displaying in the comment section
            commentsAndObjectives = commentsAndObjectives.concat(needForActionComments, objectiveAgreements);
            if (props.needForAction.progress && props.needForAction.progress.length > 0) {
                commentsAndObjectives = commentsAndObjectives.concat(props.needForAction.progress);
            }

            // sort the array by oldest first
            commentsAndObjectives.sort(
                (
                    a: ObjectiveAgreement | NeedForActionComment | NeedForActionProgress,
                    b: ObjectiveAgreement | NeedForActionComment | NeedForActionProgress
                ) => {
                    let firstElement;
                    let secondElement;
                    if (a.createdAt) {
                        firstElement = a.createdAt;
                    } else {
                        firstElement = a.date;
                    }

                    if (b.createdAt) {
                        secondElement = b.createdAt;
                    } else {
                        secondElement = b.date;
                    }

                    return new Date(secondElement).getTime() - new Date(firstElement).getTime();
                }
            );

            return commentsAndObjectives.map((commentsAndObjectives, index) => {
                return (
                    <div key={commentsAndObjectives.id} className="update-need-for-action-comments">
                        {'deadline' in commentsAndObjectives ? (
                            <Formik
                                initialValues={commentsAndObjectives}
                                validateOnChange={false}
                                validateOnBlur={false}
                                enableReinitialize
                                // We need to pass a handler here because formik expects one, but we do not want to use the formik submit mechanism
                                onSubmit={() => console.log('Form submit.')}
                            >
                                {({ values, handleChange, setFieldValue }) => (
                                    <>
                                        <SingleObjective
                                            key={`${values.name} ${index}`}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            reload={requestComments}
                                            objective={values}
                                        />
                                    </>
                                )}
                            </Formik>
                        ) : (
                            <Formik
                                initialValues={commentsAndObjectives}
                                validateOnChange={false}
                                validateOnBlur={false}
                                enableReinitialize
                                // We need to pass a handler here because formik expects one, but we do not want to use the formik submit mechanism
                                onSubmit={() => console.log('Form submit.')}
                            >
                                {({ values, handleChange }) => (
                                    <>
                                        <SingleComment
                                            handleChange={handleChange}
                                            reload={requestComments}
                                            key={index}
                                            comment={values}
                                        />
                                    </>
                                )}
                            </Formik>
                        )}
                    </div>
                );
            });
        } else {
            return <LoadingSpinner />;
        }
    };

    /**
     * Call back when the user presses enter in the input field
     * @param event
     */
    const onChangeObjectiveAgreementWithEnter = (event: React.KeyboardEvent<HTMLDivElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            onCreateObjectiveAgreement().then();
        }
    };

    /**
     * Call back when the user clicks on the save button
     */
    const onSubmit = async () => {
        const progressArray: NeedForActionProgress[] = props.values.progress ? props.values.progress : [];
        try {
            await onCreateObjectiveAgreement();
            if (props.values.newComment) {
                await apiCreateNeedForActionComment({
                    text: props.values.newComment,
                    date: new Date(),
                    needForActionId: props.needForAction.id,
                    user: {
                        id: currentUser?.id
                    }
                });
            }

            // check if the progress values are existing and different from the last entry
            if (props.values.newProgressTendency && props.values.newProgressDate) {
                if (props.needForAction.progress && props.needForAction.progress.length > 0) {
                    if (
                        props.values.newProgressTendency !==
                            props.needForAction.progress[props.needForAction.progress.length - 1].tendency &&
                        props.values.newProgressDate !==
                            props.needForAction.progress[props.needForAction.progress.length - 1].date
                    ) {
                        progressArray.push({
                            tendency: props.values.newProgressTendency,
                            date: props.values.newProgressDate
                        });
                    }
                } else {
                    progressArray.push({
                        tendency: props.values.newProgressTendency,
                        date: props.values.newProgressDate
                    });
                }
            }

            await apiUpdateNeedForAction(props.needForAction.id, {
                ...props.values,
                progress: progressArray
            });
            showSuccessMessage('Handlungsbedarf aktualisiert.');
            props.onGoBack();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Call back when user clicks the enter button in the input field
     * @param event
     */
    const onEnterInput = async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        try {
            const comment = props.values.newComment;
            if (event.key === 'Enter' && comment) {
                await apiCreateNeedForActionComment({
                    text: comment,
                    date: new Date(),
                    needForActionId: props.needForAction.id,
                    user: {
                        id: currentUser?.id
                    }
                });
                props.setFieldValue('newComment', '');
                requestComments().then();
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Change answer when multi value is allowed
     * @param answer
     */
    const onChangeAnswer = (answer: MultiValue<{ label: string | undefined; value: string | undefined }>) => {
        props.setFieldValue(
            'options',
            answer.map((item) => {
                return {
                    name: props.correspondingSuggestion?.options[0].name,
                    type: props.correspondingSuggestion?.options[0].type,
                    value: item.value
                };
            })
        );
    };

    /**
     * Close need for action in case there are no more open goals
     */
    const onFinishNeedForAction = async () => {
        const goals = await apiObjectiveAgreementSearch({
            filter: {
                field: 'need_for_action_id',
                operator: 'eq',
                value: props.needForAction.id
            }
        });

        if (!goals.results.some((goal) => goal.achieved === 'open')) {
            try {
                await apiUpdateNeedForAction(props.needForAction.id, { closed: true });
                showSuccessMessage('Handlungsbedarf wurde geschlossen');
                onShowArchiveModal();
                if (props.onClose) {
                    props.onClose();
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            throwInfo('Bitte schließen Sie erst alle Ziele ab');
        }
    };

    /**
     * If a need for action was close, reopen it
     */
    const onActivateNeedForAction = async () => {
        try {
            await apiUpdateNeedForAction(props.needForAction.id, { closed: false });
            showSuccessMessage('Handlungsbedarf erneut geöffnet');
            if (props.onClose) {
                props.onClose();
            }
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Add a new progress tendency manually to formik
     * @param tendency
     */
    const onChangeProgressTendency = (tendency: NeedForActionTendency | undefined) => {
        props.setFieldValue('newProgressTendency', tendency);
    };

    /**
     * Add a new progress date to formik
     * @param date
     */
    const onChangeProgressDate = (date: Date) => {
        props.setFieldValue('newProgressDate', date);
    };

    /**
     * Function that renders the options an user can enter based on the need for action
     */
    const renderNeedForActionsForm = () => {
        const elements: ReactElement[] = [];
        props.correspondingSuggestion?.options.forEach((option, index) => {
            if (option.type === 'enum') {
                elements.push(
                    <InputSelect
                        label={'Antwort'}
                        dropdownOptions={props.correspondingSuggestion?.options
                            .find((option) => option.type === 'enum')
                            ?.enumValues?.map((value) => {
                                return { value: value, id: value };
                            })}
                        initialValue={
                            props.values.options.find(
                                (option) => option.type === props.correspondingSuggestion?.options[index].type
                            )?.value
                        }
                        onChange={(arg) =>
                            props.setFieldValue(`options[${index}]`, {
                                name: props.correspondingSuggestion?.options[index].name,
                                type: props.correspondingSuggestion?.options[index].type,
                                value: arg
                            })
                        }
                    />
                );
            } else if (option.type === 'boolean') {
                elements.push(
                    <InputSelect
                        label={'Antwort'}
                        dropdownOptions={[
                            { value: 'Ja', id: 'Ja' },
                            { value: 'Nein', id: 'Nein' }
                        ]}
                        initialValue={
                            props.values.options.find(
                                (option) => option.type === props.correspondingSuggestion?.options[index].type
                            )?.value
                        }
                        onChange={(arg) =>
                            props.setFieldValue(`options[${index}]`, {
                                name: props.correspondingSuggestion?.options[index].name,
                                type: props.correspondingSuggestion?.options[index].type,
                                value: arg
                            })
                        }
                    />
                );
            } else if (option.type === 'string') {
                elements.push(
                    <Input
                        label={option.name}
                        value={
                            props.values.options.find(
                                (option) => option.type === props.correspondingSuggestion?.options[index].type
                            )?.value
                        }
                        onChange={(event) =>
                            props.setFieldValue(`options[${index}]`, {
                                name: props.correspondingSuggestion?.options[index].name,
                                type: props.correspondingSuggestion?.options[index].type,
                                value: event.target.value
                            })
                        }
                    />
                );
            } else if (option.type === 'date') {
                const optionValue = props.values.options.find(
                    (option) => option.type === props.correspondingSuggestion?.options[index].type
                )?.value;

                elements.push(
                    <Datepicker
                        label={'Antwort'}
                        value={optionValue ? new Date(optionValue) : new Date()}
                        onChange={(date) =>
                            props.setFieldValue(`options[${index}]`, {
                                name: props.correspondingSuggestion?.options[index].name,
                                type: props.correspondingSuggestion?.options[index].type,
                                value: date
                            })
                        }
                    />
                );
            }
        });
        return elements;
    };

    /**
     * Change objectiveAgreement manually
     * @param date
     */
    const onChangeDate = (date: Date) => {
        props.setFieldValue('objectiveAgreement.date', date);
    };

    return (
        <>
            {' '}
            <FullScreenModal
                key={props.needForAction.id}
                footer={
                    <>
                        <Button
                            type={'primary'}
                            size={'small'}
                            buttonStyle={'outline'}
                            text={'Abbrechen'}
                            onClick={props.onClose}
                        />
                        <Button
                            type={'primary'}
                            size={'medium'}
                            buttonStyle={'filled'}
                            text={'Speichern'}
                            onClick={onSubmit}
                        />
                    </>
                }
            >
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '42px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={'create-need-for-action-head'}>
                                {!props.dontShowGoBack && <Icon type={'ArrowLeft'} onClick={props.onGoBack} />}
                                <h5>{props.needForAction?.needForActionType}</h5>
                            </div>
                            {props.needForAction.closed ? (
                                <Button
                                    onClick={onActivateNeedForAction}
                                    type={'primary'}
                                    size={'small'}
                                    buttonStyle={'outline'}
                                    text={'Handlungsbedarf erneut öffnen'}
                                />
                            ) : (
                                <Button
                                    onClick={onShowArchiveModal}
                                    type={'primary'}
                                    size={'small'}
                                    buttonStyle={'outline'}
                                    text={'Handlungsbedarf archivieren'}
                                />
                            )}
                        </div>
                        <div className="create-need-for-action-form">
                            <div className="create-need-for-action-form-body">
                                {!props.multipleAnswers && props.values.options && renderNeedForActionsForm()}

                                {props.multipleAnswers && (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Label className={'input-select-label'} size={3}>
                                            Antwort
                                        </Label>
                                        <Select
                                            options={props.correspondingSuggestion?.options[0].enumValues?.map(
                                                (option) => {
                                                    return {
                                                        value: option,
                                                        label: option
                                                    };
                                                }
                                            )}
                                            isMulti
                                            onChange={onChangeAnswer}
                                            placeholder={'Antwort'}
                                            value={props.values.options.map((option) => {
                                                return {
                                                    label: option.value,
                                                    value: option.value
                                                };
                                            })}
                                        />
                                    </div>
                                )}
                                <div>
                                    {props.values.priority !== 'information' && (
                                        <InputSelect
                                            label={'Einschätzung'}
                                            initialValue={mapNeedForActionEstimate(props.values.estimate)}
                                            dropdownOptions={estimates.map((estimate) => {
                                                return {
                                                    value: mapNeedForActionEstimate(estimate),
                                                    id: estimate
                                                };
                                            })}
                                            onChange={onChangeEstimates}
                                        />
                                    )}
                                </div>
                                <InputSelect
                                    label={'Priorität'}
                                    initialValue={mapNeedForActionPriority(props.values.priority)}
                                    dropdownOptions={priorities}
                                    onChange={onChangeNeedForActionPriority}
                                />
                            </div>
                            <div className="update-need-for-action-form-progress">
                                <InputSelect
                                    label={'Entwicklung'}
                                    dropdownOptions={needForActionTendency.map((tendency) => {
                                        return {
                                            value: mapNeedForActionTendency(tendency),
                                            id: tendency
                                        };
                                    })}
                                    initialValue={
                                        props.values.newProgressTendency
                                            ? mapNeedForActionTendency(props.values.newProgressTendency)
                                            : props.values?.progress && props.values?.progress.length > 0
                                              ? mapNeedForActionTendency(
                                                    props.values.progress[props.values.progress.length - 1].tendency
                                                )
                                              : ''
                                    }
                                    onChange={onChangeProgressTendency}
                                />
                                <Datepicker
                                    onChange={onChangeProgressDate}
                                    label={'Datum'}
                                    value={
                                        props.values.newProgressDate
                                            ? props.values.newProgressDate
                                            : new Date(props.values.progress[props.values.progress.length - 1].date)
                                    }
                                />
                            </div>

                            {(props.needForAction ||
                                (props.objectiveAgreements && props.objectiveAgreements.length > 0)) && (
                                <div className={'update-need-for-action-tags'}>
                                    <Tag
                                        className={`tag-need-for-action tag-${props.needForAction.category
                                            ?.toLowerCase()
                                            .replace(/[^A-Za-z]+/g, '')}`}
                                    >
                                        <Label size={3}>{props.needForAction.category}</Label>
                                    </Tag>
                                    {props.objectiveAgreements?.map((objectiveAgreement) => {
                                        return (
                                            <Tag
                                                key={objectiveAgreement.id}
                                                className="tag-need-for-action tag-support-plan"
                                            >
                                                <Label size={3}> {objectiveAgreement.supportPlan.name} </Label>
                                            </Tag>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="update-task-details-render-comments">
                        <div className="update-task-details-render-comments-border">
                            {props.defineObjectiveAgreement && 'objectiveAgreement' in props.values ? (
                                <div className="update-need-for-action-comments">
                                    <Icon type={'Circle'} />
                                    <Datepicker
                                        onChange={onChangeDate}
                                        initialDate={props.values.objectiveAgreement.date}
                                        value={props.values.objectiveAgreement.date}
                                        position={'fixed'}
                                    >
                                        {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                            <>
                                                <Icon
                                                    className="update-need-for-action-deadline-icon"
                                                    type={'Calendar'}
                                                    onClick={openDatepicker}
                                                />
                                                <input
                                                    className="input"
                                                    value={formatDate(
                                                        new Date(
                                                            'objectiveAgreement' in props.values
                                                                ? props.values.objectiveAgreement.date
                                                                : ''
                                                        )
                                                    )}
                                                    onChange={onChangeDateInForm}
                                                    onPaste={onPasteDate}
                                                    onKeyDown={onPressEnterSubmit}
                                                />
                                            </>
                                        )}
                                    </Datepicker>
                                    <EmptyDatepicker
                                        initialDate={props.values.objectiveAgreement.deadline}
                                        onChange={onChangeDeadline}
                                        value={props.values.objectiveAgreement.deadline}
                                    />
                                    <Input
                                        name="objectiveAgreement.text"
                                        value={props.values.objectiveAgreement.text}
                                        className={'width-100'}
                                        onKeyDown={onChangeObjectiveAgreementWithEnter}
                                        onChange={props.handleChange}
                                        placeholder={'Ziel hinzufügen'}
                                    />
                                    <Icon type={'ArrowRight'} onClick={onCreateObjectiveAgreement} />
                                </div>
                            ) : (
                                <div className="create-need-for-action-comment">
                                    <div className="create-need-for-action-comment-icon">
                                        <Icon type={'DocumentText'} />
                                        <div className={'p4-medium'}>{formatDate(new Date())}</div>
                                    </div>
                                    <Input
                                        className="update-task-form-text"
                                        name="newComment"
                                        placeholder={'Notizen hinzufügen'}
                                        value={props.values.newComment}
                                        onChange={props.handleChange}
                                        onKeyDown={onEnterInput}
                                        icon={<Icon type={'Enter'} />}
                                    />
                                </div>
                            )}
                            <div className="update-need-for-action-comment-section">
                                {renderCommentsAndObjectiveAgreement()}
                            </div>
                        </div>
                    </div>
                </>
            </FullScreenModal>
            {showArchiveModal && (
                <Modal
                    show={showArchiveModal}
                    header={'Handlungsbedarf archivieren'}
                    buttons={{
                        primary: { text: 'Ja, Handlungsbedarf archivieren', onClick: onFinishNeedForAction },
                        secondary: { text: 'Abbrechen', onClick: onShowArchiveModal }
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div>Sie sind dabei, einen Handlungsbedarf zu archivieren.</div>
                        <div>
                            Archivierte Handlungsbedarfe können nicht weiter bearbeitet werden. Nutzen Sie stattdessen
                            den &quot;Schließen&quot;-Button, um das Fenster zu schließen, ohne den Handlungsbedarf zu
                            archivieren.
                        </div>
                        <div>Sind Sie sicher, dass Sie den Handlungsbedarf archivieren wollen?</div>
                    </div>
                </Modal>
            )}
        </>
    );
};
