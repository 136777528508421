import { Course, CourseSubmit } from '../../models/Course/Type';
import { mapCourseCategories } from '../../models/Course/Course';
import { formatDate } from '../../models/Date/Date';
import styles from './CourseListeItem.module.css';

/**
 * Props for the CourseListItem componemt.
 */
interface CourseListItemProps {
    // The course to show
    course: Course | CourseSubmit;
}

/**
 * Shows the course specified by the props as list item.
 *
 * @param props
 * @constructor
 */
export const CourseListItem = (props: CourseListItemProps) => {
    return (
        <div className={styles.container}>
            <div>
                <div>{mapCourseCategories(props.course.category)}</div>
                <div className={styles.name}>&quot;{props.course.name}&quot;</div>
            </div>
            <div>
                <div className={styles.date}>{formatDate(props.course.date)}</div>
            </div>
        </div>
    );
};
