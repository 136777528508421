
const digest = 'e06193bb08368cf9b670a6c1ae61d59ceebb6dcae9b4837d833a31065e1ee2c9';
const css = `/* font *//* background *//* borders *//* fonts *//* paddings *//* margins and gaps *//* borders */

._container_kr2au_6 {
    position: relative;
    display: flex;
    flex-direction: column;
}

._innerContainer_kr2au_12 {
    position: absolute;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 0.6rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

._innerContainer_kr2au_12.top-right {
    top: 0;
    right: 0;
}

._innerContainer_kr2au_12.top-left {
    top: 0;
    left: 0;
}

._innerContainer_kr2au_12.bottom-right {
    bottom: 0;
    right: 0;
}

._innerContainer_kr2au_12.bottom-left {
    bottom: 0;
    left: 0;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"colors":"\"../../styles/colors.module.css\"","distances":"\"../../styles/distances.module.css\"","colorBorderLight":"lightgrey","colorBackgroundModal":"white","sizeBorderSmall":"1px","radiusBorderMedium":"0.6rem","paddingMedium":"0.5rem","container":"_container_kr2au_6","innerContainer":"_innerContainer_kr2au_12"};
export { css, digest };
  