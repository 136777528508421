import React, { useState, MouseEvent, useMemo } from 'react';
import useSWR from 'swr';
import { KeyedMutator } from 'swr';
import { useAppSelector } from '../../hooks';
import { formatDate } from '../../models/Date/Date';
import { NeedForAction, NeedForActionComment, NeedForActionSuggestion } from '../../models/NeedForAction/Type';
import { mapObjectiveAgreementStatus } from '../../models/ObjectiveAgreement/ObjectiveAgreement';
import { ObjectiveAgreement, ObjectiveAgreementStatus } from '../../models/ObjectiveAgreement/Type';
import { SupportPlan } from '../../models/SupportPlans/Type';
import { throwError } from '../../models/Toasts/Toasts';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';
import { ObjectiveAgreementStatusChange } from '../ObjectiveAgreementStatusChange/ObjetiveAgreementStatusChange';
import { Tag } from '../Tag/Tag';
import { UpdateNeedForAction } from '../UpdateNeedForAction/UpdateNeedForAction';
import './ObjectiveAgreementCard.css';
import { useObjectiveAgreementsApi } from '../../api/useObjectiveAgreementsApi';
import { useNeedForActionsApi } from '../../api/useNeedForActionsApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { sortByDate } from '../../utils/DateUtils';

interface ObjectiveAgreementCardProps {
    objectiveAgreement: ObjectiveAgreement;
    reloadObjectiveAgreements?: KeyedMutator<ObjectiveAgreement[]>;
    supportPlan?: SupportPlan;
    onAddObjectiveAgreementToNewSupportPlan?: (objectiveAgreement: ObjectiveAgreement) => void;
    dontShowGoBack?: boolean;
}

export const ObjectiveAgreementCard = (props: ObjectiveAgreementCardProps) => {
    const [selectNeedForAction, setSelectNeedForAction] = useState<NeedForAction>();
    const participant = useAppSelector((x) => x.participant.selectedParticipant);
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { data: comments } = useSWR<NeedForActionComment[]>([
        backendUrl,
        `need_for_actions/${props.objectiveAgreement.needForActionId}/comments`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: suggestions } = useSWR<NeedForActionSuggestion[]>([
        backendUrl,
        'need_for_actions/suggestions',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { apiUpdateObjectiveAgreement } = useObjectiveAgreementsApi();
    const { apiNeedForActionsSearch } = useNeedForActionsApi();

    const [changeStatus, setChangeStatus] = useState<boolean>(false);

    const onChangeStatus = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setChangeStatus(!changeStatus);
    };

    const onRequestStatusChange = async (status: ObjectiveAgreementStatus) => {
        try {
            if (props.objectiveAgreement) {
                await apiUpdateObjectiveAgreement(props.objectiveAgreement?.id, { achieved: status });
                if (props.reloadObjectiveAgreements) {
                    props.reloadObjectiveAgreements().then();
                }
            }
        } catch (e) {
            throwError();
            console.log(e);
        }

        setChangeStatus(!changeStatus);
    };

    const onShowNeedForAction = async () => {
        if (!selectNeedForAction) {
            try {
                const needForAction = await apiNeedForActionsSearch({
                    filter: {
                        field: 'id',
                        operator: 'eq',
                        value: props.objectiveAgreement?.needForActionId
                    }
                });
                setSelectNeedForAction(needForAction.results[0]);
            } catch (e) {
                throwError();
            }
        } else {
            setSelectNeedForAction(undefined);
        }
    };

    const onAddObjectiveAgreementToNewSupportPlan = () => {
        if (props.onAddObjectiveAgreementToNewSupportPlan) {
            props.onAddObjectiveAgreementToNewSupportPlan(props.objectiveAgreement);
        }
    };

    /**
     * Called if the fetched comments change.
     * Orders the comments by date, hence the next comments are shown first.
     */
    const orderedComments = useMemo(() => {
        if (!comments) return undefined;

        return sortByDate(comments, 'date', 'asc');
    }, [comments]);

    return (
        <>
            <Card onClick={onShowNeedForAction}>
                <div
                    className={`objective-agreement-card-top ${
                        orderedComments && orderedComments.length > 0 && 'objective-agreement-card-top-comment'
                    }`}
                >
                    <div style={{ display: 'flex' }}>
                        <div className={'objective-agreement-card-top-text'}>
                            <div className="p3-medium">{props.objectiveAgreement?.text}</div>
                            <div className={'objective-agreement-top-tags'}>
                                <div className={'objective-agreement-top-created-at'}>
                                    <Icon type={'Calendar'} />
                                    <Label size={3}>
                                        {props.objectiveAgreement.deadline
                                            ? formatDate(new Date(props.objectiveAgreement.deadline))
                                            : '-'}
                                    </Label>
                                </div>
                                <Tag
                                    onClick={onChangeStatus}
                                    className={`objective-agreement-tag ${props.objectiveAgreement?.achieved}`}
                                >
                                    <svg
                                        width="6"
                                        height="7"
                                        viewBox="0 0 6 7"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect y="0.5" width="6" height="6" rx="2" fill="#10B981" />
                                    </svg>
                                    <Label size={3}>
                                        {mapObjectiveAgreementStatus(props.objectiveAgreement?.achieved)}
                                    </Label>
                                    {changeStatus && !participant?.measuresParticipants?.inactive && (
                                        <ObjectiveAgreementStatusChange onClick={onRequestStatusChange} />
                                    )}
                                </Tag>
                                <Tag
                                    className={`tag-need-for-action tag-${props.objectiveAgreement.name
                                        .toLowerCase()
                                        .replace(/[^A-Za-z]+/g, '')}`}
                                >
                                    <div>{props.objectiveAgreement.name}</div>
                                </Tag>
                            </div>
                        </div>
                        {props.supportPlan?.closed && props.objectiveAgreement.achieved !== 'full_achieved' && (
                            <Button
                                type={'primary'}
                                size={'medium'}
                                buttonStyle={'link'}
                                text={'Zu neuem Förderplan hinzufügen'}
                                secondIcon={<Icon type={'ArrowRight'} />}
                                className="ml-auto"
                                onClick={onAddObjectiveAgreementToNewSupportPlan}
                            />
                        )}
                    </div>
                </div>
                {orderedComments && orderedComments.length > 0 && (
                    <div className={'objective-agreement-card-bottom'}>
                        {orderedComments.slice(0, 2).map((comment: NeedForActionComment) => {
                            return (
                                <div key={comment.id} className="objective-agreement-card-bottom-comments">
                                    <div className="p5-regular"> {formatDate(new Date(comment.date))} </div>
                                    <div className="p5-regular objective-agreement-card-bottom-comments-text">
                                        {comment.text}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </Card>
            {selectNeedForAction && (
                <UpdateNeedForAction
                    dontShowGoBack={props.dontShowGoBack}
                    multipleAnswers={selectNeedForAction.needForActionType.includes('Mehrfachnennung möglich')}
                    onClose={onShowNeedForAction}
                    supportPlan={props.supportPlan}
                    defineObjectiveAgreement
                    needForAction={selectNeedForAction}
                    onGoBack={onShowNeedForAction}
                    correspondingSuggestion={suggestions
                        ?.find((suggestion) =>
                            suggestion.needForActionTypes.find(
                                (type) => type.needForActionType === selectNeedForAction?.needForActionType
                            )
                        )
                        ?.needForActionTypes.find(
                            (type) => type.needForActionType === selectNeedForAction?.needForActionType
                        )}
                />
            )}
        </>
    );
};
